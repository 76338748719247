import {
  CloudProviderAccess,
  CloudProviderAccessAWSAccountDetails,
  CloudProviderAccessAWSIAMRole,
  CloudProviderAccessAzureAccountDetails,
  CloudProviderAccessAzureServicePrincipal,
  CloudProviderAccessAzureServicePrincipalCreateRequest,
  CloudProviderAccessGCPServiceAccount,
} from '@packages/types/nds/cloudProviderAccess';
import { CloudProvider } from '@packages/types/nds/provider';

import fetchWrapper from '../fetchWrapper';

function fetchCloudProviderAccess(groupId: string): Promise<CloudProviderAccess> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function deleteCloudProviderAccessAWSIAMRole(groupId: string, role: CloudProviderAccessAWSIAMRole): Promise<Response> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess/${CloudProvider.AWS}/${role.roleId}`, {
    method: 'DELETE',
  });
}

function deleteCloudProviderAccessAzureServicePrincipal(
  groupId: string,
  servicePrincipal: CloudProviderAccessAzureServicePrincipal
): Promise<Response> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess/${CloudProvider.AZURE}/${servicePrincipal._id}`, {
    method: 'DELETE',
  });
}

function deleteCloudProviderAccessGCPServiceAccount(
  groupId: string,
  serviceAccount: CloudProviderAccessGCPServiceAccount
): Promise<Response> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess/${CloudProvider.GCP}/${serviceAccount.roleId}`, {
    method: 'DELETE',
  });
}

function postCloudProviderAccessAWSIAMRole(
  groupId: string,
  awsIamRole: Partial<CloudProviderAccessAWSIAMRole> = {}
): Promise<CloudProviderAccessAWSIAMRole> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess`, {
    method: 'POST',
    body: JSON.stringify({
      ...awsIamRole,
      providerName: CloudProvider.AWS,
    }),
  }).then((resp) => resp.json());
}

function postCloudProviderAccessAzureServicePrincipal(
  groupId: string,
  azureServicePrincipal: CloudProviderAccessAzureServicePrincipalCreateRequest
): Promise<CloudProviderAccessAzureServicePrincipal> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess`, {
    method: 'POST',
    body: JSON.stringify({
      ...azureServicePrincipal,
      providerName: CloudProvider.AZURE,
    }),
  }).then((resp) => resp.json());
}

function postCloudProviderAccessGCPServiceAccount(
  groupId: string,
  gcpServiceAccount: Partial<CloudProviderAccessGCPServiceAccount> = {}
): Promise<CloudProviderAccessGCPServiceAccount> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess`, {
    method: 'POST',
    body: JSON.stringify({
      ...gcpServiceAccount,
      providerName: CloudProvider.GCP,
    }),
  }).then((resp) => resp.json());
}

function patchCloudProviderAccessAWSIAMRole(
  groupId: string,
  awsIamRole: CloudProviderAccessAWSIAMRole
): Promise<CloudProviderAccessAWSIAMRole> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess/${awsIamRole.roleId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      ...awsIamRole,
      providerName: CloudProvider.AWS,
    }),
  }).then((resp) => resp.json());
}

function getAtlasAWSAccountDetails(groupId: string): Promise<CloudProviderAccessAWSAccountDetails> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess/${CloudProvider.AWS}/atlasAccountDetails`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function getAtlasAzureAccountDetails(groupId: string): Promise<CloudProviderAccessAzureAccountDetails> {
  return fetchWrapper(`/nds/${groupId}/cloudProviderAccess/${CloudProvider.AZURE}/atlasAccountDetails`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export {
  fetchCloudProviderAccess,
  deleteCloudProviderAccessAWSIAMRole,
  deleteCloudProviderAccessAzureServicePrincipal,
  deleteCloudProviderAccessGCPServiceAccount,
  postCloudProviderAccessAWSIAMRole,
  postCloudProviderAccessAzureServicePrincipal,
  postCloudProviderAccessGCPServiceAccount,
  patchCloudProviderAccessAWSIAMRole,
  getAtlasAWSAccountDetails,
  getAtlasAzureAccountDetails,
};
