// constants and types
import { DefaultTemplateRegions } from '@packages/types/nds/defaultTemplate';
import { CloudProvider, Providers, ProviderToOptionsMapping } from '@packages/types/nds/provider';

import fetchWrapper from '../fetchWrapper';

export default {
  getDefaultTemplates(groupId: string, centralUrl = '') {
    function fetchProviderDefault(provider) {
      return fetchWrapper(`${centralUrl}/nds/clusters/${groupId}/${provider}/default`, {
        method: 'GET',
        credentials: 'include',
      });
    }

    return Promise.all([
      fetchProviderDefault('AWS').then((r) => r.json()),
      fetchProviderDefault('AZURE').then((r) => r.json()),
      fetchProviderDefault('GCP').then((r) => r.json()),
    ]).then(([awsDefault, azureDefault, gcpDefault]) => ({
      AWS: awsDefault,
      AZURE: azureDefault,
      GCP: gcpDefault,
    }));
  },

  fetchProviderOptions<P extends keyof ProviderToOptionsMapping>(
    provider: P,
    groupId: string,
    centralUrl = ''
  ): Promise<ProviderToOptionsMapping[P]> {
    return fetchWrapper(`${centralUrl}/nds/clusters/${groupId}/${provider}/options`, {
      method: 'GET',
      credentials: 'include',
    }).then((r) => r.json());
  },

  getProviderOptions(groupId: string, centralUrl = ''): Promise<Providers> {
    return Promise.all([
      this.fetchProviderOptions(CloudProvider.AWS, groupId, centralUrl),
      this.fetchProviderOptions(CloudProvider.AZURE, groupId, centralUrl),
      this.fetchProviderOptions(CloudProvider.GCP, groupId, centralUrl),
      this.fetchProviderOptions(CloudProvider.FREE, groupId, centralUrl),
      this.fetchProviderOptions(CloudProvider.FLEX, groupId, centralUrl),
    ]).then(([awsOptions, azureOptions, gcpOptions, freeDefault, flexDefault]) => ({
      AWS: awsOptions,
      AZURE: azureOptions,
      GCP: gcpOptions,
      FREE: freeDefault,
      FLEX: flexDefault,
    }));
  },

  getDefaultRegions(groupId: string): Promise<DefaultTemplateRegions> {
    return fetchWrapper(`/nds/clusters/${groupId}/template/regions`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
