import { Permission, PermissionResult } from '@packages/types/auth/fga';

import fetchWrapper from './fetchWrapper';

export async function fetchIsAuthorized(
  permissions: Array<Permission>,
  orgId: string
): Promise<{ authorizationResults: Array<PermissionResult> }> {
  return fetchWrapper(`/authz/${orgId}/isAuthorized`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      permissions,
    }),
  }).then((resp) => resp.json());
}
