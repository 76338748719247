import queryString from 'query-string';

export function isMongodbUrl(url: URL, includeLocalHost = true): boolean {
  // Validation of .mongodb instead of mongodb prevents urls like evilmongodb.com
  const validHostnamePatterns = [
    '\\.mongodb\\.com$',
    '\\.mongodbgov\\.com$',
    ...(includeLocalHost ? ['^localhost$', '^127.0.0.1$'] : []),
  ];
  const isValidHostname = validHostnamePatterns.some((pattern) => url.hostname.match(pattern));
  return isUrlProtocolSupported(url) && isValidHostname;
}

export function isMongodbUrlStr(url: string, includeLocalHost = true): boolean {
  try {
    const urlObj = new URL(url);
    return isMongodbUrl(urlObj, includeLocalHost);
  } catch (err) {
    return false;
  }
}

export function getRedirectUri(origin: string, search = '') {
  const url = new URL(origin);
  const { redirectUri } = queryString.parse(search);

  if (typeof redirectUri === 'string' && isMongodbUrlStr(redirectUri)) {
    url.href = redirectUri;
  }

  const urlSearchParams = new URLSearchParams(search);
  urlSearchParams.delete('redirectUri');
  const paramString = urlSearchParams.toString() ? '?' + urlSearchParams.toString() : '';

  // Since we're handling the search params separately, it makes sense to
  // add them parsed separately as well.
  return url.origin + url.pathname + paramString + url.hash;
}

function isUrlProtocolSupported(url: URL) {
  const supportedUrlProtocols = ['http:', 'https:'];
  return supportedUrlProtocols.includes(url.protocol);
}

export function originUrlHasSupportedProtocol(url: string, origin = window.location.origin) {
  try {
    const urlObj = new URL(url, origin);
    return isUrlProtocolSupported(urlObj);
  } catch (err) {
    return false;
  }
}
