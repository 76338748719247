import fetchWrapper from '../fetchWrapper';

export function getPushBasedLogExport(groupId: string): Promise<any> {
  return fetchWrapper(`/nds/${groupId}/pushBasedLogExport`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function deletePushBasedLogExport(groupId: string): Promise<any> {
  return fetchWrapper(`/nds/${groupId}/pushBasedLogExport`, {
    method: 'DELETE',
  });
}

export function validatePushBasedLogExport(
  groupId: string,
  bucketName: string,
  prefixPath: string,
  iamRoleId: string | null
): Promise<any> {
  return fetchWrapper(`/nds/${groupId}/pushBasedLogExport/validate`, {
    method: 'POST',
    body: JSON.stringify({
      bucketName: bucketName,
      prefixPath: prefixPath,
      iamRoleId: iamRoleId,
    }),
  });
}

export function createPushBasedLogExport(
  groupId: string,
  bucketName: string,
  prefixPath: string,
  iamRoleId: string | null
): Promise<any> {
  return fetchWrapper(`/nds/${groupId}/pushBasedLogExport`, {
    method: 'POST',
    body: JSON.stringify({
      bucketName: bucketName,
      prefixPath: prefixPath,
      iamRoleId: iamRoleId,
    }),
  });
}

export function editPushBasedLogExport(
  groupId: string,
  bucketName: string | null,
  prefixPath: string | null,
  iamRoleId: string | null
): Promise<any> {
  return fetchWrapper(`/nds/${groupId}/pushBasedLogExport`, {
    method: 'PATCH',
    body: JSON.stringify({
      bucketName: bucketName,
      prefixPath: prefixPath,
      iamRoleId: iamRoleId,
    }),
  });
}
