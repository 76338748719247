import fetchWrapper from '../fetchWrapper';

export function getOnlineArchives(groupId: string, clusterName: string) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives/${clusterName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMetrics(groupId: string, clusterName: string, archiveId: string) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives/${clusterName}/${archiveId}/metrics`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDataLandingZoneSupportedRegions(groupId: string, clusterName: string) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives/${clusterName}/dlsSupportedRegions`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDefaultDLZSupportedRegionForCluster(groupId: string, clusterName: string) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives/${clusterName}/defaultDLZSupportedRegion`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function createOnlineArchive(groupId: string, clusterName: string, data: any) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives/${clusterName}`, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function getOnlineArchivesForGroup(groupId: string) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateOnlineArchive(groupId: string, clusterName: string, archiveId: string, data: any) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives/${clusterName}/${archiveId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function deleteOnlineArchive(groupId: string, archiveId: string) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives/${archiveId}`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function getArchiveSystemPauseStatus(groupId: string, archiveId: string) {
  return fetchWrapper(`/nds/${groupId}/onlineArchives/${archiveId}/isArchivePausedBySystem`, {
    method: 'GET',
  }).then((resp) => resp.json());
}
