import { createSelector } from 'reselect';
import _ from 'underscore';

import * as api from '@packages/common/services/api';
import { permissionValueToLabelMap } from '@packages/common/services/permissionsService';

import * as user from './user';
// dependencies
import { actionTypes as commonActionTypes } from './common';

const { SET_GROUP_TEAMS } = commonActionTypes;

// Copied from group reducer due to circular dependencies.
// TODO(hswolff): Once we have ES Modules we can revert back to importing the function.
const getGroup = (state, { groupId }) => {
  const group = state.group[groupId];
  return group ? group.data : {};
};
// Copied from group reducer due to circular dependencies.
// TODO(hswolff): Once we have ES Modules we can revert back to importing the function.
const setMultipleGroups = (payload) => ({
  type: 'group/SET_MULTIPLE_GROUPS',
  payload,
});

const SET_MULTIPLE_TEAMS = 'team/SET_MULTIPLE_TEAMS';
const SET_TEAM = 'team/SET_TEAM';
const ADD_TEAM_USERS = 'team/ADD_TEAM_USERS';
const REMOVE_TEAM = 'team/REMOVE_TEAM';
const REMOVE_USER = 'team/REMOVE_USER';
const EDIT_ROLES = 'team/EDIT_ROLES';

const initialState = {};

function setTeam(nextState, team) {
  nextState[team.id] = { ...nextState[team.id] };

  nextState[team.id].data = {
    ...nextState[team.id].data,
    ...team,
  };

  if (team.groups) {
    nextState[team.id].groups = team.groups.map((g) => g.groupId);
  }

  if (team.users) {
    nextState[team.id].users = team.users.map((u) => user.getIdKeyForUser(u));
  }

  // Don't have group and users on the data object.
  delete nextState[team.id].data.groups;
  delete nextState[team.id].data.users;

  return nextState;
}

// Reducer
export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAM: {
      const { payload } = action;

      return setTeam({ ...state }, payload);
    }
    case SET_MULTIPLE_TEAMS:
    case SET_GROUP_TEAMS: {
      const { payload } = action;

      if (payload.length === 0) {
        return state;
      }

      return payload.reduce(setTeam, { ...state });
    }
    case ADD_TEAM_USERS: {
      const newState = { ...state };
      const users = [...newState[action.meta.teamId].users, ...action.payload];
      newState[action.meta.teamId].users = users;
      return newState;
    }
    case REMOVE_TEAM: {
      const teamId = action.payload;
      return _.omit(state, teamId);
    }
    case REMOVE_USER: {
      const { teamId, userId, username, isInvite } = action.payload;
      const idKey = isInvite ? username : userId;
      return {
        ...state,
        [teamId]: {
          ...state[teamId],
          users: _.without(state[teamId].users, idKey),
        },
      };
    }
    case EDIT_ROLES: {
      const { teamId } = action.meta;
      const { roles, rolesString } = action.payload;
      return setTeam(
        { ...state },
        {
          id: teamId,
          roles,
          rolesString,
        }
      );
    }
    default:
      return state;
  }
}

// Selectors
export const getTeamsKeyedById = (state) => state.team;

const getTeam = (state, { teamId }) => {
  const team = getTeamsKeyedById(state)[teamId];
  return team || {};
};

export const getTeamData = createSelector(getTeam, (team) => {
  return (
    {
      ...team.data,
      userIds: team.users || [],
    } || {}
  );
});

export const getHydratedTeam = (state, { teamId }) => {
  const team = getTeam(state, { teamId });
  return {
    data: team.data,
    users: (team.users || []).map((userId) => user.getUser(state, { userId })),
    groups: (team.groups || []).map((groupId) => getGroup(state, { groupId })),
  };
};

// Action Creators
export const setMultipleTeams = (payload = []) => ({
  type: SET_MULTIPLE_TEAMS,
  payload,
});

const editTeamRoles = ({ teamId, roles, rolesString }) => ({
  type: EDIT_ROLES,
  payload: {
    roles,
    rolesString,
  },
  meta: { teamId },
});

export const fetchTeam =
  ({ orgId, teamId }) =>
  (dispatch) => {
    return api.organization.getTeamById({ orgId, teamId }).then((payload) => {
      dispatch(user.setMultipleUsers(payload.users));
      dispatch(
        setMultipleGroups(
          payload.groups.map((g) => ({
            ...g,
            id: g.groupId,
          }))
        )
      );
      dispatch({
        type: SET_TEAM,
        payload,
      });
    });
  };

export const updateTeamUsers =
  ({ orgId, teamId, users }) =>
  (dispatch) => {
    return api.organization.addUsersToTeam({ orgId, teamId, users }).then(() => {
      dispatch({
        type: ADD_TEAM_USERS,
        meta: { teamId },
        payload: users,
      });
    });
  };

export const removeTeam = (teamId) => ({
  type: REMOVE_TEAM,
  payload: teamId,
});

export const removeUser =
  ({ orgId, teamId, userId, username, isInvite }) =>
  (dispatch) => {
    return api.organization.deleteUserFromTeam({ orgId, teamId, username }).then(() => {
      dispatch({
        type: REMOVE_USER,
        payload: { teamId, userId, username, isInvite },
      });
    });
  };

export const removeSelf =
  ({ orgId, teamId, userId }) =>
  (dispatch) => {
    return api.organization.deleteSelfFromTeam({ orgId, teamId }).then(() => {
      dispatch({
        type: REMOVE_USER,
        payload: { teamId, userId },
      });
    });
  };

export const saveGroupTeamRoles =
  ({ groupId, teamId, roles }) =>
  async (dispatch) => {
    await api.group.setTeamRoles({ groupId, teamId, roles });

    dispatch(
      editTeamRoles({
        teamId,
        roles,
        rolesString: roles.map((r) => permissionValueToLabelMap[r]).join(', '),
      })
    );
  };
