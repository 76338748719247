import stringify from 'fast-json-stable-stringify';
import _ from 'underscore';

import { ClusterDescription } from '@packages/types/nds/clusterDescription';
import { DataFederationQueryLimit, LimitSpan } from '@packages/types/nds/dataFederationQueryLimits';
import {
  Database,
  DataLakeMetrics,
  DataLakeTenant,
  DataLakeTenantMap,
  DataLakeType,
  DataSource,
  DataStore,
  DataStoreProvider,
  HTTPDataSource,
  HttpDataStore,
  StorageConfig,
  StorageConfigMMS,
} from '@packages/types/nds/dataLakes';
import {
  ExternalPrivateEndpoint,
  ExternalPrivateEndpointForConnectModal,
} from '@packages/types/nds/privateNetworkSettings';
import { Region } from '@packages/types/nds/region';

import { WILDCARD } from '@packages/common/constants/dataLakes';
import AtlasConnectOptions from '@packages/common/models/AtlasConnectOptions';
import ClusterDescriptionModel from '@packages/common/models/ClusterDescription';
import { generateDataLakeTenant, generateHttpDataSource } from '@packages/common/utils/objectInitializers';
import analytics, { SEGMENT_EVENTS } from '@packages/common/utils/segmentAnalytics';

/*
  General shared utilities for data lake. does not include utilities for updating storage config.
 */

export enum TenantSetupState {
  INITIAL = 'INITIAL',
  QUERIED = 'QUERIED',
}

const getQueryExecutedFromMetrics = (metrics: DataLakeMetrics) =>
  metrics && metrics.totalDataScanned && metrics.totalDataScanned > 0;

export const tenantToTenantSetupState = (metrics: DataLakeMetrics): TenantSetupState => {
  if (getQueryExecutedFromMetrics(metrics)) {
    return TenantSetupState.QUERIED;
  } else {
    return TenantSetupState.INITIAL;
  }
};

export const getDatabasesAndCollectionsCount = (tenant) => {
  const dbs = tenant?.storage?.databases ?? [];
  if (dbs.length) {
    return {
      isStorageConfigValid: true,
      databaseCount: dbs.length,
      collectionsCount: dbs.map((db) => db.collections.length).reduce((a, b) => a + b, 0),
    };
  }
  return { isStorageConfigValid: false };
};

export const getDatabaseNames = (tenant: DataLakeTenant | undefined) =>
  tenant?.storage?.databases?.map((db) => db.name).filter((dbName) => dbName !== WILDCARD) ?? [];

export const getTenantDisplayNameForAtlasSQLTenant = (
  tenant: DataLakeTenant,
  clusterDescriptions?: Array<ClusterDescription>
) => {
  const clusterName = getClusterNameForAtlasSQLTenant(tenant, clusterDescriptions);
  return clusterName ? `${clusterName} Atlas SQL` : tenant.name;
};

export const getTenantDisplayNameForOnlineArchiveTenant = (
  tenant: DataLakeTenant,
  clusterDescriptions?: Array<ClusterDescription>
) => {
  const clusterDescription = getClusterDescriptionForOATenant(tenant, clusterDescriptions);
  if (clusterDescription) {
    return `${clusterDescription.name} ${isArchiveOnlyTenant(tenant) ? 'Archive' : 'Cluster Archive'}`;
  }
  return tenant.name;
};

export const CLUSTER_RESERVED_DATABASES = ['admin', 'config', 'local'];

export const getTenantDisplayName = (tenant: DataLakeTenant, clusterDescriptions?: Array<ClusterDescription>) => {
  switch (tenant.dataLakeType) {
    case DataLakeType.ATLAS_SQL:
      return getTenantDisplayNameForAtlasSQLTenant(tenant, clusterDescriptions);
    case DataLakeType.ONLINE_ARCHIVE:
      return getTenantDisplayNameForOnlineArchiveTenant(tenant, clusterDescriptions);
    default:
      return tenant.name;
  }
};

export const getDataSourceNameCount = (
  tenant: DataLakeTenant,
  metrics: DataLakeMetrics
): { valid: boolean; count: number } => {
  const queryExecuted = getQueryExecutedFromMetrics(metrics);

  if (!queryExecuted) {
    return {
      valid: false,
      count: -1,
    };
  }

  const dbs = tenant?.storage?.databases || [];
  const datasetNames = dbs.flatMap((db) => db.collections || []).flatMap((coll) => coll.dataSources || []);

  return {
    valid: true,
    count: datasetNames.length,
  };
};

const removeProviderFromDataSource = ({ provider, ...ds }: DataSource): Omit<DataSource, 'provider'> => ds;

export const removeProvidersFromDataSources = (databases: Array<Database>): Array<Database> =>
  databases.map((d) => ({
    ...d,
    name: d.name || '',
    collections: (d.collections || []).map((c) => ({
      name: c.name || '',
      // remove provider field for json configuration
      dataSources: (c.dataSources || []).map(removeProviderFromDataSource) as Array<DataSource>,
    })),
  }));

// utility to convert MMS consumable json representation to the json editor
export const convertStorageForJsonEditorConsumption = (storage) => {
  if (storage) {
    try {
      return {
        databases: removeProvidersFromDataSources(storage.databases),
        stores: storage.stores,
      };
    } catch (err) {
      // in case the storage is not in valid format, just return it to be converted to json string
      return storage;
    }
  }
  return storage;
};

export const convertTenantsForConfigEditorConsumption = (tenants: Array<any>): Array<DataLakeTenant> => {
  return tenants?.map(convertTenantForConfigEditorConsumption);
};

// utility to prepare a tenant's storage configuration for visual and json editor consumption
export const convertTenantForConfigEditorConsumption = (tenant: any) => {
  if (tenant?.storage) {
    const storage = convertStorageForConfigEditorConsumption(tenant.storage);
    return {
      ...tenant,
      storage: storage,
    };
  }
  return tenant;
};

export const convertStorageForConfigEditorConsumption = (storage: any): StorageConfig => {
  if (storage?.config) {
    const { config } = storage;
    return hydrateDataSourceProviders({
      stores: config.stores ?? [],
      databases: (config.databases ?? []).map((d) => ({
        ...d,
        views: d.views ?? [],
        collections: (d.collections ?? []).map((c) => ({
          ...c,
          dataSources: c.dataSources ?? [],
        })),
      })),
    });
  }
  return storage;
};

// utility to add providers to the data sources in a storage config
export const hydrateDataSourceProviders = (storageConfig: StorageConfig): StorageConfig => {
  const { stores, databases } = storageConfig;
  const storeNameMap = new Map<string, DataStoreProvider>(stores.map((s) => [s.name, s.provider]));
  return {
    stores,
    databases: databases.map((d) => ({
      ...d,
      collections: d.collections.map((c) => ({
        ...c,
        dataSources: c.dataSources.map(
          (ds) =>
            ({
              ...ds,
              provider: storeNameMap.get(ds.storeName),
            }) as DataSource
        ),
      })),
    })),
  };
};

// utility to convert json editor version of storage config to that consumable by MMS
export const convertStorageForMMSConsumption = (storage): StorageConfigMMS => {
  return {
    config: {
      databases: removeProvidersFromDataSources(storage.databases),
      stores: storage.stores,
    },
  };
};

// utility to convert json version of the tenant's storage config to that consumable by MMS
export const convertTenantForMMSConsumption = (tenant: DataLakeTenant) => {
  if (tenant?.storage) {
    const storage = convertStorageForMMSConsumption(tenant.storage);
    return {
      ...tenant,
      storage: storage,
    };
  }
  return tenant;
};

export const isArchiveOnlyTenant = (tenant: DataLakeTenant): boolean => {
  return tenant.name.startsWith('archived');
};

export const getClusterDescriptionForOATenant = (
  tenant: DataLakeTenant,
  clusterDescriptions?: Array<ClusterDescription>
) => {
  const clusterNameOrUniqueId = isArchiveOnlyTenant(tenant)
    ? tenant.name.replace('archived-atlas-online-archive-', '')
    : tenant.name.replace('atlas-online-archive-', '');
  return clusterDescriptions?.find((cd) => cd.name === clusterNameOrUniqueId || cd.uniqueId === clusterNameOrUniqueId);
};

export const getOnlineArchivesFromTenant = (
  tenant: DataLakeTenant,
  onlineArchives,
  clusterDescriptions: Array<ClusterDescription>
) => {
  const clusterDescription = getClusterDescriptionForOATenant(tenant, clusterDescriptions);
  if (typeof clusterDescription === 'undefined') {
    return [];
  }
  return onlineArchives.filter((onlineArchive) => onlineArchive.clusterName === clusterDescription.name);
};

export const getClusterIdForAtlasSQLTenant = (tenant: DataLakeTenant) =>
  tenant.dataLakeType === DataLakeType.ATLAS_SQL ? tenant.name.replace('atlas-sql-', '') : undefined;

export const getClusterDescriptionForAtlasSQLTenant = (
  tenant: DataLakeTenant,
  clusterDescriptions?: Array<ClusterDescription>
) => {
  const clusterNameOrUniqueId = getClusterIdForAtlasSQLTenant(tenant);
  return clusterDescriptions?.find((cd) => cd.name === clusterNameOrUniqueId || cd.uniqueId === clusterNameOrUniqueId);
};

export const getClusterNameForAtlasSQLTenant = (
  tenant: DataLakeTenant,
  clusterDescriptions?: Array<ClusterDescription>
) => getClusterDescriptionForAtlasSQLTenant(tenant, clusterDescriptions)?.name;

export const getAtlasSQLTenantForClusterModel = (
  tenants: Array<DataLakeTenant>,
  clusterDescription: typeof ClusterDescriptionModel
) => {
  const tenantName = `atlas-sql-${clusterDescription.getUniqueId()}`;
  return tenants.filter((tenant) => tenant.dataLakeType === DataLakeType.ATLAS_SQL && tenant.name === tenantName)[0];
};

// original data lake tenant name format contained Atlas cluster name, current format contains unique id
export const getOnlineArchiveTenantForClusterModel = (
  tenants: Array<DataLakeTenant>,
  clusterDescription: typeof ClusterDescriptionModel,
  archiveOnly = false
) => {
  const prefix = `${archiveOnly ? 'archived-' : ''}atlas-online-archive-`;
  return tenants.filter(
    (tenant) =>
      tenant.dataLakeType === DataLakeType.ONLINE_ARCHIVE &&
      (tenant.name === `${prefix}${clusterDescription.getName()}` ||
        tenant.name === `${prefix}${clusterDescription.getUniqueId()}`)
  )[0];
};

export const getTenantConnectOptionsForClusterModel = (
  tenants: Array<DataLakeTenant>,
  clusterDescription: typeof ClusterDescriptionModel,
  onlineArchiveAsSQLTenant = false
) => {
  const onlineArchiveTenant = getOnlineArchiveTenantForClusterModel(tenants, clusterDescription);
  const onlineArchiveOnlyTenant = getOnlineArchiveTenantForClusterModel(tenants, clusterDescription, true);
  const atlasSQLTenant = onlineArchiveAsSQLTenant
    ? onlineArchiveTenant // select atlas sql option from online archive tab in a cluster ¯\_(ツ)_/¯
    : getAtlasSQLTenantForClusterModel(tenants, clusterDescription);
  const emptyAtlasSQLTenant = generateDataLakeTenant({ dataLakeType: DataLakeType.ATLAS_SQL });

  const federatedConnectOptions = onlineArchiveTenant
    ? AtlasConnectOptions.fromDataLakeTenant(onlineArchiveTenant)
    : undefined;
  const archiveOnlyConnectOptions = onlineArchiveOnlyTenant
    ? AtlasConnectOptions.fromDataLakeTenant(onlineArchiveOnlyTenant)
    : undefined;
  // atlasSQLConnectOptions model can not be undefined to allow update through react in ConnectSQLInterface
  const atlasSQLConnectOptions = atlasSQLTenant
    ? AtlasConnectOptions.fromDataLakeTenant(atlasSQLTenant)
    : AtlasConnectOptions.fromDataLakeTenant(emptyAtlasSQLTenant);

  return {
    federatedConnectOptions,
    archiveOnlyConnectOptions,
    atlasSQLConnectOptions,
  };
};

export const getAvailableDataLakePrivateLinks = (
  allDataLakePrivateLinks: Array<ExternalPrivateEndpoint>,
  dataLakeTenants: Array<DataLakeTenant>,
  regions: Array<Region>
): Array<ExternalPrivateEndpointForConnectModal> => {
  // get data lake private links that map to dedicated hostnames that exist in tenants
  return allDataLakePrivateLinks
    .filter(
      (pl) =>
        !pl.region ||
        dataLakeTenants.every(
          (tenant) => tenant && tenant.privateEndpointHostnameMap && pl.endpointId in tenant.privateEndpointHostnameMap
        )
    )
    .map(
      (pl): ExternalPrivateEndpointForConnectModal => ({
        endpointId: pl.endpointId,
        provider: pl.provider,
        comment: pl.comment,
        type: pl.type,
        customerEndpointDNSName: pl.customerEndpointDNSName,
        region: regions.find((r) => r.key === pl.region),
      })
    );
};

const DATA_LAKE_NAME_MAX_LENGTH = 57;
const DATA_LAKE_NAME_PATTERN = /^[a-zA-Z0-9][a-zA-Z0-9-]+$/;

export const validateDataLakeName = (dataLakeName, existingDataLakeNames) => {
  if (!DATA_LAKE_NAME_PATTERN.test(dataLakeName)) {
    throw new Error('Your data lake name is invalid.');
  } else if (existingDataLakeNames.some((existingName) => existingName === dataLakeName)) {
    throw new Error(`A data lake already exists with name '${dataLakeName}'.`);
  } else if (dataLakeName.length > DATA_LAKE_NAME_MAX_LENGTH) {
    throw new Error(`Your data lake name must be a maximum of ${DATA_LAKE_NAME_MAX_LENGTH} characters.`);
  }
};

export const validateObjectAsStorageConfig = (storageConfig: any): StorageConfig => {
  const { stores, databases } = storageConfig;
  if (!_.isArray(stores)) {
    throw new Error(`'stores' field is not an array`);
  }
  if (!_.isArray(databases)) {
    throw new Error(`'databases' field is not an array`);
  }
  // validate stores
  stores.forEach((s, idx) => {
    if (!_.isObject(s)) {
      throw new Error(`'stores[${idx}]' field is not an object`);
    }
    if (!s.name) {
      throw new Error(`'stores[${idx}]' field - name is required`);
    }
  });
  // validate databases
  databases.forEach((d, databaseIdx) => {
    if (!_.isObject(d)) {
      throw new Error(`'databases[${databaseIdx}]' is not an object`);
    }
    if (!_.isArray(d.collections)) {
      throw new Error(`'collections' field in 'databases[${databaseIdx}]' is not an array`);
    }
    d.collections.forEach((c, collectionIdx) => {
      if (!_.isObject(c)) {
        throw new Error(`'databases[${databaseIdx}].collections[${collectionIdx}]' field is not an object`);
      }
      if (!_.isArray(c.dataSources)) {
        throw new Error(
          `'dataSources' field in 'databases[${databaseIdx}].collections[${collectionIdx}]' is not an array`
        );
      }
      c.dataSources.forEach((ds, dsIdx) => {
        if (!_.isObject(ds)) {
          throw new Error(
            `'databases[${databaseIdx}].collections[${collectionIdx}].dataSources[${dsIdx}]' field is not an object`
          );
        }
      });
    });
  });
  return storageConfig as StorageConfig;
};

export const mergeUrls = (u1: Array<string> | undefined = [], u2: Array<string> | undefined = []) => {
  return [...new Set([...u1, ...u2])];
};

// move all URLs specified in the http stores into the collections that reference them
// so the referenced stores should have no URLs and the collections contain a union of the URLs
// that they originally specified with the ones that were in the store.
// stores that were not referenced by any collection should remain untouched
// this method update storage in place
export const moveUrlsFromReferencedHttpStoreToCollection = (storage: StorageConfig) => {
  if (!storage?.databases) {
    return;
  }
  const referencedStores: Array<DataStore> = [];
  const httpSourcesInCollection: Array<HTTPDataSource> = storage.databases
    .flatMap((d) => d.collections)
    .flatMap((c) => c.dataSources)
    .filter((ds) => ds.provider === DataStoreProvider.HTTP && ds.urls)
    .map((ds) => ds as HTTPDataSource);

  // merge urls in a http data source with urls in the referenced http data store
  httpSourcesInCollection.forEach((httpSource) => {
    const store = storage.stores?.find((s) => s.name === httpSource.storeName);
    if (store) {
      httpSource.urls = mergeUrls(httpSource.urls, (store as HttpDataStore).urls);
      referencedStores.push(store);
    }
  });

  // remove urls in referenced http store
  referencedStores.forEach((s) => {
    (s as HttpDataStore).urls = [];
  });
};

export const mergeStoreToDataSourcesMap = (
  storage: StorageConfig,
  currDataSourceMap: Map<string, Array<DataSource>> = new Map<string, Array<DataSource>>()
): Map<string, Array<DataSource>> => {
  const { stores = [], databases = [] } = storage;
  const allDataSources = [
    // http data sources
    ...stores.flatMap((store) => (store.provider === DataStoreProvider.HTTP ? httpDataStoreToDataSources(store) : [])),
    ...getUsedDataSources(databases),
  ];
  const newDataSourceMap = _.uniq(allDataSources, (ds) => stringify(ds)).reduce((result, ds) => {
    const dataSources = result.get(ds.storeName) || [];
    result.set(ds.storeName, [...dataSources, ds]);
    return result;
  }, new Map<string, Array<DataSource>>());
  // merge the new data source map with the current one, in order to preserve other non-http data sources
  // the stale values in the current map for keys that are present in both maps will be
  // overwritten by the updated values in the new map (last key wins).
  const allStoreNames = new Set([...currDataSourceMap.keys(), ...newDataSourceMap.keys()]);
  return new Map<string, Array<DataSource>>(
    [...allStoreNames].map((storeName) => {
      // merge together all data sources associated for a given storeName
      const prevDataSources = currDataSourceMap.get(storeName) || [];
      const newDataSources = newDataSourceMap.get(storeName) || [];
      // use string representation as key to determine uniqueness of data source
      const uniqueSources: Array<DataSource> = [
        ...new Map<string, DataSource>(
          [...prevDataSources, ...newDataSources].map((ds) => [stringify(ds), ds])
        ).values(),
      ];
      return [storeName, uniqueSources];
    })
  );
};

export const getUsedDataSources = (databases: Array<Database> = []): Array<DataSource> => {
  const dataSources = databases.flatMap((db) => db.collections).flatMap((coll) => coll.dataSources);
  return expandDataSources(dataSources);
};

export const expandDataSources = (dataSources: Array<DataSource>): Array<DataSource> => {
  return dataSources
    .map((ds) => {
      if (ds.provider === DataStoreProvider.HTTP) {
        return expandHttpDataSource(ds);
      }
      return [ds];
    })
    .flat();
};

export const expandHttpDataSource = (httpDataSource: HTTPDataSource): Array<HTTPDataSource> =>
  (httpDataSource.urls ?? []).map((url) =>
    generateHttpDataSource({
      ...httpDataSource,
      urls: [url],
    })
  );

export const httpDataStoreToDataSources = (dataStore: HttpDataStore): Array<DataSource> => {
  return (dataStore.urls ?? [])
    .map((url) =>
      generateHttpDataSource({
        storeName: dataStore.name,
        urls: [url],
      })
    )
    .map((ds) =>
      dataStore.defaultFormat
        ? {
            ...ds,
            defaultFormat: dataStore.defaultFormat,
          }
        : ds
    );
};

// UTILITY FUNCTIONS
export const isS3DataStore = (dataStore: DataStore | undefined): boolean => {
  return dataStore !== undefined && dataStore.provider === DataStoreProvider.S3;
};

export const isAtlasDataStore = (dataStore: DataStore | undefined): boolean => {
  return dataStore !== undefined && dataStore.provider === DataStoreProvider.ATLAS;
};

export const isHttpDataStore = (dataStore: DataStore | undefined): boolean => {
  return dataStore !== undefined && dataStore.provider === DataStoreProvider.HTTP;
};

export const getDataSourceDescription = (dataSource: DataSource): string => {
  const defaultDisplayName = `${dataSource.storeName}_${_.uniqueId('source_')}`;
  switch (dataSource.provider) {
    case DataStoreProvider.S3:
    case DataStoreProvider.AZURE_BLOB_STORAGE:
    case DataStoreProvider.GCS:
      return dataSource.path || defaultDisplayName;
    case DataStoreProvider.HTTP:
      return dataSource?.urls?.[0] || defaultDisplayName;
    case DataStoreProvider.ATLAS:
      return `${dataSource.database}.${dataSource.collection}`;
  }
  return defaultDisplayName;
};

export const getDataStore = (storage: StorageConfig, storeName: string): DataStore | undefined => {
  return (storage?.stores || []).find((store) => store.name === storeName);
};

export const getDatabase = (dataLakeTenant: DataLakeTenant, databaseName: string): Database | undefined => {
  return dataLakeTenant.storage.databases.find(({ name }) => name === databaseName);
};

const EXTERNAL_DATA_SOURCE_DELIMITER = ':';

export const toExternalDataSourceKey = (dataSource: DataSource): string => {
  const description = getDataSourceDescription(dataSource);
  return ['source', dataSource.provider, dataSource.storeName, description].join(EXTERNAL_DATA_SOURCE_DELIMITER);
};

interface DataSourceAttrs {
  provider: DataStoreProvider;
  storeName: string;
  description: string;
}

export const fromExternalDataSourceKey = (dataSourceKey: string): DataSourceAttrs => {
  // first level static
  const [, provider, storeName, description] = dataSourceKey.split(EXTERNAL_DATA_SOURCE_DELIMITER);
  return {
    provider: provider as DataStoreProvider,
    storeName,
    description,
  };
};

export const findDataSource = (
  dataSourceKey: string,
  storage: StorageConfig,
  dataSourceMap: Map<string, Array<DataSource>>
): DataSource | undefined => {
  const { storeName } = fromExternalDataSourceKey(dataSourceKey);
  const dataSources = dataSourceMap.get(storeName) ?? [];
  return dataSources.find((dataSource) => toExternalDataSourceKey(dataSource) === dataSourceKey);
};

export const generateUniqueName = (prefix: string, existingNames: Set<string>): string => {
  for (let i = 0; i < existingNames.size; i++) {
    const name = `${prefix}${i}`;
    if (!existingNames.has(name)) {
      return name;
    }
  }

  return `${prefix}${existingNames.size}`;
};

export const getHasTagSetError = (tagSetsJSON: string | undefined): boolean => {
  if (!tagSetsJSON || tagSetsJSON === '') return false;
  try {
    const tagSetsArray = JSON.parse(tagSetsJSON);
    if (!Array.isArray(tagSetsArray)) {
      return true;
    }
    return (tagSetsArray as Array<any>).some((tagSets) => !Array.isArray(tagSets));
  } catch (e) {
    return true;
  }
};

export const convertRegionNameToValue = (regionName: string): string => regionName.replaceAll('_', '-').toLowerCase();
export const convertRegionValueToName = (regionValue: string): string => regionValue.replaceAll('-', '_').toUpperCase();

export const analyticsTrack = (orgId: string, action: string, value = '') => {
  const trackingProperties: any = {
    context: 'Data Federation Page',
    action,
  };
  if (value) {
    trackingProperties.value = value;
  }
  analytics.track(SEGMENT_EVENTS.UX_ACTION_PERFORMED, trackingProperties);
};

export const hasDefaultQueryLimit = (queryLimits: Array<DataFederationQueryLimit>, dataLakeName: string): boolean => {
  for (const { tenantName, limitSpan, limitInBytes } of queryLimits) {
    if (tenantName === dataLakeName && limitSpan === LimitSpan.MONTHLY && limitInBytes === 2 ** 40 * 100) {
      return true;
    }
  }
  return false;
};

export const getDataLakeTenantNameMap = (dataLakeTenants: Array<DataLakeTenant>): DataLakeTenantMap =>
  dataLakeTenants.reduce((dataLakeMap, dataLake) => {
    dataLakeMap[dataLake.name] = dataLake;
    return dataLakeMap;
  }, {});

export const sanitizeAzureCmdOutput = (value: string) => value.trim().replace(/^"/, '').replace(/",?$/, ''); //remove quotes from the azure command output
