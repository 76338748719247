export enum RunResult {
  NOT_DONE = 'NOT_DONE',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum RunStatus {
  NEW = 'NEW',
  WORKING = 'WORKING',
  CANCELED = 'CANCELED',
  FINALIZING = 'FINALIZING',
  COMPLETE = 'COMPLETE',
}

export enum CorruptionDetectionOperationOrigin {
  AUTOMATED = 'AUTOMATED',
  MANUAL = 'MANUAL',
}

export interface DbCheck {
  _id: string;
  groupId: string;
  clusterName: string;
  result: RunResult;
  status: RunStatus;
  lastManualStatusChangeReason?: string;
  lastManualStatusChangeDate?: Date;
  operationOrigin?: CorruptionDetectionOperationOrigin;
}

/** Format of the DbCheck when it is freshly deserialized from the API response */
export interface RawDbCheck {
  _id: string;
  groupId: string;
  clusterName: string;
  result: string;
  status: string;
  lastManualStatusChangeReason?: string;
  lastManualStatusChangeDate?: string;
  operationOrigin?: string;
}

export interface DbCheckFilters {
  groupId?: string;
  clusterUniqueId?: string;
  result?: string;
  status?: string;
  minCreateDate?: number;
  maxCreateDate?: number;
  limit: number;
  /** offset in number of DbCheck records */
  offset: number;
}

export interface DataValidationFilters {
  clusterName?: string;
  groupId?: string;
  targetInstanceId?: string;
  status?: string;
  validationStatus?: string;
  minCreateTimestamp?: number;
  maxCreateTimestamp?: number;
  minCompletionTimestamp?: number;
  maxCompletionTimestamp?: number;
  latest?: boolean;
  limit: number;
  offset: number;
}

// URL may or may not have filters defined so we make all properties optional
export type UrlDbCheckFilters = Partial<DbCheckFilters>;
