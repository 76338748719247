import fetchWrapper from '../fetchWrapper';

export default {
  getInstances() {
    return fetchWrapper('/admin/nds/envoyInstances', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  replaceInstance(id: string) {
    return fetchWrapper(`/admin/nds/envoyInstances/${id}`, {
      method: 'POST',
    });
  },

  rotateUISKeys(id: string) {
    return fetchWrapper(`/admin/nds/envoyInstances/rotateUISKeys/${id}`, {
      method: 'POST',
    });
  },

  fetchEnvoySshPassphrase(id: string) {
    return fetchWrapper(`/admin/nds/envoyInstances/${id}/sshPassphrase`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateChefConfig(envoyInstanceId: string) {
    return fetchWrapper(`/admin/nds/envoyInstances/${envoyInstanceId}/updateChefConfig`, {
      method: 'POST',
    });
  },
};
