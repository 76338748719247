import { BaseFontSize } from '@leafygreen-ui/tokens';
import { Link } from '@leafygreen-ui/typography';

import Image from '@packages/components/Image';

export interface ErrorMessageProps {
  codeStatus?: string | null;
  codeMessage?: string;
  message?: string;
  showError?: boolean;
  componentStack?: string;
  error?: {
    message?: string;
    stack?: string;
  };
}

function ErrorMessage({
  codeStatus = '404',
  codeMessage = 'Document Not Found',
  message = "Oops! We can't find the page you were looking for.",
  showError = process.env.NODE_ENV === 'development',
  componentStack,
  error,
}: ErrorMessageProps) {
  return (
    <div className="not-found-error-message-container">
      <div className="not-found-container">
        <Image className="not-found-image" src="/static/images/sadface.gif" alt="a sad computer" />
        <div className="not-found-code">
          {codeStatus ? (
            <>
              &#123; status: {codeStatus}, <br />
              &nbsp;&nbsp;message: &#34;{codeMessage}&#34; &#125;
            </>
          ) : (
            <>&#123; message: &#34;{codeMessage}&#34; &#125;</>
          )}
        </div>
      </div>
      <p className="not-found-text">{message}</p>

      <p className="not-found-text">
        If you&#39;re experiencing a critical issue, please reach out to Chat Support through our{' '}
        <Link baseFontSize={BaseFontSize.Body2} href="https://www.mongodb.com/company/contact" target="_blank">
          Contact Us
        </Link>{' '}
        page.
      </p>

      {showError && error && componentStack && (
        <div className="test-debug-info" data-testid="debug-info-container">
          <hr />
          <h3>
            Debug Info <small>(only shown in development mode)</small>
          </h3>
          <pre>
            <b>{error.message}</b>
            {componentStack}
          </pre>
        </div>
      )}
    </div>
  );
}

ErrorMessage.ClientError = function ClientError(props: Partial<ErrorMessageProps>) {
  return (
    <ErrorMessage codeStatus={null} codeMessage="Error Occurred" message="Oops! Something went wrong." {...props} />
  );
};

export default ErrorMessage;
