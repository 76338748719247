import * as React from 'react';
import { useCallback, useContext, useState } from 'react';

import { useRequestParams } from '@packages/common/context/RequestParamsContext';
import { user } from '@packages/common/services/api';
import { updateCsrfHeaders } from '@packages/common/services/api/fetchWrapper';

export type AuthType = 'google' | 'voice' | 'sms' | null;

export interface AppUserParams {
  authType: AuthType;
  hasBackupPhone: boolean;
  username: string;
  firstName: string;
  lastName: string;
  country: string;
  hasMultiFactorAuth: boolean;
  hasAccountMultiFactorAuth: boolean;
  failedToLoad: boolean;
  csrfTime: string;
  csrfToken: string;
}

interface AppUserProviderProps {
  appUserParams?: AppUserParams;
  loadAppUser?: () => Promise<AppUserParams>;
  children: React.ReactNode;
}

const initialParams: AppUserParams = {
  authType: null,
  hasBackupPhone: false,
  username: '',
  firstName: '',
  lastName: '',
  country: '',
  hasMultiFactorAuth: false,
  hasAccountMultiFactorAuth: false,
  failedToLoad: false,
  csrfTime: '',
  csrfToken: '',
};

const AppUserContext = React.createContext({
  appUserParams: initialParams,
  loadAppUserParams: () => {},
});

export const AppUserProvider = ({ children }: AppUserProviderProps) => {
  const [appUserParams, setAppUserParams] = useState(initialParams);
  const { centralUrl } = useRequestParams();

  const loadAppUserParams = async () => {
    try {
      const { csrfTime, csrfToken, appUser } = await user.params(centralUrl);

      let mfaType;
      let mfa;

      if (csrfTime && csrfToken) {
        updateCsrfHeaders({ csrfTime, csrfToken });

        mfa = appUser?.multiFactorAuth;

        if (mfa != null) {
          if (mfa.authenticator) {
            mfaType = 'google';
          } else {
            mfaType = mfa.voice ? 'voice' : 'sms';
          }
        }
      }

      setAppUserParams({
        authType: mfaType,
        hasBackupPhone: mfa?.backupPhone ?? false,
        username: appUser?.username ?? '',
        firstName: appUser?.firstName ?? '',
        lastName: appUser?.lastName ?? '',
        country: appUser?.isoA2CountryCode,
        hasMultiFactorAuth: appUser?.hasMultiFactorAuth ?? false,
        hasAccountMultiFactorAuth: appUser?.hasAccountMultiFactorAuth ?? false,
        failedToLoad: false,
        csrfTime,
        csrfToken,
      });
    } catch (e) {
      setAppUserParams({
        ...appUserParams,
        failedToLoad: true,
      });
    }
  };

  return (
    <AppUserContext.Provider value={{ appUserParams, loadAppUserParams: useCallback(loadAppUserParams, []) }}>
      {children}
    </AppUserContext.Provider>
  );
};

export const useAppUserParams = () => {
  return useContext(AppUserContext);
};
