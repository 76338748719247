import type { CostEstimate, PurchaseTransactionBillingEstimate } from '@packages/types/billing';
import {
  ClusterDescription,
  ClusterTemplateParamsView,
  ConfigServerTypeEstimate,
} from '@packages/types/nds/clusterDescription';
import { TemplateKey } from '@packages/types/nds/defaultTemplate';
import { NamespaceWithUUID } from '@packages/types/nds/namespaceWithUUID';
import { CrossCloudProviderOptionsView } from '@packages/types/nds/provider';

import fetchWrapper, { formParams } from '../fetchWrapper';

export default {
  getClusterDescriptions(groupId, centralUrl = '') {
    return fetchWrapper(`${centralUrl}/nds/clusters/${groupId}`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  getClusterDescription(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  postClusterDescription(groupId, clusterDescriptionJson, recaptchaToken = '') {
    return fetchWrapper(`/nds/clusters/${groupId}?recaptchaToken=${recaptchaToken}`, {
      method: 'POST',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  patchClusterDescription(groupId, clusterDescriptionJson) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterDescriptionJson.name}`, {
      method: 'PATCH',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  getDeletedClusterNamesWithActiveSnapshots(groupId) {
    return fetchWrapper(`/nds/clusters/deletedClusterNamesWithActiveSnapshots/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  // This endpoint is used from the Account/ app, so it needs `credentials: true` due to CORS policy
  createVercelCluster({ centralUrl, groupId, clusterDescriptionJson }) {
    return fetchWrapper(`${centralUrl}/nds/clusters/vercel/${groupId}`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  // This endpoint was defined separately from patchClusterDescription so it can
  // explicitly set needsMongoDBConfigPublishAfter upon submit.
  patchGeoSharding(groupId, clusterName, geoShardingJson) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/geoSharding`, {
      method: 'PATCH',
      body: JSON.stringify(geoShardingJson),
    }).then((resp) => resp.json());
  },

  upgradeTenantCluster(groupId, clusterDescriptionJson) {
    return fetchWrapper(`/nds/clusterUpgrade/${groupId}`, {
      method: 'POST',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  getCostEstimate(groupId, clusterDescriptionJson): Promise<CostEstimate> {
    return fetchWrapper(`/nds/clusters/${groupId}/costEstimate`, {
      method: 'POST',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  getConfigServerTypeEstimate(groupId, clusterDescriptionJson): Promise<ConfigServerTypeEstimate | null> {
    return fetchWrapper(`/nds/clusters/${groupId}/configServerTypeEstimate`, {
      method: 'POST',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  getCrossCloudProviderOptions(groupId, providers): Promise<CrossCloudProviderOptionsView> {
    const providerString = providers.map((provider) => `providers=${encodeURIComponent(provider)}`).join('&');
    const method = 'GET';
    return fetchWrapper(`/nds/clusters/${groupId}/providerOptions?${providerString}`, { method }).then((resp) =>
      resp.json()
    );
  },

  getNewFormLocationMapping(groupId, replicationSpecs, customZoneMapping) {
    return fetchWrapper(`/nds/geoSharding/${groupId}/newFormLocationMapping`, {
      method: 'POST',
      body: JSON.stringify({
        replicationSpecs,
        customZoneMapping,
      }),
    }).then((resp) => resp.json());
  },

  getLocationMapping(groupId, replicationSpecs, customZoneMapping) {
    return fetchWrapper(`/nds/geoSharding/${groupId}/locationMapping`, {
      method: 'POST',
      body: JSON.stringify({
        replicationSpecs,
        customZoneMapping,
      }),
    }).then((resp) => resp.json());
  },

  getProcessArgs(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/processArgs`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDefaultProcessArgs(groupId) {
    return fetchWrapper(`/nds/clusters/${groupId}/processArgs/default`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  executeProcessArgsService(
    groupId,
    clusterName,
    processArgsJson,
    diskSizeGB,
    mongoDBVersion,
    instanceSize,
    serviceHttpMethod
  ) {
    const diskSizeGBParam = `diskSizeGB=${encodeURIComponent(diskSizeGB)}`;
    const mongoDBVersionParam = `mongoDBVersion=${encodeURIComponent(mongoDBVersion)}`;
    const instanceSizeParam = `instanceSize=${encodeURIComponent(instanceSize)}`;
    const queryParams = [diskSizeGBParam, mongoDBVersionParam, instanceSizeParam].join('&');

    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/processArgs?${queryParams}`, {
      method: serviceHttpMethod,
      body: JSON.stringify(processArgsJson),
    }).then((resp) => resp.json());
  },

  postProcessArgs(groupId, clusterName, processArgsJson, diskSizeGB, mongoDBVersion, instanceSize) {
    return this.executeProcessArgsService(
      groupId,
      clusterName,
      processArgsJson,
      diskSizeGB,
      mongoDBVersion,
      instanceSize,
      'POST'
    );
  },

  patchProcessArgs(groupId, clusterName, processArgsJson, diskSizeGB, mongoDBVersion, instanceSize) {
    return this.executeProcessArgsService(
      groupId,
      clusterName,
      processArgsJson,
      diskSizeGB,
      mongoDBVersion,
      instanceSize,
      'PATCH'
    );
  },

  getClusterUsageStats(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/clusterUsageStats`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getReplicationLag(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/replicationLag`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  grantEmployeeClusterAccess(groupId, clusterName, grantType) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/grantEmployeeClusterAccess`, {
      method: 'POST',
      body: JSON.stringify({ grantType }),
    });
  },

  revokeEmployeeClusterAccess(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/revokeEmployeeClusterAccess`, {
      method: 'POST',
    });
  },

  fixFeatureCompatibilityVersion(groupId: string, clusterName: string, expirationDate: number) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/featureCompatibilityVersion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams({
        expirationDate,
      }),
    });
  },

  unfixFeatureCompatibilityVersion(groupId: string, clusterName: string) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/featureCompatibilityVersion`, {
      method: 'DELETE',
    });
  },

  updateFixedFeatureCompatibilityVersion(groupId: string, clusterName: string, expirationDate: number) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/featureCompatibilityVersion`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams({
        expirationDate,
      }),
    });
  },

  fetchNamespaces(groupId, clusterName, requestOptions = {}): Promise<Array<string>> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/data/namespaces`, {
      method: 'GET',
      ...requestOptions,
    }).then((resp) => resp.json());
  },

  fetchNamespacesWithUUID(groupId, clusterName, requestOptions = {}): Promise<Array<NamespaceWithUUID>> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/data/namespacesWithUUID`, {
      method: 'GET',
      ...requestOptions,
    }).then((resp) => resp.json());
  },

  fetchDatabases(groupId, clusterName, requestOptions = {}) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/data/databases`, {
      method: 'GET',
      ...requestOptions,
    }).then((resp) => resp.json());
  },

  runSearchAggregation(groupId, clusterName, databaseName, collectionName, searchQuery) {
    return fetchWrapper(
      `/nds/clusters/${groupId}/${clusterName}/data/databases/${databaseName}/collections/${collectionName}/runSearchAggregation`,
      {
        method: 'POST',
        body: JSON.stringify(searchQuery),
      }
    ).then((resp) => resp.json());
  },

  sampleCollectionFieldNames(groupId, clusterName, databaseName, collectionName, requestOptions = {}) {
    return fetchWrapper(
      `/nds/clusters/${groupId}/${clusterName}/data/databases/${databaseName}/collections/${collectionName}/sampleCollectionFieldNames`,
      {
        method: 'GET',
        ...requestOptions,
      }
    ).then((resp) => resp.json());
  },

  getInstanceHardwareReplicationViews(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/instances`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  createTemplateCluster(
    groupId: string,
    templateKey: TemplateKey,
    templateParams: ClusterTemplateParamsView,
    recaptchaToken = ''
  ): Promise<ClusterDescription> {
    const tagsWithVisibility = templateParams.tags;
    return fetchWrapper(`/nds/clusters/${groupId}/template/${templateKey}?recaptchaToken=${recaptchaToken}`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ ...templateParams, tags: tagsWithVisibility }),
    }).then((resp) => resp.json());
  },
  /** Looks up what cluster description would result from calling the createTemplateCluster API.
   * Does not actually create a cluster or use any other resources.
   */
  getTemplateClusterDescription(
    groupId: string,
    templateKey: TemplateKey,
    /** Tags and automateSecurity don't affect the cluster description, so they aren't necessary for this call */
    templateParams: Omit<ClusterTemplateParamsView, 'tags' | 'automateSecurity'> & {
      tags?: never;
      automateSecurity?: never;
    }
  ): Promise<ClusterDescription> {
    const searchParams = new URLSearchParams(
      // Convert all the non-string values to their JSON representations
      Object.fromEntries(
        Object.entries(templateParams).map(([key, val]) => [key, typeof val === 'string' ? val : JSON.stringify(val)])
      )
    );
    return fetchWrapper(`/nds/clusters/${groupId}/template/${templateKey}?${searchParams}`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },
  /** Looks up a cost estimate for what a given template would cost in a given region.
   * NOTE: Serverless deployments have slightly different fields returned
   */
  getTemplateClusterCostEstimate<Key extends TemplateKey>(
    groupId: string,
    templateKey: Key,
    /** Tags and automateSecurity don't affect the cluster description, and clusterName and loadSampleDataset don't affect
     * the cost estimate, so they aren't necessary for this call */
    templateParams: Omit<ClusterTemplateParamsView, 'tags' | 'automateSecurity' | 'clusterName' | 'loadSampleDataset'>
  ): Promise<Key extends TemplateKey.SERVERLESS ? PurchaseTransactionBillingEstimate : CostEstimate> {
    const searchParams = new URLSearchParams(
      // Convert all the non-string values to their JSON representations
      Object.fromEntries(
        Object.entries(templateParams).map(([key, val]) => [key, typeof val === 'string' ? val : JSON.stringify(val)])
      )
    );
    return fetchWrapper(`/nds/clusters/${groupId}/template/${templateKey}/costEstimate?${searchParams}`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },
};
