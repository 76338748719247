import { AlertConfig } from '@packages/types/alerts/alertConfigs/AlertConfig';

import fetchWrapper from '@packages/common/services/api/fetchWrapper';

export function getProjectAlert(alertId: string, groupId: string) {
  return fetchWrapper(`/activity/alerts/group/${groupId}/alert/${alertId}`, {
    method: 'GET',
    headers: { Accept: 'application/json' },
  }).then((resp) => resp.json());
}

export function getOrgAlert(alertId: string, orgId: string) {
  return fetchWrapper(`/orgs/${orgId}/alerts/${alertId}`, {
    method: 'GET',
    headers: { Accept: 'application/json' },
  }).then((resp) => resp.json());
}

export const getAlertConfigsForProject = async (groupId: string) => {
  return fetchWrapper(`/activity/alertConfigs/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
};

export const upsertAlertConfig = async (groupId: string, payload: AlertConfig) => {
  const alertConfigId = payload?.id;
  if (alertConfigId) {
    return fetchWrapper(`/activity/alertConfigs/${groupId}/${alertConfigId}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  } else {
    return fetchWrapper(`/activity/alertConfigs/${groupId}`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  }
};

export const saveAlertConfig = async (alertConfig: AlertConfig) => {
  const groupId = alertConfig.cid;
  if (groupId) {
    return await upsertAlertConfig(groupId, alertConfig);
  }
};

export function getProjectAlertConfig(alertConfigId: string, groupId: string): Promise<AlertConfig> {
  return fetchWrapper(`/activity/alertConfigs/${groupId}/${alertConfigId}`, {
    method: 'GET',
    headers: { Accept: 'application/json' },
  }).then((resp) => resp.json());
}
