const WEBPACK_DEV_SERVER_HOSTNAME = 'http://localhost:8081';

export const imageSrc = (src = '', cdnEnabled = true): string => {
  if (!src) {
    return '';
  }

  let hostname = window.location.origin;
  let url = src;

  // we'll enable the CDN hostnames when ready
  // webpack dev server is set as ASSETS_URL in skip_assets mode, but does not serve images
  if (cdnEnabled && window.ASSETS_URL && !window.ASSETS_URL.includes(WEBPACK_DEV_SERVER_HOSTNAME)) {
    hostname = window.ASSETS_URL;
    if (url.startsWith('/static/')) {
      url = `/mms${url}`;
    }
  }

  // base url (hostname here) is only applied if it is a relative url
  try {
    return new URL(url, hostname).toString();
  } catch (e) {
    return url;
  }
};
