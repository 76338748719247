import { ClusterDescriptionModel } from '@packages/types/nds/clusterDescription';

export interface CheckRegionalized {
  isNDSGroup: boolean | null;
  isRegionalizationFlagEnabled: boolean | null;
  clusterDescription: ClusterDescriptionModel | undefined;
  groupRegion: string | null;
}

export function getDataProcessingRegionUrl({
  isNDSGroup,
  isRegionalizationFlagEnabled,
  clusterDescription,
  groupRegion,
}: CheckRegionalized) {
  if (!isNDSGroup || !isRegionalizationFlagEnabled) {
    return;
  }
  if (!clusterDescription) {
    console.warn(
      'Regionalized endpoint with Atlas group should have clusterDescription passed through. ' +
        'These requests will begin to fail once regionalization is turned on by default.'
    );
    // Fall back to group level region if clusterDescription is missing
    return groupRegion;
  }
  if (!clusterDescription.hasDataProcessingRegion()) {
    console.error(
      'clusterDescription must have dataProcessingRegion set. ' +
        'These requests will begin to fail once regionalization is turned on by default.'
    );
    // Fall back to group level region when clusterDescription region is missing
    return groupRegion;
  }
  // still possibly returns null if DPR does not supportRegionalization
  return clusterDescription?.getDataProcessingRegion();
}
