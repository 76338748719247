import Cookies from 'js-cookie';

import {
  MarketplaceRegistrationCookie,
  PartnerCustomerLinkOrgResult,
  PartnerLinkStatusCode,
  PartnerSubscriptionStatus,
  PartnerSubscriptionStatusCode,
  PartnerSubscriptionStatusResult,
  PartnerType,
} from '@packages/types/billing';
import { PaymentMethod } from '@packages/types/billing';

export const getPartnerSubscriptionStatusFromResponse = (
  response: PartnerSubscriptionStatusResult
): PartnerSubscriptionStatus => {
  if (response.success) {
    return PartnerSubscriptionStatus.SUCCESS;
  }
  if (!response.success && response.subscriptionStatusCode === PartnerSubscriptionStatusCode.PENDING) {
    return PartnerSubscriptionStatus.PENDING;
  }

  return PartnerSubscriptionStatus.FAILURE;
};

export const getLinkOrgStatusFromResponse = (response: PartnerCustomerLinkOrgResult): PartnerSubscriptionStatus => {
  if (response.success) {
    return PartnerSubscriptionStatus.SUCCESS;
  }
  if (!response.success && response.linkStatusCode === PartnerLinkStatusCode.PENDING_CONFIRMATION) {
    return PartnerSubscriptionStatus.PENDING;
  }

  return PartnerSubscriptionStatus.FAILURE;
};

export const isPartnerType = (val: unknown): val is PartnerType => Object.values(PartnerType).some((pt) => val === pt);

export const getMarketplaceAccountLinkQueryString = (): PartnerType | null => {
  const queryParamsAfterHash = window.location.hash.split('?')[1];
  if (!queryParamsAfterHash) {
    return null;
  }

  const marketplaceAccountLinkQuery = new URLSearchParams(queryParamsAfterHash).get('marketplaceAccountLink');
  if (!isPartnerType(marketplaceAccountLinkQuery)) {
    return null;
  }
  return marketplaceAccountLinkQuery;
};

const selfServicePaymentMethodToPartnerType = {
  AWS_MARKETPLACE_SELF_SERVE: PartnerType.AWS,
  GCP_MARKETPLACE_SELF_SERVE: PartnerType.GCP,
  AZURE_MARKETPLACE_SELF_SERVE: PartnerType.AZURE,
} as const;

export const getMarketplacePartnerTypeFromPaymentMethod = (
  paymentMethod: PaymentMethod | null | undefined
): PartnerType | null => selfServicePaymentMethodToPartnerType[paymentMethod?.type ?? ''] ?? null;

export const getMarketplaceRegistrationCookie = (): MarketplaceRegistrationCookie | null => {
  const rawMarketplaceToken = Cookies.get('marketplaceToken');
  if (!rawMarketplaceToken) {
    return null;
  }
  const decodedMarketplaceToken = decodeURI(rawMarketplaceToken);
  return JSON.parse(decodedMarketplaceToken);
};

export const getDateFromBillingAnalyticsShortString = (dateString: string): Date | undefined => {
  if (!dateString) {
    return undefined;
  }

  const dateParts = dateString.split('-').map((datePart) => parseInt(datePart));
  return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
};
