import { groupBy } from 'underscore';

import * as api from '@packages/common/services/api';

const SET_INVOICE = 'invoice/SET_INVOICE';
const SET_MULTIPLE_INVOICES = 'invoice/SET_MULTIPLE_INVOICES';
const SET_ADDRESS = 'invoice/SET_ADDRESS';
const SET_LINE_ITEMS = 'invoice/SET_LINE_ITEMS';
const SET_NON_CREDIT_LINE_ITEMS = 'invoice/SET_NON_CREDIT_LINE_ITEMS';
const SET_PER_PROJECT_PRICE_CENTS = 'invoice/SET_PER_PROJECT_PRICE_CENTS';
const SET_CREDIT_DRAWDOWN_PAYMENTS = 'invoice/SET_CREDIT_DRAWDOWN_PAYMENTS';
const SET_NON_MONTHLY_COMMITMENT_PAYMENTS = 'invoice/SET_NON_MONTHLY_COMMITMENT_PAYMENTS';
const SET_MONTHLY_COMMITMENT_PAYMENTS = 'invoice/SET_MONTHLY_COMMITMENT_PAYMENTS';
const SET_REFUNDS = 'invoice/SET_REFUNDS';
const SET_INVOICE_SKU_SERVICE_SUMMARIES = 'invoice/SET_INVOICE_SKU_SERVICE_SUMMARIES';
const SET_INVOICE_BACKUP_SUMMARY = 'invoice/SET_INVOICE_BACKUP_SUMMARY';
const SET_INVOICE_SUPPORT_SUMMARY = 'invoice/SET_INVOICE_SUPPORT_SUMMARY';
const SET_LINE_ITEMS_BY_SKU = 'invoice/SET_LINE_ITEMS_BY_SKU';

const SET_DAILY_AGGREGATED_USAGE_SUMMARY = 'invoice/SET_DAILY_AGGREGATED_USAGE_SUMMARY';

const initialState = {};

// Reducer
export default function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INVOICE: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].invoice = payload;
      return nextState;
    }
    case SET_MULTIPLE_INVOICES: {
      const { payload } = action;

      if (payload.length === 0) {
        return state;
      }

      const nextState = { ...state };

      return payload.reduce((acc, invoice) => {
        if (!nextState[invoice.id]) {
          nextState[invoice.id] = {};
        }
        nextState[invoice.id].invoice = invoice;
        return acc;
      }, nextState);
    }
    case SET_ADDRESS: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].address = payload;
      return nextState;
    }
    case SET_LINE_ITEMS: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].lineItems = payload;
      return nextState;
    }
    case SET_LINE_ITEMS_BY_SKU: {
      const { meta, payload } = action;

      const resultsBySku = groupBy(payload, 'sku');
      return {
        ...state,
        [meta.invoiceId]: {
          ...state[meta.invoiceId],
          lineItemsBySKU: {
            ...state[meta.invoiceId]?.lineItemsBySKU,
            ...resultsBySku,
          },
        },
      };
    }
    case SET_NON_CREDIT_LINE_ITEMS: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].nonCreditLineItems = payload;
      return nextState;
    }
    case SET_PER_PROJECT_PRICE_CENTS: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].perProjectPriceCents = payload;
      return nextState;
    }
    case SET_CREDIT_DRAWDOWN_PAYMENTS: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].creditDrawdownPayments = payload;
      return nextState;
    }
    case SET_NON_MONTHLY_COMMITMENT_PAYMENTS: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].nonMonthlyCommitmentPayments = payload;
      return nextState;
    }
    case SET_MONTHLY_COMMITMENT_PAYMENTS: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].monthlyCommitmentPayments = payload;
      return nextState;
    }
    case SET_REFUNDS: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].refunds = payload;
      return nextState;
    }
    case SET_INVOICE_SKU_SERVICE_SUMMARIES: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].skuServiceSummaries = payload;
      return nextState;
    }
    case SET_INVOICE_BACKUP_SUMMARY: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].backupSummary = payload;
      return nextState;
    }
    case SET_INVOICE_SUPPORT_SUMMARY: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].supportSummary = payload;
      return nextState;
    }

    case SET_DAILY_AGGREGATED_USAGE_SUMMARY: {
      const { meta, payload } = action;
      const nextState = { ...state };
      if (!nextState[meta.invoiceId]) {
        nextState[meta.invoiceId] = {};
      }
      nextState[meta.invoiceId].dailyAggregatedUsageSummary = payload;
      return nextState;
    }

    default:
      return state;
  }
}

// Selectors

export const getInvoice = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].invoice) || null;

export const getAddress = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].address) || null;
export const getNonCreditLineItems = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].nonCreditLineItems) || [];
export const getLineItems = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].lineItems) || [];

export const getLineItemsBySKU = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].lineItemsBySKU) || {};
export const getPerProjectPriceCents = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].perProjectPriceCents) || {};
export const getRefunds = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].refunds) || [];

export const getInvoiceSkuServiceSummaries = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].skuServiceSummaries) || [];

export const getInvoiceBackupSummary = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].backupSummary) || [];

export const getPrepaidCredits = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].prepaidCredits) || [];

export const getMonthlyCommitmentCredits = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].monthlyCommitmentCredits) || [];

export const getCreditDrawdownPayments = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].creditDrawdownPayments) || [];

export const getNonMonthlyCommitmentPayments = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].nonMonthlyCommitmentPayments) || [];

export const getMonthlyCommitmentPayments = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].monthlyCommitmentPayments) || [];

export const getInvoiceSupportSummary = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].supportSummary) || [];

export const getDailyAggregatedUsageSummary = (state, { invoiceId }) =>
  (state.invoice[invoiceId] && state.invoice[invoiceId].dailyAggregatedUsageSummary) || [];

// Action Creators

export const setInvoice = (payload) => ({
  type: SET_INVOICE,
  payload,
  meta: {
    invoiceId: payload.id,
  },
});

export const setMultipleInvoices = (payload = []) => ({
  type: SET_MULTIPLE_INVOICES,
  payload,
});

export const setAddress = ({ address, invoiceId }) => ({
  type: SET_ADDRESS,
  payload: address,
  meta: {
    invoiceId,
  },
});

export const setLineItems = ({ lineItems, invoiceId }) => ({
  type: SET_LINE_ITEMS,
  payload: lineItems,
  meta: {
    invoiceId,
  },
});

export const setLineItemsBySKU = ({ lineItems, invoiceId }) => ({
  type: SET_LINE_ITEMS_BY_SKU,
  payload: lineItems,
  meta: {
    invoiceId,
  },
});

export const setNonCreditLineItems = ({ nonCreditLineItems, invoiceId }) => ({
  type: SET_NON_CREDIT_LINE_ITEMS,
  payload: nonCreditLineItems,
  meta: {
    invoiceId,
  },
});

export const setPerProjectPriceCents = ({ perProjectPriceCents, invoiceId }) => ({
  type: SET_PER_PROJECT_PRICE_CENTS,
  payload: perProjectPriceCents,
  meta: {
    invoiceId,
  },
});

export const setCreditDrawdownPaymentViews = ({ creditDrawdownPaymentViews, invoiceId }) => ({
  type: SET_CREDIT_DRAWDOWN_PAYMENTS,
  payload: creditDrawdownPaymentViews,
  meta: {
    invoiceId,
  },
});

export const setNonMonthlyCommitPaymentViews = ({ nonMonthlyCommitPaymentViews, invoiceId }) => ({
  type: SET_NON_MONTHLY_COMMITMENT_PAYMENTS,
  payload: nonMonthlyCommitPaymentViews,
  meta: {
    invoiceId,
  },
});

export const setRefunds = ({ refunds, invoiceId }) => ({
  type: SET_REFUNDS,
  payload: refunds,
  meta: {
    invoiceId,
  },
});

const setInvoiceSkuServiceSummaries = ({ data, invoiceId }) => ({
  type: SET_INVOICE_SKU_SERVICE_SUMMARIES,
  payload: data,
  meta: {
    invoiceId,
  },
});

const setInvoiceBackupSummary = ({ data, invoiceId }) => ({
  type: SET_INVOICE_BACKUP_SUMMARY,
  payload: data,
  meta: {
    invoiceId,
  },
});

const setInvoiceSupportSummary = ({ data, invoiceId }) => ({
  type: SET_INVOICE_SUPPORT_SUMMARY,
  payload: data,
  meta: {
    invoiceId,
  },
});

const setMonthlyCommitmentPayments = ({ data, invoiceId }) => ({
  type: SET_MONTHLY_COMMITMENT_PAYMENTS,
  payload: data,
  meta: {
    invoiceId,
  },
});

const setInvoiceDailyAggregatedUsageSummary = ({ data, invoiceId }) => ({
  type: SET_DAILY_AGGREGATED_USAGE_SUMMARY,
  payload: data,
  meta: {
    invoiceId,
  },
});

export const loadInvoice = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getInvoice({ orgId, invoiceId }).then((response) => {
    dispatch(setInvoice(response));
  });
};

export const loadAddress = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getInvoiceAddress({ orgId, invoiceId }).then((response) => {
    dispatch(
      setAddress({
        address: response,
        invoiceId,
      })
    );
  });
};

export const loadNonCreditLineItems = (orgId, invoiceId, canViewZeroCentLineItems) => (dispatch) => {
  return api.billing.getNonCreditLineItems({ orgId, invoiceId, canViewZeroCentLineItems }).then((response) => {
    dispatch(
      setNonCreditLineItems({
        nonCreditLineItems: response,
        invoiceId,
      })
    );
  });
};

export const loadLineItemsBySKU = (orgId, invoiceId, sku) => (dispatch) => {
  return api.billing.getLineItemsBySKU({ orgId, invoiceId, sku }).then((response) => {
    dispatch(
      setLineItemsBySKU({
        lineItems: response,
        invoiceId,
      })
    );
  });
};

export const loadPerProjectPriceCents = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getPerProjectPriceCents({ orgId, invoiceId }).then((response) => {
    dispatch(
      setPerProjectPriceCents({
        perProjectPriceCents: response,
        invoiceId,
      })
    );
  });
};

export const loadInvoiceCreditDrawdownPayments = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getCreditDrawdownPayments({ orgId, invoiceId }).then((response) => {
    dispatch(
      setCreditDrawdownPaymentViews({
        creditDrawdownPaymentViews: response,
        invoiceId,
      })
    );
  });
};

export const loadInvoiceNonMonthlyCommitmentPayments = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getNonMonthlyCommitmentPayments({ orgId, invoiceId }).then((response) => {
    dispatch(
      setNonMonthlyCommitPaymentViews({
        nonMonthlyCommitPaymentViews: response,
        invoiceId,
      })
    );
  });
};

export const loadInvoiceMonthlyCommitmentPayments = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getInvoiceMonthlyCommitmentPayments({ orgId, invoiceId }).then((response) => {
    dispatch(
      setMonthlyCommitmentPayments({
        data: response,
        invoiceId,
      })
    );
  });
};

export const loadRefunds = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getRefunds({ orgId, invoiceId }).then((response) => {
    dispatch(
      setRefunds({
        refunds: response,
        invoiceId,
      })
    );
  });
};

export const loadInvoiceSkuServiceSummaries = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getInvoiceSkuServiceSummaries({ orgId, invoiceId }).then((data) => {
    dispatch(
      setInvoiceSkuServiceSummaries({
        data,
        invoiceId,
      })
    );
  });
};

export const loadInvoiceBackupSummary = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getInvoiceBackupSummary({ orgId, invoiceId }).then((data) => {
    dispatch(
      setInvoiceBackupSummary({
        data,
        invoiceId,
      })
    );
  });
};

export const loadInvoiceSupportSummary = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getInvoiceSupportSummary({ orgId, invoiceId }).then((data) => {
    dispatch(
      setInvoiceSupportSummary({
        data,
        invoiceId,
      })
    );
  });
};

export const loadInvoiceDailyAggregatedUsageSummary = (orgId, invoiceId) => (dispatch) => {
  return api.billing.getDailyUsageForInvoice({ orgId, invoiceId }).then((data) => {
    dispatch(
      setInvoiceDailyAggregatedUsageSummary({
        data,
        invoiceId,
      })
    );
  });
};
