import queryString from 'query-string';

import { Group } from '@packages/types/groups';
import { CreateOrganization } from '@packages/types/organizations';
import { ResourceView } from '@packages/types/resourceView';
import { UiAccessListIpAddress } from '@packages/types/uiAccessList';
import { VercelProject } from '@packages/types/vercel';

import fetchWrapper, { formParams } from './fetchWrapper';

export default {
  groups({ orgId }: { orgId: string }): Promise<Array<Group>> {
    return fetchWrapper(`/orgs/${orgId}/groups`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  // This is added to support fetching all resources within an organization for a user
  // that is able to create or edit policy assignments for Users or API Keys.
  allResources({ orgId }: { orgId: string }): Promise<Array<ResourceView>> {
    return fetchWrapper(`/orgs/${orgId}/resources`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createOrganization({
    name,
    initialGroupType,
    apiIpAccessListRequired,
    securityContact,
    centralUrl = '',
  }: CreateOrganization) {
    const params = {
      name,
      initialGroupType,
      apiIpAccessListRequired,
      securityContact,
    };

    return fetchWrapper(`${centralUrl}/orgs`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(params),
    }).then((resp) => resp.json());
  },

  organization({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateOrgName({ orgId, orgName }) {
    return fetchWrapper(`/orgs/${orgId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        name: orgName,
      }),
    }).then((resp) => resp.json());
  },

  clearSlackToken({ orgId }) {
    return fetchWrapper(`/alertOAuth2/org/${orgId}/clearSlackToken`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  getMultipleOrganizations() {
    return fetchWrapper('/orgs/orgData', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  addUsers({ orgId, users }) {
    return fetchWrapper(`/orgs/${orgId}/addMultipleUsers`, {
      method: 'POST',
      body: JSON.stringify(users),
    }).then((resp) => resp.json());
  },

  deleteOrganization(orgId) {
    return fetchWrapper(`/orgs/${orgId}`, {
      method: 'DELETE',
    });
  },

  deleteUser({ orgId, username }) {
    return fetchWrapper(`/orgs/${orgId}/users/${encodeURIComponent(username)}`, {
      method: 'DELETE',
    });
  },

  deleteInvite({ orgId, username }) {
    return fetchWrapper(`/orgs/${orgId}/invitations/${encodeURIComponent(username)}`, {
      method: 'DELETE',
    });
  },

  editUser({ orgId, username, user }) {
    return fetchWrapper(`/orgs/${orgId}/users/${encodeURIComponent(username)}`, {
      method: 'PATCH',
      body: JSON.stringify({
        username,
        roles: user.roles,
      }),
    }).then((resp) => resp.json());
  },

  getUsers({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/users`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getApiUserApiAccessList({ orgId, apiUserId }) {
    return fetchWrapper(`/orgs/${orgId}/apiUsers/${apiUserId}/publicApiAccessList`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getActivities({ orgId, params }) {
    return fetchWrapper(`/orgs/${orgId}/activity/list?${queryString.stringify(params)}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  filterActivities({ orgId, payload }) {
    return fetchWrapper(`/orgs/${orgId}/activity/list`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  },

  saveFilter({ orgId, payload }) {
    return fetchWrapper(`/orgs/${orgId}/activity/filters`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  },

  downloadActivities({ orgId, payload }) {
    return fetchWrapper(`/orgs/${orgId}/activity/downloadEvents`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  },

  getOrgEvents({ orgId, payload }) {
    return fetchWrapper(`/cloud/activity/org/${orgId}`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((resp) => {
      return resp.json();
    });
  },

  getUsersAndInvites({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/usersAndInvites`, {
      method: 'GET',
    }).then((resp) => {
      return resp.json();
    });
  },

  getApiUsers({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/apiUsers`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteApiUser({ orgId, apiUserId }) {
    return fetchWrapper(`/orgs/${orgId}/apiUsers/${apiUserId}`, {
      method: 'DELETE',
    });
  },

  createApiUser({ orgId, desc, roles, policyAssignments }) {
    return fetchWrapper(`/orgs/${orgId}/apiUsers`, {
      method: 'POST',
      body: JSON.stringify({
        desc,
        roles,
        policyAssignments,
      }),
    }).then((resp) => resp.json());
  },

  updateApiUser({ orgId, apiUserId, desc, roles, policyAssignments }) {
    return fetchWrapper(`/orgs/${orgId}/apiUsers/${apiUserId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        desc,
        roles,
        policyAssignments,
      }),
    }).then((resp) => resp.json());
  },

  addApiUserAccessListEntry({ orgId, apiUserId, ipAddress }) {
    return fetchWrapper(`/orgs/${orgId}/apiUsers/${apiUserId}/publicApiAccessList`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({ ipAddress }),
    }).then((response) => response.json());
  },

  deleteApiUserAccessListEntry({ orgId, apiUserId, accessListEntryId }) {
    return fetchWrapper(`/orgs/${orgId}/apiUsers/${apiUserId}/publicApiAccessList/${accessListEntryId}`, {
      method: 'DELETE',
    });
  },

  updateOrgUiAccessListInheritance({ orgId, apiUserId, shouldApplyOrgUiAccessListForApi }) {
    return fetchWrapper(`/orgs/${orgId}/apiUsers/${apiUserId}/orgUiAccessListInheritance`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
      body: formParams({ shouldApplyOrgUiAccessListForApi }),
    });
  },

  getTeams({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/teams`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getTeamById({ orgId, teamId }) {
    return fetchWrapper(`/orgs/${orgId}/teams/${teamId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  addUsersToTeam({ orgId, teamId, users }) {
    return fetchWrapper(`/orgs/${orgId}/teams/addMultipleUsers/${teamId}`, {
      method: 'POST',
      body: JSON.stringify(users),
    }).then((resp) => resp.json());
  },

  getOrgServiceAccounts({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getOrgServiceAccount({ orgId, clientId }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createOrgServiceAccount({ orgId, payload }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  },

  editOrgServiceAccount({ orgId, clientId, payload }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  },

  listServiceAccountGroups({ orgId, clientId }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}/groups`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteServiceAccount({ orgId, clientId }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}`, {
      method: 'DELETE',
    });
  },

  createOrgServiceAccountSecret({ orgId, clientId, secretExpiresAfterHours }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}/secrets`, {
      method: 'POST',
      body: JSON.stringify({ secretExpiresAfterHours }),
    }).then((resp) => resp.json());
  },

  deleteOrgServiceAccountSecret({ orgId, clientId, secretId }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}/secrets/${secretId}`, {
      method: 'DELETE',
    });
  },

  getOrgServiceAccountAccessList({ orgId, clientId }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}/accessList`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  addOrgServiceAccountAccessList({ orgId, clientId, accessList }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}/accessList`, {
      method: 'POST',
      body: JSON.stringify(accessList),
    }).then((resp) => resp.json());
  },

  deleteOrgServiceAccountAccessListEntry({ orgId, clientId, entryToDelete }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}/accessList/${encodeURIComponent(entryToDelete)}`, {
      method: 'DELETE',
    });
  },

  setMaxServiceAccountSecretValidity({ orgId, maxSecretValidityInHours }) {
    const params = {
      maxSecretValidityInHours,
    };

    const resp = fetchWrapper(`/orgs/${orgId}/setMaxServiceAccountSecretValidityInHours`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    });

    return resp;
  },

  getServiceAccountOrgUiAccessListInheritance({ orgId, clientId }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}/shouldApplyOrgUiAccessListForApi`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateServiceAccountOrgUiAccessListInheritance({ orgId, clientId, shouldApplyOrgUiAccessListForApi }) {
    return fetchWrapper(`/orgs/${orgId}/serviceAccounts/${clientId}/orgUiAccessListInheritance`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PUT',
      body: formParams({ shouldApplyOrgUiAccessListForApi }),
    });
  },

  leaveOrganization({ orgId, username }) {
    return fetchWrapper(`/orgs/${orgId}/users/self/${encodeURIComponent(username)}`, {
      method: 'DELETE',
    });
  },

  search(term = '', isNDSOnly, sourceGroupId, filterAWSCNRegionOnlyGroups) {
    return fetchWrapper(
      `/orgs/search?term=${encodeURIComponent(term)}&ndsOnly=${encodeURIComponent(
        isNDSOnly
      )}&filterAWSCNRegionOnlyGroups=${filterAWSCNRegionOnlyGroups}${
        sourceGroupId ? `&sourceGroupId=${sourceGroupId}` : ''
      }`,
      {
        method: 'GET',
      }
    ).then((resp) => resp.json());
  },

  v2Search(term = '', isNDSOnly, sourceGroupId, filterAWSCNRegionOnlyGroups) {
    return fetchWrapper(
      `/orgs/v2/search?term=${encodeURIComponent(term)}&ndsOnly=${encodeURIComponent(
        isNDSOnly
      )}&filterAWSCNRegionOnlyGroups=${filterAWSCNRegionOnlyGroups}${
        sourceGroupId ? `&sourceGroupId=${sourceGroupId}` : ''
      }`,
      {
        method: 'GET',
      }
    ).then((resp) => resp.json());
  },

  validateGroupName({ orgId, groupName, centralUrl = '' }) {
    const params = {
      groupName,
    };

    return fetchWrapper(`${centralUrl}/orgs/${orgId}/validateGroupName`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      credentials: 'include',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  validateOrgName({ orgName }) {
    const params = {
      orgName,
    };

    return fetchWrapper('/orgs/validateOrgName', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  validateNewUser({ username }) {
    const params = {
      username,
    };

    return fetchWrapper('/orgs/validateNewUser', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  validateUser({ orgId, username }) {
    const params = {
      username,
    };

    return fetchWrapper(`/orgs/${orgId}/validateUser`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  validateUserEmail({ username }) {
    const params = {
      username,
    };

    return fetchWrapper('/orgs/validateNewUser', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  setMultiFactorAuthRequired({ orgId, multiFactorAuthRequired }) {
    const params = {
      multiFactorAuthRequired,
    };

    return fetchWrapper(`/orgs/${orgId}/setMultiFactorAuthRequired`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  setPublicApiAccessListRequired({ orgId, publicApiWhitelistRequired }) {
    const params = {
      publicApiAccessListRequired: publicApiWhitelistRequired,
    };

    return fetchWrapper(`/orgs/${orgId}/setPublicApiAccessListRequired`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  setRestrictEmployeeAccess({ orgId, restrictEmployeeAccess }) {
    const params = {
      restrictEmployeeAccess,
    };

    return fetchWrapper(`/orgs/${orgId}/setRestrictEmployeeAccess`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  setGenAIFeaturesEnabled({ orgId, genAIFeaturesEnabled }) {
    return fetchWrapper(`/orgs/${orgId}/setGenAiFeaturesEnabled`, {
      method: 'POST',
      body: JSON.stringify(genAIFeaturesEnabled),
    }).then((resp) => resp.status);
  },

  acceptCNRegionsTermsOfService({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/acceptCNRegionsTermsOfService`, {
      method: 'PATCH',
    }).then((resp) => resp.json());
  },

  createTeam({ orgId, name, users }) {
    return fetchWrapper(`/orgs/${orgId}/teams`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        users,
      }),
    }).then((resp) => resp.json());
  },

  validateTeamUser({ orgId, teamId, username }) {
    const params = {
      username,
    };

    return fetchWrapper(`/orgs/${orgId}/teams/validateUser/${teamId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  validateConfigLimits({ usernames }) {
    const params = {
      usernames,
    };

    return fetchWrapper(`/orgs/validateConfigLimits?${formParams(params)}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  renameTeam({ orgId, teamId, teamName }) {
    return fetchWrapper(`/orgs/${orgId}/teams/${teamId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        name: teamName,
      }),
    }).then((resp) => resp.json());
  },

  deleteTeam({ orgId, teamId }) {
    return fetchWrapper(`/orgs/${orgId}/teams/${teamId}`, {
      method: 'DELETE',
    });
  },

  deleteUserFromTeam({ orgId, teamId, username }) {
    return fetchWrapper(`/orgs/${orgId}/teams/${teamId}/users/${encodeURIComponent(username)}`, {
      method: 'DELETE',
    });
  },

  deleteSelfFromTeam({ orgId, teamId }) {
    return fetchWrapper(`/orgs/${orgId}/teams/${teamId}/user`, {
      method: 'DELETE',
    });
  },

  loadVATStatus({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/vatStatus`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateVATStatus({ orgId, vatStatus }) {
    return fetchWrapper(`/orgs/${orgId}/vatStatus`, {
      method: 'PUT',
      body: JSON.stringify(vatStatus),
    });
  },

  getAlerts({ orgId, afterId, beforeId, acked, status }) {
    const query = queryString.stringify({ afterId, beforeId, acked, status });
    return fetchWrapper(`/orgs/${orgId}/alerts/list?${query}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getTags({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/tags`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createTag({ orgId, tag }) {
    return fetchWrapper(`/orgs/${orgId}/tags`, {
      method: 'POST',
      body: JSON.stringify(tag),
    }).then((resp) => resp.json());
  },

  getTag({ orgId, tagId }) {
    return fetchWrapper(`/orgs/${orgId}/tags/${tagId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateTag({ orgId, tag }) {
    return fetchWrapper(`/orgs/${orgId}/tags`, {
      method: 'PUT',
      body: JSON.stringify(tag),
    }).then((resp) => resp.json());
  },

  deleteTag({ orgId, tag }) {
    return fetchWrapper(`/orgs/${orgId}/tags/${tag.id}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  getResources({ orgId }) {
    return fetchWrapper(`/cloud/resources/orgs/${orgId}`, {
      method: 'GET',
    }).then((resp) => {
      return resp.json();
    });
  },

  getOpenAlertsCount({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/openAlertsCount`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAvailableAlertTargets({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs/availableTargets`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAlertSettings({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getSingleAlertSetting({ orgId, alertId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs/${alertId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createAlertSetting({ orgId, alertConfig }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs`, {
      method: 'POST',
      body: JSON.stringify(alertConfig),
    }).then((resp) => resp.json());
  },

  editAlertSetting({ orgId, alertConfig, alertConfigId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs/${alertConfigId}`, {
      method: 'PUT',
      body: JSON.stringify(alertConfig),
    }).then((resp) => resp.json());
  },

  deleteAlertSetting({ orgId, alertConfigId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs/${alertConfigId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  disableAlertSetting({ orgId, alertConfigId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs/${alertConfigId}/disable`, {
      method: 'PUT',
    }).then((resp) => resp.json());
  },

  enableAlertSetting({ orgId, alertConfigId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs/${alertConfigId}/enable`, {
      method: 'PUT',
    }).then((resp) => resp.json());
  },

  sfscSync({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/sfscSync`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAlertSettingHistory({ orgId, alertId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs/${alertId}/history`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDeletedAlertSettings({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/configs/deleted`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  acknowledgeAlert({ orgId, ackInMinutes, comment, selectedIds }) {
    const params = {
      ackInMinutes,
      comment,
      selectedIds,
    };

    return fetchWrapper(`/orgs/${orgId}/alerts/ack`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then((resp) => resp.json());
  },

  acknowledgeAllAlerts({ orgId, ackInMinutes, comment }) {
    const params = {
      ackInMinutes,
      comment,
    };

    return fetchWrapper(`/orgs/${orgId}/alerts/ackAll`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then((resp) => resp.json());
  },

  unacknowledgeAlert({ orgId, selectedIds }) {
    const params = {
      selectedIds,
    };

    return fetchWrapper(`/orgs/${orgId}/alerts/unack`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then((resp) => resp.json());
  },

  unacknowledgeAllAlerts({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/unackAll`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  testNotification({ orgId, notification }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/testNotification`, {
      method: 'POST',
      body: JSON.stringify(notification),
    }).then((resp) => resp.json());
  },

  testExistingNotification({ orgId, alertConfigId, notificationId, notification }) {
    return fetchWrapper(`/orgs/${orgId}/alerts/testNotification/${alertConfigId}/${notificationId}`, {
      method: 'POST',
      body: JSON.stringify(notification),
    }).then((resp) => resp.json());
  },

  deleteIpAccessLists({ orgId }) {
    return fetchWrapper(`/admin/orgs/${orgId}/ipAccessLists`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  terminateServices({ orgId }) {
    return fetchWrapper(`/admin/orgs/${orgId}/terminateServices`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  updateOrgPaymentStatus(orgId, paymentStatusType) {
    return fetchWrapper(`/admin/orgs/${orgId}/updatePaymentStatus?paymentStatusType=${paymentStatusType}`, {
      method: 'PUT',
    }).then((resp) => resp.json());
  },

  paymentMethodAdminOverride({ orgId, expirationDate }) {
    const params = {
      expirationDate,
    };
    return fetchWrapper(`/admin/orgs/${orgId}/paymentMethodOverride`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  removePaymentMethodAdminOverride({ orgId }) {
    return fetchWrapper(`/admin/orgs/${orgId}/removePaymentMethodOverride/remove`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  activateOrg({ orgId, suspensionDate }) {
    const params = {
      suspensionDate,
    };

    return fetchWrapper(`/admin/billing/delinquentOrgs/activate/${orgId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  setBypassAccessAutoRestriction({ orgId, bypassAccessAutoRestriction }) {
    const params = {
      bypassAccessAutoRestriction,
    };

    return fetchWrapper(`/admin/orgs/${orgId}/setBypassAccessAutoRestriction`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  getOrgSuspensionDate({ orgId }) {
    return fetchWrapper(`/admin/billing/delinquentOrgs/org/${orgId}/suspensionDate`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  terminateBackup({ orgId }) {
    return fetchWrapper(`/admin/billing/delinquentOrgs/terminateBackup/${orgId}`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  getOrgPolicies({ orgId }) {
    return fetchWrapper(`/orgs/${orgId}/policy`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  canRedirectToSupportPortal({ orgId }) {
    return fetchWrapper(`/support/canRedirectToSupportPortal/${orgId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getFederationSettings({ orgId }): Promise<{ id: string }> {
    return fetchWrapper(`/orgs/${orgId}/federationSettings`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  isOrgConnectedToAFederation(orgId: string) {
    return this.getFederationSettings({ orgId })
      .then((response) => response != null)
      .catch(() => false);
  },

  getDomainUsageStats({ orgId }: { orgId: string }): Promise<{ [key: string]: number }> {
    return fetchWrapper(`/orgs/${orgId}/domainUsageStats`, { method: 'GET' }).then((resp) => resp.json());
  },

  getUsersByGroup({ orgId }: { orgId: string }): Promise<{ [key: string]: any }> {
    return fetchWrapper(`/orgs/${orgId}/groups/users`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getVisibleGroupsWithUserRoles({
    orgId,
    centralUrl = '',
  }: {
    orgId: string;
    centralUrl?: string;
  }): Promise<[{ groupId: string; groupName: string; userRoles: Array<string> }]> {
    return fetchWrapper(`${centralUrl}/orgs/${orgId}/groups/user/roles`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  confirmVercelIntegration({
    orgId,
    vercelUserId,
    centralUrl,
  }: {
    orgId: string;
    vercelUserId: string;
    centralUrl: string;
  }) {
    return fetchWrapper(`${centralUrl}/orgs/${orgId}/integrations/vercel/confirmVercel`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        vercelUserId,
      }),
    });
  },

  disconnectVercel({
    orgId,
    deleteUsers,
    deleteAccessRule,
  }: {
    orgId: string;
    deleteUsers?: boolean;
    deleteAccessRule?: boolean;
  }) {
    return fetchWrapper(`/orgs/${orgId}/integrations/vercel/disconnectVercel`, {
      method: 'PATCH',
      body: JSON.stringify({
        deleteUsers,
        deleteAccessRule,
      }),
    });
  },

  getVercelProjects({ orgId, centralUrl }: { orgId: string; centralUrl: string }) {
    return fetchWrapper(`${centralUrl}/orgs/${orgId}/integrations/vercel/projects`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  linkClusterToVercelProjects({
    centralUrl = '',
    orgId,
    cluster,
    vercelProjects,
    vercelUserId,
    isEditingExistingLinking = false,
    groupId,
    setupInitialDataApi = false,
    dataApiKeyName,
    dataApiKey,
    dataApiUrl,
  }: {
    centralUrl?: string;
    orgId: string;
    cluster: string;
    vercelProjects: Array<VercelProject>;
    vercelUserId: string;
    isEditingExistingLinking?: boolean;
    groupId: string;
    setupInitialDataApi: boolean;
    dataApiKeyName?: string;
    dataApiKey?: string;
    dataApiUrl?: string;
  }) {
    return fetchWrapper(`${centralUrl}/orgs/${orgId}/integrations/vercel/${vercelUserId}/link/${groupId}`, {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify({
        cluster,
        vercelProjects,
        isEditingExistingLinking,
        setupInitialDataApi,
        dataApiKeyName,
        dataApiKey,
        dataApiUrl,
      }),
    }).then((resp) => resp.json());
  },

  unlinkClusterFromVercelProjects({
    orgId,
    cluster,
    vercelProjects,
    vercelUserId,
    groupId,
  }: {
    orgId: string;
    cluster: string;
    vercelProjects: Array<VercelProject>;
    vercelUserId: string;
    groupId: string;
  }) {
    return fetchWrapper(`/orgs/${orgId}/integrations/vercel/${vercelUserId}/link/${groupId}`, {
      method: 'DELETE',
      body: JSON.stringify({
        cluster,
        vercelProjects,
        isEditingExistingLinking: false,
      }),
    }).then((resp) => resp.json());
  },

  getUiAccessList({ orgId }: { orgId: string }): Promise<{ ipAddresses: Array<UiAccessListIpAddress> }> {
    return fetchWrapper(`/orgs/${orgId}/uiAccessList`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  createUiAccessList({
    orgId,
    ipAddresses,
  }: {
    orgId: string;
    ipAddresses: Array<Omit<UiAccessListIpAddress, 'includesUsersIpAddress' | 'type'>>;
  }): Promise<{
    ipAddresses: Array<UiAccessListIpAddress>;
  }> {
    return fetchWrapper(`/orgs/${orgId}/uiAccessList`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        ipAddresses,
      }),
    }).then((resp) => resp.json());
  },

  updateUiAccessList({
    orgId,
    ipAddresses,
  }: {
    orgId: string;
    ipAddresses: Array<Omit<UiAccessListIpAddress, 'includesUsersIpAddress' | 'type'>>;
  }): Promise<{
    ipAddresses: Array<UiAccessListIpAddress>;
  }> {
    return fetchWrapper(`/orgs/${orgId}/uiAccessList`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({
        ipAddresses,
      }),
    }).then((resp) => resp.json());
  },

  enableUiAccessList({ orgId, enable }: { orgId: string; enable: boolean }) {
    return fetchWrapper(`/orgs/${orgId}/uiAccessList`, {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify({
        enabled: enable,
      }),
    });
  },

  validateUiAccessListItem({ orgId, ipAddress }: { orgId: string; ipAddress: string }): Promise<{
    isDuplicate: boolean;
    invalidFormat: boolean;
    includesUsersIpAddress: boolean;
  }> {
    return fetchWrapper(`/orgs/${orgId}/uiAccessList/validation?ip=${ipAddress}`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  updateSecurityContact({ orgId, email }: { orgId: string; email: string }) {
    return fetchWrapper(`/orgs/${orgId}/securityContact`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
      credentials: 'include',
      body: formParams({
        email: email,
      }),
    }).then((resp) => resp.json());
  },
};
