import _ from 'underscore';

import {
  AzureBlobStorageDataStore,
  Collection,
  Database,
  DataSource,
  DataStore,
  DataStoreProvider,
  DLSAWSDataSource,
  DLSAzureDataSource,
  DLSGCPDataSource,
  GCSDataStore,
  HTTPDataSource,
  S3DataStore,
  StorageConfig,
  StorageConfigFailedReason,
  StorageConfigMMS,
  StorageConfigWarnReason,
  StorageValidationResult,
  StorageValidationStatus,
  ValidationResponse,
} from '@packages/types/nds/dataLakes';
import { DLSIngestionSink, IngestionPipeline } from '@packages/types/nds/pipelines';
import { CloudProvider } from '@packages/types/nds/provider';
import { RegionView } from '@packages/types/nds/replicationSpec';

import {
  DEFAULT_CLOUD_PROVIDER,
  DLS_AWS_STORE_NAME,
  DLS_AZURE_STORE_NAME,
  DLS_GCP_STORE_NAME,
  PARTITION_ATTRIBUTE_TYPES,
  WILDCARD,
} from '@packages/common/constants/dataLakes';
import { OnlineArchive } from '@packages/common/schemas/onlineArchive';
import { exceptionToMessage } from '@packages/common/services/errorHelper';
import {
  convertRegionNameToValue,
  convertStorageForMMSConsumption,
  findDataSource,
  validateObjectAsStorageConfig,
} from '@packages/common/utils/dataLake';
import { fromFullDatasetName } from '@packages/common/utils/dataset';
import {
  generateAzureBlobStorageStore,
  generateDLSAWSDataSource,
  generateDLSAWSStore,
  generateDLSAzureDataSource,
  generateDLSAzureStore,
  generateDLSGCPDataSource,
  generateDLSGCPStore,
  generateGCSDataStore,
  generateHttpDataSource,
  generateS3DataStore,
  generateWildcardDataSource,
} from '@packages/common/utils/objectInitializers';

/*
  Utilities for immutably updating storage config after user actions.  Consumed primarily by
  DataLakeConfigEditor.
 */

const databasesHasStore = (storeName: string, databases?: Array<Database>): boolean =>
  (databases || [])
    .flatMap((d) => d.collections || [])
    .flatMap((c) => c.dataSources || [])
    .some((ds) => ds.storeName === storeName);

const dlsStoreExistsForRegion = (store: DataStore, archiveProvider: string, archiveRegion: string): boolean => {
  if (store.provider === DataStoreProvider.DLS_AWS) {
    return archiveProvider === CloudProvider.AWS && archiveRegion === store.region;
  } else if (store.provider === DataStoreProvider.DLS_AZURE) {
    return archiveProvider === CloudProvider.AZURE && archiveRegion === store.region;
  } else if (store.provider === DataStoreProvider.DLS_GCP) {
    return archiveProvider === CloudProvider.GCP && archiveRegion === store.region;
  } else {
    return false;
  }
};

export const convertDataProcessRegionToNameFromRegionView = (
  provider: string,
  region: string | undefined,
  regions: Array<RegionView>
): string => {
  // region is the dataProcessRegion.region on the OnlineArchive. regions is a list
  // of regions with ADFA infrastructure.
  const regionView = regions.find((r) => r.provider === provider && r.key === region);
  if (regionView) {
    return regionView.name;
  }
  // If we failed to map the dataProcessRegion to a region with ADFA infrastructure,
  // it could be that this is a OAv3 cross-region archive (see MHOUSE-9019), for
  // which the data lives in a region without ADFA infrastructure. Such archives
  // should only exist through OAv1 migration, and all OAv1 archives are in AWS.
  // Conveniently, the mapping between dataProcessRegion (e.g. "US_EAST_2") and
  // regionName (e.g. "us-east-2") is a simple string translation for AWS.
  if (region && provider === CloudProvider.AWS) {
    return region.toLowerCase().replace(/_/g, '-');
  }

  throw new Error(`failed handling data process region`);
};

export const updateStorageConfigAddIngestionDataSource = (
  storageConfig: StorageConfig,
  dbName: string,
  collectionName: string,
  ingestionPipeline: IngestionPipeline
): StorageConfig => {
  if (!storageConfig?.databases || !storageConfig?.stores) {
    return storageConfig;
  }

  const { databases, stores } = storageConfig;
  const ingestionSink = ingestionPipeline.sink as DLSIngestionSink;
  const ingestionRegion = convertRegionNameToValue(ingestionSink.metadataRegion);

  const updatedDatabases = databases.map((d: Database) =>
    d.name === dbName
      ? {
          ...d,
          collections: d.collections.map((c: Collection) => {
            if (c.name !== collectionName) {
              return c;
            }

            return {
              ...c,
              dataSources: [
                ...c.dataSources,
                generateDLSAWSDataSource({
                  storeName: `${DLS_AWS_STORE_NAME}-${ingestionRegion}`,
                  pipelineId: ingestionPipeline._id,
                }),
              ],
            };
          }),
        }
      : d
  );

  // ensure dls store for ingestion region is added if not already exists
  const dlsStoreWithRegionExists = stores.some((store) =>
    dlsStoreExistsForRegion(store, CloudProvider.AWS, ingestionRegion)
  );

  return {
    databases: updatedDatabases,
    stores: dlsStoreWithRegionExists
      ? stores
      : [
          ...stores,
          generateDLSAWSStore({
            name: `${DLS_AWS_STORE_NAME}-${ingestionRegion}`,
            region: ingestionRegion,
          }),
        ],
  };
};

export const updateStorageConfigAddOnlineArchiveDataSource = (
  storageConfig: StorageConfig,
  dbName: string,
  collName: string,
  archive: OnlineArchive,
  regions: Array<RegionView>
): StorageConfig => {
  if (!storageConfig?.databases || !storageConfig?.stores) {
    return storageConfig;
  }

  const { databases, stores } = storageConfig;
  const archiveProvider = archive.dataProcessRegion?.cloudProvider ?? DEFAULT_CLOUD_PROVIDER;
  const archiveRegion = convertDataProcessRegionToNameFromRegionView(
    archiveProvider,
    archive.dataProcessRegion?.region,
    regions
  );

  let newDataSource: DataSource;
  let newDataStore: DataStore;

  switch (archiveProvider) {
    case CloudProvider.AWS:
      newDataSource = generateDLSAWSDataSource({
        storeName: `${DLS_AWS_STORE_NAME}-${archiveRegion}`,
        datasetName: archive.dataSetName ?? '',
      });
      newDataStore = generateDLSAWSStore({
        name: `${DLS_AWS_STORE_NAME}-${archiveRegion}`,
        region: archiveRegion,
      });
      break;

    case CloudProvider.AZURE:
      newDataSource = generateDLSAzureDataSource({
        storeName: `${DLS_AZURE_STORE_NAME}-${archiveRegion}`,
        datasetName: archive.dataSetName ?? '',
      });
      newDataStore = generateDLSAzureStore({
        name: `${DLS_AZURE_STORE_NAME}-${archiveRegion}`,
        region: archiveRegion,
      });
      break;

    case CloudProvider.GCP:
      newDataSource = generateDLSGCPDataSource({
        storeName: `${DLS_GCP_STORE_NAME}-${archiveRegion}`,
        datasetName: archive.dataSetName ?? '',
      });
      newDataStore = generateDLSGCPStore({
        name: `${DLS_GCP_STORE_NAME}-${archiveRegion}`,
        region: archiveRegion,
      });
      break;

    default:
      return storageConfig;
  }

  const updatedDatabases = databases.map((d: Database) =>
    d.name === dbName
      ? {
          ...d,
          collections: d.collections.map((c: Collection) => {
            if (c.name !== collName) {
              return c;
            }

            return {
              ...c,
              dataSources: [...c.dataSources.filter((ds) => !_.isEqual(ds, newDataSource)), newDataSource],
            };
          }),
        }
      : d
  );

  // ensure dls store for archive region is added if not already exists
  const dlsStoreWithRegionExists = stores.some((store) =>
    dlsStoreExistsForRegion(store, archiveProvider, archiveRegion)
  );

  return {
    databases: updatedDatabases,
    stores: dlsStoreWithRegionExists ? stores : [...stores, newDataStore],
  };
};

export const updateStorageConfigRemoveDLSDataSource = (
  storageConfig: StorageConfig,
  adlDatabaseName: string,
  adlCollectionName: string,
  dataSource: DLSAWSDataSource | DLSAzureDataSource | DLSGCPDataSource
): StorageConfig => {
  const { databases, stores } = storageConfig;

  const updatedDatabases = databases.map((d) =>
    d.name === adlDatabaseName
      ? {
          ...d,
          collections: d.collections.map((c: Collection) =>
            c.name === adlCollectionName
              ? {
                  ...c,
                  dataSources: c.dataSources.filter((ds: DataSource) => !_.isEqual(ds, dataSource)),
                }
              : c
          ),
        }
      : d
  );

  // determine if databases still has references to dls data store
  const { storeName } = dataSource;
  const updatedStores = databasesHasStore(storeName, updatedDatabases)
    ? stores
    : stores.filter((store) => store.name !== storeName);

  return {
    ...storageConfig,
    stores: updatedStores,
    databases: updatedDatabases,
  };
};

export const updateDatabasesAddWildCardCollection = (
  databases: Array<Database>,
  adlDatabaseName: string,
  adlCollectionName: string,
  dataSource: DLSAWSDataSource,
  adlDataSourceIndex: number
): Array<Database> => {
  return databases.map((d: Database) => {
    if (adlDatabaseName !== d.name) {
      return d;
    }

    const { wildcardCollection, nonWildcardCollections } = bucketWildcardCollections(d);

    // update wild card collection, create if exist if not, append data source to existing
    const { storeName, datasetPrefix } = dataSource;
    const wildcardDataSources: Array<DLSAWSDataSource> = [
      ...wildcardCollection.dataSources
        .filter((ds) => ds.provider === DataStoreProvider.DLS_AWS && ds.datasetPrefix !== datasetPrefix)
        .map((ds) => ds as DLSAWSDataSource),
      generateWildcardDataSource({ storeName, datasetPrefix }),
    ];
    const updatedWildcardCollection: Collection = {
      ...wildcardCollection,
      dataSources: wildcardDataSources,
    };

    // remove source/trigger data source
    const updatedNonWildcardCollections = nonWildcardCollections.map((c) =>
      c.name === adlCollectionName
        ? {
            ...c,
            dataSources: c.dataSources.filter((ds, i) => i !== adlDataSourceIndex),
          }
        : c
    );

    const collections = [updatedWildcardCollection, ...updatedNonWildcardCollections];

    return {
      ...d,
      collections,
    };
  });
};

export const updateDatabasesUpdateIngestionDatasource = (
  databases: Array<Database>,
  adlDatabaseName: string,
  adlCollectionName: string,
  dataSource: DLSAWSDataSource,
  adlDataSourceIndex: number
): Array<Database> => {
  return databases.map((db) => {
    if (db.name !== adlDatabaseName) {
      return db;
    }
    return {
      ...db,
      collections: db.collections.map((c) => {
        if (c.name !== adlCollectionName) {
          return c;
        }
        return {
          ...c,
          dataSources: c.dataSources.map((ds, i) => {
            if (i !== adlDataSourceIndex) {
              return ds;
            }
            return dataSource;
          }),
        };
      }),
    };
  });
};

export const updateStorageConfigRemoveWildCardCollection = (
  storageConfig: StorageConfig,
  adlDatabaseName: string,
  adlDatasetPrefix: string
): StorageConfig => {
  const { stores, databases } = storageConfig;

  const updatedDatabases: Array<Database> = databases.map((d) => {
    if (d.name !== adlDatabaseName) {
      return d;
    }

    const { hasWildcardCollection, wildcardCollection, nonWildcardCollections } = bucketWildcardCollections(d);

    if (!hasWildcardCollection) {
      throw new Error('Unable to find corresponding wildcard collection');
    }

    const updatedWildcardCollections: Array<Collection> = [
      {
        ...wildcardCollection,
        dataSources: wildcardCollection.dataSources.filter(
          (ds) => ds.provider === DataStoreProvider.DLS_AWS && ds.datasetPrefix !== adlDatasetPrefix
        ),
      },
    ].filter(({ dataSources }) => !!dataSources.length);

    return {
      ...d,
      collections: [...updatedWildcardCollections, ...nonWildcardCollections],
    };
  });

  // determine if databases still have references to dls data stores
  const updatedStores: Array<DataStore> = [
    // non dls stores
    ...stores.filter((s) => s.provider !== DataStoreProvider.DLS_AWS),
    // only allow dls store if databases still contain references
    ...stores.filter((s) => s.provider === DataStoreProvider.DLS_AWS && databasesHasStore(s.name, updatedDatabases)),
  ];

  return {
    stores: updatedStores,
    databases: updatedDatabases,
  };
};

interface WildcardCollectionInfo {
  hasWildcardCollection: boolean;
  wildcardCollection: Collection;
  nonWildcardCollections: Array<Collection>;
}

const EMPTY_WILDCARD_COLLECTION: Collection = {
  name: WILDCARD,
  dataSources: [],
};

export const bucketWildcardCollections = (database: Database): WildcardCollectionInfo => {
  const [wildcardCollections, nonWildcardCollections] = _.partition(
    database.collections,
    ({ name }) => name === WILDCARD
  );

  if (wildcardCollections.length > 1) {
    throw new Error('Unexpected number of wild card collections, only one per database');
  }

  const wildcardCollection: Collection | undefined = wildcardCollections[0];

  return {
    hasWildcardCollection: !!wildcardCollection,
    wildcardCollection: wildcardCollection ?? EMPTY_WILDCARD_COLLECTION,
    nonWildcardCollections,
  };
};

export const validateDataSources = (dataSources: Array<DataSource>): boolean =>
  dataSources.length > 0 &&
  dataSources.every((ds) => {
    if (ds.provider === DataStoreProvider.DLS_AWS) {
      const { datasetName = '', datasetPrefix = '' } = ds;
      const { dateAndTime } = fromFullDatasetName(datasetName);
      // has a valid individual dataset selected or is a wildcard collection datasource
      return (datasetName && dateAndTime) || datasetPrefix;
    } else {
      return !!ds.storeName;
    }
  });

const mergeHTTPDataSources = (existingDataSource: HTTPDataSource, newDataSource: HTTPDataSource): HTTPDataSource =>
  generateHttpDataSource({
    ...existingDataSource,
    urls: [...new Set([...(existingDataSource.urls ?? []), ...(newDataSource.urls ?? [])])],
  });

const updateDataSourcesAddExternalDataSources = (
  existingSources: Array<DataSource>,
  newSource: DataSource
): Array<DataSource> => {
  // if no existing data sources, just return a singleton list
  if (!existingSources?.length) {
    return [newSource];
  }

  if (newSource.provider === DataStoreProvider.HTTP) {
    const newUrls = newSource.urls || [];
    if (!newUrls.length) {
      // no need to add if it doesn't have any URLs
      return existingSources;
    }
    const otherSources = existingSources.filter((ds) => ds.storeName !== newSource.storeName);
    const existingSource = existingSources.find((ds) => ds.storeName === newSource.storeName);
    const updatedSource = existingSource
      ? mergeHTTPDataSources(existingSource as HTTPDataSource, newSource)
      : newSource;
    return [...otherSources, updatedSource];
  }

  // otherwise make sure that there are no duplicates and append the new source to the list
  return [...existingSources.filter((ds) => !_.isEqual(ds, newSource)), newSource];
};

export const updateStorageConfigAddExternalDataSource = (
  storage: StorageConfig,
  dataSourceMap: Map<string, Array<DataSource>>,
  dbName: string,
  collectionName: string,
  dataSourceKey: string
): StorageConfig => {
  const dataSource = findDataSource(dataSourceKey, storage, dataSourceMap);
  if (!dataSource) {
    return storage;
  }
  if (!storage.databases) {
    return storage;
  }

  const databases = storage.databases.map((d: Database) => {
    if (d.name !== dbName) {
      return d;
    }

    return {
      ...d,
      collections: d.collections.map((c: Collection) => {
        if (c.name === collectionName) {
          return {
            ...c,
            dataSources: updateDataSourcesAddExternalDataSources(c.dataSources, dataSource),
          };
        }
        return c;
      }),
    };
  });

  return {
    ...storage,
    databases,
  };
};

export const updateDatabaseRemoveExternalDataSource = (
  db: Database,
  collectionName: string,
  dataSource: DataSource
): Database => {
  return {
    ...db,
    collections: db.collections.map((c) => {
      if (c.name !== collectionName) {
        return c;
      }
      return {
        ...c,
        dataSources: c.dataSources.flatMap((ds) => {
          if (_.isEqual(dataSource, ds)) {
            return [];
          } else if (
            dataSource.provider === DataStoreProvider.HTTP &&
            ds.provider === DataStoreProvider.HTTP &&
            ds.storeName === dataSource.storeName
          ) {
            const urlToDelete = dataSource.urls?.[0] ?? '';
            const updatedDataSource = {
              ...ds,
              urls: (ds.urls ?? []).filter((url) => url !== urlToDelete),
            };
            return updatedDataSource.urls?.length ? [updatedDataSource] : [];
          }
          return [ds];
        }),
      };
    }),
  };
};
export const CLOUD_PROVIDER_TO_INCOMPATIBLE_DATA_STORE_TYPE = {
  [CloudProvider.AWS]: [
    DataStoreProvider.AZURE_BLOB_STORAGE,
    DataStoreProvider.DLS_AZURE,
    DataStoreProvider.DLS_GCP,
    DataStoreProvider.GCS,
  ],
  [CloudProvider.AZURE]: [
    DataStoreProvider.DLS_AWS,
    DataStoreProvider.S3,
    DataStoreProvider.DLS_GCP,
    DataStoreProvider.GCS,
  ],
  [CloudProvider.GCP]: [
    DataStoreProvider.AZURE_BLOB_STORAGE,
    DataStoreProvider.DLS_AZURE,
    DataStoreProvider.S3,
    DataStoreProvider.DLS_AWS,
  ],
};

export const removeIncompatibleCloudProviderReferences = (
  newProvider: CloudProvider,
  storageConfig: StorageConfig
): StorageConfig => {
  const incompatibleDataStoreProviders: Array<DataStoreProvider | undefined> =
    CLOUD_PROVIDER_TO_INCOMPATIBLE_DATA_STORE_TYPE[newProvider];
  return {
    ...storageConfig,
    stores: storageConfig.stores.filter((store) => !incompatibleDataStoreProviders.includes(store.provider)),
    databases: storageConfig.databases.map((db) => {
      return {
        ...db,
        collections: db.collections
          .map((c) => ({
            ...c,
            dataSources: c.dataSources.filter((ds) => !incompatibleDataStoreProviders.includes(ds.provider)),
          }))
          // remove empty wildcard collections
          .filter(({ name, dataSources }) => !(name === WILDCARD && dataSources.length === 0)),
      };
    }),
  };
};

// STORAGE CONFIG VALIDATIONS
export const VISUAL_EDITOR_MAX_ITEM_SUPPORTED = 100;

const getWildcardCollectionIncompatibleReason = (collection: Collection) => {
  const { name, dataSources } = collection;
  const compatible = '';
  if (name !== WILDCARD) {
    return compatible;
  }
  const incompatibleReason = 'Visual Editor does not support wildcard collections';
  if (!dataSources || dataSources.length !== 1) {
    return incompatibleReason;
  }
  // wild card collection must contain single ingestion pipeline source to be allowed for visual editor
  const isDLSWildcardDataSource = dataSources[0].provider === DataStoreProvider.DLS_AWS && dataSources[0].datasetPrefix;
  return isDLSWildcardDataSource ? compatible : incompatibleReason;
};

const getPathIncompatibleReason = (path: string) => {
  if (path) {
    const parts = path.split('/');
    for (const part of parts) {
      if (part.startsWith('{') && part.endsWith('}')) {
        const fieldAndType = part.slice(1, part.length - 1).split(' ');
        if (fieldAndType.length > 1) {
          const type = fieldAndType[1];
          if (PARTITION_ATTRIBUTE_TYPES.indexOf(type) < 0) {
            return 'Visual Editor does not support regular expressions in data source paths';
          }
        }
      }
    }
  }
  return '';
};

export const getVisualEditorIncompatibleReason = (storage?: StorageConfig) => {
  if (!storage) {
    return 'Storage config not found';
  }
  if (storage.stores) {
    if (storage.stores.length > VISUAL_EDITOR_MAX_ITEM_SUPPORTED) {
      return `Visual Editor only supports maximum ${VISUAL_EDITOR_MAX_ITEM_SUPPORTED} stores`;
    }
    const seenStoreNames = new Set();
    for (const store of storage.stores) {
      const { name } = store;
      if (seenStoreNames.has(name)) {
        return `Multiple stores have the same name '${name}'`;
      }
      seenStoreNames.add(name);
      if (!name) {
        return 'Store name is required';
      }
      if (store.provider === DataStoreProvider.S3) {
        if (store.delimiter !== '/') {
          return "Visual Editor only supports the '/' delimiter";
        }
      }
    }
  }
  if (storage.databases) {
    if (storage.databases.length > VISUAL_EDITOR_MAX_ITEM_SUPPORTED) {
      return `Visual Editor only supports maximum ${VISUAL_EDITOR_MAX_ITEM_SUPPORTED} databases`;
    }
    const seenDatabaseNames = new Set();
    for (const { name: dbName, collections } of storage.databases) {
      if (collections.length > VISUAL_EDITOR_MAX_ITEM_SUPPORTED) {
        return `Visual Editor only supports maximum ${VISUAL_EDITOR_MAX_ITEM_SUPPORTED} collections`;
      }
      if (seenDatabaseNames.has(dbName)) {
        return `Multiple databases have the same name '${dbName}'`;
      }
      seenDatabaseNames.add(dbName);
      const seenCollectionNames = new Set();
      if (collections) {
        for (const collection of collections) {
          const wildcardCollectionIncompatibleReason = getWildcardCollectionIncompatibleReason(collection);
          if (wildcardCollectionIncompatibleReason) {
            return wildcardCollectionIncompatibleReason;
          }
          const { name: collectionName, dataSources } = collection;
          if (seenCollectionNames.has(collectionName)) {
            return `Multiple collections in the '${dbName}' database have the same name '${collectionName}'`;
          }
          seenCollectionNames.add(collectionName);
          if (dataSources) {
            for (const dataSource of dataSources) {
              const { storeName, provider } = dataSource;
              if (!storeName) {
                return 'All collection data sources must have a storeName';
              }
              if (provider === DataStoreProvider.S3) {
                const pathIncompatibleReason = getPathIncompatibleReason(dataSource.path);
                if (pathIncompatibleReason) {
                  return pathIncompatibleReason;
                }
              }
              if (!storage.stores.find(({ name }) => name === storeName)) {
                return `Database store '${storeName}' reference invalid`;
              }
            }
          }
        }
      }
    }
  }
  return '';
};

type ValidateStorageFn = (
  groupId: string,
  cloudProvider: CloudProvider,
  storageConfig: StorageConfigMMS
) => { response: Promise<ValidationResponse>; signal: AbortSignal };

const getValidationResultForConfig = async (
  groupId: string,
  cloudProvider: CloudProvider,
  storageConfig: StorageConfig,
  validateStorage: ValidateStorageFn
): Promise<StorageValidationResult> => {
  // pre flight validations
  let convertedConfig: StorageConfigMMS;
  try {
    // make sure config parses
    const validatedStorageConfig = validateObjectAsStorageConfig(storageConfig);
    convertedConfig = convertStorageForMMSConsumption(validatedStorageConfig);
  } catch (e) {
    return {
      status: StorageValidationStatus.FAIL,
      errorLevel: StorageConfigFailedReason.STORAGE_CONFIG_CLIENT_INVALID,
      message: e.message || '',
    };
  }

  const { response: validationPromise, signal } = validateStorage(groupId, cloudProvider, convertedConfig);
  try {
    const validationResponse = await validationPromise;
    if (validationResponse.errorsCount > 0) {
      return {
        status: StorageValidationStatus.FAIL,
        errorLevel: StorageConfigFailedReason.STORAGE_CONFIG_ADL_INVALID,
        message: validationResponse.errors[0] || 'Unexpected error',
        storageConfig: storageConfig,
      };
    }
  } catch (e) {
    // ignore aborted requests
    if (signal && signal.aborted) {
      return {
        status: StorageValidationStatus.SKIPPED,
        storageConfig: storageConfig,
      };
    }

    const message = exceptionToMessage(e);

    // response cannot be deserialized from json string, should be same as invalid config
    if (e.statusCode === 400) {
      return {
        status: StorageValidationStatus.FAIL,
        errorLevel: StorageConfigFailedReason.STORAGE_CONFIG_DESERIALIZATION_INVALID,
        message,
      };
    }

    return {
      status: StorageValidationStatus.WARN,
      errorLevel: StorageConfigWarnReason.UNKNOWN,
      message,
      storageConfig: storageConfig,
    };
  }

  return getValidationResultForVisualCompatibility(storageConfig);
};

export const getValidationResultForVisualCompatibility = (storageConfig: StorageConfig): StorageValidationResult => {
  const visualEditorDisabledReason = getVisualEditorIncompatibleReason(storageConfig);
  if (visualEditorDisabledReason) {
    return {
      status: StorageValidationStatus.WARN,
      errorLevel: StorageConfigWarnReason.VISUAL_INCOMPATIBLE,
      message: visualEditorDisabledReason,
      storageConfig,
    };
  }

  // clear error
  return {
    status: StorageValidationStatus.SUCCESS,
    storageConfig,
  };
};

export const getValidationResultForJSON = async (
  groupId: string,
  cloudProvider: CloudProvider,
  storageConfigJson: string,
  validateStorage: ValidateStorageFn
): Promise<StorageValidationResult> => {
  // parse json string
  let storageConfig: StorageConfig;
  try {
    storageConfig = JSON.parse(storageConfigJson);
  } catch (e) {
    return {
      status: StorageValidationStatus.FAIL,
      errorLevel: StorageConfigFailedReason.JSON_INVALID,
      message: 'Storage config is malformed.  Please update to continue.',
    };
  }
  return getValidationResultForConfig(groupId, cloudProvider, storageConfig, validateStorage);
};

export const SAMPLE_DATASET_PATHS = [
  '/airbnb/listingsAndReviews/{bedrooms int}/{review_scores.review_scores_rating int}/',
  '/analytics/accounts/{limit int}/',
  '/analytics/customers/{birthdate isodate}/',
  '/analytics/transactions/{account_id int}/',
  '/mflix/movies/{type string}/{year int}/',
  '/mflix/sessions.json',
  '/mflix/theaters/{theaterId string}/{location.address.zipcode string}/',
  '/mflix/users.json',
  '/nyc-yellow-cab-trips/{trip_start_isodate isodate}/{passenger_count int}/{fare_type string}/',
];

export const SampleBucket = {
  [DataStoreProvider.S3]: 'sample-data-atlas-data-lake',
  [DataStoreProvider.AZURE_BLOB_STORAGE]: 'sample-data-atlas-data-lake',
  [DataStoreProvider.GCS]: 'sample-data-atlas-data-lake',
};

export function generateSampleS3DataStore(): S3DataStore {
  return generateS3DataStore({
    bucket: SampleBucket.s3,
    delimiter: '/',
    includeTags: false,
    name: SampleBucket.s3,
    region: 'us-east-1',
  });
}

export function generateSampleAzureBlobStorageDataStore(): AzureBlobStorageDataStore {
  return generateAzureBlobStorageStore({
    serviceURL: 'https://mdbsampledata.blob.core.windows.net/',
    containerName: SampleBucket.azure,
    delimiter: '/',
    name: SampleBucket.azure,
    region: 'eastus2',
    public: true,
  });
}

export function generateSampleGCSDataStore(): GCSDataStore {
  return generateGCSDataStore({
    bucket: SampleBucket.gcs,
    delimiter: '/',
    name: SampleBucket.gcs,
    region: 'us-central1',
    public: true,
  });
}
