import fetchWrapper from '../fetchWrapper';

export default {
  getMaintenanceWindow(groupId) {
    return fetchWrapper(`/nds/${groupId}/maintenanceWindow`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getTargetMongoDBVersions(groupId) {
    return fetchWrapper(`/nds/${groupId}/maintenanceWindow/targetMongoDBVersions`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getIsInOngoingPhasedVersionRelease(groupId, version): Promise<boolean> {
    return fetchWrapper(`/nds/${groupId}/maintenanceWindow/isInOngoingPhasedVersionRelease/${version}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  patchMaintenanceWindow(groupId, maintenanceWindow) {
    return fetchWrapper(`/nds/${groupId}/maintenanceWindow`, {
      method: 'PATCH',
      body: JSON.stringify(maintenanceWindow),
    }).then((resp) => resp.json());
  },

  resetCustomMaintenanceWindow(groupId) {
    return fetchWrapper(`/nds/${groupId}/maintenanceWindow`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  deferMaintenanceOneWeek(groupId) {
    return fetchWrapper(`/nds/${groupId}/maintenanceWindow/defer`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },
};
