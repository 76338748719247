import { createSelector } from 'reselect';

import type { ClusterDescription } from '@packages/types/nds/clusterDescription';
import { CloudProvider } from '@packages/types/nds/provider';

import { setClusterDescriptionsFetchIsPending } from '@packages/redux/common/nds/clusterDescriptionsFetchIsPending';

import * as api from '@packages/common/services/api';
import { isDedicatedCluster } from '@packages/common/utils/clusterDescription';
import replicationSpecListUtils from '@packages/common/utils/replicationSpecList';

// ActionTypes
const SET_CLUSTER_DESCRIPTIONS = 'nds/clusterDescriptions/SET_CLUSTER_DESCRIPTIONS';

type WriteableClusterDescriptionsState = Record<string, { data: ClusterDescription }>;
export type ClusterDescriptionsState = Readonly<WriteableClusterDescriptionsState>;

const initialState: ClusterDescriptionsState = {};

// Reducer
export default function clusterDescriptionsReducer(state = initialState, action): ClusterDescriptionsState {
  switch (action.type) {
    case SET_CLUSTER_DESCRIPTIONS: {
      const payload: Array<ClusterDescription> = action.payload;
      return payload.reduce<WriteableClusterDescriptionsState>((acc, clusterDescription) => {
        acc[clusterDescription.name] = {
          data: clusterDescription,
        };
        return acc;
      }, {});
    }
    default:
      return state;
  }
}

const getClusterDescriptionData = (state): ClusterDescriptionsState => state.nds.clusterDescriptions;

// Selectors
export const getClusterDescriptionsKeyedByName = (state) => getClusterDescriptionData(state);

export const getClusterDescriptionsAsList = createSelector([getClusterDescriptionsKeyedByName], (keyedByName) =>
  Object.values(keyedByName).map((entry) => entry.data)
);

export const getClusterDescription = (state, { clusterName }) => {
  const clusterData = getClusterDescriptionsKeyedByName(state)[clusterName];
  return clusterData ? clusterData.data : null;
};

export const getFirstDedicatedTierClusterDescription = createSelector(
  [getClusterDescriptionsAsList],
  (clusterDescriptions: Array<ClusterDescription>) =>
    clusterDescriptions.find((cd) =>
      isDedicatedCluster(replicationSpecListUtils.getFirstInstanceSize(cd.replicationSpecList))
    ) ?? null
);

// Action Creators
const setClusterDescriptions = (payload) => ({
  type: SET_CLUSTER_DESCRIPTIONS,
  payload,
});
export { setClusterDescriptions };

export const loadClusterDescriptions = (groupId: string) => (dispatch) => {
  dispatch(setClusterDescriptionsFetchIsPending(true));
  return api.nds.clusterDescriptions.getClusterDescriptions(groupId).then((response) => {
    const clusterDispatchRes = dispatch(setClusterDescriptions(response));
    dispatch(setClusterDescriptionsFetchIsPending(false));
    return clusterDispatchRes;
  });
};

export const isTenantCluster = (clusterDescription) => {
  return clusterDescription.replicationSpecList
    .flatMap((spec) => spec.regionConfigs)
    .some((config) => config.cloudProvider === CloudProvider.FREE || config.cloudProvider === CloudProvider.SERVERLESS);
};

export const isMTMCluster = (clusterDescription) => {
  return clusterDescription.isMTM;
};
