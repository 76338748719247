import { getActiveGroupId } from '@packages/redux/common/app';
import { getBaasCentralUrl, getBaasLegacyCentralUrl } from '@packages/redux/common/settings';
import { getId, getUsername } from '@packages/redux/common/viewer';

export const getBaasParamsFromState = (state) => ({
  groupId: getActiveGroupId(state),
  baasUrl: getBaasCentralUrl(state),
  baasLegacyUrl: getBaasLegacyCentralUrl(state),
  username: getUsername(state),
  userId: getId(state),
});

export const getBaasParamsFromSettingsModel = (settingsModel) => ({
  groupId: settingsModel.getCurrentGroupId(),
  baasUrl: settingsModel.getBaasUrl(),
  baasLegacyUrl: settingsModel.getBaasLegacyCentralUrl(),
  username: settingsModel.getCurrentUser(),
});
