import { SharedTierTerm } from './nds/clusterEditor';

/**
 * Test group names should be written in camelCase.
 * Include the suffix "Control" for the base condition,
 * and "Variant" with a brief descriptor for experimental groups:
 *
 * {experimentName}Control
 * {experimentName}VariantBasic
 * {experimentName}VariantFreeHobby
 *
 * The word 'Experiment' should not be used in test group names,
 * as that term is reserved for experiments at large. We are leaving
 * historical test group names intact until experiments are removed from the codebase.
 *
 * Please avoid using {experimentName} without a suffix,
 * as it complicates targeting in downstream analytics tools.
 */
export enum TestGroupName {
  fakeTestGroupForUnitTestingControl = 'fakeTestGroupNameForUnitTestingControl',
  fakeTestGroupForUnitTestingVariant = 'fakeTestGroupNameForUnitTestingVariant',
  emailVerificationExperiment = 'emailVerificationExperiment',
  appcuesOnboardingTreatment = 'appcuesOnboardingTreatment',
  dataAPIBrowserAccessUseCaseVariant = 'dataAPIBrowserAccessUseCaseVariant',
  searchIndexVideoControl = 'searchIndexVideoControl',
  searchIndexVideoVariant = 'searchIndexVideoVariant',
  searchLandingControl = 'searchLandingControl',
  searchLandingInformationalTimelineVariant = 'searchLandingInformationalTimelineVariant',
  searchLandingFeatureFirstVariant = 'searchLandingFeatureFirstVariant',
  searchLandingInformationalSearchEnvVariant = 'searchLandingInformationalSearchEnvVariant',
  searchMenuNameControl = 'searchMenuNameControl',
  searchMenuNameVariant = 'searchMenuNameVariant',
  searchMenuNameVariant2 = 'searchMenuNameVariant2',
  searchVsVibControl = 'searchVsVibControl',
  searchVsVibVariant = 'searchVsVibVariant',
  triggersEmptyStateVariant = 'triggersEmptyStateVariant',
  newChartsTabControl = 'newChartsTabControl',
  newChartsTabVariant = 'newChartsTabVariant',
  atlasNewNavigationControl = 'atlasNewNavigationControl',
  atlasNewNavigationVariant = 'atlasNewNavigationVariant',
  productionTipsMetricsVariant = 'productionTipsMetricsVariant',
  connectRedesignVariant = 'connectRedesignVariant',
  connectRedesignWithModalVariant = 'connectRedesignWithModalVariant',
  slowQueryDiagnosticVariant = 'slowQueryDiagnosticVariant',
  atlasHomePageUpgradeSharedTier2ButtonsVariant = 'atlasHomePageUpgradeSharedTier2ButtonsVariant',
  atlasHomePageUpgradeSharedTier1CtaVariant = 'atlasHomePageUpgradeSharedTier1CtaVariant',
  replaceServerlessOnCstControl = 'replaceServerlessOnCstControl',
  replaceServerlessOnCstVariantM2 = 'replaceServerlessOnCstVariantM2',
  replaceServerlessOnCstVariantM5 = 'replaceServerlessOnCstVariantM5',
  limitsAndAlertsVariant = 'limitsAndAlertsVariant',
  cstOnPricingPageVariant = 'cstOnPricingPageVariant',
  cstOnPricingPageControl = 'cstOnPricingPageControl',
  atlasHomePageDataModuleVariant = 'atlasHomePageDataModuleVariant',
  atlasHomePageDataModuleControl = 'atlasHomePageDataModuleControl',
  payWithAwsPaintedDoorVariant = 'payWithAwsPaintedDoorVariant',
  selectivelyLoadSampleDatasetsVariant = 'selectivelyLoadSampleDatasetsVariant',
  revampedCliToolsVariant = 'revampedCliToolsVariant',
  ussInitialLaunchControl = 'ussInitialLaunchControl',
  ussInitialLaunchVariant = 'ussInitialLaunchVariant',
  cstOnPricingPageNewUsersControl = 'cstOnPricingPageNewUsersControl',
  cstOnPricingPageNewUsersVariant = 'cstOnPricingPageNewUsersVariant',
  searchPFWControl = 'searchPFWControl',
  searchPFWVariant = 'searchPFWVariant',
  ptClusterChurnDeflectionControl = 'ptClusterChurnDeflectionControl',
  ptClusterChurnDeflectionVariant = 'ptClusterChurnDeflectionVariant',
  invitedUserOnboardingVariant = 'invitedUserOnboardingVariant',
}

/**
 * Naming standard: {EXPERIMENT_NAME}_YYYYMMDD, where
 * the date is exactly what you have in your Scope Experiment Appendix,
 * typically the prod branch cut date or date you start development.
 * EXAMPLE: FULL_PAGE_PAYMENT_CLICK_20210519
 */
export enum TestName {
  fakeTestForUnitTesting = 'FAKE_TEST_FOR_UNIT_TESTING',
  emailVerification = 'EMAIL_VERIFICATION_20210309',
  appcuesOnboarding = 'APPCUES_ONBOARDING_EXPERIMENT_20210914',
  cloudFeatureUpdatesExperiment = 'CLOUD_FEATURE_UPDATES_EXPERIMENT_20220126',
  clusterBuilderSidebar = 'CLUSTER_BUILDER_SIDEBAR_20220613',
  dataAPIBrowserAccessUseCase = 'DATA_API_BROWSER_ACCESS__USE_CASE_20230523',
  searchLandingExperiment = 'SEARCH_LANDING_EXPERIMENT_20221005',
  searchLandingRelaunch = 'SEARCH_LANDING_EXPERIMENT_RELAUNCH_20230301',
  searchMenuNameV2 = 'SEARCH_MENU_NAME_V2_20240823',
  searchVsVib = 'SEARCH_VS_VIB_20241028',
  newChartsTab = 'NEW_CHARTS_TAB_EXPERIMENT_20230215',
  churnSurveyDowngradeSuggestion = 'CHURN_SURVEY_DOWNGRADE_20230614',
  proactivelySurfacingDataModeling = 'PROACTIVELY_SURFACING_DATA_MODELING_ITERATION_v20241023',
  atlasNewNavigationDevelopment = 'ATLAS_NEW_NAVIGATION_DEVELOPMENT',
  productionTipsMetrics = 'PRODUCTION_TIPS_METRICS_20240417',
  connectRedesign = 'CONNECT_REDESIGN_20240619',
  slowQueryDiagnostic = 'SLOW_QUERY_DIAGNOSTIC_20240408',
  atlasHomePageUpgradeSharedTierCta = 'ATLAS_HOME_PAGE_UPGRADE_SHARED_TIER_CTA_20240422',
  replaceServerlessOnCst = 'REPLACE_SERVERLESS_ON_CST_20240617',
  preserveM10sFromCstToCb = 'PRESERVE_M10S_FROM_CST_TO_CB_20240611',
  devToolsInAtlasHomePage = 'DEV_TOOLS_IN_ATLAS_HOME_PAGE_20240821',
  breakingOutCheckboxesCst = 'BREAKING_OUT_CHECKBOXES_CST_20240729',
  limitsAndAlerts = 'LIMITS_AND_ALERTS_20240812',
  dedicatedTierFeatureAwareness = 'DEDICATED_TIER_FEATURE_AWARENESS_20240821',
  cstOnPricingPage = 'CST_ON_PRICING_PAGE_20240719',
  atlasHomePageDataModule = 'ATLAS_HOME_PAGE_DATA_MODULE_EXPERIMENT_20240821',
  payWithAwsPaintedDoor = 'PAY_WITH_AWS_PAINTED_DOOR_20240805',
  selectivelyLoadSampleDatasets = 'SELECTIVELY_LOAD_SAMPLE_DATASETS_20240801',
  revampedCliTools = 'REVAMPED_CLI_TOOLS_v20241023',
  ussInitialLaunch = 'USS_INTIAL_LAUNCH_20241113',
  aiAwarenessIteration = 'AI_AWARENESS_ITERATION_20241023',
  dedicatedTierFeatureAwarenessRerun = 'DEDICATED_TIER_FEATURE_AWARENESS_RERUN_20240917',
  cstOnPricingPageSupportNewUsers = 'CST_ON_PRICING_PAGE_NEW_USERS_20240905',
  searchProductFeatureWalls = 'SEARCH_PRODUCT_FEATURE_WALLS_20240911',
  ptClusterChurnDeflection = 'PT_CLUSTER_CHURN_DEFLECTION_20240909',
  invitedUserOnboarding = 'INVITED_USER_ONBOARDING_20241206',
  clusterConfigurationUtility = 'CLUSTER_CONFIGURATION_UTILITY_20241204',
}

/**
 * When adding a new experiment feature flag, please add the new key to the
 * `ExperimentFeatureFlag` enum.  Then, using this enum, add the proper
 * typing for your feature flag to the `ExperimentFeatureFlagsPossible` interface.
 *
 * Please update the Experiment Attributes Registry in the wiki, whenever updating this interface:
 * https://wiki.corp.mongodb.com/pages/viewpage.action?pageId=143699037#ExperimentAttributes-ExperimentAttributesRegistry
 *
 * @TODO https://jira.mongodb.org/browse/CLOUDP-138475
 * Move the ExperimentFeatureFlag enums to @packages/common/constants/project.ts
 * with the other Experiment constants.
 */

export enum ExperimentFeatureFlag {
  clusterCardDataExplorerCtaText = 'clusterCardDataExplorerCtaText',
  clusterListCreateCtaText = 'clusterListCreateCtaText',
  sharedTierTerm = 'sharedTierTerm',
  atlasSearchLeftNavLinkText = 'atlasSearchLeftNavLinkText',

  showChartsTabInCollectionHeader = 'showChartsTabInCollectionHeader',
  showVisualizeDataButtonInSubheaders = 'showVisualizeDataButtonInSubheaders',

  // Adding the following value for testing purposes.  Please remove as real number become available
  testNumber = 'testNumber',

  showDowngradeSuggestionInChurnSurvey = 'showDowngradeSuggestionInChurnSurvey',

  selectedAtlasUsageQuestion = 'selectedAtlasUsageQuestion',
  levelExpertiseQuestion = 'levelExpertiseQuestion',
  selectedLanguageQuestion = 'selectedLanguageQuestion',
  appArchitecturalModels = 'appArchitecturalModels',
  appArchitecturalModelsQuestion = 'appArchitecturalModelsQuestion',
  projectDataTypesOtherQuestion = 'projectDataTypesOtherQuestion',
  projectDataTypesQuestion = 'projectDataTypesQuestion',
  selectedLanguage = 'selectedLanguage',
  projectDataTypes = 'projectDataTypes',
  levelExpertise = 'levelExpertise',
  selectedAtlasUsage = 'selectedAtlasUsage',
  personalizationWizardTitle = 'personalizationWizardTitle',
  personalizationWizardSubtitle = 'personalizationWizardSubtitle',
  personalizationWizardSubmitButton = 'personalizationWizardSubmitButton',

  // Support Offering Awareness
  showSupportGuideCue = 'showSupportGuideCue',

  // Proactively Surfacing Data Modeling
  showProactivelySurfacingDataModeling = 'showProactivelySurfacingDataModeling',

  // Slow Query Diagnostic
  queryTimeThresholdInMS = 'queryTimeThresholdInMS',

  // Dev Tools Awareness on AHP
  showDevToolsInAtlasHomePage = 'showDevToolsInAtlasHomePage',

  // Preserve M10s from CST to CB
  preserveM10sFromCstToCb = 'preserveM10sFromCstToCb',

  // Breaking Out Checkboxes on CST
  breakOutCheckboxes = 'breakOutCheckboxes',
  uncheckSampleDataCheckboxByDefaultForM0s = 'uncheckSampleDataCheckboxByDefaultForM0s',

  // Dedicated Tier Feature Awareness
  showDedicatedTierFeaturesOnAhp = 'showDedicatedTierFeaturesOnAhp',
  showProductFeatureWalls = 'showProductFeatureWalls',

  // Data Module
  showClustersModule = 'showClustersModuleOnAtlasHomePage',
  showDataModule = 'showDataModuleOnAtlasHomePage',

  // Revamped Command Line Tools Products Page in Atlas
  showRevampedCliToolsPage = 'showRevampedCliToolsPage',
  showProgrammaticAccessInSideNav = 'showProgrammaticAccessInSideNav',

  // Display USS instead of serverless and shared
  displayUSS = 'displayUSS',

  // AI Awareness Iteration
  showAiAwarenessIteration = 'showAiAwarenessIteration',

  // Search Product Feature Walls
  showSearchProductFeatureWalls = 'showSearchProductFeatureWalls',

  // Invited user Onboarding | CLOUDP-257716
  shouldInviterSeeExperimentalExperience = 'shouldInviterSeeExperimentalExperience',
  shouldInvitedUserSeeExperimentalExperience = 'shouldInvitedUserSeeExperimentalExperience',

  // Cluster Configuration Transformation Utility | Jira Epic: CLOUDP-261633
  useClusterConfigurationTransformationUtility = 'useClusterConfigurationTransformationUtility',
}

export interface ExperimentFeatureFlagsPossible {
  // Please update the Experiment Attributes Registry in the wiki, whenever updating this interface:
  // https://wiki.corp.mongodb.com/pages/viewpage.action?pageId=143699037#ExperimentAttributes-ExperimentAttributesRegistry
  [ExperimentFeatureFlag.clusterCardDataExplorerCtaText]: string;
  [ExperimentFeatureFlag.clusterListCreateCtaText]: string;
  [ExperimentFeatureFlag.sharedTierTerm]: SharedTierTerm;
  [ExperimentFeatureFlag.atlasSearchLeftNavLinkText]: string;

  // Adding the following values for testing purposes.  Please remove as real number become available
  [ExperimentFeatureFlag.testNumber]: number;

  [ExperimentFeatureFlag.showChartsTabInCollectionHeader]: boolean;
  [ExperimentFeatureFlag.showVisualizeDataButtonInSubheaders]: boolean;

  [ExperimentFeatureFlag.showDowngradeSuggestionInChurnSurvey]: boolean;

  [ExperimentFeatureFlag.selectedAtlasUsageQuestion]: string;
  [ExperimentFeatureFlag.levelExpertiseQuestion]: string;
  [ExperimentFeatureFlag.projectDataTypesOtherQuestion]: string;
  [ExperimentFeatureFlag.selectedLanguageQuestion]: string;
  [ExperimentFeatureFlag.appArchitecturalModelsQuestion]: string;
  [ExperimentFeatureFlag.projectDataTypesQuestion]: string;
  [ExperimentFeatureFlag.appArchitecturalModels]: string;
  [ExperimentFeatureFlag.selectedLanguage]: string;
  [ExperimentFeatureFlag.projectDataTypes]: string;
  [ExperimentFeatureFlag.levelExpertise]: string;
  [ExperimentFeatureFlag.selectedAtlasUsage]: string;
  [ExperimentFeatureFlag.personalizationWizardTitle]: string;
  [ExperimentFeatureFlag.personalizationWizardSubtitle]: string;
  [ExperimentFeatureFlag.personalizationWizardSubmitButton]: string;

  // Support Offering Awareness
  [ExperimentFeatureFlag.showSupportGuideCue]: boolean;

  // Proactively Surfacing Data Modeling
  [ExperimentFeatureFlag.showProactivelySurfacingDataModeling]: boolean;

  // Slow Query Diagnostic
  [ExperimentFeatureFlag.queryTimeThresholdInMS]: string;

  // Dev Tools Awareness on App Dev HP Module
  [ExperimentFeatureFlag.showDevToolsInAtlasHomePage]: boolean;

  // Preserve M10 from CST to CB
  [ExperimentFeatureFlag.preserveM10sFromCstToCb]: boolean;

  // Breaking Out Checkboxes on CST
  [ExperimentFeatureFlag.breakOutCheckboxes]: boolean;
  [ExperimentFeatureFlag.uncheckSampleDataCheckboxByDefaultForM0s]: boolean;

  // Dedicated Tier Feature Awareness
  [ExperimentFeatureFlag.showDedicatedTierFeaturesOnAhp]: boolean;
  [ExperimentFeatureFlag.showProductFeatureWalls]: boolean;

  // Data Module
  [ExperimentFeatureFlag.showClustersModule]: boolean;
  [ExperimentFeatureFlag.showDataModule]: boolean;

  // Revamped Command Line Tools Products Page in Atlas
  [ExperimentFeatureFlag.showRevampedCliToolsPage]: boolean;
  [ExperimentFeatureFlag.showProgrammaticAccessInSideNav]: boolean;

  // Display option to create USS Clusters
  [ExperimentFeatureFlag.displayUSS]: boolean;

  // AI Awareness Iteration
  [ExperimentFeatureFlag.showAiAwarenessIteration]: boolean;

  //Search Product Feature Walls
  [ExperimentFeatureFlag.showSearchProductFeatureWalls]: boolean;

  // Invited user Onboarding
  [ExperimentFeatureFlag.shouldInviterSeeExperimentalExperience]: boolean;
  [ExperimentFeatureFlag.shouldInvitedUserSeeExperimentalExperience]: boolean;

  // Cluster Configuration Transformation Utility | Jira Epic: CLOUDP-261633
  [ExperimentFeatureFlag.useClusterConfigurationTransformationUtility]: boolean;
}

export enum Scope {
  USER = 'USER',
  GROUP = 'GROUP',
  ORG = 'ORG',
}

export interface ABTest {
  _id: string;
  testName: TestName;
  description: string;
  jiraLink: string;
  scope: Scope;
  scopePercentage: number;
  experimentStatus: string;
  testGroups: Array<TestGroup>;
  tag: string;
}

export interface TestGroup {
  testGroupName: TestGroupName;
  testGroupDatabaseId: string;
  percent: number;
}

interface AssignmentBase {
  assignmentDate: string;
  entityId: string;
  entityType: Scope;
  id: string;
  meta?: {
    isLaunchedExperiment: boolean;
  };
  tag: string;
  testId: string;
  testName: TestName;
}

export interface OverrideAssignment {
  emailAddress: string;
  testName: string;
  testId: string;
  testGroupId: string;
  testGroupDatabaseId: string;
  entityType: string;
  tag: string;
}

export interface OverrideAssignmentFromServer extends OverrideAssignment {
  userId: string;
  _id: string;
}

export interface OverrideAssignmentUpdateForm {
  testGroupId: string;
  testGroupDatabaseId: string;
}

export interface Assignment extends AssignmentBase {
  testGroupName: TestGroupName | null;
  testGroupDatabaseId: string | null;
}

export interface GhostAssignment extends Assignment {
  linkedHoldoutTestName: string;
  linkedHoldoutTestId: string;
}

export interface NonNullAssignment extends AssignmentBase {
  testGroupName: TestGroupName;
  testGroupDatabaseId: string;
}

export interface NonNullGhostAssignment extends NonNullAssignment {
  linkedHoldoutTestName: string;
  linkedHoldoutTestId: string;
}

export interface NullAssignment extends AssignmentBase {
  testGroupName: null;
  testGroupDatabaseId: null;
}

export interface ServerAssignment extends AssignmentBase {
  testGroupId: TestGroupName | null;
  testGroupDatabaseId: string | null;
}

export interface ServerGhostAssignment extends ServerAssignment {
  linkedHoldoutTestName: string;
  linkedHoldoutTestId: string;
}

export type Assignments = Array<Assignment>;
export type NonNullAssignments = Array<NonNullAssignment>;
export type NonNullGhostAssignments = Array<NonNullGhostAssignment>;
export type ServerAssignments = Array<ServerAssignment>;
export type ServerGhostAssignments = Array<ServerGhostAssignment>;
export type GhostAssignments = Array<GhostAssignment>;
export type ExperimentFeatureFlagValue = string | boolean | number;

export type ExperimentFeatureFlags = Partial<ExperimentFeatureFlagsPossible>;

export type ExperimentFeatureFlagsFromServer = {
  [key in ExperimentFeatureFlag]?: string;
};

export interface AssignmentPayload {
  data: ServerAssignments;
  experimentFeatureFlags: ExperimentFeatureFlagsFromServer;
  ghostAssignments?: ServerGhostAssignments;
}

export interface BulkAllocationPayload {
  entities: Array<{
    id: string;
    type: Scope;
  }>;
  testIds: Array<string>;
}

export enum AllocationPoint {
  CLUSTER_CARD_PAGE_WITHOUT_SERVERLESS = 'CLUSTER_CARD_PAGE_WITHOUT_SERVERLESS',
  ATLAS_REGISTRATION_SUCCEEDED = 'ATLAS_REGISTRATION_SUCCEEDED',
  CLUSTER_BUILDER_SEEN = 'CLUSTER_BUILDER_SEEN',
  CLUSTER_CARD_PAGE = 'CLUSTER_CARD_PAGE',
  PROJECT_APPLICATION_START = 'PROJECT_APPLICATION_START',
  THREE_DOOR_PAGE_SEEN = 'THREE_DOOR_PAGE_SEEN',
  // edit/upgrade cluster
  EDIT_CLUSTER = 'EDIT_CLUSTER',
  NEW_CLUSTER = 'NEW_CLUSTER',
  CLUSTER_CARD_PAGE_AT_LEAST_ONE_DEPLOYMENT = 'CLUSTER_CARD_PAGE_AT_LEAST_ONE_DEPLOYMENT',
  CLUSTER_STARTER_TEMPLATES_PAGE_SEEN = 'CLUSTER_STARTER_TEMPLATES_PAGE_SEEN',
  PERSONALIZATION_WIZARD_SUBMITTED_FOR_MIGRATORS = 'PERSONALIZATION_WIZARD_SUBMITTED_FOR_MIGRATORS',
  ATLAS_HOME_PAGE_SEEN = 'ATLAS_HOME_PAGE_SEEN',
  ATLAS_HOME_PAGE_WITH_AT_LEAST_ONE_DEPLOYMENT = 'ATLAS_HOME_PAGE_WITH_AT_LEAST_ONE_DEPLOYMENT',
  ATLAS_HOME_PAGE_REACHED_AHA_MOMENT = 'ATLAS_HOME_PAGE_REACHED_AHA_MOMENT',
  FULL_PAGE_PAYMENT_SEEN = 'FULL_PAGE_PAYMENT_SEEN',
  PRICING_LANDING_PAGE_SEEN = 'PRICING_LANDING_PAGE_SEEN',
  CLUSTER_CARD_PAGE_AT_LEAST_ONE_DEDICATED_DEPLOYMENT = 'CLUSTER_CARD_PAGE_AT_LEAST_ONE_DEDICATED_DEPLOYMENT',
  CLUSTER_UPGRADE_TEMPLATE_PAGE_SEEN = 'CLUSTER_UPGRADE_TEMPLATE_PAGE_SEEN',
}
