import { proxy, subscribe } from 'valtio';
import { subscribeKey } from 'valtio/utils';

import { Permission, PermissionResult, PermissionStore, PermissionString } from '@packages/types/auth/fga';

import { fetchIsAuthorized } from '@packages/common/services/api/authzApi';

export class AuthzManager {
  permissions: PermissionStore = proxy({});
  pendingRequests: { PermissionString: boolean } | {} = {};

  /**
   * Updates the permission store with the provided list of permissions.
   *
   * @param permissionResults - Permission results to be added.
   */
  updatePermissions(permissionResults: Array<PermissionResult>) {
    for (const permissionResult of permissionResults) {
      this.permissions[getPermissionKey(permissionResult.permission)] = permissionResult;
    }
  }

  /**
   * Fetches the permissions given and adds them to the store. If an outgoing request is already pending,
   * a request will not be made.
   *
   * @param permissions - Array of permissions to fetch.
   */
  async fetchPermissions(permissions: Array<Permission>, orgId: string) {
    const permissionsNeeded = this.#getPermissionsNotPendingRequests(permissions);

    if (permissionsNeeded.length === 0) {
      return;
    }

    for (let permission of permissionsNeeded) {
      permission.service = permission.service || 'cloud'; // optional service key required on request
      this.pendingRequests[getPermissionKey(permission)] = true;
    }
    try {
      const { authorizationResults } = await fetchIsAuthorized(permissionsNeeded as Array<Permission>, orgId);
      for (const authorizationResult of authorizationResults) {
        this.permissions[getPermissionKey(authorizationResult.permission)] = authorizationResult;
      }
    } finally {
      for (const permission of permissionsNeeded) {
        delete this.pendingRequests[getPermissionKey(permission)];
      }
    }
  }

  /**
   * Returns the current state for a specific permission in the store or null.
   *
   * @param permission - The specific permission to lookup.
   */
  getPermission(permission: Permission): PermissionResult | null {
    return this.permissions[getPermissionKey(permission)] || null;
  }

  /**
   * Subscribes a listener method that will be called every time permissions are updated.
   *
   * @param listener - method to be called on permissions updates.
   */
  subscribe(listener: () => any) {
    return subscribe(this.permissions, listener);
  }

  /**
   * Subscribes a listener method to updates of a specific permission.
   *
   * @param permission - the permission to to be subscribe to.
   * @param listener - method to be called on permission update.
   */
  subscribeToPermission(permission: Permission, listener: (updatedValue: PermissionResult) => any) {
    subscribeKey(this.permissions, getPermissionKey(permission), listener);
  }

  /**
   * Clears all properties in the permission store.
   */
  clearPermissions() {
    for (const key in this.permissions) delete this.permissions[key as PermissionString];
  }

  /**
   * Given a list of permissions, returns only those that don't have requests already pending.
   *
   * @param permissions - List of permissions to be filtered from.
   * @returns - Filtered list of only the permissions from the given list that are not in the store.
   */
  #getPermissionsNotPendingRequests(permissions: Array<Permission>): Array<Permission> {
    return permissions.filter((permission) => !this.pendingRequests[getPermissionKey(permission)]);
  }
}

export const authzManager = new AuthzManager();

export function getPermissionKey({ service, action, resourceType, resourceInstanceId }: Permission): PermissionString {
  return `${service || 'cloud'}:${resourceType}:${resourceInstanceId}:${action}`;
}
