import Backbone from 'backbone';

import StitchApplication from '@packages/common/models/StitchApplication';

export interface StitchApplicationCollectionInterface extends Backbone.Collection {
  getApplicationsByDataSourceName(dataSourceName: string): StitchApplicationCollectionInterface;
}

const StitchApplicationCollection = Backbone.Collection.extend({
  model: StitchApplication,

  getApplicationsByDataSourceName(dataSourceName) {
    return new StitchApplicationCollection(
      this.filter((app) => app.getDataSourceNames().some((appDataSourceName) => appDataSourceName === dataSourceName))
    );
  },
});

export default StitchApplicationCollection;
