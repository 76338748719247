export enum Environment {
  LOCAL = 'local',
  E2E = 'e2e',
  // currently DEMOBOX value is just used by experimentUtils
  DEMOBOX = 'demobox',
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
}

/**
 * @description
 * Returns the environment type based on the window URL. If no match is found, PROD is the default.
 *
 * @returns a {@link Environment} enum value equivalent to current environment.
 */
export const getEnvironment = (): Environment => {
  switch (window.location.host) {
    case 'localhost:8080':
      return Environment.LOCAL;
    case 'localhost:9080':
      return Environment.E2E;
    case 'cloud-dev.mongodb.com':
    case 'account-dev.mongodb.com':
      return Environment.DEV;
    case 'cloud-qa.mongodb.com':
    case 'account-qa.mongodb.com':
      return Environment.QA;
    case 'cloud.mongodb.com':
    case 'account.mongodb.com':
      return Environment.PROD;
    default:
      console.error('no window.location.host match found, using PROD environment');
      return Environment.PROD;
  }
};
