import type { ClusterDescriptionModel } from '@packages/types/nds/clusterDescription';

import { IntelEndpointFromParam } from '@packages/common/constants/queryInsights';

import fetchWrapper, { formParams } from './fetchWrapper';

export interface Index {
  accessCount: number;
  index: Array<Record<string, any>>;
  name: string;
  namespace: string;
  since: string;
  sizeBytes: number;
}

export interface RedundantIndex extends Index {
  relatedIndexes: Array<Index>;
}

export interface ClusterDropIndexAdviceResponse {
  clusterId: string;
  hiddenIndexes: Array<Index>;
  redundantIndexes: Array<RedundantIndex>;
  unusedIndexes: Array<Index>;
  replSetId: string;
  topNameSpaces: Array<String>;
}

export default {
  getIndexCreationAdvice({
    hostId,
    startTime,
    groupId,
    feedbackId,
    runId,
    engineType,
    fastMode,
    from,
  }: {
    hostId: string;
    startTime: number;
    groupId: string;
    feedbackId?: string;
    runId?: string;
    engineType?: string;
    fastMode?: boolean;
    from?: IntelEndpointFromParam;
  }) {
    let url = `/performanceAdvisor/groups/${groupId}/hosts/${hostId}/indexCreationAdvice?since=${startTime}`;
    url += feedbackId ? `&_fi=${feedbackId}` : ``;
    url += runId ? `&_ri=${runId}` : ``;
    url += engineType ? `&_et=${engineType}` : ``;
    url += fastMode ? `&_fm=${fastMode}` : ``;
    // Indicates the request comes from the Production Tips Metrics Experiment (CLOUDP-201828)
    url += from ? `&from=${from}` : ``;

    return fetchWrapper(url, {
      method: 'GET',
      isSendToRegionalServer: true,
      clusterDescription: undefined,
    }).then((resp) => resp.json());
  },

  getClusterIndexCreationAdvice({
    hostId,
    clusterId,
    startTime,
    endTime,
    groupId,
    feedbackId,
    runId,
    engineType,
    selectedHosts,
    from,
  }: {
    hostId: string;
    clusterId: string;
    startTime: number;
    endTime: number;
    groupId: string;
    feedbackId?: string;
    runId?: string;
    engineType?: string;
    selectedHosts?: Array<string>;
    from?: IntelEndpointFromParam;
  }) {
    let url;
    if (clusterId) {
      url = `/performanceAdvisor/groups/${groupId}/clusterLevelIndexCreationAdvice?clusterId=${clusterId}&since=${startTime}`;
      url += endTime ? `&until=${endTime}` : ``;
      if (selectedHosts && selectedHosts.length > 0) {
        for (const selectedHost of selectedHosts) {
          url += `&hostIds[]=${selectedHost}`;
        }
      }
    } else {
      url = `/performanceAdvisor/groups/${groupId}/clusterLevelIndexCreationAdvice?since=${startTime}&hostIds[]=${hostId}`;
    }
    url += feedbackId ? `&_fi=${feedbackId}` : ``;
    url += runId ? `&_ri=${runId}` : ``;
    url += engineType ? `&_et=${engineType}` : ``;
    // Indicates source for Atlas Growth experiments
    url += from ? `&from=${from}` : ``;

    return fetchWrapper(url, {
      method: 'GET',
      isSendToRegionalServer: true,
      clusterDescription: undefined,
    }).then((resp) => resp.json());
  },

  getServerlessIndexCreationAdvice({ startTime, groupId, clusterUniqueId, runId, engineType }) {
    let url = `/performanceAdvisor/groups/${groupId}/serverless/${clusterUniqueId}/indexCreationAdvice?since=${startTime}`;
    url += runId ? `&_ri=${runId}` : ``;
    url += engineType ? `&_et=${engineType}` : ``;
    return fetchWrapper(url, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  saveCreatedIndexes(groupId, runId, index) {
    if (!runId) {
      return;
    }

    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/saveCreatedIndex/${runId}`, {
      method: 'POST',
      isSendToRegionalServer: true,
      body: JSON.stringify(index),
      clusterDescription: undefined,
    }).then((resp) => resp.json());
  },

  getSchemaAdvice({ hostId, groupId }) {
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/hosts/${hostId}/schemaAdvice`, {
      method: 'GET',
      isSendToRegionalServer: true,
      clusterDescription: undefined,
    }).then((resp) => resp.json());
  },

  getDataServicesAdvice({ hostId, groupId }) {
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/hosts/${hostId}/dataServicesAdvice`, {
      method: 'GET',
      isSendToRegionalServer: true,
      clusterDescription: undefined,
    }).then((resp) => resp.json());
  },

  sendHeartbeat({ id, groupId, idType }) {
    return fetchWrapper(`/explorer/v1/groups/${groupId}/${idType}/${id}/advisor/heartbeat`, {
      method: 'POST',
    });
  },

  saveFeedback({ groupId, hostId, body }) {
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/hosts/${hostId}/feedback`, {
      method: 'POST',
      body: JSON.stringify(body),
      isSendToRegionalServer: true,
      clusterDescription: undefined,
    }).then((resp) => resp.json());
  },

  getIndexBuildStatus({ groupId, hostId, namespaces }) {
    const queryParams = formParams(namespaces);
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/hosts/${hostId}/buildInProgress?${queryParams}`, {
      method: 'GET',
      isSendToRegionalServer: true,
      clusterDescription: undefined,
    }).then((resp) => resp.json());
  },

  getTextQueryUsageExceedingThreshold({ groupId, rsAndHostIds }) {
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/hosts/textQueryUsage`, {
      method: 'POST',
      body: JSON.stringify(rsAndHostIds),
    }).then((resp) => resp.json());
  },

  getServerlessIndexBuildStatus({ groupId, clusterUniqueId, namespaces }) {
    const queryParams = formParams(namespaces);
    return fetchWrapper(
      `/performanceAdvisor/groups/${groupId}/serverless/${clusterUniqueId}/buildInProgress?${queryParams}`,
      {
        method: 'GET',
      }
    ).then((resp) => resp.json());
  },

  getDropIndexAdvice({ hostId, groupId }) {
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/hosts/${hostId}/dropIndexes`, {
      method: 'GET',
      isSendToRegionalServer: true,
      clusterDescription: undefined,
    }).then((resp) => resp.json());
  },

  // TODO: Add ClusterDropIndexAdviceResponse as return type of this call
  getClusterDropIndexAdvice({
    groupId,
    clusterId,
    hostId,
    clusterDescription,
  }: {
    groupId: string;
    clusterId?: string;
    hostId?: string;
    clusterDescription?: ClusterDescriptionModel;
  }) {
    if (hostId == null && clusterId == null) {
      console.log('Error, both clusterId and hostId were not provided');
    }

    let url;
    if (clusterId) {
      url = `/performanceAdvisor/groups/${groupId}/clusterLevelDropIndexes?clusterId=${clusterId}`;
    } else {
      url = `/performanceAdvisor/groups/${groupId}/clusterLevelDropIndexes?hostIds[]=${hostId}`;
    }
    return fetchWrapper(url, {
      method: 'GET',
      isSendToRegionalServer: true,
      clusterDescription: clusterDescription,
    })
      .then((resp) => resp.json())
      .catch((err) => console.log(err));
  },

  getServerlessDropIndexAdvice({ groupId, clusterUniqueId }) {
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/serverless/${clusterUniqueId}/dropIndexes`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  setServerlessAutoIndexing(groupId, clusterUniqueId, enabled) {
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/serverless/${clusterUniqueId}/autoIndexing`, {
      method: 'POST',
      body: JSON.stringify(enabled),
    }).then((resp) => resp.status);
  },

  getServerlessAutoIndexingEnabled(groupId, clusterUniqueId) {
    return fetchWrapper(`/performanceAdvisor/groups/${groupId}/serverless/${clusterUniqueId}/autoIndexing`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
