import _ from 'underscore';

import { GRAVITON_INSTANCE_FAMILIES } from '@packages/types/nds/provider';
import { INSTANCE_TIERS_WITHOUT_PROVISIONED_IOPS_SUPPORT } from '@packages/types/nds/replicationSpec';

import deepClone from '@packages/common/utils/deepClone';

class InstanceSize {
  instanceClass: $TSFixMe;
  size: $TSFixMe;
  // Parse an instance size name (M30 or R70 for example) into
  // the constituent parts
  constructor(name) {
    if (name === 'FLEX' || name === 'SERVERLESS') {
      this.instanceClass = name;
      this.size = name;
    } else {
      const re = /([MR])([0-9]+).*/;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.instanceClass = re.exec(name)[1];
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.size = parseInt(re.exec(name)[2], 10);
    }
  }
}

function lastIndexOf(arr, startIndex, filterFn) {
  for (let i = startIndex; i >= 0; i--) {
    if (filterFn(arr[i], i)) {
      return arr[i];
    }
  }
  return null;
}

function getMinRamFromRamPerInstanceFamily(instanceSize) {
  return Object.values(instanceSize.ramPerInstanceFamily).sort().shift() || 0.0;
}

// The memory demands of software needed for compliance is too much for an M10.
const INSTANCE_NAMES_NOT_SUPPORTED_IN_GOV_CLOUD = ['M10'];

export default {
  // export the following function
  getMinRamFromRamPerInstanceFamily,

  /**
   * Compare two instance size names using the class (M or R) and the size (10 or 20 etc.).
   * The size take precedent and the class is only considered when the sizes are equal
   */
  compare(i1, i2) {
    try {
      const instance1 = new InstanceSize(i1);
      const instance2 = new InstanceSize(i2);
      if (instance1.size !== instance2.size) {
        return instance1.size - instance2.size;
      }
      return -instance1.instanceClass.localeCompare(instance2.instanceClass);
    } catch (exception) {
      if (i1.toUpperCase().indexOf('SERVERLESS') !== -1 || i2.toUpperCase().indexOf('SERVERLESS') !== -1) {
        // this util fn does not deal with serverless clusters
        return 0;
      }
      if (i1.toUpperCase().indexOf('FLEX') !== -1 || i2.toUpperCase().indexOf('FLEX') !== -1) {
        // this util fn does not deal with flex clusters
        return 0;
      }
      throw new Error(exception);
    }
  },

  isAvailableOnGovCloud(instanceName) {
    return INSTANCE_NAMES_NOT_SUPPORTED_IN_GOV_CLOUD.indexOf(instanceName.toUpperCase()) == -1;
  },

  isNotAvailableOnGovCloud(instanceName) {
    return !this.isAvailableOnGovCloud(instanceName);
  },

  addToInstanceArray(array, instance) {
    const displayName = instance.highCPUEquivalent || instance.name;
    const existing = array.find((i) => i.displayName === displayName);

    if (existing) {
      if (instance.isLowCPU) {
        existing.lowCPU = instance;
      } else if (instance.isNVMe) {
        existing.NVMe = instance;
      } else {
        existing.highCPU = instance;
      }
    } else {
      const newInstance = { displayName };
      if (instance.isLowCPU) {
        (newInstance as $TSFixMe).lowCPU = instance;
      } else if (instance.isNVMe) {
        (newInstance as $TSFixMe).NVMe = instance;
      } else {
        (newInstance as $TSFixMe).highCPU = instance;
      }

      array.push(newInstance);
    }
  },

  getInstanceSizesList(providerOptions, provider, instanceVisibilityFilter = () => true) {
    const currentProviderOptions = providerOptions[provider];

    const sortedInstances = _.chain(currentProviderOptions.instanceSizes)
      .sortBy((instanceSize) => getMinRamFromRamPerInstanceFamily(instanceSize))
      .sortBy((instanceSize) => instanceSize.defaultStorageGB)
      .value();

    return sortedInstances.filter(instanceVisibilityFilter);
  },

  getInstanceSizesFromOptions(options, instanceVisibilityFilter = () => true) {
    const sortedInstances = _.chain(options.instanceSizes)
      .sortBy((instanceSize) => getMinRamFromRamPerInstanceFamily(instanceSize))
      .sortBy((instanceSize) => instanceSize.defaultStorageGB)
      .value();

    return sortedInstances.filter(instanceVisibilityFilter);
  },

  // filter out deprecated instance sizes, allowing for exceptions to the rule
  getActiveInstanceSizes(instanceSizes, allowedDeprecatedInstanceSizeNames = []) {
    return Object.values(instanceSizes).filter(
      (instanceSize) =>
        !(instanceSize as $TSFixMe).isDeprecated ||
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        allowedDeprecatedInstanceSizeNames.includes((instanceSize as $TSFixMe).name)
    );
  },

  getComputeAutoScalingInstanceSizes(
    instanceSizes,
    isSharded = false,
    currInstanceSizeName,
    maxInstanceSizeName,
    minInstanceSizeName,
    areIOPSProvisioned = false
  ): Array<$TSFixMe> {
    // Compute auto-scaling max and min instance sizes are M and R series only
    // i.e. not NVMe instance classes
    return Object.values(
      this.getActiveInstanceSizes(
        instanceSizes,
        [currInstanceSizeName, maxInstanceSizeName, minInstanceSizeName].filter(Boolean)
      )
    )
      .filter(
        (instanceSize) =>
          !(instanceSize as $TSFixMe).isNVMe &&
          (instanceSize as $TSFixMe).isLowCPU === (instanceSizes[currInstanceSizeName]?.isLowCPU || false) &&
          (!isSharded || (instanceSize as $TSFixMe).supportsSharding) &&
          (!areIOPSProvisioned || !INSTANCE_TIERS_WITHOUT_PROVISIONED_IOPS_SUPPORT.has((instanceSize as $TSFixMe).name))
      )
      .sort((i1, i2) => this.compare((i1 as $TSFixMe).name, (i2 as $TSFixMe).name));
  },

  isApplicableInstanceSizeByDiskSizeGB(instanceSize, maxInstanceSizeName, diskSizeGB) {
    const { maxAllowedStorageGB, maxAllowedStorageGBForV2, name } = instanceSize;
    return (
      maxInstanceSizeName !== name && (diskSizeGB <= maxAllowedStorageGB || diskSizeGB <= maxAllowedStorageGBForV2)
    );
  },

  getPreviousApplicableMinInstanceSize(
    computeAutoScaleInstanceSizes,
    currInstanceSizeName,
    maxInstanceSizeName,
    diskSizeGB
  ) {
    const selectedInstanceIndex = computeAutoScaleInstanceSizes.findIndex(({ name }) => name === currInstanceSizeName);
    const proposedPreviousInstance = lastIndexOf(
      computeAutoScaleInstanceSizes,
      selectedInstanceIndex - 1,
      (instanceSize) => this.isApplicableInstanceSizeByDiskSizeGB(instanceSize, maxInstanceSizeName, diskSizeGB)
    );
    return (proposedPreviousInstance && proposedPreviousInstance.name) || currInstanceSizeName;
  },

  getNextApplicableMinInstanceSize(
    computeAutoScaleInstanceSizes,
    minInstanceSizeName,
    selectedInstanceSizeName,
    diskSizeGB
  ): $TSFixMe {
    const minInstanceIndex = computeAutoScaleInstanceSizes.findIndex(({ name }) => name === minInstanceSizeName);
    const selectedInstanceIndex = computeAutoScaleInstanceSizes.findIndex(
      ({ name }) => name === selectedInstanceSizeName
    );
    let proposedNextMinInstance = null;
    for (let i = minInstanceIndex; i <= selectedInstanceIndex; i++) {
      const instanceSize = computeAutoScaleInstanceSizes[i];
      const { maxAllowedStorageGB, maxAllowedStorageGBForV2 } = instanceSize;
      if (diskSizeGB <= maxAllowedStorageGB || (!!maxAllowedStorageGBForV2 && diskSizeGB <= maxAllowedStorageGBForV2)) {
        proposedNextMinInstance = instanceSize;
        break;
      }
    }
    return (proposedNextMinInstance && (proposedNextMinInstance as $TSFixMe).name) || null;
  },

  groupInstancesWithCrossCloudSupport(
    providerOptions,
    crossCloudProviderOptions,
    backingProviders,
    isAwsGravitonSupported,
    instanceVisibilityFilter = () => true
  ) {
    let currentProviderOptions;
    if (backingProviders.length > 1) {
      currentProviderOptions = crossCloudProviderOptions;
    } else {
      currentProviderOptions = providerOptions[backingProviders[0]];
    }
    // Intentionally not passing in the visibility filter here since we want to do the filter after
    // grouping the instances
    let sortedInstances: Array<$TSFixMe> = [];
    if (backingProviders.length === 1) {
      sortedInstances = sortedInstances.concat(this.getInstanceSizesFromOptions(providerOptions['FREE']));
    }
    sortedInstances = sortedInstances.concat(this.getInstanceSizesFromOptions(currentProviderOptions));

    const minDevelopmentInstance = currentProviderOptions.minDevelopmentInstanceSize;
    const minProductionInstance = currentProviderOptions.minProductionInstanceSize;
    const rawTenantInstances: Array<$TSFixMe> = [];
    const rawDevelopmentInstances: Array<$TSFixMe> = [];
    const rawPerformanceInstances: Array<$TSFixMe> = [];
    let isDevelopment = false;
    let isPerformance = false;

    sortedInstances.forEach((instance) => {
      const clonedInstance = deepClone(instance);

      if (!isAwsGravitonSupported) {
        clonedInstance.availableRegions.forEach((region) => {
          if (region.providerName === 'AWS') {
            region.availableFamilies = region.availableFamilies.filter(
              (f) => GRAVITON_INSTANCE_FAMILIES.indexOf(f) === -1
            );
          }
        });
      }

      if (clonedInstance.name === minDevelopmentInstance) {
        isDevelopment = true;
      }
      if (clonedInstance.name === minProductionInstance) {
        isPerformance = true;
      }

      if (isPerformance) {
        rawPerformanceInstances.push(clonedInstance);
      } else if (isDevelopment) {
        rawDevelopmentInstances.push(clonedInstance);
      } else {
        rawTenantInstances.push(clonedInstance);
      }
    });

    const groupedTenantInstances: Array<$TSFixMe> = [];
    const groupedDevelopmentInstances: Array<$TSFixMe> = [];
    const groupedPerformanceInstances: Array<$TSFixMe> = [];

    rawTenantInstances
      .filter(instanceVisibilityFilter)
      .forEach((i) => this.addToInstanceArray(groupedTenantInstances, i));
    rawDevelopmentInstances
      .filter(instanceVisibilityFilter)
      .forEach((i) => this.addToInstanceArray(groupedDevelopmentInstances, i));
    rawPerformanceInstances
      .filter(instanceVisibilityFilter)
      .forEach((i) => this.addToInstanceArray(groupedPerformanceInstances, i));

    return {
      tenantInstances: groupedTenantInstances,
      developmentInstances: groupedDevelopmentInstances,
      performanceInstances: groupedPerformanceInstances,
    };
  },

  parseInstanceSizeString(instanceSizeString) {
    return new InstanceSize(instanceSizeString);
  },

  isInstanceSizeLessThanM40(instanceSizeString: string): boolean {
    try {
      return this.compare(instanceSizeString, 'M40') < 0;
    } catch (exception) {
      console.log('Exception while checking isInstanceSizeLessThanM40; skip rendering warning banner', exception);
      return false;
    }
  },
};
