import { UserProperties } from '@packages/types/user';

import { NDSUserCollectionInterface } from '@packages/common/collections/NDSUserCollection';
import NDSUser from '@packages/common/models/NDSUser';

export const SELECTED_DB_USER_KEY = 'selectedDbUser';
export const SELECTED_DRIVER_KEY = 'selectedDriver';
export const SELECTED_DRIVER_VERSION_KEY = 'selectedDriverVersion';

export const SELECTED_CONNECTION_OPTION = 'selectedConnectionOption';

export const createLocalStorageKeyForConnectPage = (groupId: string) => {
  return `MMS.ConnectPageSettings.${groupId}`;
};

export const getDefaultDatabaseUser = (
  dbUsers: NDSUserCollectionInterface,
  savedDbUser?: UserProperties
): typeof NDSUser => {
  const defaultUser = dbUsers.at(0);

  if (!savedDbUser?.user) {
    return defaultUser;
  }

  const savedUser = dbUsers.find((user) => user.getUser() === savedDbUser.user);

  if (!savedUser) {
    return defaultUser;
  }

  return savedUser;
};
