import { MultiFactorAuth } from '@packages/types/multiFactorAuth';

import fetchWrapper, { formParams } from './fetchWrapper';

function authForceCreator({ forceType, forceBackup = false }) {
  return ({ username, centralUrl = '' }) =>
    fetchWrapper(`${centralUrl}/user/mfa/forceSendCode`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
      method: 'POST',
      body: formParams({
        forceType,
        forceBackup,
        username,
      }),
    }).then((resp) => resp.json());
}

export default {
  auth({ username, password, inviteToken, reCaptchaResponse }) {
    return fetchWrapper('/user/v1/auth', {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        inviteToken,
        reCaptchaResponse,
      }),
    }).then((resp) => resp.json());
  },

  validateMfa({ authcode, idpId, inviteToken, centralUrl = '', clientState = {} }) {
    return fetchWrapper(`${centralUrl}/user/mfa/auth`, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        authcode,
        inviteToken,
        idpId,
        clientState,
      }),
    }).then((resp) => resp.json());
  },

  authForceText: authForceCreator({ forceType: 'SMS' }),
  authForceCall: authForceCreator({ forceType: 'VOICE' }),
  authForceBackupText: authForceCreator({ forceType: 'SMS', forceBackup: true }),
  authForceBackupCall: authForceCreator({ forceType: 'VOICE', forceBackup: true }),

  resetRequest({ username, nds, resetType }: { username: string; nds?: boolean; resetType: string }) {
    const params = {
      username,
      resetType,
    };
    if (nds != null) {
      (params as any).nds = nds ? 'true' : 'false';
    }
    return fetchWrapper('/user/resetRequest', {
      method: 'POST',
      body: JSON.stringify(params),
    }).then((resp) => resp.json());
  },

  userRegisterCall(data: any = {}) {
    const params = {
      username: data.username,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      honeypot: data.honeypot,
      c: data.c,
      kwd: data.kwd,
      company: data.company,
      country: data.country,
      newGroup: data.newGroup,
      signupSource: data.signupSource,
      invitationToken: data.invitationToken,
      activationCode: data.activationCode,
      jobResponsibility: data.jobResponsibility || '',
      jumpId: data.jumpId,
      phoneNumber: data.phoneNumber,
      anonymousId: data.anonymousId || '',
      response: data.response || '',
      clientState: data.clientState,
      clearbitData: data.clearbitData,
      altasCLI: data.altasCLI,
      tosChecked: data.tosChecked,
    };

    return fetchWrapper('/user/registerCall', {
      method: 'POST',
      body: JSON.stringify(params),
    }).then((resp) => resp.json());
  },

  addAnotherGroup({ group, anonymousId, orgId, signupSource, signupMethod, centralUrl = '' }: any = {}) {
    const params = {
      group,
      anonymousId,
    };

    if (signupSource) {
      (params as any).signupSource = signupSource;
    }
    if (signupMethod) {
      (params as any).signupMethod = signupMethod;
    }

    return fetchWrapper(`${centralUrl}/user/addAnotherGroup/${orgId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  params(centralUrl = '') {
    return fetchWrapper(`${centralUrl}/user/params`, {
      credentials: 'include',
      method: 'GET',
    }).then((resp) => resp.json());
  },

  multiFactorSendCode({ forceSms, forceVoice, centralUrl = '' }: any = {}) {
    return fetchWrapper(`${centralUrl}/user/mfa/sendCode`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
      method: 'POST',
      body: formParams({
        forceSms,
        forceVoice,
      }),
    }).then((resp) => resp.json());
  },

  multiFactorSendUpdateCode({ mobileNumber, voice, extension, centralUrl = '' }) {
    return fetchWrapper(`${centralUrl}/user/mfa/sendUpdateCode`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
      method: 'POST',
      body: formParams({
        mobileNumber,
        voice,
        extension,
      }),
    }).then((resp) => resp.json());
  },

  multiFactorCheckCode({ authCode }) {
    return fetchWrapper('/user/mfa/checkCode', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        authCode,
      }),
    }).then((resp) => resp.json());
  },

  multiFactorCheckUpdateCode({ authCode, centralUrl = '' }) {
    return fetchWrapper(`${centralUrl}/user/mfa/checkUpdateCode`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
      method: 'POST',
      body: formParams({
        authCode,
      }),
    }).then((resp) => resp.json());
  },

  multiFactorAuthGet({ centralUrl = '' } = {}): Promise<MultiFactorAuth> {
    return fetchWrapper(`${centralUrl}/user/mfa?updateKey=true`, {
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getMultiFactorAuthBarcode({ updateKey, centralUrl = '' }) {
    return fetchWrapper(`${centralUrl}/user/mfa/barcode`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
      method: 'POST',
      body: formParams({
        updateKey,
      }),
    }).then((resp) => resp.json());
  },

  inviteGet({ username, inviteToken }) {
    const params = {
      inviteToken,
    };
    const inviteGetUrl = `/user/invitation/${encodeURIComponent(username)}/?${formParams(params)}`;
    return fetchWrapper(inviteGetUrl, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getInvites() {
    return fetchWrapper('/user/invitations', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  acceptInvite({ invitationId }) {
    const inviteGetUrl = `/user/invitations/${invitationId}/accept`;
    return fetchWrapper(inviteGetUrl, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  declineInvite({ invitationId }) {
    const inviteGetUrl = `/user/invitations/${invitationId}/decline`;
    return fetchWrapper(inviteGetUrl, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  multiFactorAuthUpdate({
    authCode,
    phone,
    voice,
    extension,
    authenticator,
    backupPhone,
    backupPhoneExtension,
    centralUrl = '',
  }) {
    return fetchWrapper(`${centralUrl}/user/mfa`, {
      credentials: 'include',
      method: 'PATCH',
      body: JSON.stringify({
        authCode,
        phone,
        voice,
        extension,
        authenticator,
        backupPhone,
        backupPhoneExtension,
      }),
    }).then((resp) => resp.json());
  },

  multiFactorAuthDelete({ centralUrl = '' } = {}) {
    return fetchWrapper(`${centralUrl}/user/mfa`, {
      method: 'DELETE',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  multiFactorBackupAuthDelete({ centralUrl = '' } = {}) {
    return fetchWrapper(`${centralUrl}/user/mfa/backupNumber`, {
      method: 'DELETE',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  generateBackupCodes({ centralUrl = '' } = {}) {
    return fetchWrapper(`${centralUrl}/user/mfa/backupCodes/generate`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  multiFactorSendBackupSMS({ backupNumber, centralUrl = '' }) {
    return fetchWrapper(`${centralUrl}/user/mfa/backupNumber/sendSMS`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
      method: 'POST',
      body: formParams({
        backupNumber,
      }),
    }).then((resp) => resp.json());
  },

  resetComplete(data: any = {}) {
    const params = {
      username: data.username,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      tempId: data.tempId,
      apiKey: data.apiKey,
      resetType: data.resetType,
      dbUsername: data.dbUsername,
      dbPassword: data.dbPassword,
      nds: data.nds,
    };

    return fetchWrapper('/user/resetComplete', {
      method: 'POST',
      body: JSON.stringify(params),
    }).then((resp) => resp.json());
  },

  checkPassword({ password }) {
    return fetchWrapper('/user/checkPassword', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        password,
      }),
    }).then((resp) => resp.json());
  },

  updateEmail(emailAddress) {
    return fetchWrapper('/user/profile/updateEmail', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        emailAddress,
      }),
    }).then((resp) => resp.json());
  },

  updateMobilePhoneNumber(mobilePhoneNumber) {
    return fetchWrapper('/user/profile/updateMobilePhoneNumber', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        mobilePhoneNumber,
      }),
    }).then((resp) => resp.json());
  },

  updatePassword({ currentPassword, newPassword, confirmPassword }) {
    return fetchWrapper('/user/updatePassword', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        current: currentPassword,
        new: newPassword,
        confirm: confirmPassword,
      }),
    }).then((resp) => resp.json());
  },

  /**
   * Update a user's roles.
   *
   * @example
   * user.updateUserRole({
   *   groupId: settingsModel.getCurrentGroupId(),
   *   // Add the group owner role and remove the group user admin one.
   *   roles: ['GROUP_OWNER', '-GROUP_USER_ADMIN'],
   *   username: 'examplefaceMcgee',
   * }).then()
   * @param {{ groupId: string, roles: Array.<string>, username: string }}
   * @returns Promise
   */
  updateUserRole({ groupId, roles, username }) {
    return fetchWrapper(`/user/updateUserRole/${groupId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        username,
        roles,
      }),
    }).then((resp) => resp.json());
  },

  removeUserFromGroup({ groupId, username }) {
    return fetchWrapper(`/user/removeUserFromGroup/${groupId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        username,
      }),
    });
  },

  getFederationSettings() {
    return fetchWrapper(`/user/federationSettings`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  refreshSession() {
    return fetchWrapper(`/user/refreshSession`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  deleteMarketplaceRegistrationData() {
    return fetchWrapper('/user/deleteMarketplaceRegistrationData', {
      method: 'PUT',
    }).then((resp) => resp.json());
  },

  getAuthCodeCreationTimestamp(centralUrl: string): Promise<{ authCodeCreationTimestamp: number }> {
    return fetchWrapper(`${centralUrl}/user/authCodeCreationTimestamp`, {
      credentials: 'include',
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
