import { createReducer } from '@packages/redux/common/reducerUtils';

import * as api from '@packages/common/services/api';

// actions
const SET_ALERTS = 'organization/alert/SET_ALERTS';
const SET_ALERT_PAGINATION = 'organization/alert/SET_ALERT_PAGINATION';
const SET_SAVED_ALERT_CONFIGURATIONS = 'organization/alert/SET_SAVED_ALERT_CONFIGURATIONS';
const SET_DELETED_ALERT_CONFIGURATIONS = 'organization/alert/SET_DELETED_ALERT_CONFIGURATIONS';
const SET_OPEN_COUNT = 'organization/alert/SET_OPEN_COUNT';
const SET_CONFIGS = 'organization/alert/SET_CONFIGS';
const SET_HISTORY = 'organization/alert/SET_HISTORY';

// Reducer

const initialState = {
  configs: {},
  histories: {},
  alerts: {},
  alertPagination: {
    canPageForwards: false,
    canPageBackwards: false,
  },
  savedAlertConfigurations: [],
  deletedAlertConfigurations: [],
  openAlertCount: 0,
};

export default createReducer(initialState, {
  [SET_ALERTS]: (state, action) => ({ ...state, alerts: action.payload }),
  [SET_ALERT_PAGINATION]: (state, action) => ({ ...state, alertPagination: action.payload }),
  [SET_SAVED_ALERT_CONFIGURATIONS]: (state, action) => ({ ...state, savedAlertConfigurations: action.payload }),
  [SET_DELETED_ALERT_CONFIGURATIONS]: (state, action) => ({ ...state, deletedAlertConfigurations: action.payload }),
  [SET_OPEN_COUNT]: (state, action) => ({ ...state, openAlertCount: action.payload }),
  [SET_CONFIGS]: (state, action) => {
    const { payload } = action;

    const nextState = { ...state };

    return payload.reduce((acc, alert) => {
      acc.configs[alert.id] = alert;
      return acc;
    }, nextState);
  },
  [SET_HISTORY]: (state, action) => ({
    ...state,
    histories: { ...state.histories, [action.meta.alertId]: action.payload },
  }),
});

// Selectors

// TODO(hswolff): De-dupe from organization/index.js once we move to es modules
const getOrgAlertsData = (state, props) => state.organization[props.orgId].alert || {};

export const getAlerts = (state, props) => getOrgAlertsData(state, props).alerts || [];
export const getAlertPagination = (state, props) => getOrgAlertsData(state, props).alertPagination || {};

export const getConfig = (state, props) => getOrgAlertsData(state, props).configs[props.alertId] || { notify: [] };

export const getHistory = (state, props) => getOrgAlertsData(state, props).histories[props.alertId] || {};
export const getOpenAlertsCount = (state, props) => getOrgAlertsData(state, props).openAlertCount || 0;

const getSavedAlertConfigurations = (state, props) => getOrgAlertsData(state, props).savedAlertConfigurations || [];

const getDeletedAlertConfigurations = (state, props) => getOrgAlertsData(state, props).deletedAlertConfigurations || [];

export const getHydratedSavedAlertConfigurations = (state, props) =>
  getSavedAlertConfigurations(state, props).map((alertId) => getConfig(state, { orgId: props.orgId, alertId }));

export const getHydratedDeletedAlertConfigurations = (state, props) =>
  getDeletedAlertConfigurations(state, props).map((alertId) => getConfig(state, { orgId: props.orgId, alertId }));

// Action Creators

export const setAlerts = ({ orgId, data }) => ({
  type: SET_ALERTS,
  payload: { data },
  meta: { orgId },
});

const setAlertPagination = ({ orgId, canPageForwards, canPageBackwards }) => ({
  type: SET_ALERT_PAGINATION,
  payload: {
    canPageForwards,
    canPageBackwards,
  },
  meta: { orgId },
});

const setSavedAlertConfigurations = ({ orgId, savedAlertConfigurations }) => ({
  type: SET_SAVED_ALERT_CONFIGURATIONS,
  payload: savedAlertConfigurations,
  meta: { orgId },
});

const setDeletedAlertConfigurations = ({ orgId, deletedAlertConfigurations }) => ({
  type: SET_DELETED_ALERT_CONFIGURATIONS,
  payload: deletedAlertConfigurations,
  meta: { orgId },
});

export const setConfigs = (payload = [], orgId) => ({
  type: SET_CONFIGS,
  payload,
  meta: { orgId },
});

export const setHistory = ({ deleted, history, alertId, orgId }) => ({
  type: SET_HISTORY,
  payload: {
    deleted,
    history,
  },
  meta: {
    alertId,
    orgId,
  },
});

const setOpenAlertCount = ({ orgId, openAlertCount }) => ({
  type: SET_OPEN_COUNT,
  payload: openAlertCount,
  meta: { orgId },
});

export const loadOpenAlertsCount = (orgId) => (dispatch) => {
  if (orgId) {
    return api.organization.getOpenAlertsCount({ orgId }).then((response) => {
      dispatch(
        setOpenAlertCount({
          openAlertCount: response,
          orgId,
        })
      );
    });
  }
};

export const loadAlerts =
  ({ orgId, afterId, beforeId, acked, status }) =>
  (dispatch) => {
    return api.organization.getAlerts({ orgId, afterId, beforeId, acked, status }).then((response) => {
      const { data, canPageForwards, canPageBackwards } = response;
      dispatch(
        setAlerts({
          data,
          orgId,
        })
      );
      dispatch(
        setAlertPagination({
          canPageForwards,
          canPageBackwards,
          orgId,
        })
      );
    });
  };

export const loadAlertSettings = (orgId) => (dispatch) => {
  return api.organization.getAlertSettings({ orgId }).then((response) => {
    dispatch(setConfigs(response, orgId));

    dispatch(
      setSavedAlertConfigurations({
        savedAlertConfigurations: response.map((r) => r.id),
        orgId,
      })
    );
  });
};

export const loadDeletedAlertSettings = (orgId) => (dispatch) => {
  return api.organization.getDeletedAlertSettings({ orgId }).then((response) => {
    dispatch(setConfigs(response, orgId));

    dispatch(
      setDeletedAlertConfigurations({
        deletedAlertConfigurations: response.map((r) => r.id),
        orgId,
      })
    );
  });
};

export const loadHistory =
  ({ orgId, alertId }) =>
  (dispatch) => {
    return api.organization.getAlertSettingHistory({ orgId, alertId }).then((response) => {
      dispatch(
        setHistory({
          deleted: response.del,
          history: response.hist,
          alertId,
          orgId,
        })
      );
    });
  };

export const loadConfig =
  ({ orgId, alertId }) =>
  (dispatch) => {
    return api.organization.getSingleAlertSetting({ orgId, alertId }).then((response) => {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      dispatch(setConfigs([response], orgId));
    });
  };
