import { Dictionary, indexBy, pluck } from 'underscore';

import * as organization from '@packages/redux/common/organization/index';
import { createReducer } from '@packages/redux/common/reducerUtils';

import * as api from '@packages/common/services/api';
import {
  Actor,
  CustomPolicy,
  ManagedPolicy,
  PermissionOption,
  UserInvitation,
} from '@packages/common/services/api/policyApi';

// actions
const SET_ORG_POLICIES = 'policies/SET_ORG_POLICIES';
const SET_ORG_POLICY_ASSIGNMENTS = 'policies/SET_ORG_POLICY_ASSIGNMENTS';
const SET_ORG_INVITATION_POLICY_ASSIGNMENTS = 'policies/SET_ORG_INVITATION_POLICY_ASSIGNMENTS';
const SET_ORG_POLICY_PERMISSION_OPTIONS = 'policies/SET_ORG_POLICY_PERMISSION_OPTIONS';
const ADD_POLICY = 'policies/ADD_POLICY';
const UPDATE_POLICY = 'policies/UPDATE_POLICY';
const REMOVE_POLICY = 'policies/REMOVE_POLICY';

// reducer
interface State {
  policies: {
    byId: Dictionary<CustomPolicy | ManagedPolicy>;
    allIds: Array<string>;
  };
  assignments: {
    byId: Dictionary<Actor>;
    allIds: Array<string>;
  };
  invitationAssignments: {
    byId: Dictionary<Actor>;
    allIds: Array<string>;
  };
  permissionOptions: Array<PermissionOption>;
}

const initialState: State = {
  policies: {
    byId: {},
    allIds: [],
  },
  assignments: {
    byId: {},
    allIds: [],
  },
  invitationAssignments: {
    byId: {},
    allIds: [],
  },
  permissionOptions: [],
};

const keyById = <T extends { id: unknown }>(array: Array<T>) => ({
  byId: indexBy(array, 'id'),
  allIds: pluck(array, 'id'),
});

const keyByUsername = <T extends { username: unknown }>(array: Array<T>) => ({
  byId: indexBy(array, 'username'),
  allIds: pluck(array, 'username'),
});

export default createReducer(initialState, {
  [SET_ORG_POLICIES]: (state: State, action: { payload: Array<CustomPolicy | ManagedPolicy> }) => ({
    ...state,
    policies: keyById(action.payload),
  }),
  [SET_ORG_POLICY_ASSIGNMENTS]: (state: State, action: { payload: Array<Actor> }) => ({
    ...state,
    assignments: keyById(action.payload),
  }),
  [SET_ORG_INVITATION_POLICY_ASSIGNMENTS]: (state: State, action: { payload: Array<UserInvitation> }) => ({
    ...state,
    invitationAssignments: keyByUsername(action.payload),
  }),
  [SET_ORG_POLICY_PERMISSION_OPTIONS]: (state: State, action: { payload: Array<PermissionOption> }) => ({
    ...state,
    permissionOptions: action.payload,
  }),
  [ADD_POLICY]: (state: State, action: { payload: CustomPolicy }) => {
    const allIds = state.policies.allIds.concat(action.payload.id);
    const byId = {
      ...state.policies.byId,
      [action.payload.id]: action.payload,
    };
    return {
      ...state,
      policies: {
        ...state.policies,
        byId,
        allIds,
      },
    };
  },
  [UPDATE_POLICY]: (state: State, action: { payload: CustomPolicy }) => {
    if (!state.policies.allIds.includes(action.payload.id)) {
      return state;
    }
    const byId = {
      ...state.policies.byId,
      [action.payload.id]: action.payload,
    };
    return {
      ...state,
      policies: {
        ...state.policies,
        byId,
      },
    };
  },
  [REMOVE_POLICY]: (state: State, action: { payload: string }) => {
    const newPolicies = Object.values(state.policies.byId).filter(
      (policy: CustomPolicy | ManagedPolicy) => policy.id !== action.payload
    );
    const newAssignments = Object.values(state.assignments.byId).map((assignment: Actor) => ({
      ...assignment,
      policyAssignments: assignment.policyAssignments.filter((pa) => pa.policyId !== action.payload),
    }));

    return {
      ...state,
      policies: keyById(newPolicies),
      assignments: keyById(newAssignments),
    };
  },
});

// selectors
const getOrgPoliciesData = (state: State, props: { orgId: string }) =>
  organization.getOrgData(state, props).policies || {};

export const getOrgPolicies = (state: State, props: { orgId: string }): Array<CustomPolicy | ManagedPolicy> => {
  const policies = getOrgPoliciesData(state, props).policies;
  return policies.allIds.map((id: string) => policies.byId[id]) || [];
};

export const getOrgPolicyById = (state: State, props: { orgId: string }) => {
  const policies = getOrgPoliciesData(state, props).policies;
  return policies.byId;
};

export const getOrgPolicyAssignments = (state: State, props: { orgId: string }) => {
  const assignments = getOrgPoliciesData(state, props).assignments;
  return assignments.allIds.map((id: string) => assignments.byId[id]) || [];
};

export const getOrgPolicyAssignmentsById = (state, props: { orgId: string }) => {
  const assignments = getOrgPoliciesData(state, props).assignments;
  return assignments.byId;
};

export const getOrgInvitationPolicyAssignments = (state: State, props: { orgId: string }) => {
  const assignments = getOrgPoliciesData(state, props).invitationAssignments;
  return assignments.allIds.map((id: string) => assignments.byId[id]) || [];
};

export const getOrgInvitationPolicyAssignmentsById = (state, props: { orgId: string }) => {
  const assignments = getOrgPoliciesData(state, props).invitationAssignments;
  return assignments.byId;
};

export const getOrgPolicyPermissionOptions = (state: State, props: { orgId: string }) => {
  return getOrgPoliciesData(state, props).permissionOptions;
};

// action creators
const setOrgPolicies = ({ policies, orgId }: { policies: Array<CustomPolicy | ManagedPolicy>; orgId: string }) => ({
  type: SET_ORG_POLICIES,
  payload: policies,
  meta: {
    orgId,
  },
});

export const addOrgPolicy = ({ policy, orgId }: { policy: CustomPolicy; orgId: string }) => ({
  type: ADD_POLICY,
  payload: policy,
  meta: {
    orgId,
  },
});

export const updateOrgPolicy = ({ policy, orgId }: { policy: CustomPolicy; orgId: string }) => ({
  type: UPDATE_POLICY,
  payload: policy,
  meta: {
    orgId,
  },
});

export const removeOrgPolicy = ({ policyId, orgId }: { policyId: string; orgId: string }) => ({
  type: REMOVE_POLICY,
  payload: policyId,
  meta: {
    orgId,
  },
});

const setOrgPolicyAssignments = ({ assignments, orgId }: { assignments: Array<Actor>; orgId: string }) => ({
  type: SET_ORG_POLICY_ASSIGNMENTS,
  payload: assignments,
  meta: {
    orgId,
  },
});

const setOrgInvitationPolicyAssignments = ({
  invitations,
  orgId,
}: {
  invitations: Array<UserInvitation>;
  orgId: string;
}) => ({
  type: SET_ORG_INVITATION_POLICY_ASSIGNMENTS,
  payload: invitations,
  meta: {
    orgId,
  },
});

const setOrgPolicyPermissionOptions = ({
  permissionOptions,
  orgId,
}: {
  permissionOptions: Array<PermissionOption>;
  orgId: string;
}) => ({
  type: SET_ORG_POLICY_PERMISSION_OPTIONS,
  payload: permissionOptions,
  meta: {
    orgId,
  },
});

export const createCustomPolicy =
  ({ orgId, policy }: { orgId: string; policy: CustomPolicy }) =>
  (dispatch) => {
    return api.policy.createCustomPolicy({ orgId, policy }).then((response) => {
      return dispatch(addOrgPolicy({ orgId, policy: response }));
    });
  };

export const updateCustomPolicy =
  ({ orgId, policy }: { orgId: string; policy: CustomPolicy }) =>
  (dispatch) => {
    return api.policy.updateCustomPolicy({ orgId, policy }).then((response) => {
      return dispatch(updateOrgPolicy({ orgId, policy: response }));
    });
  };

export const deleteCustomPolicy =
  ({ orgId, policyId }: { orgId: string; policyId: string }) =>
  (dispatch) => {
    return api.policy.deleteCustomPolicy({ orgId, policyId }).then(() => {
      return dispatch(removeOrgPolicy({ orgId, policyId }));
    });
  };

export const loadOrgPolicies = (orgId: string) => (dispatch) => {
  return api.policy.getOrgPolicies({ orgId }).then((response) => {
    return dispatch(
      setOrgPolicies({
        policies: response,
        orgId,
      })
    );
  });
};

export const loadOrgPolicyAssignments = (orgId: string) => (dispatch) => {
  return api.policy.getOrgPolicyAssignments({ orgId }).then((response) => {
    return Promise.all([
      dispatch(
        setOrgPolicyAssignments({
          assignments: response.actors,
          orgId,
        })
      ),
      dispatch(
        setOrgInvitationPolicyAssignments({
          invitations: response.invitations,
          orgId,
        })
      ),
    ]);
  });
};

export const loadOrgPolicyPermissions = (orgId: string) => (dispatch) => {
  return api.policy.getAllPermissionsUnderOrgResourceType({ orgId }).then((response) => {
    return dispatch(
      setOrgPolicyPermissionOptions({
        permissionOptions: response,
        orgId,
      })
    );
  });
};
