export const LanguageValueToDisplay = {
  JavaScript: 'JavaScript / Node.js',
};

export const LanguageDisplayToValue = {
  'JavaScript / Node.js': 'JavaScript',
};

export const mapSelectedLanguage = (language: string, direction: string) => {
  if (direction === 'toValue') {
    return LanguageDisplayToValue[language] || language;
  } else {
    return LanguageValueToDisplay[language] || language;
  }
};
