import {
  DataAPIConfig,
  MongoDBBaseRule,
  MongoDBNamespaceRule,
  PartialAPIKey,
  PartialAuthProviderConfig,
  PresetRole,
  ProviderRegion,
} from 'baas-admin-sdk';

import { BaasSettingsHostname } from '@packages/types/baas';
import { DataAPILogItem, Environment } from '@packages/types/dataAPI';

import { ActionType, DataAPIAction } from './actions';

export interface DataAPILogState {
  isLoadingDataAPILogs: boolean;
  dataAPILogsError?: string;
  dataAPILogs: Array<DataAPILogItem>;
  paginationNextEndTime?: Date;
  paginationCurrentSkip?: number;
}

export interface DataAPIState {
  baasApp: any;
  isLoadingBaasApp: boolean;
  enableDataAPIAppError: string;
  loadingDataAPIAppError: string;
  isCreatingDataAPIApp: boolean;
  apiKeys: Array<PartialAPIKey>;
  logs: DataAPILogState;
  isLoadingDefaultRules: boolean;
  loadingDefaultRulesError: string;
  defaultRuleByDataSourceName: Map<string, MongoDBBaseRule>;
  isLoadingDataSourceRules: boolean;
  loadingDataSourceRulesError: string;
  deleteDataSourceRulesError: string;
  rulesByDataSourceName: Map<string, Array<MongoDBNamespaceRule>>;
  versions: Array<string>;
  isLoadingNearestProviderRegion: boolean;
  nearestProviderRegion: ProviderRegion;
  presetRoleConfigs: Array<PresetRole>;
  defaultRuleActionError: string;
  isLoadingConfig: boolean;
  configLoadError: string;
  config: DataAPIConfig;
  endpointAPIUrlsByProviderRegion: Map<string, string>;
  hostnamesByProviderRegion: Map<string, BaasSettingsHostname>;
  isLoadingAuthProviders: boolean;
  authProvidersLoadError: string;
  authProviders: Array<PartialAuthProviderConfig>;
  environmentDesc: Environment;
}

const dataAPILocalStorageInitialState = {
  metrics: {
    computeTime: 0,
    dataOutGB: 0,
    requestCount: 0,
    lastRefresh: undefined,
  },
  defaultRuleByDataSourceName: {},
  rulesByDataSourceName: {},
};

export const initialState: DataAPIState = {
  ...dataAPILocalStorageInitialState,
  baasApp: undefined,
  isLoadingBaasApp: false,
  enableDataAPIAppError: '',
  loadingDataAPIAppError: '',
  isCreatingDataAPIApp: false,
  apiKeys: [],
  logs: {
    isLoadingDataAPILogs: false,
    dataAPILogs: [],
  },
  isLoadingDefaultRules: false,
  loadingDefaultRulesError: '',
  defaultRuleByDataSourceName: new Map<string, MongoDBBaseRule>(),
  isLoadingDataSourceRules: false,
  loadingDataSourceRulesError: '',
  deleteDataSourceRulesError: '',
  rulesByDataSourceName: new Map<string, Array<MongoDBNamespaceRule>>(),
  versions: ['beta', 'v1'], // initialize with default values
  isLoadingNearestProviderRegion: false,
  nearestProviderRegion: ProviderRegion.AWSProviderRegionUSEast1, // initialize with default value
  presetRoleConfigs: [],
  defaultRuleActionError: '',
  isLoadingConfig: false,
  configLoadError: '',
  config: {},
  endpointAPIUrlsByProviderRegion: new Map<ProviderRegion, string>(),
  hostnamesByProviderRegion: new Map<string, BaasSettingsHostname>(),
  isLoadingAuthProviders: false,
  authProvidersLoadError: '',
  authProviders: [],
  environmentDesc: Environment.Prod, // initialize with default value
};

export default function dataAPIReducer(state = initialState, action: DataAPIAction) {
  switch (action.type) {
    case ActionType.GetDataAPIAppReq:
      return {
        ...state,
        isLoadingBaasApp: true,
      };
    case ActionType.GetDataAPIAppRcv:
      return {
        ...state,
        baasApp: action.payload,
        loadingDataAPIAppError: '',
        isLoadingBaasApp: false,
      };
    case ActionType.GetDataAPIAppErr:
      return {
        ...state,
        loadingDataAPIAppError: action.error.message,
        isLoadingBaasApp: false,
      };
    case ActionType.CreateDataAPIAppReq:
      return {
        ...state,
        enableDataAPIAppError: '',
        isCreatingDataAPIApp: true,
      };
    case ActionType.CreateDataAPIAppRcv:
      return {
        ...state,
        isCreatingDataAPIApp: false,
        config: action.payload,
      };
    case ActionType.CreateDataAPIAppErr:
      return {
        ...state,
        enableDataAPIAppError: action.error.message,
        isCreatingDataAPIApp: false,
      };
    case ActionType.SyncFromLocalStorage:
      return {
        ...state,
        metrics: action.payload.metrics,
      };
    case ActionType.GetAPIKeys:
      return {
        ...state,
        apiKeys: action.payload,
      };
    case ActionType.CreateAPIKey:
      return {
        ...state,
        apiKeys: state.apiKeys.concat(new PartialAPIKey(action.payload)),
      };
    case ActionType.DeleteAPIKey:
      return {
        ...state,
        apiKeys: state.apiKeys.filter((key) => key.id !== action.payload),
      };
    case ActionType.LoadDataAPILogsReq:
      return {
        ...state,
        logs: {
          ...state.logs,
          isLoadingDataAPILogs: true,
          dataAPILogsError: undefined,
        },
      };
    case ActionType.LoadDataAPILogsRcv: {
      const { logs, paginationCurrentSkip, paginationNextEndTime } = action.payload;
      return {
        ...state,
        logs: {
          ...state.logs,
          isLoadingDataAPILogs: false,
          dataAPILogsError: undefined,
          dataAPILogs: paginationCurrentSkip ? state.logs.dataAPILogs.concat(logs) : logs,
          paginationNextEndTime,
          paginationCurrentSkip,
        },
      };
    }
    case ActionType.LoadDataAPILogsErr:
      return {
        ...state,
        logs: {
          ...state.logs,
          isLoadingDataAPILogs: false,
          dataAPILogsError: action.error?.message,
        },
      };
    case ActionType.LoadDefaultRulesReq:
      return {
        ...state,
        isLoadingDefaultRules: true,
      };
    case ActionType.LoadDefaultRulesErr:
      return {
        ...state,
        isLoadingDefaultRules: false,
        loadingDefaultRulesError: action.error?.message,
      };
    case ActionType.LoadDefaultRulesRcv:
      return {
        ...state,
        isLoadingDefaultRules: false,
        defaultRuleByDataSourceName: action.payload,
      };
    case ActionType.LoadDataSourceRulesReq:
      return {
        ...state,
        isLoadingServiceRules: true,
      };
    case ActionType.LoadDataSourceRulesErr:
      return {
        ...state,
        isLoadingServiceRules: false,
        loadingServiceRulesError: action.error?.message,
      };
    case ActionType.LoadDataSourceRulesRcv:
      return {
        ...state,
        isLoadingServiceRules: false,
        rulesByDataSourceName: action.payload,
      };
    case ActionType.SetDataSourceDefaultRuleErr:
      return {
        ...state,
        defaultRuleActionError: action.error?.message,
      };
    case ActionType.SetDataSourceDefaultRuleRcv: {
      const newDefaultRuleByDataSourceName = new Map<string, MongoDBBaseRule>(state.defaultRuleByDataSourceName);
      newDefaultRuleByDataSourceName.set(action.payload.name, action.payload.rule);
      return {
        ...state,
        defaultRuleByDataSourceName: newDefaultRuleByDataSourceName,
      };
    }
    case ActionType.DeleteDataSourceRulesErr:
      return {
        ...state,
        deleteDataSourceRulesError: action.error?.message,
      };
    case ActionType.DeleteDataSourceRulesRcv: {
      const newRulesByDataSourceName = new Map<string, Array<MongoDBNamespaceRule>>(state.rulesByDataSourceName);
      newRulesByDataSourceName.delete(action.payload);
      return {
        ...state,
        rulesByDataSourceName: newRulesByDataSourceName,
      };
    }
    case ActionType.GetVersions:
      return {
        ...state,
        versions: action.payload,
      };
    case ActionType.GetNearestProviderRegionReq:
      return {
        ...state,
        isLoadingNearestProviderRegion: true,
      };
    case ActionType.GetNearestProviderRegionRcv:
      return {
        ...state,
        nearestProviderRegion: action.payload,
        isLoadingNearestProviderRegion: false,
      };
    case ActionType.GetNearestProviderRegionErr:
      return {
        ...state,
        isLoadingNearestProviderRegion: false,
      };
    case ActionType.GetPresetRoleConfigs:
      return {
        ...state,
        presetRoleConfigs: action.payload,
      };
    case ActionType.GetDataAPIConfigReq:
      return {
        ...state,
        isLoadingConfig: true,
      };
    case ActionType.GetDataAPIConfigRcv:
      return {
        ...state,
        isLoadingConfig: false,
        config: action.payload,
      };
    case ActionType.GetDataAPIConfigErr:
      return {
        ...state,
        isLoadingConfig: false,
        configLoadError: action.error?.message,
      };
    case ActionType.GetAuthProvidersReq:
      return {
        ...state,
        isLoadingAuthProviders: true,
      };
    case ActionType.GetAuthProvidersRcv:
      return {
        ...state,
        isLoadingAuthProviders: false,
        authProviders: action.payload,
      };
    case ActionType.GetAuthProvidersErr:
      return {
        ...state,
        isLoadingAuthProviders: false,
        authProvidersLoadError: action.error?.message,
      };
    case ActionType.GetAppSPASettingsGlobal:
      return {
        ...state,
        endpointAPIUrlsByProviderRegion: new Map<string, string>(
          Object.entries(action.payload.endpointAPIUrlsByProviderRegion || {})
        ),
        environmentDesc: action.payload.environmentDesc,
        hostnamesByProviderRegion: new Map<string, BaasSettingsHostname>(
          Object.entries(action.payload.hostnamesByProviderRegion || {})
        ),
      };
    default:
      return state;
  }
}
