import { ReactNode } from 'react';

import { ClassNames } from '@emotion/react';
import { ToastProvider as LGToastProvider } from '@leafygreen-ui/toast';

import { headerZIndex } from '@packages/common/styles/layoutStyles';

// TODO: Remove this workaround once LG supports setting a z-index
// See LG-4423
export default function ToastProvider({ children }: { children: ReactNode }) {
  return (
    <ClassNames>
      {({ css }) => (
        <LGToastProvider
          portalClassName={css`
            > * {
              /* Some overlays use headerZIndex + 1, so this should ensure toasts are above those */
              z-index: ${headerZIndex + 2};
            }
          `}
        >
          {children}
        </LGToastProvider>
      )}
    </ClassNames>
  );
}
