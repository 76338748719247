import { Action as UpdatedAction } from './Action';

// export { Action } from './Action';

export type Service = string | 'cloud'; // default value

// TODO(action migration is complete): Remove all uses of ActionType and DeprecatedAction
type ActionType =
  | 'get'
  | 'list'
  | 'update'
  | 'delete'
  | 'create'
  | 'read'
  | 'write'
  | 'manage'
  | 'restore'
  | 'configure';
type DeprecatedAction = `${string}.${ActionType}`;
export type Action = UpdatedAction | DeprecatedAction;

export const ResourceType = {
  GLOBAL: 'global',
  CLUSTER: 'cluster',
  PROJECT: 'project',
  ORGANIZATION: 'organization',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ResourceType = (typeof ResourceType)[keyof typeof ResourceType];

export type ResourceInstanceId = string;

export type PermissionString = `${Service}:${ResourceType}:${ResourceInstanceId}:${Action}`;

export interface Permission {
  service?: Service;
  action: Action;
  resourceType: ResourceType;
  resourceInstanceId: ResourceInstanceId;
}

export interface PermissionResult {
  permission: Permission;
  isAuthorized?: boolean | null;
  lastFetched?: number | null;
}

export type PermissionStore = Record<PermissionString, PermissionResult>;

export interface BasePermissionProps {
  requiredPermissions: Array<Permission>;
  proactive?: boolean;
}
