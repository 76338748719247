import { DataLakeTenant, Namespace, StorageConfigMMS, ValidationResponse } from '@packages/types/nds/dataLakes';
import { CloudProvider } from '@packages/types/nds/provider';

import fetchWrapper from '@packages/common/services/api/fetchWrapper';
import { convertTenantForMMSConsumption } from '@packages/common/utils/dataLake';

let controller = new AbortController();
const getControllerSignal = () => {
  controller.abort();
  controller = new AbortController();
  return controller.signal;
};

export default {
  getDataLakes(groupId: string, includeStorage = false) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/v1/tenants?includeStorage=${!!includeStorage}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDataLake(groupId, tenantName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/v1/tenants/${tenantName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAtlasSQLDataLake(groupId, clusterName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/atlasSQL/${clusterName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDataLakeMetrics(groupId, tenantName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/tenants/${tenantName}/metrics`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  saveDataLake(groupId, dataLakeTenant, skipRoleValidation) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/v1/tenants?skipRoleValidation=${!!skipRoleValidation}`, {
      method: 'POST',
      body: JSON.stringify(dataLakeTenant),
    }).then((resp) => resp.json());
  },

  saveAtlasSQLDataLake(groupId, clusterName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/atlasSQL/${clusterName}`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  updateDataLake(groupId, dataLakeTenantName, dataLakeTenant, skipRoleValidation) {
    return fetchWrapper(
      `/nds/dataLakes/${groupId}/v1/tenants/${dataLakeTenantName}?skipRoleValidation=${!!skipRoleValidation}`,
      {
        method: 'PATCH',
        body: JSON.stringify(dataLakeTenant),
      }
    ).then((resp) => resp.json());
  },

  deleteDataLake(groupId, dataLakeTenantName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/tenants/${dataLakeTenantName}`, {
      method: 'DELETE',
    });
  },

  getDataLakeRegions(groupId) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/availableRegions`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAllSchemas(groupId, dataLakeTenantName) {
    return fetchWrapper(`/nds/atlasSQL/${groupId}/${dataLakeTenantName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getScheduledUpdate(groupId, dataLakeTenantName) {
    return fetchWrapper(`/nds/atlasSQL/${groupId}/${dataLakeTenantName}/schedule`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createScheduledUpdate(groupId, dataLakeTenantName, newSchedule) {
    return fetchWrapper(`/nds/atlasSQL/${groupId}/${dataLakeTenantName}/schedule`, {
      method: 'POST',
      body: JSON.stringify(newSchedule),
    }).then((resp) => resp.json());
  },

  updateScheduledUpdate(groupId, dataLakeTenantName, modifiedSchedule) {
    return fetchWrapper(`/nds/atlasSQL/${groupId}/${dataLakeTenantName}/schedule`, {
      method: 'PATCH',
      body: JSON.stringify(modifiedSchedule),
    }).then((resp) => resp.json());
  },

  deleteScheduledUpdate(groupId, dataLakeTenantName) {
    return fetchWrapper(`/nds/atlasSQL/${groupId}/${dataLakeTenantName}/schedule`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  generateAllSchemas(groupId, dataLakeTenantName) {
    return fetchWrapper(`/nds/atlasSQL/${groupId}/${dataLakeTenantName}/generateAllSchemas`, {
      method: 'POST',
    });
  },

  generateSchema(groupId, dataLakeTenantName, namespace: Namespace) {
    return fetchWrapper(
      `/nds/atlasSQL/${groupId}/${dataLakeTenantName}/generateSchemas/db/${namespace.db}/collection/${namespace.collection}`,
      {
        method: 'POST',
      }
    ).then((resp) => resp.json());
  },

  clearAllSchemas(groupId, dataLakeTenantName) {
    return fetchWrapper(`/nds/atlasSQL/${groupId}/${dataLakeTenantName}`, {
      method: 'DELETE',
    });
  },

  clearSchema(groupId, dataLakeTenantName, namespace: Namespace) {
    return fetchWrapper(
      `/nds/atlasSQL/${groupId}/${dataLakeTenantName}/schema/db/${namespace.db}/collection/${namespace.collection}`,
      {
        method: 'DELETE',
      }
    );
  },

  setSchema(groupId, dataLakeTenantName, namespace: Namespace, isGenerated: boolean, jsonSchema) {
    return fetchWrapper(
      `/nds/atlasSQL/${groupId}/${dataLakeTenantName}/schema/db/${namespace.db}/collection/${namespace.collection}?isGenerated=${isGenerated}`,
      {
        method: 'POST',
        body: JSON.stringify(jsonSchema),
      }
    );
  },

  getSchema(groupId, dataLakeTenantName, namespace: Namespace) {
    return fetchWrapper(
      `/nds/atlasSQL/${groupId}/${dataLakeTenantName}/schema/db/${namespace.db}/collection/${namespace.collection}`,
      {
        method: 'GET',
      }
    ).then((resp) => resp.json());
  },

  validateStorageConfig(
    groupId: string,
    cloudProvider: CloudProvider,
    storageConfig: StorageConfigMMS
  ): { response: Promise<ValidationResponse>; signal: AbortSignal } {
    const signal = getControllerSignal();
    const response = fetchWrapper(`/nds/dataLakes/${groupId}/v1/validateStorage?cloudProvider=${cloudProvider}`, {
      method: 'POST',
      body: JSON.stringify(storageConfig),
      // only allow single validate request go at a time
      signal,
    }).then((resp) => resp.json());

    return {
      response,
      signal,
    };
  },

  // TODO: CLOUDP-170146 - verify cloud provider config details for s3 bucket access
  validateCloudProviderConfig(
    groupId: string,
    tenant: DataLakeTenant,
    skipRoleValidation: boolean
  ): Promise<DataLakeTenant> {
    const convertedDataLakeTenant = {
      ...convertTenantForMMSConsumption(tenant),
    };
    return fetchWrapper(
      `/nds/dataLakes/${groupId}/v1/validateCloudProviderConfig?skipRoleValidation=${skipRoleValidation}`,
      {
        method: 'POST',
        body: JSON.stringify(convertedDataLakeTenant),
      }
    ).then((resp) => resp.json());
  },
};
