import { Metrics } from '@packages/types/charts';
import { AvailableMetric, AvailableMetrics, MetaData, MetricsQuery, Window } from '@packages/types/MetricInterfaces';

import { MetricsApi, MetricsResponse } from '@packages/common/services/api/MetricsApiInterface';
import { MetricsStateApi } from '@packages/common/services/api/MetricsStateInterface';
import metricsService from '@packages/common/services/metricsService';

export interface State {
  metricsMeta: MetaData;
  metrics: Array<Metrics>;
  availableMetrics: AvailableMetrics;
  metricsDisplayRow: boolean;
  selectedCharts: Array<string>;
  isZoomCustom: boolean;
}

export enum ActionType {
  SET_METRICS_DATA = 'search/metrics/SET_METRICS_DATA',
  SET_AVAILABLE_CHARTS = 'search/metrics/SET_AVAILABLE_CHARTS',
  SET_METRICS_DISPLAY_ROW = 'search/metrics/SET_METRICS_DISPLAY_ROW',
  SET_SELECTED_CHARTS = 'search/metrics/SET_SELECTED_CHARTS',
  SET_IS_ZOOM_CUSTOM = 'search/metrics/SET_IS_ZOOM_CUSTOM',
  SET_METRICS_DATA_META_WINDOW = 'search/metrics/SET_METRICS_DATA_META_WINDOW',
}

export interface Action {
  type: ActionType;
  payload: any;
}

function getMetrics(state): State {
  return state.search.metrics;
}

function getMetricsData(state): Array<Metrics> {
  return getMetrics(state).metrics || [];
}

function getMetricsMeta(state): MetaData {
  return getMetrics(state).metricsMeta || {};
}

function getAvailableMetrics(state): AvailableMetrics {
  return getMetrics(state).availableMetrics || {};
}

function getWindow(state): Window | undefined {
  return getMetricsMeta(state).window;
}

function getMetricsDisplayRow(state): boolean {
  return getMetrics(state).metricsDisplayRow;
}

function getSelectedCharts(state): Array<string> {
  return getMetrics(state).selectedCharts;
}

function getIsZoomCustom(state): boolean {
  return getMetrics(state).isZoomCustom;
}

function getAvailableMetricsForMetricId(state, metricId): AvailableMetric {
  return getMetrics(state).availableMetrics[metricId] ?? {};
}

function setMetricsData(payload): Action {
  return {
    type: ActionType.SET_METRICS_DATA,
    payload,
  };
}

function setAvailableCharts(payload): Action {
  return {
    type: ActionType.SET_AVAILABLE_CHARTS,
    payload,
  };
}

function setMetricsDisplayRow(payload): Action {
  return {
    type: ActionType.SET_METRICS_DISPLAY_ROW,
    payload,
  };
}

function setSelectedCharts(payload): Action {
  return {
    type: ActionType.SET_SELECTED_CHARTS,
    payload,
  };
}

function setIsZoomCustom(payload: boolean): Action {
  return {
    type: ActionType.SET_IS_ZOOM_CUSTOM,
    payload,
  };
}

function setMetricsDataMetaWindow(payload: Window): Action {
  return {
    type: ActionType.SET_METRICS_DATA_META_WINDOW,
    payload,
  };
}

function buildQueryParams(isZoomCustom: boolean, window: Window | undefined, settingsModel: any): MetricsQuery {
  const defaultGranularity = settingsModel.get('DEFAULT_CHART_GRANULARITY');
  const isGranularityAuto = defaultGranularity === 0;
  return {
    retention: !isZoomCustom ? settingsModel.get('DEFAULT_CHART_ZOOM') : undefined,
    since: isZoomCustom && window ? window.since : undefined,
    until: isZoomCustom && window ? window.until : undefined,
    granularity: !isGranularityAuto ? defaultGranularity : undefined,
  };
}

export function getMetricsStateApi(api: MetricsApi): MetricsStateApi {
  const loadAvailableCharts = (groupId: string, searchNodeHost: string) => {
    return (dispatch) =>
      api.getAvailableCharts(groupId, searchNodeHost).then((response) => dispatch(setAvailableCharts(response)));
  };

  const loadMetricsData = (
    groupId: string,
    searchNodeHost: string,
    settingsModel: any,
    queryString: MetricsQuery,
    retry: boolean
  ) => {
    return async (dispatch) => {
      const isAutoGranularity = !queryString.granularity;
      let metrics: MetricsResponse;
      try {
        metrics = await api.getMetrics(groupId, searchNodeHost, queryString);
      } catch (error) {
        if (error?.errorCode === 'DATA_NOT_KEPT_FOR_GRANULARITY_AND_TIMEFRAME' && retry) {
          // if granularity & zoom pair aren't valid, then transition user back to Auto gran and retry loading
          const newQueryString = { ...queryString, granularity: undefined };
          loadMetricsData(groupId, searchNodeHost, settingsModel, newQueryString, false)(dispatch);
        }
        return;
      }
      dispatch(setMetricsData(metrics));
      const newChartZoom = metrics.meta.zoom?.millis;
      const newGranularity = metrics.meta.granularities?.selected.millis;
      if (newChartZoom !== settingsModel.get('DEFAULT_CHART_ZOOM')) {
        settingsModel.set('DEFAULT_CHART_ZOOM', newChartZoom);
        metricsService.saveDefaultChartZoom(newChartZoom);
      }
      if (isAutoGranularity && settingsModel.get('DEFAULT_CHART_GRANULARITY') !== 0) {
        settingsModel.set('DEFAULT_CHART_GRANULARITY', 0);
        metricsService.saveDefaultChartGranularity(0);
      }
      if (newGranularity !== settingsModel.get('DEFAULT_CHART_GRANULARITY') && !isAutoGranularity) {
        settingsModel.set('DEFAULT_CHART_GRANULARITY', newGranularity);
        metricsService.saveDefaultChartGranularity(newGranularity);
      }
    };
  };

  const loadSelectedCharts = (groupId: string) => {
    return (dispatch) =>
      api.getSelectedCharts(groupId).then((response) => dispatch(setSelectedCharts(response.selectedCharts)));
  };

  return {
    buildQueryParams,
    getIsZoomCustom,
    getMetricsMeta,
    loadAvailableCharts,
    loadMetricsData,
    getAvailableMetrics,
    getMetricsDisplayRow,
    getSelectedCharts,
    loadSelectedCharts,
    getAvailableMetricsForMetricId,
    getMetricsData,
    getWindow,
    setSelectedCharts,
    setMetricsDisplayRow,
    setAvailableCharts,
    setMetricsData,
    setIsZoomCustom,
    setMetricsDataMetaWindow,
  };
}
