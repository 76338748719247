import fetchWrapper, { formParams } from './fetchWrapper';

export interface MigratePojectParams {
  groupId: string;
  orgId: string;
  migrateTags: boolean;
}

export default {
  group({ groupId }) {
    return fetchWrapper(`/group/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createGroup({
    group,
    useCNRegionsOnly,
    orgId,
    regionUsageRestrictions,
    centralUrl = '',
  }: {
    group: string;
    useCNRegionsOnly: boolean;
    orgId: string;
    regionUsageRestrictions: string;
    centralUrl?: string;
  }) {
    const params = {
      name: group,
      useCNRegionsOnly,
      regionUsageRestrictions,
    };

    return fetchWrapper(`${centralUrl}/orgs/${orgId}/groups`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(params),
    }).then((resp) => resp.json());
  },

  checkGroupName(data = {}) {
    const params = {
      groupName: (data as any).groupName,
    };

    const groupNameURL = `/group/checkGroupName?${formParams(params)}`;

    return fetchWrapper(groupNameURL, {
      method: 'GET',
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ method: string; params: { grou... Remove this comment to see the full error message
      params,
    }).then((resp) => resp.json());
  },

  searchGroup({ term }) {
    const path = `/group/search?term=${term}`;
    return fetchWrapper(path, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteGroup(groupId) {
    return fetchWrapper(`/group/${groupId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  leaveGroup({ groupId, username }) {
    const params = { username };
    return fetchWrapper(`/user/removeUserFromGroup/${groupId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  fetchGroupUsers(groupId) {
    return fetchWrapper(`/settings/group/users/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  validateUser({ groupId, username }) {
    const params = {
      username,
    };

    return fetchWrapper(`/group/validateUser/${groupId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  validateConfigLimits({ orgId, numOfTeamsToAdd, usernames, centralUrl = '' }) {
    const params = {
      orgId,
      numOfTeamsToAdd,
      usernames,
    };

    return fetchWrapper(`${centralUrl}/group/validateConfigLimits?${formParams(params)}`, {
      method: 'GET',
      credentials: 'include',
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ method: string; params: { orgI... Remove this comment to see the full error message
      params,
    }).then((resp) => resp.json());
  },

  getTargetOrgsForMigration({ groupId }) {
    return fetchWrapper(`/group/getTargetOrgsForMigration/${groupId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'GET',
    }).then((resp) => resp.json());
  },

  migrateGroupToOrg({ groupId, orgId, migrateTags }: MigratePojectParams) {
    return fetchWrapper(`/group/migrateToOrg/${groupId}?orgId=${orgId}&migrateTags=${!!migrateTags}`, {
      method: 'POST',
    });
  },

  renameGroup({ groupId, groupName }) {
    const params = {
      groupName,
    };

    return fetchWrapper(`/group/updateGroupName/${groupId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  setGroupTimeZone({ groupId, timeZoneId, timeZoneDisplay }) {
    const params = {
      timeZoneId,
      timeZoneDisplay,
    };

    return fetchWrapper(`/settings/updateGroupTimeZoneId/${groupId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  deleteGroupInvitation({ groupId, username }) {
    return fetchWrapper(`/group/deleteGroupInvitation/${groupId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({
        username,
      }),
    });
  },

  deleteTeamFromGroup({ groupId, teamId }) {
    return fetchWrapper(`/group/removeTeam/${groupId}/${teamId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  getTeams({ groupId }: { groupId: string }) {
    return fetchWrapper(`/group/${groupId}/teams`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  setTeamRoles({ groupId, teamId, roles }) {
    return fetchWrapper(`/group/setTeamRoles/${groupId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        teamId,
        roles,
      }),
    });
  },

  setApiUserRoles({ groupId, apiUserId, roles, desc }) {
    return fetchWrapper(`/group/${groupId}/apiUsers/${apiUserId}/setRoles`, {
      method: 'PATCH',
      body: JSON.stringify({ roles, desc }),
    });
  },

  removeApiUser({ groupId, apiUserId }) {
    return fetchWrapper(`/group/${groupId}/apiUsers/${apiUserId}/remove`, {
      method: 'POST',
    });
  },

  getApiUserApiAccessList({ groupId, apiUserId }) {
    return fetchWrapper(`/group/${groupId}/apiUsers/${apiUserId}/publicApiAccessList`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createApiUser({ groupId, desc, roles }) {
    return fetchWrapper(`/group/${groupId}/apiUsers`, {
      method: 'POST',
      body: JSON.stringify({
        desc,
        roles,
      }),
    }).then((resp) => resp.json());
  },

  addApiUserAccessListEntry({ groupId, apiUserId, ipAddress }) {
    return fetchWrapper(`/group/${groupId}/apiUsers/${apiUserId}/publicApiAccessList`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({ ipAddress }),
    }).then((response) => response.json());
  },

  deleteApiUserAccessListEntry({ groupId, apiUserId, accessListEntryId }) {
    return fetchWrapper(`/group/${groupId}/apiUsers/${apiUserId}/publicApiAccessList/${accessListEntryId}`, {
      method: 'DELETE',
    });
  },

  updateOrgUiAccessListInheritance({ groupId, apiUserId, shouldApplyOrgUiAccessListForApi }) {
    return fetchWrapper(`/group/${groupId}/apiUsers/${apiUserId}/orgUiAccessListInheritance`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
      body: formParams({ shouldApplyOrgUiAccessListForApi }),
    });
  },

  grantAccess({ groupId, users, teams, apiUsers, serviceAccounts, centralUrl = '' }) {
    return fetchWrapper(`${centralUrl}/group/${groupId}/grantAccess`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        users,
        teams,
        apiUsers,
        serviceAccounts,
      }),
    }).then((resp) => resp.json());
  },

  storeLicenseAcceptance({ groupId }, licenses) {
    return fetchWrapper(`/group/setLicenses/${groupId}`, {
      method: 'PATCH',
      body: JSON.stringify(licenses),
    });
  },

  setPagerDutyIntegration({ groupId, body }) {
    const url = `/settings/updatePagerDuty/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removePagerDutyIntegration({ groupId }) {
    const url = `/settings/updatePagerDuty/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  setSlackIntegration({ groupId, body }) {
    const url = `/settings/updateSlack/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removeSlackIntegration({ groupId }) {
    const url = `/settings/updateSlack/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  setPrometheusIntegration({ groupId, body }) {
    const url = `/settings/prometheus/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PUT',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removePrometheusIntegration({ groupId }) {
    const url = `/settings/prometheus/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  setDatadogIntegration({ groupId, body }) {
    const url = `/settings/updateDatadog/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removeDatadogIntegration({ groupId }) {
    const url = `/settings/updateDatadog/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  setVictorOpsIntegration({ groupId, body }) {
    const url = `/settings/updateVictorOps/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removeVictorOpsIntegration({ groupId }) {
    const url = `/settings/updateVictorOps/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  setOpsgenieIntegration({ groupId, body }) {
    const url = `/settings/updateOpsGenie/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removeOpsgenieIntegration({ groupId }) {
    const url = `/settings/updateOpsGenie/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  setWebhookIntegration({ groupId, body }) {
    const url = `/settings/updateWebhook/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removeWebhookIntegration({ groupId }) {
    const url = `/settings/updateWebhook/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  testNewRelicIntegration({ body }) {
    const url = '/settings/verifyNewRelicCredentials';
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  setNewRelicIntegration({ groupId, body }) {
    const url = `/settings/updateNewRelic/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removeNewRelicIntegration({ groupId }) {
    const url = `/settings/updateNewRelic/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  setMicrosoftTeamsIntegration({ groupId, body }) {
    const url = `/settings/updateMicrosoftTeams/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(body),
    }).then((resp) => resp.json());
  },

  removeMicrosoftTeamsIntegration({ groupId }) {
    const url = `/settings/updateMicrosoftTeams/${groupId}`;
    return fetchWrapper(url, {
      method: 'DELETE',
    });
  },

  updateDbStatsEnabled({ groupId, enabled }) {
    const url = `/settings/changeDbstats/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({ value: enabled }),
    }).then((resp) => resp.json());
  },

  updateLogCollectionAllHosts({ groupId, enabled }) {
    const url = `/settings/updateLogDataCollectionAllHosts/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({ value: enabled }),
    }).then((resp) => resp.json());
  },

  updateProfilingInfoAllHosts({ groupId, enabled }) {
    const url = `/settings/updateEnableAllHostProfilers/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({ value: enabled }),
    }).then((resp) => resp.json());
  },

  updateSuppressMongosDiscovery({ groupId, enabled }) {
    const url = `/settings/updateSuppressMongosAutoDiscovery/${groupId}`;
    return fetchWrapper(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams({ value: enabled }),
    }).then((resp) => resp.json());
  },

  addPreferredHostname({ groupId, isEndsWith, isRegexp, value }) {
    const params = { isRegexp, isEndsWith, value };
    return fetchWrapper(`/settings/addPreferredHostname/${groupId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams(params),
    });
  },

  deletePreferredHostname(groupId, nameId) {
    const params = { id: nameId };
    return fetchWrapper(`/settings/deletePreferredHostname/${groupId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams(params),
    });
  },

  getPreferredHostnames(groupId) {
    return fetchWrapper(`/settings/preferredHostnames/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getGroupServiceAccounts({ groupId }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getGroupServiceAccount({ groupId, clientId }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createGroupServiceAccount({ groupId, payload }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  },

  editGroupServiceAccount({ groupId, clientId, payload }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
    }).then((resp) => resp.json());
  },

  getGroupServiceAccountAccessList({ groupId, clientId }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}/accessList`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteServiceAccount({ groupId, clientId }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}`, {
      method: 'DELETE',
    });
  },

  createGroupServiceAccountSecret({ groupId, clientId, secretExpiresAfterHours }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}/secrets`, {
      method: 'POST',
      body: JSON.stringify({ secretExpiresAfterHours }),
    }).then((resp) => resp.json());
  },

  deleteGroupServiceAccountSecret({ groupId, clientId, secretId }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}/secrets/${secretId}`, {
      method: 'DELETE',
    });
  },

  addGroupServiceAccountAccessList({ groupId, clientId, accessList }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}/accessList`, {
      method: 'POST',
      body: JSON.stringify(accessList),
    }).then((resp) => resp.json());
  },

  deleteGroupServiceAccountAccessListEntry({ groupId, clientId, entryToDelete }) {
    return fetchWrapper(
      `/groups/${groupId}/serviceAccounts/${clientId}/accessList/${encodeURIComponent(entryToDelete)}`,
      {
        method: 'DELETE',
      }
    );
  },

  getServiceAccountOrgUiAccessListInheritance({ groupId, clientId }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}/shouldApplyOrgUiAccessListForApi`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateServiceAccountOrgUiAccessListInheritance({ groupId, clientId, shouldApplyOrgUiAccessListForApi }) {
    return fetchWrapper(`/groups/${groupId}/serviceAccounts/${clientId}/orgUiAccessListInheritance`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PUT',
      body: formParams({ shouldApplyOrgUiAccessListForApi }),
    });
  },
};
