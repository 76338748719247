import { useEffect } from 'react';

import { History } from 'history';

import { useRequestParams } from '@packages/common/context/RequestParamsContext';
import analytics, { sanitizeLocation } from '@packages/common/utils/segmentAnalytics';

export default function useAnalytics(history: History) {
  const { analyticsEnabled } = useRequestParams();

  if (analyticsEnabled === undefined) {
    throw new Error('Analytics enabled setting should not be undefined.');
  }

  useEffect(() => {
    if (analyticsEnabled) {
      const unlisten = history.listen(() => {
        const location = sanitizeLocation();
        analytics.page('', {
          path: location,
        });
      });
      return () => unlisten();
    }
  }, [analyticsEnabled, history]);
}
