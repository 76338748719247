interface AddPathElementsOptions {
  idPlaceholder?: boolean;
}

export interface SanitizeLocationOptions extends AddPathElementsOptions {}

// Given an array of strings this function will return a concatenated string of all elements
// that are not empty nor ObjectID
// i.e. given an array of ['foo', 'bar', '55a06f300cc6908d8bb5632b'] it should return '/foo/bar'
const addPathElements = (arr: Array<string>, resultString: string, options: AddPathElementsOptions = {}) => {
  return arr.reduce((acc: $TSFixMe, pathElem: $TSFixMe) => {
    if (pathElem) {
      // skip empties
      if (pathElem.match(/^[0-9A-Fa-f]+$/)) {
        if (options.idPlaceholder) {
          acc += '/<id>';
        }
      } else {
        // skip hex-looking strings.
        acc += `/${pathElem}`;
      }
    }

    return acc;
  }, resultString);
};

const dataExplorerPathRegex = 'explorer\\/((.+\\/){2})?(.+)?';

const sanitizeDataExplorerPath = (path: $TSFixMe) => {
  const pathMatch = path.match(dataExplorerPathRegex);
  // remove database and collection name
  if (pathMatch && pathMatch.index && pathMatch[1]) {
    let sanitizedPath = '';
    path.split(pathMatch[1]).map((subst: $TSFixMe) => (sanitizedPath += subst));
    return sanitizedPath;
  }
  // remove database name
  if (pathMatch && pathMatch[3]) {
    return path.slice(0, -pathMatch[3].length);
  }
  return path;
};

const sanitizeQueryString = () => {
  const search = window.location.search.replace(/^\?/, '');

  let sanitized = '';

  search.split('&').forEach((keyeqval) => {
    const lrvalues = keyeqval.split('=');
    const key = decodeURI(lrvalues[0]);
    const rvalue = lrvalues[1];

    if (key === '_ga' || key === 'gclid' || key === 'adpos' || !!key.match(/^utm_/)) {
      sanitized += sanitized === '' ? '?' : '&';
      sanitized += encodeURI(key);
      sanitized += '=';
      sanitized += rvalue;
    }
  });

  return sanitized;
};

export const sanitizeLocation = (loc = window.location, options: SanitizeLocationOptions = {}) => {
  let sanitized = '';
  const pathDelimiterRegex = /^\|\/$/;
  sanitized = addPathElements(loc.pathname.replace(pathDelimiterRegex, '').split('/'), sanitized, options);

  if (loc.search) {
    sanitized += sanitizeQueryString();
  }

  if (loc.hash) {
    let hash = sanitizeDataExplorerPath(loc.hash);
    // Some backbone router navigate calls can occasionally strip out a leading slash.
    // Add it back in to make URLs consistent. This shouldn't be needed anymore once we've moved to React Router.
    if (!hash.startsWith('#/')) {
      hash = '#/' + hash.slice(1);
    }
    hash = hash.replace(pathDelimiterRegex, '').split('/');
    sanitized += hash[0];
    hash = hash.slice(1);
    sanitized = addPathElements(hash, sanitized, options);
  }

  return sanitized;
};
