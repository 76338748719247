import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';
import { orgNavHeight as ORG_NAV_HEIGHT, projectNavHeight as PROJECT_NAV_HEIGHT } from '@lg-private/mongo-nav';

import useThemeValue from '@packages/theme/hooks/useThemeValue';

const DEFAULT_MARGIN = 24;
const FEATURE_REQUEST_HEIGHT = 56;
const FREE_TIER_BANNER_HEIGHT = 40;
const EMAIL_VERIFICATION_BANNER_HEIGHT = 42;
const SIDE_NAV_PADDING = 8;
const SIDE_NAV_ITEM_PADDING = 16;
const SIDE_NAV_WIDTH = 235;
const KUBERNETES_CONTAINER_BOTTOM_POSITION = 46;

export const constants = {
  defaultMargin: DEFAULT_MARGIN,
  featureRequestHeight: FEATURE_REQUEST_HEIGHT,
  sideNavItemPadding: SIDE_NAV_ITEM_PADDING,
  sideNavPadding: SIDE_NAV_PADDING,
  sideNavWidth: SIDE_NAV_WIDTH,
  emailVerificationBannerHeight: EMAIL_VERIFICATION_BANNER_HEIGHT,
  kubernetesContainerBottomPosition: KUBERNETES_CONTAINER_BOTTOM_POSITION,
};

interface HeaderHeightProps {
  includeOrgNav?: boolean;
  includeProjectNav?: boolean;
  includeFreeTierBanner?: boolean;
  includeEmailVerificationBanner?: boolean;
}

export const calculateHeaderHeight = ({
  includeOrgNav,
  includeProjectNav,
  includeFreeTierBanner,
  includeEmailVerificationBanner,
}: HeaderHeightProps) => {
  let totalHeight = 0;
  totalHeight += includeOrgNav ? ORG_NAV_HEIGHT : 0;
  totalHeight += includeProjectNav ? PROJECT_NAV_HEIGHT : 0;
  totalHeight += includeFreeTierBanner ? FREE_TIER_BANNER_HEIGHT : 0;
  totalHeight += includeEmailVerificationBanner ? EMAIL_VERIFICATION_BANNER_HEIGHT : 0;
  return totalHeight;
};

/**
 *  grid template strings for the default layout and modifications
 */
const getGridTemplate = ({
  showSideNav,
  showHeader,
  headerHeight,
}: {
  showSideNav: boolean;
  showHeader: boolean;
  headerHeight: number;
}) => {
  // default layout
  if (showHeader && showSideNav) {
    return `
      [header-start] 'header header' ${headerHeight}px [header-end]
      [body-start] 'side-nav body' auto [body-end]
      / auto 1fr;
    `;
  }

  // layout without header
  if (showSideNav) {
    return `
      [body-start] 'side-nav body' auto [body-end]
      / auto 1fr;
    `;
  }

  // layout without side-nav
  if (showHeader) {
    return `
      [header-start] 'header' ${headerHeight}px [header-end]
      [body-start] 'body' auto [body-end]
      / auto;
    `;
  }

  // full-page body layout
  return `
    [body-start] 'body' auto [body-end]
    / auto;
  `;
};

/**
 *  default layout component and layout modifications
 */

interface DefaultLayoutProps {
  headerHeight: number;
  hasGrayBackground: boolean;
  isFullWidth: boolean;
  showSideNav: boolean;
  showHeader: boolean;
}

export const DefaultLayout = styled.div(
  ({ hasGrayBackground, isFullWidth, showSideNav, showHeader, headerHeight }: DefaultLayoutProps) => {
    const gridTemplate = getGridTemplate({ showHeader, showSideNav, headerHeight });
    const themeValue = useThemeValue();
    const backgroundColor = themeValue({ light: hasGrayBackground ? 'grayLight3' : 'white', dark: 'black' });

    return css({
      display: 'grid',
      gridTemplate,
      width: '100vw',
      maxWidth: '100%',
      height: '100vh',
      minWidth: isFullWidth ? 'auto' : '767px',
      margin: isFullWidth ? 'auto' : '0',
      backgroundColor,
    });
  }
);

/**
 *  section containers for use within a layout, each assigned to a template area
 *  regardless of their positioning
 */

export const BodyContainer = styled.div(({ isFullWidth }: { isFullWidth: boolean }) => {
  return css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gridArea: 'body',
    position: 'relative',
    overflowX: 'scroll',
    overflowY: 'scroll',
    zIndex: 'auto',
    padding: isFullWidth ? 0 : `${DEFAULT_MARGIN}px`,
    minWidth: 0,
  });
});

// Exporting this so that toasts and other absolutely-positioned objects can be sure to display above the side nav
// If this is updated, please update the DarkModeMarketingModal z-index as that isn't reading from here for circular dependency reasons
export const hoveredSideNavZIndex = 100;
export const sideNavZIndex = 1;
export const SideNavContainer = styled.div`
  grid-area: side-nav;
  overflow-y: visible;
  list-style: none;
  z-index: ${sideNavZIndex};
  /*
  The SideNav when collapsed should appear over all other content
  when hovered or focused with a keyboard.

  This also will apply to hover/focus of the SideNav when expanded (default)
  */
  &:focus-within,
  &:hover {
    z-index: ${hoveredSideNavZIndex};
  }
`;

// Exporting this so that modals and other full-page components can be sure to display above the header
export const headerZIndex = hoveredSideNavZIndex + 1;
export const popoverZIndex = 9999;

export const FixedHeaderContainer = styled.div(({ headerHeight }: { headerHeight: number }) => {
  return css({
    backgroundColor: palette.white,
    position: 'fixed',
    zIndex: headerZIndex,
    top: 0,
    left: 0,
    right: 0,
    height: `${headerHeight}px`,
  });
});
