import { PermissionResult } from '@packages/types/auth/fga';

import { authzManager } from '@packages/common/services/authzManager';
import errorHelpers from '@packages/common/utils/legacyErrorHelper';
import redirectTo from '@packages/common/utils/redirectTo';

let disabled = false;

const defaultRedirectToLogin = () => {
  const urlStr = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  window.location.assign(`/user/login?n=${encodeURIComponent(urlStr)}`);
};
let redirectToLogin = defaultRedirectToLogin;

// from http://api.jquery.com/jQuery.ajax/#jqXHR (as of v1.11.x)
// ----------------------------------------------------------------------------
// The function receives three arguments: The jqXHR object, a string describing
// the type of error that occurred and an optional exception object,
// if one occurred.
// Possible values for the second argument (besides null) are "timeout",
// "error", "abort", and "parsererror". When an HTTP error occurs, errorThrown
// receives the textual portion of the HTTP status, such as
// "Not Found" or "Internal Server Error."

function jqueryErrorHandler(jqXHR?, textStatus?, errorThrown?) {
  const errorHelperValues = errorHelpers.getErrorMessage({ xhr: jqXHR });
  if (disabled) {
    throw errorHelperValues;
  }

  // if user has been locked out or account suspended
  if (jqXHR && jqXHR.status) {
    if (jqXHR.status === 402) {
      // redirect them to the billing page to fix it
      redirectTo('settings/billing');
      return;
    }
    if (jqXHR.status === 401) {
      redirectToLogin();
      return;
    }
    if (jqXHR.status === 403) {
      // if FGA Authz error, update authzManager and prevent further application failure
      const body = jqXHR.responseJSON;
      if (body.authorizationResults != null) {
        authzManager.updatePermissions(body.authorizationResults as Array<PermissionResult>);
        return;
      }
    }

    // log the error details
    console.error(jqXHR.status, textStatus, errorThrown);
  }

  // and return with the error message (for successive fail handlers)
  throw errorHelperValues;
}

jqueryErrorHandler.disable = function () {
  disabled = true;
};

jqueryErrorHandler.enable = function () {
  disabled = false;
};

jqueryErrorHandler.stubRedirectToLogin = (fn) => {
  redirectToLogin = fn;
};

jqueryErrorHandler.restoreRedirectToLogin = () => {
  redirectToLogin = defaultRedirectToLogin;
};

export default jqueryErrorHandler;
