import { Action, ActionFromReducer, combineReducers } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import app from '../common/app';
import dataAPI from '../common/dataAPI/reducers';
import group from '../common/group';
import invoice from '../common/invoice';
import organization from '../common/organization';
import type { createStore } from '../common/reduxHelpers';
import search from '../common/search/reducer';
import settings from '../common/settings';
import team from '../common/team';
import user from '../common/user';
import viewer from '../common/viewer';

const rootReducer = combineReducers({
  dataAPI,
  search,
  viewer,
  settings,
  group,
  organization,
  user,
  team,
  app,
  invoice,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export type RootAction = ActionFromReducer<typeof rootReducer>;
export type RootThunk<ReturnType, A extends Action = RootAction> = ThunkAction<ReturnType, RootState, unknown, A>;
export type RootStore = ReturnType<typeof createStore<RootState, RootAction>>;
