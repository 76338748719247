import { lazy, Suspense } from 'react';

// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { History, Redirect, Route, Router, Switch } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import componentLoader from '@packages/component-loader';

const MultiFactorPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'multi-factor-page' */ './components/MultiFactorPage'))
);

const VercelLoginPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'vercel-login-page' */ './components/VercelLoginPage'))
);

const LoginPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'login-page' */ './components/LoginPage'))
);

const RegistrationPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'registration-page' */ './components/RegistrationPage'))
);

const RegistrationSuccessPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'registration-success-page' */ './components/RegistrationSuccessPage')
  )
);

const VercelRegistrationPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'vercel-registration-page' */ './components/VercelRegistrationPage')
  )
);

const VercelConfirmationPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'vercel-confirmation-page' */ './components/VercelConfirmationPage')
  )
);

const VercelClusterLinkingPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'vercel-cluster-linking-page' */ './components/VercelClusterLinkingPage')
  )
);

const VercelClusterCreationPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'vercel-cluster-creation-page' */ './components/VercelClusterCreationPage')
  )
);

const VercelErrorHandlingPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'vercel-error-handling-page' */ './components/VercelErrorHandlingPage')
  )
);

const VercelOrgSelectionPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'vercel-org-selection-page' */ './components/VercelOrgSelectionPage')
  )
);

const TermsOfServicePage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'terms-of-service-page' */ './components/TermsOfServicePage'))
);

const ResetPasswordPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'reset-password-page' */ './components/ResetPasswordPage'))
);

const UniversityPasswordResetPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'univ-reset-password-page' */ './components/UniversityPasswordResetPage')
  )
);

const DeviceAuthPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'device-auth-page' */ './components/DeviceAuthPage'))
);

const DeviceAuthSuccessPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'device-auth-success-page' */ './components/DeviceAuthSuccessPage'))
);

const ResetMultiFactorPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'reset-multi-factor-page' */ './components/ResetMultiFactorPage'))
);

const MultiFactorSetupPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'multi-factor-setup-page' */ './components/MultiFactorSetupPage'))
);

const IdentityVerificationPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'identity-verification-page' */ './components/IdentityVerificationPage')
  )
);

const EmailVerificationPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: 'email-verification-page' */ './components/EmailVerificationPage'))
);

const AccountMultiFactorAuthPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'account-multi-factor-page' */ './components/AccountMultiFactorAuthPage')
  )
);

const AccountMultiFactorAuthSetupPage = lazy(() =>
  componentLoader(
    () =>
      import(
        /* webpackChunkName: 'account-multi-factor-auth-setup-page' */ './components/AccountMultiFactorAuthSetupPage'
      )
  )
);

const MfaEncouragementPromptPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'mfa-encouragement-prompt-page' */ './components/MfaEncouragementPromptPage')
  )
);

const MfaTransitionPromptPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: 'mfa-transition-prompt-page' */ './components/MfaTransitionPromptPage')
  )
);

const AccountMultiFactorAuthFactorSetupPage = lazy(() =>
  componentLoader(
    () =>
      import(
        /* webpackChunkName: 'account-multi-factor-auth-factor-setup-page' */ './components/AccountMultiFactorAuthFactorSetupPage'
      )
  )
);

const AccountMultiFactorAuthTransitionPage = lazy(() =>
  componentLoader(
    () =>
      import(
        /* webpackChunkName: 'account-multi-factor-auth-transition-page' */ './components/AccountMultiFactorAuthTransitionPage'
      )
  )
);

export default function AuthRouter({
  history,
  accountMultiFactorAuthEnabled,
}: {
  history: History;
  accountMultiFactorAuthEnabled: boolean;
}) {
  return (
    <Router history={history}>
      <CompatRouter>
        <Suspense fallback={null}>
          <Switch>
            <Route path="/login/mfa" component={MultiFactorPage} exact />
            <Route path="/login/vercel" component={VercelLoginPage} exact />
            <Route path="/login" component={LoginPage} exact />
            <Route path="/register" component={RegistrationPage} exact />
            <Route path="/register/success" component={RegistrationSuccessPage} exact />
            <Route path="/register/vercel" component={VercelRegistrationPage} exact />
            <Route path="/register/:flow" component={RegistrationPage} exact />
            <Route path="/vercel/confirm" component={VercelConfirmationPage} exact />
            <Route path="/vercel/cluster/link" component={VercelClusterLinkingPage} exact />
            <Route path="/vercel/cluster/create" component={VercelClusterCreationPage} exact />
            <Route path="/vercel/cluster/error" component={VercelErrorHandlingPage} exact />
            <Route path="/vercel/org/selection" component={VercelOrgSelectionPage} exact />
            <Route path="/tos" component={TermsOfServicePage} />
            <Route path="/reset/password/:tempId?" component={ResetPasswordPage} />
            <Route path="/reset/university/password" component={UniversityPasswordResetPage} exact />
            <Route path="/connect" component={DeviceAuthPage} exact />
            <Route path="/connect/success" component={DeviceAuthSuccessPage} exact />
            <Route path="/reset/mfa/:planType/:tempId?" component={ResetMultiFactorPage} exact />
            <Route path="/mfa/setup" component={MultiFactorSetupPage} />
            <Route path="/auth/verify" component={IdentityVerificationPage} />
            <Route path="/email/verify" component={EmailVerificationPage} />
            {accountMultiFactorAuthEnabled && (
              <Route path="/security/mfa" component={AccountMultiFactorAuthPage} exact />
            )}
            {accountMultiFactorAuthEnabled && (
              <Route path="/security/mfa/setup" component={AccountMultiFactorAuthSetupPage} exact />
            )}
            {accountMultiFactorAuthEnabled && (
              <Route path="/security/mfa/transition" component={AccountMultiFactorAuthTransitionPage} exact />
            )}
            {accountMultiFactorAuthEnabled && (
              <Route path="/encourage/mfa" component={MfaEncouragementPromptPage} exact />
            )}
            {accountMultiFactorAuthEnabled && (
              <Route path="/transition/mfa" component={MfaTransitionPromptPage} exact />
            )}
            {accountMultiFactorAuthEnabled && (
              <Route path="/security/mfa/setup/:factor" component={AccountMultiFactorAuthFactorSetupPage} exact />
            )}
            <Route
              path="/"
              component={({ location }: $TSFixMe) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: '/login',
                  }}
                />
              )}
            />
          </Switch>
        </Suspense>
      </CompatRouter>
    </Router>
  );
}
