import { MetadataEntry } from '@packages/types/clientMetadata';

import fetchWrapper from '@packages/common/services/api/fetchWrapper';

const listToQueryParams = (queryParam: string, list: Array<String>): string => {
  if (list === undefined || list.length === 0) return '';
  let result = '?';
  list.forEach((elem, i) => {
    if (i > 0) {
      result += '&';
    }
    result += `${queryParam}=${elem}`;
  });
  return result;
};

export const getClientMetadata = async (
  groupId: string,
  clusterNames: Array<string>
): Promise<Array<MetadataEntry>> => {
  return fetchWrapper(`/nds/${groupId}/clientMetadata${listToQueryParams('clusterNames', clusterNames)}`, {
    method: 'GET',
  }).then((resp) => resp.json());
};
