import * as React from 'react';

import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

// utilities
import { CloudTeams, ErrorSeverity } from '@packages/types/observability';

import ErrorMessage from '@packages/components/ErrorBoundary/ErrorMessage';
import { sendError } from '@packages/observability';

export interface Metadata {
  name: string;
  team: CloudTeams;
  extra?: any;
}

interface ErrorBoundaryProps {
  FallbackComponent?: React.ComponentType<FallbackProps>;
  metadata: Metadata;
  onError?: (
    error: Error & { severity?: ErrorSeverity },
    componentStack: { componentStack: string },
    metadata: Metadata
  ) => void;
}

function ErrorBoundary({
  children,
  FallbackComponent = ErrorMessage.ClientError,
  metadata,
  onError = (error, componentStack, metadata) => {
    sendError({
      error,
      team: metadata.team,
      extras: {
        name: metadata.name,
        ...metadata.extra,
        componentStack,
      },
      severity: error.severity ?? ErrorSeverity.ERROR,
    });
  },
}: React.PropsWithChildren<ErrorBoundaryProps>) {
  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, componentStack) => {
        onError(error, componentStack, metadata);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}

function withErrorBoundary<TProps>({
  Component,
  FallbackComponent,
  onError,
  metadata,
}: ErrorBoundaryProps & {
  Component: React.ComponentType<TProps>;
}) {
  return (props: TProps & {}) => {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent} onError={onError} metadata={metadata}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}
export { ErrorBoundary, withErrorBoundary };
