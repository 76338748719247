import { LoadSampleDatasetStatus, SampleDataset } from '@packages/types/nds/loadSampleDataset';

import fetchWrapper from '../fetchWrapper';

export type PostLoadSampleDatasetProps = {
  clusterName: string;
  groupId: string;
} & (
  | {
      dataset?: SampleDataset;
    }
  | {
      // When selectively loading sample datasets, DEFAULT should not be allowed as it indicates load all datasets
      selectedDatasets?: Array<Exclude<SampleDataset, SampleDataset.DEFAULT>>;
    }
);

export default {
  getLoadSampleDatasetStatuses(groupId): Promise<Array<LoadSampleDatasetStatus>> {
    return fetchWrapper(`/nds/sampleDatasetLoad/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  /**
   * Initiates the sample data load for a given cluster and group.
   *
   * An optional third parameter `dataset` can be passed in. If this
   * value is `sampledata` (SampleDataset.DEFAULT) the entire sample
   * dataset will be downloaded.
   *
   * Alternatively, `selectedDatasets` can be passed in to load multiple specified datasets.
   */
  postLoadSampleDataset(data: PostLoadSampleDatasetProps) {
    return fetchWrapper(`/nds/sampleDatasetLoad/${data.groupId}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },
};
