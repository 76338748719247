import { Language } from '@leafygreen-ui/code';
import {
  DeploymentModel,
  EndpointReturnType,
  MongoDBBaseRule,
  MongoDBNamespaceRule,
  PresetRole,
  ProviderRegion,
} from 'baas-admin-sdk';
import equal from 'fast-deep-equal';

import { BaasSettingsHostname } from '@packages/types/baas';
import {
  ClusterServiceConfig,
  DataLakeServiceConfig,
  DataSourceServiceType,
  MongoServiceConfig,
} from '@packages/types/dataAPI';
import { ClusterDescription } from '@packages/types/nds/clusterDescription';
import { CloudProvider } from '@packages/types/nds/provider';

import { AccessName, noAccessDefaultRule } from '../constants/dataAPI';

interface URLEndpointParams {
  version: string;
  clientAppId?: string;
  deploymentModel?: DeploymentModel;
  providerRegionURL?: string;
}

export const isCluster = (cfg?: MongoServiceConfig): cfg is ClusterServiceConfig =>
  cfg?.serviceType === DataSourceServiceType.SVCTYPE_MONGODB_ATLAS;

export const isDataLake = (cfg?: MongoServiceConfig): cfg is DataLakeServiceConfig =>
  cfg?.serviceType === DataSourceServiceType.SVCTYPE_DATALAKE;

export const getURLEndpoint = (urlParams: URLEndpointParams) => {
  const baseUrl =
    urlParams.deploymentModel === DeploymentModel.Local && urlParams.providerRegionURL
      ? urlParams.providerRegionURL
      : 'https://data.mongodb-api.com';

  return `${baseUrl}/app/${urlParams.clientAppId || '<CLIENT_APP_ID>'}/endpoint/data/${urlParams.version}`;
};

export const getDisplayURL = (urlParams: URLEndpointParams) => {
  const endpointPrefix = 'https://data.mongodb-api.com/'; // default app services endpoint URL
  const fullURL = getURLEndpoint(urlParams);
  return `${fullURL.slice(0, endpointPrefix.length)}.../data/${urlParams.version}`;
};

export const generateAccessTokenSnippet = (
  language: Language,
  username: string,
  password?: string,
  baasCentralUrl?: string,
  clientAppId?: string,
  deploymentModel?: DeploymentModel,
  hostname?: BaasSettingsHostname
) => {
  const usernameVal = username || '<USER_EMAIL>';
  const passwordVal = password || '<USER_PASSWORD>';
  const clientAppIdVal = clientAppId || '<CLIENT_APP_ID>';

  let domain = baasCentralUrl;

  if (deploymentModel === DeploymentModel.Local && hostname) {
    domain = hostname.http;
  }

  switch (language) {
    case Language.JavaScript: {
      return `import * as Realm from "realm-web";

const app = new Realm.App({ id: '${clientAppIdVal}' });

async function loginEmailPassword(email, password) {
  // Create an email/password credential
  const credentials = Realm.Credentials.emailPassword(email, password);
  // Authenticate the user
  const user = await app.logIn(credentials);
  // 'App.currentUser' updates to match the logged in user
  console.assert(user.id === app.currentUser.id);
  return user;
}

const user = await loginEmailPassword('${usernameVal}', '${passwordVal}');
return user.accessToken;`;
    }

    case Language.Bash:
    default: {
      return `curl -X POST '${domain}/api/client/v2.0/app/${clientAppIdVal}/auth/providers/local-userpass/login' \
--header 'Content-Type: application/json' \
--data-raw '{
  "username": "${usernameVal}",
  "password": "${passwordVal}"
}'`;
    }
  }
};

export const generateSnippet = (
  language: Language,
  showEJSONHeader: boolean,
  version: string,
  showBearerAuthHeader: boolean,
  clientAppId?: string,
  cluster?: string,
  database?: string,
  collection?: string,
  apiKey?: string,
  deploymentModel?: DeploymentModel,
  providerRegionURL?: string
) => {
  const endpointURL = getURLEndpoint({ version, clientAppId, deploymentModel, providerRegionURL });

  const collVal = collection || '<COLLECTION_NAME>';
  const dbVal = database || '<DATABASE_NAME>';
  const clusterVal = cluster || '<CLUSTER_NAME>';
  const apiKeyVal = apiKey || '<API_KEY>';

  const bearerAccessTokenPlaceholder = 'Bearer <ACCESS_TOKEN>';

  switch (language) {
    case Language.Python: {
      return `import requests
import json
url = "${endpointURL}/action/findOne"

payload = json.dumps({
    "collection": "${collVal}",
    "database": "${dbVal}",
    "dataSource": "${clusterVal}",
    "projection": {
        "_id": 1
    }
})
headers = {
  'Content-Type': 'application/json',
  'Access-Control-Request-Headers': '*',
  ${showBearerAuthHeader ? `'Authorization': '${bearerAccessTokenPlaceholder}'` : `'api-key': '${apiKeyVal}'`},${
    showEJSONHeader ? `\n  'Accept': 'application/ejson'` : ''
  }
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)
`;
    }

    case Language.Java: {
      return `import com.squareup.okhttp.*;

class Main {
    public static void main(String[] args) {
        OkHttpClient client = new OkHttpClient().newBuilder().build();
        MediaType mediaType = MediaType.parse("application/json");
        RequestBody body = RequestBody.create(mediaType, "{\\n    \\"collection\\":\\"${collVal}\\",\\n    \\"database\\":\\"${dbVal}\\",\\n    \\"dataSource\\":\\"${clusterVal}\\",\\n    \\"projection\\": {\\"_id\\": 1}\\n\\n}");
        Request request = new Request.Builder()
          .url("${endpointURL}/action/findOne")
          .method("POST", body)
          .addHeader("Content-Type", "application/json")
          .addHeader("Access-Control-Request-Headers", "*")
          .addHeader(${
            showBearerAuthHeader ? `"Authorization", "${bearerAccessTokenPlaceholder}"` : `"api-key", "${apiKeyVal}"`
          })${showEJSONHeader ? `\n          .addHeader("Accept", "application/ejson")` : ''}
          .build();
        Response response = client.newCall(request).execute();
    }
}
`;
    }

    case Language.Go: {
      return `package main

import (
    "fmt"
    "strings"
    "net/http"
    "io/ioutil"
)

func main() {
    url := "${endpointURL}/action/findOne"
    method := "POST"

    payload := strings.NewReader(\`{
        "collection":"${collVal}",
        "database":"${dbVal}",
        "dataSource":"${clusterVal}",
        "projection": {"_id": 1}
    }\`)

    client := &http.Client{}

    req, err := http.NewRequest(method, url, payload)
    if err != nil {
        fmt.Println(err)
        return
    }

    req.Header.Add("Content-Type", "application/json")
    req.Header.Add("Access-Control-Request-Headers", "*")
    req.Header.Add(${
      showBearerAuthHeader ? `"Authorization", "${bearerAccessTokenPlaceholder}"` : `"api-key", "${apiKeyVal}"`
    })${showEJSONHeader ? `\n    req.Header.Add("Accept", "application/ejson")` : ''}

    res, err := client.Do(req)
    if err != nil {
        fmt.Println(err)
        return
    }
    defer res.Body.Close()

    body, err := ioutil.ReadAll(res.Body)
    if err != nil {
        fmt.Println(err)
        return
    }
    fmt.Println(string(body))
}
`;
    }

    case Language.JavaScript: {
      return `var axios = require('axios');
var data = JSON.stringify({
    "collection": "${collVal}",
    "database": "${dbVal}",
    "dataSource": "${clusterVal}",
    "projection": {
        "_id": 1
    }
});

var config = {
    method: 'post',
    url: '${endpointURL}/action/findOne',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Request-Headers': '*',
      ${showBearerAuthHeader ? `'Authorization': '${bearerAccessTokenPlaceholder}'` : `'api-key': '${apiKeyVal}'`},${
        showEJSONHeader ? `\n      'Accept': 'application/ejson'` : ''
      }
    },
    data: data
};

axios(config)
    .then(function (response) {
        console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
        console.log(error);
    });
`;
    }

    case Language.Csharp: {
      return `using System;
using RestSharp;

var client = new RestClient("${endpointURL}/action/findOne");
var request = new RestRequest();
request.AddHeader("Content-Type", "application/json");
request.AddHeader("Access-Control-Request-Headers", "*");
request.AddHeader(${
        showBearerAuthHeader ? `"Authorization", "${bearerAccessTokenPlaceholder}"` : `"api-key", "${apiKeyVal}"`
      });${showEJSONHeader ? `\nrequest.AddHeader("Accept", "application/ejson");` : ''}
var body = @"{" + "\n" +
@" ""collection"":""${collVal}""," + "\n" +
@" ""database"":""${dbVal}""," + "\n" +
@" ""dataSource"":""${clusterVal}""," + "\n" +
@" ""projection"":{""_id"": 1}" + "\n" +
@"" + "\n" +
@"}";
request.AddStringBody(body, DataFormat.Json);
RestResponse response = await client.PostAsync(request);
Console.WriteLine(response.Content);
`;
    }

    case Language.Bash:
    default: {
      return `curl --location --request POST '${endpointURL}/action/findOne' \\
--header 'Content-Type: application/json' \\
--header 'Access-Control-Request-Headers: *' \\
--header ${showBearerAuthHeader ? `'Authorization: ${bearerAccessTokenPlaceholder}'` : `'api-key: ${apiKeyVal}'`} \\${
        showEJSONHeader ? `\n--header 'Accept: application/ejson' \\` : ''
      }
--data-raw '{
    "collection":"${collVal}",
    "database":"${dbVal}",
    "dataSource":"${clusterVal}",
    "projection": {"_id": 1}
}'
`;
    }
  }
};

export const stringToAccessName = (s: string): AccessName => {
  switch (s) {
    case AccessName.ReadOnly:
      return AccessName.ReadOnly;
    case AccessName.ReadAndWrite:
      return AccessName.ReadAndWrite;
    case AccessName.DenyAllAccess:
      return AccessName.DenyAllAccess;
    default:
      return AccessName.CustomAccess;
  }
};

export const getClusterAccessValue = (
  clusterDescription: ClusterDescription,
  rulesByDataSourceName: Map<String, Array<MongoDBNamespaceRule>>,
  defaultRuleByDataSourceName: Map<String, MongoDBBaseRule>,
  presetRoleConfigs: Array<PresetRole>
): AccessName => {
  const dataSourceHasDefaultRule = !!defaultRuleByDataSourceName.get(clusterDescription.name)?.roles?.length;
  const dataSourceHasRules = !!rulesByDataSourceName.get(clusterDescription.name)?.length;

  if (!dataSourceHasDefaultRule && !dataSourceHasRules) {
    return AccessName.DenyAllAccess;
  }

  if (dataSourceHasRules) {
    return AccessName.CustomAccess;
  }

  const dataSourceDefaultRule = defaultRuleByDataSourceName.get(clusterDescription.name);
  if (dataSourceDefaultRule && dataSourceDefaultRule.roles) {
    if (dataSourceDefaultRule.roles.length > 1) {
      return AccessName.CustomAccess;
    }

    const defaultRole = dataSourceDefaultRule.roles[0];
    const foundRole = presetRoleConfigs.find((presetRole) => equal(defaultRole, presetRole.roles[0]));
    if (foundRole) {
      return stringToAccessName(foundRole.name);
    }
  }

  return AccessName.CustomAccess;
};

export const generateReturnTypeSnippet = (type: EndpointReturnType) => {
  switch (type) {
    case EndpointReturnType.JSON:
      return `{
  "_id": "573a1390f29313caabcd4135", // ObjectId
  "Name": "Mango", // String
  "Year": 2022,  // Integer
}`;
    case EndpointReturnType.EJSON:
      return `{
  "_id": { "$oid": "573a1390f29313caabcd4135" }, // ObjectId
  "Name": "Mango", // String
  "Year": 2022,  // Integer
}`;
    default:
      return ``;
  }
};

export const getCloudProviderFromRegion = (region: ProviderRegion) => {
  if (region.includes('aws')) {
    return CloudProvider.AWS;
  } else if (region.includes('azure')) {
    return CloudProvider.AZURE;
  } else if (region.includes('gcp')) {
    return CloudProvider.GCP;
  }
  return CloudProvider.AWS;
};

export const dataSourceHasAccessRules = (
  dataSourceDefaultRule?: MongoDBBaseRule,
  dataSourceNamespaceRules?: Array<MongoDBNamespaceRule>
) => {
  if (dataSourceNamespaceRules?.length) {
    return true;
  }

  if (dataSourceDefaultRule) {
    if (equal(dataSourceDefaultRule?.roles, noAccessDefaultRule.roles)) {
      return false;
    }
    return true;
  }

  return false;
};
