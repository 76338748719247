import { BaseSearchIndex } from '@packages/types/clusters/atlasSearch/baseSearchIndex';
import {
  FetchFTSIndexFeatureVersionResponse,
  FTSCreateIndexRequest,
  FTSUpdateIndexRequest,
} from '@packages/types/clusters/atlasSearch/searchIndex/searchIndex';
import {
  VectorIndexCreateRequest,
  VectorIndexUpdateRequest,
} from '@packages/types/clusters/atlasSearch/vectorSearchIndex/vectorSearchIndexAPI';

import fetchWrapper from '../fetchWrapper';

export default {
  createFTSIndex(groupId: string, clusterName: string, data: FTSCreateIndexRequest, createCollection: boolean = false) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes?createCollection=${createCollection}`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  createVectorIndex(groupId: string, clusterName: string, data: VectorIndexCreateRequest) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  updateFTSIndex(groupId: string, clusterName: string, data: FTSUpdateIndexRequest) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes/${data.indexDefinition.indexID}`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  updateVectorIndex(groupId: string, clusterName: string, data: VectorIndexUpdateRequest) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes/${data.indexDefinition.indexID}`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  fetchFTSIndexes(groupId: string, clusterName: string): Promise<Array<BaseSearchIndex>> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  fetchFTSIndexFeatureVersion(
    groupId: string,
    clusterName: string,
    indexId: string
  ): Promise<FetchFTSIndexFeatureVersionResponse> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexFeatureVersion/${indexId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteFTSIndex(groupId: string, clusterName: string, indexId: string) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes/${indexId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  fetchFTSAnalyzers(groupId: string, clusterName: string) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/analyzers`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  putFTSAnalyzers(groupId: string, clusterName: string, analyzers: string) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/analyzers`, {
      method: 'PUT',
      body: JSON.stringify(analyzers),
    }).then((resp) => resp.json());
  },

  fetchFTSIndexDraft(groupId: string, clusterName: string) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexDraft`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateFTSIndexDraft(groupId: string, clusterName: string, data) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexDraft`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  deleteFTSIndexDraft(groupId: string, clusterName: string) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexDraft`, {
      method: 'DELETE',
    });
  },
};
