import { PersonalizationWizardForm, PersonalizationWizardResponseDb } from '@packages/types/setup/personalization';

import fetchWrapper from '../fetchWrapper';

export function upsertPersonalizationWizardForm(
  groupId: string,
  form: PersonalizationWizardForm
): Promise<PersonalizationWizardResponseDb> {
  return fetchWrapper(`/${groupId}/setup/personalization`, {
    method: 'PUT',
    body: JSON.stringify({ ...form }),
  }).then((resp) => resp.json());
}
