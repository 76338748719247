import { get, set } from 'unchanged';
import _ from 'underscore';

import { merge } from '@packages/common/utils/objectUtils';

// Action Types that can be listened to in reducers.
const actionTypes = {
  SYNC_FROM_BACKBONE: 'SYNC_FROM_BACKBONE',
  SYNC_CHANGES_FROM_BACKBONE: 'SYNC_CHANGES_FROM_BACKBONE',
};

// Action creators to create actions for dispatching on redux.
const actionCreators = {
  /**
   * Takes a backbone model and syncs the redux state with it.
   * @param  {Backbone.Model} settingsModel
   * @return {Object} Action object, with a Backbone model as its payload.
   *   It also includes the entire redux state on the meta object.
   */
  syncFromBackbone: (backboneModel, state) => ({
    type: actionTypes.SYNC_FROM_BACKBONE,
    payload: backboneModel,
    meta: {
      state,
    },
  }),

  /**
   * Takes an object of just the fields that have changed on the backbone model,
   * and syncs the redux state accordingly.
   * @param  {Object} changes
   * @return {Object} Action object, with an object of changes as its payload.
   *   It also includes the entire redux state on the meta object.
   */
  syncChangesFromBackbone: (changes, state) => ({
    type: actionTypes.SYNC_CHANGES_FROM_BACKBONE,
    payload: changes,
    meta: {
      state,
    },
  }),
};
export { actionTypes, actionCreators };

/**
 * Creates event listeners on the given Backbone.Model and Redux store so that
 * changes that occur in either object are synced across both.
 * @param {Backbone.Model} options.backboneModel Backbone Model.
 * @param {Object} options.reduxStore Redux store.
 * @param {Object.<string, function>} options.reduxToBackboneMapping Object which has keys
 *   that are backboneAttributes, values that are redux selectors,
 *   and used to sync changes from redux to Backbone.
 *   @example
 *      mapping = {
 *          ORG_ID: (state) => state.organization.id,
 *      };
 * @return {function} Unsubscribe function.
 */
export const connectModelWithReduxStore = ({ backboneModel, reduxStore, reduxToBackboneMapping }) => {
  // Synchronize initial state.
  reduxStore.dispatch(actionCreators.syncFromBackbone(backboneModel, reduxStore.getState()));

  // Track the redux values we've seen so we can ignore unrelated state changes.
  // Keys are backbone attributes, and values are the last seen redux values.
  // Used when syncing changes from redux to Backbone.
  const lastStateVals = {};

  const anyChanges = (state) =>
    !_.every(
      reduxToBackboneMapping,
      (selector, backboneAttribute) => selector(state) === lastStateVals[backboneAttribute]
    );

  const storeStateVals = (state) =>
    _.forEach(reduxToBackboneMapping, (selector, backboneAttribute) => {
      lastStateVals[backboneAttribute] = selector(state);
    });

  // Store the initial values from redux state, to avoid unnecessary updates on the first dispatched action.
  storeStateVals(reduxStore.getState());

  const reduxUnsubscribe = reduxStore.subscribe(() => {
    const state = reduxStore.getState();
    if (!anyChanges(state)) {
      return;
    }

    // Store a reference to state so we don't do unnecessary updates.
    storeStateVals(state);

    // Use the stored references to update the backbone model.
    backboneModel.set(
      _.reduce(
        lastStateVals,
        (updates, reduxValue, backboneAttribute) => {
          const prevBackboneValue = backboneModel.get(backboneAttribute);

          if (prevBackboneValue !== reduxValue) {
            // Only add to the updates object if the value actually changed.
            updates[backboneAttribute] = reduxValue;
          }

          return updates;
        },
        {}
      )
    );
  });

  /**
   * Handle change events on the Backbone Model.
   * Will dispatch the SYNC_CHANGES_FROM_BACKBONE action type for all reducers
   * to listen to and respond accordingly.
   * @param {Event} event An event object provided by backbone which has a `changed` property
   */
  const onModelChange = ({ changed }) => {
    reduxStore.dispatch(actionCreators.syncChangesFromBackbone(changed, reduxStore.getState()));
  };

  backboneModel.on('change', onModelChange);

  return () => {
    reduxUnsubscribe();
    backboneModel.off('change', onModelChange);
  };
};

/**
 * Utility function to be used in reducers that are simply mapping backbone attributes into
 * redux state. Handles the SYNC_FROM_BACKBONE action.
 * @param {Object} state
 * @param {Object} action
 * @param {Object} backboneToReduxKeyMapping
 *   Object with keys as backbone attributes, and values as keys where the value should be stored in redux state.
 * @return {Object} Next state
 */
export const handleSyncFromBackbone = (state, action, backboneToReduxKeyMapping) => {
  const backboneModel = action.payload;
  const updates = _.reduce(
    backboneToReduxKeyMapping,
    (obj, reduxAttribute, backboneAttribute) => {
      const backboneVal = get(backboneAttribute, backboneModel.attributes);
      // For future curious historians: This is to avoid incorrect updates when two different backbone keys map to the
      // same redux key (for example, if the backbone to redux mapping contains both the keys from the admin app
      // and the bundles app)
      if (backboneVal === undefined) {
        return obj;
      }

      const reduxVal = get(reduxAttribute, state);
      if (backboneVal !== reduxVal) {
        // Only include an update if something changed.
        return set(reduxAttribute, backboneVal, obj);
      }

      return obj;
    },
    {}
  );

  if (_.isEmpty(updates)) {
    // Nothing changed, so don't bother making a copy of the state object.
    return state;
  }

  return {
    ...state,
    ...updates,
  };
};

/**
 * Utility function to be used in reducers that are simply mapping backbone attributes into
 * redux state. Handles the SYNC_CHANGES_FROM_BACKBONE action.
 * @param {Object} state
 * @param {Object} action
 * @param {Object} backboneToReduxKeyMapping
 *   Object with keys as backbone attributes, and values as keys where the value should be stored in redux state.
 * @return {Object} Next state
 */
export const handleSyncChangesFromBackbone = (state, action, backboneToReduxKeyMapping) => {
  const changes = action.payload;
  const updates = _.reduce(
    backboneToReduxKeyMapping,
    (obj, reduxAttribute, backboneAttribute) => {
      const backboneVal = get(backboneAttribute, changes);
      if (backboneVal == null) {
        // Ignore any keys we don't know about.
        return obj;
      }

      const reduxVal = get(reduxAttribute, state);
      if (backboneVal !== reduxVal) {
        return set(reduxAttribute, backboneVal, obj);
      }

      return obj;
    },
    {}
  );

  if (_.isEmpty(updates)) {
    // Nothing changed, so don't bother making a copy of the state object.
    return state;
  }

  return merge(state, updates);
};
