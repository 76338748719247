import { combineReducers } from 'redux';

import { Action, ActionType, State } from './actions';

const defaultState: State = {
  metricsMeta: {},
  metrics: [],
  availableMetrics: {},
  metricsDisplayRow: true,
  selectedCharts: [],
  isZoomCustom: false,
};

function metricsReducer(state: State = defaultState, action: Action): State {
  const { type, payload } = action;
  switch (type) {
    case ActionType.SET_METRICS_DATA: {
      return {
        ...state,
        metrics: payload.metrics,
        metricsMeta: payload.meta,
      };
    }
    case ActionType.SET_AVAILABLE_CHARTS: {
      return {
        ...state,
        availableMetrics: payload,
      };
    }
    case ActionType.SET_METRICS_DISPLAY_ROW: {
      return {
        ...state,
        metricsDisplayRow: payload,
      };
    }
    case ActionType.SET_SELECTED_CHARTS: {
      return {
        ...state,
        selectedCharts: payload,
      };
    }
    case ActionType.SET_IS_ZOOM_CUSTOM: {
      return {
        ...state,
        isZoomCustom: payload,
      };
    }
    case ActionType.SET_METRICS_DATA_META_WINDOW: {
      return {
        ...state,
        metricsMeta: {
          ...state.metricsMeta,
          window: payload,
        },
      };
    }
    default:
      return state;
  }
}

const reducer = combineReducers({
  metrics: metricsReducer,
});

export default reducer;
