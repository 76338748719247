import * as React from 'react';
import { useContext } from 'react';

import { type RequestParams } from '@packages/types/RequestParams';

declare global {
  interface Window {
    REQUEST_PARAMS: RequestParams;
  }
}

const RequestParamsContext = React.createContext(window.REQUEST_PARAMS ?? {});

interface RequestParamsProviderProps {
  children: React.ReactNode;
  value?: RequestParams;
}

export const RequestParamsProvider = ({ children, value }: RequestParamsProviderProps) => {
  return (
    <RequestParamsContext.Provider value={value ?? window.REQUEST_PARAMS ?? {}}>
      {children}
    </RequestParamsContext.Provider>
  );
};

export const useRequestParams = () => {
  return useContext(RequestParamsContext);
};
