import {
  AzureDiskType,
  BackingCloudProvider,
  BackingCloudProviderName,
  CloudProvider,
  CpuArchitecture,
  InstanceSize,
  InstanceSizes,
  PreferredStorageType,
  Provider,
  Providers,
} from '@packages/types/nds/provider';
import {
  AWSEnv,
  ComplianceLevel,
  ContinentName,
  RegionName,
  RegionNames,
  RegionNamesNone,
} from '@packages/types/nds/region';

export enum VolumeType {
  io1 = 'io1',
  io2 = 'io2',
  gp2 = 'gp2',
  gp3 = 'gp3',
}

export enum ConfigServerType {
  EMBEDDED = 'EMBEDDED',
  DEDICATED = 'DEDICATED',
}

export { AzureDiskType as DiskType };

export interface HardwareSpec {
  nodeCount: number;
  instanceSize: InstanceSize;

  // for AWS
  diskIOPS?: number;
  volumeType?: VolumeType;
  encryptEBSVolume?: boolean;

  // for AZURE
  diskType?: AzureDiskType;
  diskThroughput?: number;
  preferredStorageType?: PreferredStorageType;

  // for FREE, FLEX, and SERVERLESS
  backingProvider?: BackingCloudProvider;

  // for SERVERLESS
  diskSizeGBLimit?: number;

  preferredCpuArchitecture?: CpuArchitecture;
}

export interface DiskAutoScaling {
  enabled: boolean;
}

export interface ComputeAutoScaling {
  enabled: boolean;
  scaleDownEnabled: boolean;
  minInstanceSize: string | null;
  maxInstanceSize: string | null;
}

export interface AutoScaling {
  autoIndex: AutoIndex;
  diskGB: DiskAutoScaling;
  compute: ComputeAutoScaling;
}

export interface AutoIndex {
  enabled: boolean;
}

// todo: sync with region.ts same UI view
export interface RegionView {
  continent: ContinentName | '';
  isRecommended?: boolean;
  key: RegionName;
  latitude?: number;
  location: string;
  longitude?: number;
  name: string;
  provider: BackingCloudProviderName;
  isoCode?: string;
  inactivePaidTierRegion?: boolean;
  awsEnv?: AWSEnv;
  complianceLevel: ComplianceLevel;
  gcpRegionCode?: string;
}

export interface RegionConfig {
  regionName: RegionName;
  cloudProvider: CloudProvider;
  autoScaling: AutoScaling;
  analyticsAutoScaling: AutoScaling | null;
  priority: number;
  electableSpecs: HardwareSpec;
  readOnlySpecs: HardwareSpec;
  analyticsSpecs: HardwareSpec;
  regionView: RegionView;
}

export interface ReplicationSpec {
  id: string;
  zoneName: string;
  zoneId?: string;
  numShards: number;
  regionConfigs: Array<RegionConfig>;
  externalId?: string;
}

export type ReplicationSpecList = Array<ReplicationSpec>;

// Legacy

export interface RegionsConfig {
  electableNodes: number;
  priority: number;
  readOnlyNodes: number;
  analyticsNodes: number;
  regionView: RegionView;
}

export interface LegacyReplicationSpec {
  id: string;
  numShards: number;
  zoneName: string;
  regionsConfig: RegionsConfig;
}

export interface RegionPair {
  name: string;
  provider: BackingCloudProvider;
}

// Leaving this as an alias for backwards compatibility
export type ProviderOptions = Providers;

// Leaving this as an alias for backwards compatibility
export type CloudProviderOptions = Provider;

export const DEFAULT_REGION_VIEW: RegionView = {
  continent: '',
  key: RegionNamesNone.UNSELECTED,
  location: '',
  name: '',
  provider: BackingCloudProvider.AWS,
  complianceLevel: ComplianceLevel.COMMERCIAL,
};

export const DEFAULT_REGIONS_CONFIG: RegionsConfig = {
  electableNodes: 0,
  priority: 0,
  readOnlyNodes: 0,
  analyticsNodes: 0,
  regionView: DEFAULT_REGION_VIEW,
};

export const DEFAULT_AUTOSCALING: AutoScaling = {
  diskGB: {
    enabled: true,
  },
  compute: {
    enabled: false,
    scaleDownEnabled: false,
    minInstanceSize: null,
    maxInstanceSize: null,
  },
  autoIndex: {
    enabled: false,
  },
};

export const DEFAULT_NULL_AUTOSCALING = null;

export const DEFAULT_DISABLED_AUTOSCALING: AutoScaling = {
  diskGB: {
    enabled: false,
  },
  compute: {
    enabled: false,
    scaleDownEnabled: false,
    minInstanceSize: null,
    maxInstanceSize: null,
  },
  autoIndex: {
    enabled: false,
  },
};

export const DEFAULT_REGION_CONFIG: RegionConfig = {
  regionName: RegionNames.US_EAST_1,
  cloudProvider: CloudProvider.AWS,
  autoScaling: DEFAULT_AUTOSCALING,
  analyticsAutoScaling: DEFAULT_NULL_AUTOSCALING,
  priority: 0,
  electableSpecs: { nodeCount: 0, instanceSize: InstanceSizes.M10 },
  readOnlySpecs: { nodeCount: 0, instanceSize: InstanceSizes.M10 },
  analyticsSpecs: { nodeCount: 0, instanceSize: InstanceSizes.M10 },
  regionView: DEFAULT_REGION_VIEW,
};

export const INSTANCE_TIERS_WITHOUT_PROVISIONED_IOPS_SUPPORT = new Set<String>(['M10', 'M20']);
