import fetchWrapper from '@packages/common/services/api/fetchWrapper';

export function getLiveImports(groupId) {
  return fetchWrapper(`/admin/nds/liveImports/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getRecentLiveImports() {
  return fetchWrapper(`/admin/nds/liveImports/recent`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getLiveImportsByGroupId(groupId) {
  return fetchWrapper(`/${groupId}/liveImport`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInProgressLiveImportsByGroupId(groupId) {
  return fetchWrapper(`/${groupId}/liveImport/history?state=NEW&state=WORKING`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getPastLiveImportsByGroupId(groupId) {
  return fetchWrapper(`/${groupId}/liveImport/history?state=COMPLETE&state=EXPIRED&state=FAILED`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getLiveImportById(groupId, liveImportId) {
  return fetchWrapper(`/${groupId}/liveImport/${liveImportId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function startLiveImport(groupId, liveImportData) {
  return fetchWrapper(`/${groupId}/liveImport`, {
    method: 'POST',
    body: JSON.stringify(liveImportData),
  }).then((resp) => resp.json());
}

export function validateLiveImport(groupId, liveImportData) {
  return fetchWrapper(`/${groupId}/liveImport/validate`, {
    method: 'POST',
    body: JSON.stringify(liveImportData),
  }).then((resp) => resp.json());
}

export function getLiveImportValidationJobStatus(groupId, jobId) {
  return fetchWrapper(`/${groupId}/liveImport/validationStatus/${jobId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateLiveImport(groupId, liveImportData) {
  return fetchWrapper(`/${groupId}/liveImport/${liveImportData._id}`, {
    method: 'PATCH',
    body: JSON.stringify(liveImportData),
  }).then((resp) => resp);
}

export function stopLiveImport(groupId, liveImportId) {
  return fetchWrapper(`/${groupId}/liveImport/${liveImportId}`, {
    method: 'DELETE',
  });
}

export function restartLiveImportForShard(groupId, shardIndex, liveImportData) {
  return fetchWrapper(`/${groupId}/liveImport/${liveImportData._id}/${shardIndex}`, {
    method: 'PATCH',
    body: JSON.stringify(liveImportData),
  }).then((resp) => resp.json());
}

export function getLiveImportWhitelistIPsForCluster(groupId, clusterName) {
  return fetchWrapper(`/${groupId}/liveImport/${clusterName}/whitelistIps`, {
    method: 'GET',
  }).then((resp) => resp.json());
}
