import {
  AwsCrossProjectRestore,
  AWSExportBucket,
  AzureExtendedStorage,
  BackupDeliveryMethod,
  BackupJob,
  BackupRestoreJob,
  DataProtectionCluster,
  DataProtectionSettings,
  SnapshotUsage,
} from '@packages/types/nds/backup';
import { BackupSnapshot } from '@packages/types/nds/backupSnapshot';
import { UserFacingCloudProvider } from '@packages/types/nds/provider';

import { get } from '@packages/common/utils/groupIdFromPath';

import fetchWrapper, { formParams } from '../fetchWrapper';

export default {
  getDataProtectionClusterList(groupId: string): Promise<Array<DataProtectionCluster>> {
    return fetchWrapper(`/nds/backup/${groupId}/dataProtectionClusterList`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDiskBackups(groupId: string, clusterUniqueId: string): Promise<Array<BackupSnapshot>> {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/snapshots`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDiskBackupRestoreHistory(groupId: string, clusterUniqueId: string): Promise<Array<BackupRestoreJob>> {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/restoreJobs`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getSnapshotUsages(
    groupId: string,
    clusterName: string,
    cloudProvider: UserFacingCloudProvider
  ): Promise<Array<SnapshotUsage>> {
    const cloudProviderString: string = (cloudProvider as string).toUpperCase();
    return fetchWrapper(`/nds/backup/${groupId}/${clusterName}/snapshotUsages/${cloudProviderString}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getExportHistory(groupId: string, clusterUniqueId: string): Promise<Array<BackupRestoreJob>> {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/exportRestoreJobs`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDiskBackupMetadata(clusterUniqueId: string, groupId = get()): Promise<BackupJob> {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/job`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getExtendedStorage(clusterUniqueId: string): Promise<AzureExtendedStorage> {
    return fetchWrapper(`/nds/backup/${clusterUniqueId}/ssdV2ExtendedStorage`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteSnapshot(groupId: string, snapshotId: string): Promise<{}> {
    return fetchWrapper(`/nds/backup/${groupId}/snapshot/${snapshotId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  performAutomatedRestore(
    groupId: string,
    snapshotId: string,
    clusterName: string,
    destinationGroupId: string,
    destinationClusterName: string,
    isPit: boolean,
    pitUTCSeconds: number | undefined | null,
    pitOplogTs: string | null,
    pitOplogInc: string | null
  ): Promise<{
    restoreJobIds: Array<string>;
  }> {
    const data = {
      snapshotId,
      deliveryMethod: isPit ? BackupDeliveryMethod.POINT_IN_TIME : BackupDeliveryMethod.AUTOMATION_PULL,
      sourceClusterName: clusterName,
      targetGroupId: destinationGroupId,
      targetClusterName: destinationClusterName,
      pointInTimeUTCSeconds: pitUTCSeconds,
      oplogTs: pitOplogTs,
      oplogInc: pitOplogInc,
    };

    return fetchWrapper(`/nds/backup/${groupId}/restoreJob`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  getRestoreDestinationItems(
    sourceGroupId: string,
    sourceClusterName: string,
    targetGroupId: string,
    isSharded: boolean,
    snapshotId: string,
    isPit: boolean,
    pitUTCSeconds: number | null | undefined,
    pitOplogTs: string | null,
    pitOplogInc: string | null
  ) {
    let url;
    if (isPit) {
      url =
        `/automation/restore/${targetGroupId}/destinationClustersCpsPit?sourceGroupId=${sourceGroupId}` +
        `&sourceClusterName=${sourceClusterName}&pitUTCSeconds=${pitUTCSeconds || ''}` +
        `&pitOplogTs=${pitOplogTs}&pitOplogInc=${pitOplogInc}`;
    } else if (isSharded) {
      url = `/automation/restore/${targetGroupId}/destinationClustersCps?snapshotId=${snapshotId}`;
    } else {
      url = `/automation/restore/${targetGroupId}/destinationReplicaSetsCps?snapshotId=${snapshotId}`;
    }

    return fetchWrapper(url, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createDownload(groupId, snapshotId) {
    const params = {
      snapshotId,
      deliveryMethod: BackupDeliveryMethod.MANUAL_DOWNLOAD,
    };
    return fetchWrapper(`/nds/backup/${groupId}/restoreJob`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  cancelDownload(groupId: string, restoreJobId: string) {
    return fetchWrapper(`/nds/backup/${groupId}/restoreJob/${restoreJobId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  markRestoreJobsAsSeen(groupId: string, restoreJobIds: Array<string>) {
    const params = { restoreJobIds };
    return fetchWrapper(`/nds/backup/${groupId}/restoreJob/seen`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  updateBackupPolicy(
    groupId,
    clusterUniqueId,
    referenceTimeInMins,
    restoreWindowDays,
    policies,
    policyId,
    updateExistingSnapshots,
    extraRetentionSettings,
    copySettings,
    deleteCopiedBackups
  ) {
    const data = {
      referenceTimeInMins,
      restoreWindowDays,
      policies: [
        {
          id: policyId,
          policyItems: policies,
        },
      ],
      updateSnapshots: updateExistingSnapshots,
      extraRetentionSettings: extraRetentionSettings,
      copySettings: copySettings,
      deleteCopiedBackups: deleteCopiedBackups,
    };

    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/backupPolicy`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  editSnapshotRetention(groupId: string, snapshotId: string, retentionValue, retentionUnit) {
    const data = {
      retentionValue,
      retentionUnit,
    };

    return fetchWrapper(`/nds/backup/${groupId}/snapshot/${snapshotId}/editRetention`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  getPitRestoreRange(groupId: string, clusterUniqueId: string) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/pitRestoreRange`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getSnapshots(groupId: string, clusterUniqueId: string) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/snapshots`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getSnapshotsAffectedByPolicy(groupId: string, clusterUniqueId: string, policies, policyId: string) {
    const data = {
      policies: [
        {
          id: policyId,
          policyItems: policies,
        },
      ],
    };
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/snapshotsAffectedByPolicy`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  takeSnapshot(groupId: string, clusterName: string, retentionInDays, snapshotDescription: string) {
    const data = {
      retentionInDays,
      snapshotDescription,
      clusterName,
    };

    return fetchWrapper(`/nds/backup/${groupId}/snapshots`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  createExportBucket(groupId: string, iamRoleId: string, bucketName: string): Promise<AWSExportBucket> {
    const data = {
      iamRoleId,
      bucketName,
    };
    return fetchWrapper(`/nds/backup/groups/${groupId}/backup/exportBuckets`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  getExportBuckets(groupId: string): Promise<Array<AWSExportBucket>> {
    return fetchWrapper(`/nds/backup/groups/${groupId}/backup/exportBuckets`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  removeExportBucket(groupId: string, exportBucketId: string): Promise<{}> {
    return fetchWrapper(`/nds/backup/groups/${groupId}/backup/exportBuckets/${exportBucketId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  createExportJob(groupId: string, clusterName: string, snapshotId: string, exportBucketId: string) {
    const data = {
      snapshotId,
      exportBucketId,
    };
    return fetchWrapper(`/nds/backup/groups/${groupId}/clusters/${clusterName}/backup/exports/`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  getDataProtectionSettings(groupId: string): Promise<DataProtectionSettings> {
    return fetchWrapper(`/nds/backup/${groupId}/dataProtection`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  disableDataProtectionSettings(groupId: string) {
    return fetchWrapper(`/nds/backup/${groupId}/dataProtection`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  saveDataProtectionSettings(settings: DataProtectionSettings) {
    return fetchWrapper(`/nds/backup/${settings.projectId}/dataProtection`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(settings),
    });
  },

  validateDataProtectionSettings(settings: DataProtectionSettings) {
    return fetchWrapper(`/nds/backup/${settings.projectId}/dataProtection/validate`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(settings),
    });
  },

  getCanOptInForAwsCrossProjectRestore(
    groupId: string,
    clusterUniqueId: string
  ): Promise<AwsCrossProjectRestore | void> {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/canOptInForAwsCrossProjectRestore`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  setRollingNodeReplacement(groupId: string, clusterUniqueId: string) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/rollingNodeReplacement`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },
};
