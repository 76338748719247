import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import { imageSrc } from '@packages/asset-utils';

export interface ImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  'data-testid'?: string;
  cdnEnabled?: boolean;
}

const Image = ({ cdnEnabled, src, ...props }: ImageProps) => <img {...props} src={imageSrc(src, cdnEnabled)} />;

Image.displayName = 'Image'; // for enzyme

export default Image;
