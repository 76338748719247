import { StrictMode } from 'react';

import LeafyGreenProvider from '@leafygreen-ui/leafygreen-provider';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'hist... Remove this comment to see the full error message
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
// redux
import { Provider } from 'react-redux';

import { Application, CloudTeams } from '@packages/types/observability';
import { AppEnv } from '@packages/types/RequestParams';

import * as settingsSelectors from '@packages/redux/common/settings';
import * as viewerSelectors from '@packages/redux/common/viewer';
import rootReducer from '@packages/redux/auth/appRootReducer';
import { createStore } from '@packages/redux/common/reduxHelpers';

import useIntercomService from '@packages/auth/hooks/useIntercomService';
import processAuth from '@packages/auth/utils/postAuthUtils';
import { AccountUserProvider } from '@packages/common/context/AccountUserContext';
import { AppUserProvider } from '@packages/common/context/AppUserContext';
import { RequestParamsProvider, useRequestParams } from '@packages/common/context/RequestParamsContext';
import { sanitize } from '@packages/common/utils/authRedirectUtils';
import { initializeAuthClient } from '@packages/common/utils/oktaUtils';
// analytics
import { initializeAnalytics } from '@packages/common/utils/segmentAnalytics';
import { ErrorBoundary } from '@packages/components/ErrorBoundary';
import useAnalytics from '@packages/hooks/useAnalytics';
import ToastProvider from '@packages/layout/ToastProvider';
import { init as initSentry, SetUpErrorTrackerState } from '@packages/observability';
import '@packages/wdyr';

import AuthRouter from './AuthRouter';

const browserHistory = createBrowserHistory({
  basename: 'account',
});
const { pathname, hash } = browserHistory.location;

initSentry(browserHistory);

/* we should get rid of url fragments (aka hashes) in the urls
  that could possibly be present from the /user#/(atlas|cloud|ops)/login redirect
  but preserve the hash values after the plan type to correctly redirect to their new locations
*/
if (hash) {
  const sanitizedLocation = sanitize(browserHistory.location);

  browserHistory.replace({
    pathname: sanitizedLocation.pathname,
    search: sanitizedLocation.search,
    hash: sanitizedLocation.hash,
  });
}

const {
  appEnv = AppEnv.PROD,
  oktaEnabled,
  oktaAuthRedirectBase,
  oktaBaseUrl,
  analyticsEnabled,
  segmentClientSideWriteKey,
  accountMultiFactorAuthEnabled,
  accountCentralUrl,
} = window.REQUEST_PARAMS;

// the Okta auth client needs to be initialized before the app is rendered so we can't move this into `useEffect`.
// also, the reason for the oktaEnabled check is due to evergreen config for e2e testing, where most
// tests run with okta disabled (and UserSvcDb implementation)
if (oktaEnabled) {
  initializeAuthClient({
    oktaRedirectBase: oktaAuthRedirectBase,
    oktaUrl: oktaBaseUrl,
  });
}

const getShouldRequestAuid = (): boolean => {
  // we are only initializing analytics on the registration success page
  // and not on other pages of the auth app
  const pathName = window.location.pathname;
  return pathName === '/account/register/success';
};

initializeAnalytics({
  analyticsEnabled,
  segmentId: segmentClientSideWriteKey,
  appId: 'auth',
  appEnv,
  accountCentralUrl,
  shouldRequestAuid: getShouldRequestAuid(),
});

function App() {
  const requestParams = useRequestParams();
  const reduxStore = createStore(rootReducer);
  useIntercomService(browserHistory);
  useAnalytics(browserHistory);

  if (pathname === '/oauth') {
    // handles all the post auth logic from oidc/callback return
    processAuth({ requestParams, location: browserHistory.location });
    return null;
  }

  return (
    <Provider store={reduxStore}>
      <SetUpErrorTrackerState
        viewer={viewerSelectors}
        settings={settingsSelectors}
        applicationName={Application.Auth}
      />
      <RequestParamsProvider>
        <AccountUserProvider>
          <AppUserProvider>
            <AuthRouter history={browserHistory} accountMultiFactorAuthEnabled={accountMultiFactorAuthEnabled} />
          </AppUserProvider>
        </AccountUserProvider>
      </RequestParamsProvider>
    </Provider>
  );
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <StrictMode>
    <ErrorBoundary metadata={{ name: 'Auth App: React ErrorBoundary Hit', team: CloudTeams.CoreIam }}>
      <LeafyGreenProvider>
        <ToastProvider>
          <App />
        </ToastProvider>
      </LeafyGreenProvider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('mms-body-application')
);
