import { AccountProfileAppUser, AccountUser } from '@packages/types/account';
import {
  OktaMfaFactorPushVerificationRequest,
  OktaMfaFactorVerificationRequest,
} from '@packages/types/accountMultiFactorAuth';
import { AccountTemporaryAuthToken } from '@packages/types/accountTemporaryAuthToken';

import fetchWrapper, { formParams } from './fetchWrapper';

export const getAccountUser = () => {
  return fetchWrapper('/account/profile/accountUser', {
    method: 'GET',
  }).then((resp) => resp.json() as Promise<AccountUser>);
};

export const getParams = () => {
  return fetchWrapper('/account/profile/params', {
    method: 'GET',
  }).then((resp) => resp.json());
};

export const getOktaUser = () => {
  return fetchWrapper(`/account/profile/oktaUser`, {
    method: 'GET',
  }).then((resp) => resp.json());
};

export const updatePassword = ({ currentPassword, newPassword, confirmPassword }) => {
  return fetchWrapper('/account/profile/updatePassword', {
    method: 'POST',
    body: JSON.stringify({
      currentPassword,
      newPassword,
      confirmPassword,
    }),
  }).then((resp) => resp.json());
};

export const setPersonalInfo = ({ firstName, lastName, company, title, countryCode, email, mobilePhone }) => {
  return fetchWrapper('/account/profile/updateProfile', {
    method: 'POST',
    body: JSON.stringify({
      firstName,
      lastName,
      company,
      title,
      countryCode,
      email,
      mobilePhone,
    }),
  }).then((resp) => resp.json());
};

export const cancelEmailChangeRequest = () => {
  return fetchWrapper(`/account/profile/emailChangeRequest`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
};

export const softDeleteUser = (accountTemporaryAuthTokenId: string) => {
  return fetchWrapper(`/account/profile/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({ accountTemporaryAuthTokenId }),
  }).then((resp) => resp.json());
};

export const getAppUser = (): Promise<AccountProfileAppUser> => {
  return fetchWrapper(`/account/profile/appUser`, {
    method: 'GET',
  }).then((resp) => resp.json());
};

export const createEmailChangeRequest = ({ newEmailAddress, accountTemporaryAuthTokenId }) => {
  return fetchWrapper(`/account/profile/emailChangeRequest`, {
    method: 'POST',
    body: JSON.stringify({
      newEmailAddress,
      accountTemporaryAuthTokenId,
    }),
  }).then((resp) => resp.json());
};

export const getEmailChangeRequest = () => {
  return fetchWrapper(`/account/profile/emailChangeRequest`, {
    method: 'GET',
  })
    .then((resp) => resp.json())
    .catch(() => {});
};

export function checkPasswordForProfileChangeAndGetTempAuthToken(password: string): Promise<AccountTemporaryAuthToken> {
  return fetchWrapper(`/account/profile/changeRequest/checkPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({ password }),
  }).then((response) => response.json());
}

export function verifyFactorForProfileChangeAndGetTempAuthToken(
  oktaMfaFactorVerificationRequestView: OktaMfaFactorVerificationRequest
): Promise<AccountTemporaryAuthToken> {
  return fetchWrapper(`/account/profile/changeRequest/verifyFactor`, {
    method: 'POST',
    body: JSON.stringify(oktaMfaFactorVerificationRequestView),
  }).then((response) => response.json());
}

export function activateFactorForProfileChangeAndGetTempAuthToken(
  mfaFactorActivationRequest: OktaMfaFactorVerificationRequest
): Promise<AccountTemporaryAuthToken> {
  return fetchWrapper(`/account/profile/changeRequest/activateFactor`, {
    method: 'POST',
    body: JSON.stringify(mfaFactorActivationRequest),
  }).then((response) => response.json());
}

export function verifyPushFactorForProfileChangeAndGetTempAuthToken(
  oktaMfaFactorPushVerificationRequestView: OktaMfaFactorPushVerificationRequest
): Promise<AccountTemporaryAuthToken> {
  return fetchWrapper(`/account/profile/changeRequest/verifyPushFactor`, {
    method: 'POST',
    body: JSON.stringify(oktaMfaFactorPushVerificationRequestView),
  }).then((response) => response.json());
}
