import { useEffect } from 'react';

import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';

import { useRequestParams } from '@packages/common/context/RequestParamsContext';
import intercomService from '@packages/intercom/intercomService';

export default function useIntercomService(history: $TSFixMe) {
  const requestParams = useRequestParams();

  const { intercomEnabled, intercomAppId } = requestParams;

  const intercomSettings = { isIntercomEnabled: intercomEnabled, intercomAppId };

  const { signedOut } = queryString.parse(history.location.search) as Search;

  useEffect(() => {
    if (signedOut) {
      intercomService.clearWidgetChatHistory();
    }
    intercomService.createWidgetForLoggedOutUser(null, intercomSettings, history.location);

    // @ts-expect-error TS(6133): 'location' is declared but its value is never read... Remove this comment to see the full error message
    const unlisten = history.listen((location: $TSFixMe, action: $TSFixMe) => {
      // Only update intercom when we update the URL route.
      // That coincides when the history action is not REPLACE, i.e. either
      // PUSH or POP
      if (action && action !== 'REPLACE') {
        intercomService.updateWidgetForLoggedOutUser(null, intercomSettings, history.location);
      }
    });

    return () => unlisten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
