import { TenantBackupDeliveryMethod } from '@packages/types/nds/backup';
import { BackupSnapshot } from '@packages/types/nds/backupSnapshot';

import fetchWrapper, { formParams } from '../fetchWrapper';

export default {
  getSnapshots(groupId: string, clusterUniqueId: string): Promise<Array<BackupSnapshot>> {
    return fetchWrapper(`/nds/backup/tenant/${groupId}/${clusterUniqueId}/snapshots`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getRestores(groupId: string, clusterUniqueId: string): Promise<Array<$TSFixMe>> {
    return fetchWrapper(`/nds/backup/tenant/${groupId}/${clusterUniqueId}/restores`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getRestore(groupId: string, restoreId: string): Promise<$TSFixMe> {
    return fetchWrapper(`/nds/backup/tenant/${groupId}/restore/${restoreId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getMetadata(groupId: string, clusterUniqueId: string): Promise<$TSFixMe> {
    return fetchWrapper(`/nds/backup/tenant/${groupId}/${clusterUniqueId}/backup`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createDownload(groupId: string, snapshotId: string): Promise<$TSFixMe> {
    const data = {
      snapshotId,
      deliveryMethod: TenantBackupDeliveryMethod.DOWNLOAD,
    };

    return fetchWrapper(`/nds/backup/tenant/${groupId}/restore`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  performAutomatedRestore(
    groupId: string,
    snapshotId: string,
    clusterName: string,
    destinationGroupId: string,
    destinationClusterName: string
  ): Promise<$TSFixMe> {
    const data = {
      snapshotId,
      deliveryMethod: TenantBackupDeliveryMethod.RESTORE,
      sourceClusterName: clusterName,
      targetGroupId: destinationGroupId,
      targetClusterName: destinationClusterName,
    };

    return fetchWrapper(`/nds/backup/tenant/${groupId}/restore`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  // Note that this method does not use all arguments. This method signature is to keep consistent with the corresponding method in backupApi.js for generic use in AutomatedRestoreModal
  getRestoreDestinationItems(
    sourceGroupId: string,
    sourceClusterName: string,
    targetGroupId: string
  ): Promise<Array<$TSFixMe>> {
    return fetchWrapper(`/automation/restore/tenant/${targetGroupId}/destinationReplicaSets`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
