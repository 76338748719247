import queryString from 'query-string';

import { PolicyItem } from '@packages/types/nds/backup';
import { BackupSnapshot } from '@packages/types/nds/backupSnapshot';
import {
  DatasetRetentionPolicy,
  IngestionPipeline,
  IngestionPipelineRun,
  IngestionPipelineStats,
} from '@packages/types/nds/pipelines';
import { RegionView } from '@packages/types/nds/replicationSpec';

import { PIPELINE_RUN_FETCH_COUNT } from '@packages/common/constants/pipelines';
import fetchWrapper from '@packages/common/services/api/fetchWrapper';

function getPipelineURI(groupId: string, pipelineName: string, path?: string) {
  return `/nds/dls/${groupId}/pipelines/${encodeURIComponent(pipelineName)}${path || ''}`;
}

function fetchIngestionPipelines(groupId: string): Promise<Array<IngestionPipeline>> {
  return fetchWrapper(`/nds/dls/${groupId}/pipelines`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function fetchSingleIngestionPipeline(groupId: string, pipelineName: string): Promise<IngestionPipeline> {
  return fetchWrapper(`/nds/dls/${groupId}/pipelines/${pipelineName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function createIngestionPipeline(groupId: string, ingestionPipeline: IngestionPipeline): Promise<IngestionPipeline> {
  return fetchWrapper(`/nds/dls/${groupId}/pipelines`, {
    method: 'POST',
    body: JSON.stringify(ingestionPipeline),
  }).then((resp) => resp.json());
}

function createOnDemandPipelineRun(
  groupId: string,
  pipelineName: string,
  snapshotId: string,
  datasetRetentionPolicy?: DatasetRetentionPolicy
): Promise<IngestionPipelineRun> {
  return fetchWrapper(getPipelineURI(groupId, pipelineName, `/trigger`), {
    method: 'POST',
    body: JSON.stringify({ snapshotId: snapshotId, datasetRetentionPolicy: datasetRetentionPolicy }),
  }).then((resp) => resp.json());
}

function patchIngestionPipeline(groupId: string, pipeline: IngestionPipeline): Promise<IngestionPipeline> {
  return fetchWrapper(getPipelineURI(groupId, pipeline.name), {
    method: 'PATCH',
    body: JSON.stringify(pipeline),
  }).then((resp) => resp.json());
}

function deleteIngestionPipeline(groupId: string, pipelineName: string): Promise<string> {
  return fetchWrapper(getPipelineURI(groupId, pipelineName), {
    method: 'DELETE',
  }).then(() => pipelineName);
}

function fetchIngestionPipelineStats(groupId: string, pipelineName: string): Promise<IngestionPipelineStats> {
  return fetchWrapper(getPipelineURI(groupId, pipelineName, '/stats'), {
    method: 'GET',
  }).then((resp) => resp.json());
}

export interface RunQueryParams {
  withDatasets: boolean;
  limit: number;
  skip: number;
  createdBefore: string;
  searchQuery: string;
}

function fetchIngestionPipelineRuns(
  pipeline: IngestionPipeline,
  queryParams: Partial<RunQueryParams> = {}
): Promise<Array<IngestionPipelineRun>> {
  const queryParamStr = queryString.stringify({
    withDatasets: false,
    limit: PIPELINE_RUN_FETCH_COUNT,
    skip: 0,
    searchQuery: '',
    ...queryParams,
  });
  return fetchWrapper(getPipelineURI(pipeline.groupId, pipeline.name, `/pipelineRuns?${queryParamStr}`), {
    method: 'GET',
  }).then((resp) => resp.json());
}

function fetchIngestionPipelineRun(
  groupId: string,
  pipelineName: string,
  pipelineRunId: string
): Promise<IngestionPipelineRun> {
  return fetchWrapper(getPipelineURI(groupId, pipelineName, `/pipelineRuns/${pipelineRunId}`), {
    method: 'GET',
  }).then((resp) => resp.json());
}

function deleteIngestionPipelineRunDataSet(
  groupId: string,
  pipelineName: string,
  pipelineRunId: string
): Promise<IngestionPipelineRun> {
  return fetchWrapper(getPipelineURI(groupId, pipelineName, `/pipelineRuns/${pipelineRunId}`), {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

function fetchBackupPolicyItemsForGroup(groupId: string): Promise<Record<string, Array<PolicyItem>>> {
  return fetchWrapper(`/nds/dls/${groupId}/backupPolicyItems`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function fetchBackupPolicyItemsForCluster(groupId: string, clusterName: string): Promise<Array<PolicyItem>> {
  return fetchWrapper(`/nds/dls/${groupId}/backupPolicyItems/${clusterName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function fetchDefaultRegionForCluster(groupId: string, clusterName: string): Promise<RegionView> {
  return fetchWrapper(`/nds/dls/${groupId}/defaultDataLakeRegionForCluster/${clusterName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function fetchAllSnapshotsForOnDemandIngestion(groupId: string, clusterName: string): Promise<Array<BackupSnapshot>> {
  return fetchWrapper(`/nds/dls/${groupId}/snapshots/${clusterName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function fetchAvailableSnapshotsForOnDemandIngestion(
  groupId: string,
  pipelineName: string
): Promise<Array<BackupSnapshot>> {
  return fetchWrapper(`/nds/dls/${groupId}/pipelines/${pipelineName}/availableSnapshots`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function fetchSnapshotsBySnapshotIds(groupId: string, snapshotIds: Array<string>): Promise<Array<BackupSnapshot>> {
  const queryParamStr = queryString.stringify({
    snapshotIds: snapshotIds,
  });
  return fetchWrapper(`/nds/dls/${groupId}/snapshots?${queryParamStr}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export {
  fetchIngestionPipelines,
  fetchSingleIngestionPipeline,
  createIngestionPipeline,
  createOnDemandPipelineRun,
  patchIngestionPipeline,
  deleteIngestionPipeline,
  fetchIngestionPipelineRun,
  fetchIngestionPipelineRuns,
  fetchIngestionPipelineStats,
  deleteIngestionPipelineRunDataSet,
  fetchBackupPolicyItemsForGroup,
  fetchBackupPolicyItemsForCluster,
  fetchDefaultRegionForCluster,
  fetchAllSnapshotsForOnDemandIngestion,
  fetchAvailableSnapshotsForOnDemandIngestion,
  fetchSnapshotsBySnapshotIds,
};
