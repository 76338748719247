import fetchWrapper from '../fetchWrapper';

export default {
  getTasks() {
    return fetchWrapper(`/admin/nds/externalMaintenance`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  createTask(data) {
    return fetchWrapper(`/admin/nds/externalMaintenance`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },
  updateTask(data) {
    return fetchWrapper(`/admin/nds/externalMaintenance`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },
  cancelTask(id) {
    return fetchWrapper(`/admin/nds/externalMaintenance/cancel/${id}`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },
  getMaintenanceMetaData(maintenanceJavaClassName) {
    return fetchWrapper(`/admin/nds/externalMaintenance/metaData/${maintenanceJavaClassName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
