interface ComponentLoaderOptions {
  attempts?: number;
  timeOut?: number;
}

interface GenericModule {
  default: any;
}

const componentLoader = <T extends GenericModule>(
  lazyComponent: () => Promise<T>,
  { attempts = 1, timeOut = 1500 }: ComponentLoaderOptions = {}
): Promise<T> => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attempts === 1) {
            reject(error);
            return;
          }
          return componentLoader(lazyComponent, { attempts: attempts - 1, timeOut }).then(resolve, reject);
        }, timeOut);
      });
  });
};

export default componentLoader;
