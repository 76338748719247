import { AllocationPoint, AssignmentPayload } from '@packages/types/abTest';

import fetchWrapper from './fetchWrapper';

interface GenerateABTestAssignmentsProps {
  centralUrl?: string;
  groupId?: string;
  orgId?: string;
  testNames: Array<string>;
}

interface GenerateAllocationPointsProps {
  centralUrl?: string;
  groupId?: string;
  orgId?: string;
  allocationPoints: Array<AllocationPoint>;
}

export interface EcosystemExperimentConfigProps {
  testId: string;
  testTag: string;
  testGroupDatabaseId: string;
}

export const getABTestList = () => {
  return fetchWrapper('/admin/abTest/tests', {
    method: 'GET',
  }).then((resp) => resp.json());
};

export const getABTestAssignments = () => {
  return fetchWrapper('/admin/abTest/assignments', {
    method: 'GET',
  }).then((resp) => resp.json());
};

function getQueryString({ groupId, orgId }: { groupId?: string; orgId?: string }) {
  if (groupId != null) {
    return `?groupId=${groupId}`;
  }
  if (orgId != null) {
    return `?orgId=${orgId}`;
  }
  return '';
}

/*
In order to generate assignments for experiments with project/org scope, a groupId must be included.
More info about using this endpoint can be found in the wiki: https://wiki.corp.mongodb.com/display/MMS/Backend+Framework+Overview
*/
export const generateABTestAssignmentsAndFlags = async ({
  centralUrl = '',
  groupId,
  orgId,
  testNames,
}: GenerateABTestAssignmentsProps): Promise<AssignmentPayload> => {
  const uri = `${centralUrl}/v2/assign${getQueryString({ groupId, orgId })}`;

  const response = await fetchWrapper(uri, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ testNames }),
  });

  return response.json();
};

export const generateABTestAssignmentsFromAllocationPoints = async ({
  centralUrl = '',
  groupId,
  orgId,
  allocationPoints,
}: GenerateAllocationPointsProps): Promise<AssignmentPayload> => {
  const uri = `${centralUrl}/v2/assignByPoint${getQueryString({ groupId, orgId })}`;

  const response = await fetchWrapper(uri, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ allocationPoints }),
  });

  return response.json();
};
