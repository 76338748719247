import {
  APIKey,
  AppFunction,
  AppLogRequest,
  AuthProviderConfig,
  AuthProviderType,
  BaasAdminClient,
  CreateAppFunction,
  CreateAppRequest,
  DataAPIConfig,
  DeploymentsFilter,
  EmailPasswordRegistrationRequest,
  ErrInvalidSession,
  ErrUIIPRestricted,
  ErrUnauthorized,
  EventSubscriptionResumeOptions,
  MeasurementRequest,
  MetricsRequestOptions,
  MongoDBBaseRule,
  PartialApp,
  PartialAppFunction,
  PartialAuthProviderConfig,
  PartialServiceDesc,
  SyncConfig,
  UserFilter,
} from 'baas-admin-sdk';
import _ from 'underscore';

import { ApplicationProductTypes, MongoDataSourceType } from '@packages/types/baas';
import { type RequestParams } from '@packages/types/RequestParams';
import { Trigger } from '@packages/types/triggers';

import * as api from '@packages/common/services/api';
import StitchApplication from '@packages/common/models/StitchApplication';
import StitchApplicationCollection from '@packages/common/models/StitchApplicationCollection';
import Cache from '@packages/common/utils/Cache';

const getProductFromApplicationType = (applicationType: ApplicationProductTypes) => {
  switch (applicationType) {
    case ApplicationProductTypes.atlasTriggerApplications:
      return 'atlas';
    case ApplicationProductTypes.dataAPIApplications:
      return 'data-api';
    case ApplicationProductTypes.deviceSyncApplications:
      return ['device-sync', 'edge-server'].join(',');
    default:
      return ['standard', 'atlas', 'data-api', 'device-sync', 'edge-server'].join(',');
  }
};

const appsCollectionByProduct = {
  [ApplicationProductTypes.allApplications]: new StitchApplicationCollection(),
  [ApplicationProductTypes.atlasTriggerApplications]: new StitchApplicationCollection(),
  [ApplicationProductTypes.dataAPIApplications]: new StitchApplicationCollection(),
  [ApplicationProductTypes.deviceSyncApplications]: new StitchApplicationCollection(),
};

// Caches
let atlasTriggersApplicationCache;
let dataAPIApplicationCache;
let deviceSyncApplicationCache;
let applicationsCache;

const cachedBaasAdminClients: { [key: string]: BaasAdminClient } = {};
const cachedSettings: { [key: string]: { groupId: any; username: any } } = {};

// Data API
const deviceSyncTemplate = 'sync.todo';

function settingsAreEqual(newSettings, baasUrl) {
  const props = ['groupId', 'username'];
  const oldProps = _.pick(cachedSettings[baasUrl], props);
  const newProps = _.pick(newSettings, props);
  return _.isEqual(oldProps, newProps);
}

function getApiKey() {
  // todo: refactor centralUrl logic for Stitch API Key in BAAS-19193
  const requestParams: RequestParams = window.REQUEST_PARAMS;
  const centralUrl = requestParams.centralUrl ?? '';
  return api.settings.addTempApiKey('Stitch API Key', centralUrl).then(({ key }) => key);
}

function getBaasAdminClient({ groupId, username, baasUrl }): BaasAdminClient {
  let cachedBaasAdminClient = cachedBaasAdminClients[baasUrl];
  if (!cachedBaasAdminClient || !cachedSettings || !settingsAreEqual({ groupId, username }, baasUrl)) {
    cachedSettings[baasUrl] = { groupId, username };
    cachedBaasAdminClient = new BaasAdminClient(baasUrl, { requestOrigin: 'mongodb-mms-ui' });
    cachedBaasAdminClients[baasUrl] = cachedBaasAdminClient;
  }
  return cachedBaasAdminClient;
}

function refreshBaasAdminClient({ groupId, username, baasUrl }) {
  return getApiKey()
    .then((apiKey) => {
      const options = { username, apiKey, cors: true, cookie: true };
      // Auth with cookie first so we never end up in a situation where we can perform actions
      // but cannot redirect
      return getBaasAdminClient({ groupId, username, baasUrl })
        .authenticate(AuthProviderType.MongoDBCloud, options)
        .then(() => apiKey);
    })
    .then((apiKey) =>
      getBaasAdminClient({ groupId, username, baasUrl }).authenticate(AuthProviderType.MongoDBCloud, {
        username,
        apiKey,
      })
    );
}

function getAuthedBaasAdminClient({ groupId, baasUrl, username }) {
  const baasAdminClient = getBaasAdminClient({ groupId, baasUrl, username });
  // Make a request for the user profile to make sure we still have a valid
  // session. If we don't then refresh the session with a new API key
  return baasAdminClient
    .userProfile()
    .catch((error) => {
      // 'InvalidSession' means the existing API key expired
      // 'Unauthorized' means the client has not been authenticated
      // In either case, we want to refresh the client with a new API key
      // 'ErrUIIPRestricted' means the client doesn't have access to the UI for
      // this group from their IP and gets redirected to the organizations page
      if (error.code === ErrInvalidSession || error.code === ErrUnauthorized) {
        return refreshBaasAdminClient({ groupId, baasUrl, username });
      } else if (error.code === ErrUIIPRestricted) {
        window.location.assign('/v2#/preferences/organizations?restrictedOrigin=appServices');
      }
    })
    .then(() => baasAdminClient);
}

function getAtlasServices(services: Array<PartialServiceDesc>) {
  return services.filter(
    (service) => service.type === MongoDataSourceType.Atlas || service.type === MongoDataSourceType.DataFederation
  );
}

interface AtlasServiceWithConfig extends PartialServiceDesc {
  config: Record<string, any>;
}

export interface StitchApp extends PartialApp {
  atlasServices: Array<AtlasServiceWithConfig>;
}

type CreateAppRequestPayload = BaasParams & CreateAppRequest;

interface SyncCreateAppRequestPayload extends CreateAppRequestPayload {
  clusterName: string;
  serviceName: string;
}

interface UpdateSyncDevModePayload extends BaasParamsForApp {
  updatedConfig: SyncConfig;
}

interface CreateEdgeServerPayload extends BaasParamsForApp {
  serverName: string;
}

interface CreateAndLinkDataLakeServicePayload extends BaasParamsForApp {
  dataLakeName: string;
  serviceName: string;
}

interface CreateAndLinkClusterServicePayload extends BaasParamsForApp {
  clusterName: string;
  serviceName: string;
}

interface UnlinkApplicationPayload extends BaasParamsForApp {
  serviceId: string;
}

interface ListSvcNamespacesPayload extends BaasParamsForApp {
  svcId: string;
}

interface UpdateServiceConfigPayload extends BaasParamsForApp {
  serviceId: string;
  config: Record<string, any>;
}

interface BulkGenerateSchemasPayload extends BaasParamsForApp {
  serviceId: string;
  generateEmptySchemas: boolean;
}

interface CreateAPIKeyPayload extends BaasParamsForApp {
  key: APIKey;
}

interface CreateEmailPassProviderPayload extends BaasParamsForApp {
  providerConfig: Record<string, any>;
}

interface CreateUserPayload extends BaasParamsForApp {
  emailPassRegRequest: EmailPasswordRegistrationRequest;
}

interface DeleteUserPayload extends BaasParamsForApp {
  userId: string;
}

interface GetUsersPayload extends BaasParamsForApp {
  userFilter?: UserFilter;
}

interface GetTriggerPayload extends BaasParamsForApp {
  triggerId: string;
}

interface CreateTriggerPayload extends BaasParamsForApp {
  trigger: Trigger;
}

interface UpdateTriggerPayload extends BaasParamsForApp {
  triggerId: string;
  updatedTrigger: Trigger;
}

interface RemoveTriggerPayload extends BaasParamsForApp {
  triggerId: string;
}

interface ResumeTriggerPayload extends BaasParamsForApp {
  triggerId: string;
  disableToken: boolean;
}

interface GetTriggerExecutionPayload extends BaasParamsForApp {
  triggerId: string;
}

interface GetFunctionPayload extends BaasParamsForApp {
  functionId: string;
}

interface CreateFunctionPayload extends BaasParamsForApp {
  func: CreateAppFunction;
}

interface UpdateFunctionPayload extends BaasParamsForApp {
  funcId: string;
  updatedFunction: AppFunction;
}

interface RemoveFunctionPayload extends BaasParamsForApp {
  functionId: string;
}

interface ExecuteDebugSourcePayload extends BaasParamsForApp {
  userId?: string;
  source: string;
  evalSource: string;
  runAsSystem: boolean;
}

interface GetLogsPayload extends BaasParamsForApp {
  filter: AppLogRequest;
}

type GetLegacyMetricsPayload = BaasParamsForApp & MeasurementRequest;

type GetMetricsPayload = BaasParamsForApp & MetricsRequestOptions;

interface UpsertDependencyPayload extends BaasParamsForApp {
  dependencyName: string;
  version?: string;
}

interface DeleteDependencyPayload extends BaasParamsForApp {
  dependencyName: string;
}

interface UploadDependenciesPayload extends BaasParamsForApp {
  filename: string;
  body: Blob;
}

interface GetDependencyDeployStatusPayload extends BaasParamsForApp {
  filter?: DeploymentsFilter;
}

interface GetDataSourceRulesPayload extends BaasParamsForApp {
  serviceId: string;
}

interface DeleteDataSourceRulesPayload extends BaasParamsForApp {
  serviceId: string;
}

interface GetServiceDefaultRulePayload extends BaasParamsForApp {
  serviceId: string;
}

interface CreateServiceDefaultRulePayload extends BaasParamsForApp {
  serviceId: string;
  newDefaultRule: MongoDBBaseRule;
}

interface UpdateServiceDefaultRulePayload extends BaasParamsForApp {
  serviceId: string;
  updatedDefaultRule: MongoDBBaseRule;
}

interface CreateDataAPIConfigPayload extends BaasParamsForApp {
  newConfig: DataAPIConfig;
}

interface UpdateDataAPIConfigPayload extends BaasParamsForApp {
  updatedConfig: DataAPIConfig;
}

interface GetNearestAtlasAppRegionPayload extends BaasParams {
  provider: string;
  atlasRegion: string;
}

interface GetSyncTemplateAppPayload extends BaasParamsForApp {
  templateId: string;
}

const loadAtlasServicesForBaasApps = async (
  client: BaasAdminClient,
  groupId: string,
  apps: Array<PartialApp>
): Promise<Array<StitchApp>> =>
  Promise.all(
    apps.map(async (app: StitchApp): Promise<StitchApp> => {
      const adminServices = client.apps(groupId).app(app.id).services();
      const services = await adminServices.list();
      const atlasServicesWithConfig = await Promise.all(
        getAtlasServices(services).map((atlasService) =>
          adminServices
            .service(atlasService.id)
            .config()
            .get()
            .then((config) => ({
              ...atlasService,
              config,
            }))
        )
      );

      app.atlasServices = atlasServicesWithConfig;
      return app;
    })
  );

const buildApplicationLoader = (applicationType: ApplicationProductTypes) => {
  return async ({ groupId, baasUrl, username }) => {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => {
      const adminApps = client.apps(groupId);
      return adminApps.list(getProductFromApplicationType(applicationType)).then(async (apps) => {
        const appsWithAtlasServices = await loadAtlasServicesForBaasApps(client, groupId, apps);
        const collection = appsCollectionByProduct[applicationType];
        collection.reset(appsWithAtlasServices.map((app) => StitchApplication.fromApiResponse(app)));
        return collection;
      });
    });
  };
};

function getAtlasTriggersApplicationCache() {
  if (!atlasTriggersApplicationCache) {
    atlasTriggersApplicationCache = new Cache({
      load: buildApplicationLoader(ApplicationProductTypes.atlasTriggerApplications),
    });
  }
  return atlasTriggersApplicationCache;
}

function getDataAPIApplicationCache() {
  if (!dataAPIApplicationCache) {
    dataAPIApplicationCache = new Cache({ load: buildApplicationLoader(ApplicationProductTypes.dataAPIApplications) });
  }
  return dataAPIApplicationCache;
}

function getDeviceSyncApplicationCache() {
  if (!deviceSyncApplicationCache) {
    deviceSyncApplicationCache = new Cache({
      load: buildApplicationLoader(ApplicationProductTypes.deviceSyncApplications),
    });
  }
  return deviceSyncApplicationCache;
}

function getApplicationsCache() {
  if (!applicationsCache) {
    applicationsCache = new Cache({ load: buildApplicationLoader(ApplicationProductTypes.allApplications) });
  }
  return applicationsCache;
}

export interface BaasParams {
  groupId: string;
  baasUrl: string;
  username: string;
}

interface BaasParamsForApp extends BaasParams {
  appId: string;
}

export default {
  loadApplicationsWithoutCache({ groupId, baasUrl, username }: BaasParams) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => {
      const adminApps = client.apps(groupId);
      return adminApps
        .list(getProductFromApplicationType(ApplicationProductTypes.allApplications))
        .then(async (apps) => {
          const appsWithAtlasServices = await loadAtlasServicesForBaasApps(client, groupId, apps);
          return appsWithAtlasServices.map((app) => StitchApplication.fromApiResponse(app));
        });
    });
  },

  doesAuthedBaasAdminClientExist(baasParams: BaasParams) {
    return getBaasAdminClient(baasParams).isAuthenticated();
  },

  loadAtlasTriggersApplication(baasParams: BaasParams) {
    return getAtlasTriggersApplicationCache().get(baasParams);
  },

  reloadAtlasTriggersApplication(baasParams: BaasParams) {
    return getAtlasTriggersApplicationCache().refresh(baasParams);
  },

  loadDataAPIApplication(baasParams: BaasParams) {
    return getDataAPIApplicationCache().get(baasParams);
  },

  reloadDataAPIApplication(baasParams: BaasParams) {
    return getDataAPIApplicationCache().refresh(baasParams);
  },

  loadDeviceSyncApplication(baasParams: BaasParams): Promise<typeof StitchApplicationCollection> {
    return getDeviceSyncApplicationCache().get(baasParams);
  },

  reloadDeviceSyncApplication(baasParams: BaasParams): Promise<typeof StitchApplicationCollection> {
    return getDeviceSyncApplicationCache().refresh(baasParams);
  },

  updateSyncDevelopmentMode(payload: UpdateSyncDevModePayload) {
    const { appId, groupId, username, baasUrl, updatedConfig } = payload;
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => {
      return client.apps(groupId).app(appId).sync().config().update(updatedConfig);
    });
  },

  loadApplications(baasParams: BaasParams) {
    return getApplicationsCache().get(baasParams);
  },

  // unlike the above loadApplications, this just uses the baas-admin-sdk types without
  // converting the result into a Backbone model
  async loadApps({ groupId, baasUrl, username }: BaasParams) {
    const client = await getAuthedBaasAdminClient({ groupId, baasUrl, username });
    return await client.apps(groupId).list(getProductFromApplicationType(ApplicationProductTypes.allApplications));
  },

  reloadApplications(baasParams: BaasParams) {
    return getApplicationsCache().refresh(baasParams);
  },

  authForRedirect(baasParams: BaasParams) {
    return refreshBaasAdminClient(baasParams);
  },

  validateSession(settings: BaasParams) {
    // If this promise resolves successfully then we are guaranteed
    // to have a valid session
    return getAuthedBaasAdminClient(settings);
  },

  createApplication(req: CreateAppRequestPayload) {
    const { groupId, baasUrl, username, ...reqPayload } = req;
    return getAuthedBaasAdminClient({ groupId, baasUrl, username })
      .then((client) => client.apps(groupId).create(reqPayload))
      .then((app) => StitchApplication.fromApiResponse(app));
  },

  createSyncTemplateApplication({
    clusterName,
    serviceName,
    name,
    deploymentModel,
    location,
    providerRegion,
    groupId,
    baasUrl,
    username,
    product,
  }: SyncCreateAppRequestPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username })
      .then((client) =>
        client.apps(groupId).create(
          new CreateAppRequest({
            name,
            deploymentModel,
            location,
            providerRegion,
            product,
            templateId: deviceSyncTemplate, // using device sync template
            dataSource: {
              name: serviceName,
              type: MongoDataSourceType.Atlas,
              config: {
                clusterName,
              },
            },
          })
        )
      )
      .then((app) => StitchApplication.fromApiResponse(app));
  },

  hasSyncEnabledApps({ groupId, baasUrl, username }: BaasParams) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).hasSyncEnabledApps()
    );
  },

  createEdgeServer({ appId, groupId, baasUrl, username, serverName }: CreateEdgeServerPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => {
      return client.apps(groupId).app(appId).edge().create({ name: serverName });
    });
  },

  createAndLinkDataLakeService({
    groupId,
    baasUrl,
    username,
    appId,
    dataLakeName,
    serviceName,
  }: CreateAndLinkDataLakeServicePayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().create({
        name: serviceName,
        type: MongoDataSourceType.DataFederation,
        config: {
          dataLakeName,
        },
      })
    );
  },

  createAndLinkClusterService({
    groupId,
    baasUrl,
    username,
    appId,
    clusterName,
    serviceName,
  }: CreateAndLinkClusterServicePayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().create({
        name: serviceName,
        type: MongoDataSourceType.Atlas,
        config: {
          clusterName,
        },
      })
    );
  },

  unlinkApplication({ groupId, baasUrl, username, appId, serviceId }: UnlinkApplicationPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).remove()
    );
  },

  deleteApplication({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).remove()
    );
  },

  getResourceNames({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).resourceNames()
    );
  },

  getServices({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().list()
    );
  },

  listSvcNamespaces({ groupId, baasUrl, username, appId, svcId }: ListSvcNamespacesPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(svcId).runCommand(`list_namespaces`, {})
    );
  },

  getMongoServices({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => {
      const serviceRequest = () => client.apps(groupId).app(appId).services();

      return serviceRequest()
        .list()
        .then((svcs) => {
          const atlasServices = svcs.filter(
            (svc) => svc.type === MongoDataSourceType.Atlas || svc.type === MongoDataSourceType.DataFederation
          );
          const configRequests = atlasServices.map((svc) => serviceRequest().service(svc.id).config().get());
          return Promise.all(configRequests).then((configs) =>
            configs.map((config, idx) => ({ ...atlasServices[idx], config }))
          );
        });
    });
  },

  updateServiceConfig({ groupId, baasUrl, username, appId, serviceId, config }: UpdateServiceConfigPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => {
      return client.apps(groupId).app(appId).services().service(serviceId).config().update(config);
    });
  },

  bulkGenerateSchemas({
    groupId,
    baasUrl,
    username,
    appId,
    serviceId,
    generateEmptySchemas,
  }: BulkGenerateSchemasPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => {
      return client.apps(groupId).app(appId).schemas().bulkGenerateSchema({
        dataSourceId: serviceId,
        generateEmptySchemas: generateEmptySchemas,
        allSchemas: true,
        allUnconfiguredSchemas: false,
      });
    });
  },

  // API Keys
  getAPIKeys({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).apiKeys().list()
    );
  },

  createAPIKey({ groupId, baasUrl, username, appId, key }: CreateAPIKeyPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).apiKeys().create(key)
    );
  },

  enableAPIKeyProvider({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client
        .apps(groupId)
        .app(appId)
        .authProviders()
        .create(
          new AuthProviderConfig({
            name: AuthProviderType.APIKey,
            type: AuthProviderType.APIKey,
            disabled: false,
          })
        )
    );
  },

  createEmailPassProvider({
    groupId,
    baasUrl,
    username,
    appId,
    providerConfig,
  }: CreateEmailPassProviderPayload): Promise<PartialAuthProviderConfig> {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client
        .apps(groupId)
        .app(appId)
        .authProviders()
        .create(
          new AuthProviderConfig({
            name: AuthProviderType.Userpass,
            type: AuthProviderType.Userpass,
            config: providerConfig,
            disabled: false,
          })
        )
    );
  },

  createUser({ groupId, baasUrl, username, appId, emailPassRegRequest }: CreateUserPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).users().create(new EmailPasswordRegistrationRequest(emailPassRegRequest))
    );
  },

  deleteUser({ groupId, baasUrl, username, appId, userId }: DeleteUserPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).users().user(userId).remove()
    );
  },

  getUsers({ groupId, baasUrl, username, appId, userFilter }: GetUsersPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).users().list(userFilter)
    );
  },

  getAuthProviders({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).authProviders().list()
    );
  },

  // Triggers
  getTriggers({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().list()
    );
  },

  getTrigger({ groupId, baasUrl, username, appId, triggerId }: GetTriggerPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).get()
    );
  },

  createTrigger({ groupId, baasUrl, username, appId, trigger }: CreateTriggerPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().create(trigger)
    );
  },

  updateTrigger({ groupId, baasUrl, username, appId, triggerId, updatedTrigger }: UpdateTriggerPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).update(updatedTrigger)
    );
  },

  removeTrigger({ groupId, baasUrl, username, appId, triggerId }: RemoveTriggerPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).remove()
    );
  },

  resumeTrigger({ groupId, baasUrl, username, appId, triggerId, disableToken }: ResumeTriggerPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client
        .apps(groupId)
        .app(appId)
        .eventSubscriptions()
        .eventSubscription(triggerId)
        .resume(new EventSubscriptionResumeOptions({ disableToken }))
    );
  },

  getTriggerExecution({ groupId, baasUrl, username, appId, triggerId }: GetTriggerExecutionPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).execution()
    );
  },

  // Functions
  getFunctions({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().list()
    );
  },

  getFunction({ groupId, baasUrl, username, appId, functionId }: GetFunctionPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().function(functionId).get()
    );
  },

  createFunction({ groupId, baasUrl, username, appId, func }: CreateFunctionPayload): Promise<PartialAppFunction> {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().create(func)
    );
  },

  updateFunction({ groupId, baasUrl, username, appId, funcId, updatedFunction }: UpdateFunctionPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().function(funcId).update(updatedFunction)
    );
  },

  removeFunction({ groupId, baasUrl, username, appId, functionId }: RemoveFunctionPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().function(functionId).remove()
    );
  },

  executeDebugSource({
    groupId,
    baasUrl,
    username,
    appId,
    userId,
    source,
    evalSource,
    runAsSystem,
  }: ExecuteDebugSourcePayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).debug().executeFunctionSource({ userId, source, evalSource, runAsSystem })
    );
  },

  // snippets
  getSnippets({ groupId, baasUrl, username }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => client.snippets().list());
  },

  // Logs
  getLogs({ groupId, baasUrl, username, appId, filter }: GetLogsPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).logs().list(filter)
    );
  },

  // Metrics
  getMetrics({ groupId, baasUrl, username, appId, start, end, granularity, metrics }: GetMetricsPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).metrics({ start, end, granularity, metrics })
    );
  },

  // Legacy Metrics
  getLegacyMetrics({ groupId, baasUrl, username, appId, start, end, granularity }: GetLegacyMetricsPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).measurements({ start, end, granularity })
    );
  },

  // Dependencies
  upsertDependency({ groupId, baasUrl, username, appId, dependencyName, version }: UpsertDependencyPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().upsert(dependencyName, version)
    );
  },

  getDependencies({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().list()
    );
  },

  deleteDependency({ groupId, baasUrl, username, appId, dependencyName }: DeleteDependencyPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().delete(dependencyName)
    );
  },

  uploadDependencies({ groupId, baasUrl, username, appId, filename, body }: UploadDependenciesPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().put(filename, body)
    );
  },

  deleteAllDependencies({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().deleteAll()
    );
  },

  getDependencyDeployStatus({ groupId, baasUrl, username, appId, filter }: GetDependencyDeployStatusPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).deploy().deployments().list(filter)
    );
  },

  getDependencyInstallStatus({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().status()
    );
  },

  // Rules
  getDataSourceRules({ groupId, baasUrl, username, appId, serviceId }: GetDataSourceRulesPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).rules().list()
    );
  },

  deleteDataSourceRules({ groupId, baasUrl, username, appId, serviceId }: DeleteDataSourceRulesPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).rules().remove()
    );
  },

  // Default Rules
  getPresetRoleConfigs({ groupId, baasUrl, username }: BaasParams) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.rules().presetRoles().list()
    );
  },

  getServiceDefaultRule({ groupId, baasUrl, username, appId, serviceId }: GetServiceDefaultRulePayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).defaultRule().get()
    );
  },

  createServiceDefaultRule({
    groupId,
    baasUrl,
    username,
    appId,
    serviceId,
    newDefaultRule,
  }: CreateServiceDefaultRulePayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).defaultRule().create(newDefaultRule)
    );
  },

  updateServiceDefaultRule({
    groupId,
    baasUrl,
    username,
    appId,
    serviceId,
    updatedDefaultRule,
  }: UpdateServiceDefaultRulePayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).defaultRule().update(updatedDefaultRule)
    );
  },

  // Data API Config
  getDataAPIConfig({ groupId, baasUrl, username, appId }: BaasParamsForApp) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dataAPI().config().get()
    );
  },

  createDataAPIConfig({ groupId, baasUrl, username, appId, newConfig }: CreateDataAPIConfigPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dataAPI().config().create(newConfig)
    );
  },

  updateDataAPIConfig({ groupId, baasUrl, username, appId, updatedConfig }: UpdateDataAPIConfigPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dataAPI().config().update(updatedConfig)
    );
  },

  getDataAPIVersions({ groupId, baasUrl, username }: BaasParams) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.dataAPI().versions().list()
    );
  },

  getNearestProviderRegion({ groupId, baasUrl, username }: BaasParams) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.providerRegions().nearest().get()
    );
  },

  getAppSPASettingsGlobal({ groupId, baasUrl, username }: BaasParams) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.private().spa().settings().global()
    );
  },

  getNearestAtlasAppRegion({ groupId, baasUrl, username, provider, atlasRegion }: GetNearestAtlasAppRegionPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.private().provider(provider).atlasRegions(atlasRegion).nearestAppRegion().get()
    );
  },

  getTemplateApp({ groupId, appId, baasUrl, username, templateId }: GetSyncTemplateAppPayload) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).templates().template(templateId).client()
    );
  },

  getEventBridgeRegions({ groupId, baasUrl, username }: BaasParams) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.private().provider('AWS').service('eventbridge').regions().get()
    );
  },
};
