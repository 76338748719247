import { useContext } from 'react';

import ExperimentSDKClient, { types } from '@mongodb-js/mdb-experiment-js';
import reactSdk, { typesReact } from '@mongodb-js/mdb-experiment-js/react';

import {
  AllocationPoint,
  ExperimentFeatureFlag,
  ExperimentFeatureFlags,
  TestGroupName,
  TestName,
} from '@packages/types/abTest';
import { CloudTeams, ErrorSeverity } from '@packages/types/observability';

import { isGovEnv } from '@packages/common/utils/envUtils';
import { ExperimentViewedAdditionalProperties } from '@packages/common/utils/segmentAnalytics';
import { Environment, getEnvironment } from '@packages/get-environment';
import { sendError } from '@packages/observability';
import { updateErrorTrackerState } from '@packages/observability';

export const PLACEHOLDER_ENTITY_IDS: types.EntityIds = {
  orgId: '[PLACEHOLDER]',
};

export const NO_ENTITIES_ERROR = new Error('No entities provided');
const DEFAULT_NO_ENTITIES_RESPONSE: typesReact.BasicHookResponse = {
  asyncStatus: typesReact.HookAsyncStatus.ERROR,
  error: NO_ENTITIES_ERROR,
};

const sdkToErrorSeverity: Record<types.Severity, ErrorSeverity> = {
  [types.Severity.ERROR]: ErrorSeverity.ERROR,
  [types.Severity.WARNING]: ErrorSeverity.WARNING,
  [types.Severity.INFO]: ErrorSeverity.INFO,
};

export const DEFAULT_API_TIMEOUT_MS = 1000;

/**
 * @description
 * Interface representing important Experiment Data enums to grant stricter typing
 * to the Experiment functions.
 *
 * Please see {@link types.TypeData} for more details of each of the fields.
 */
export interface ExpTypes extends types.TypeData {
  experimentName: TestName;
  experimentVariantName: TestGroupName;
  experimentAttributeName: ExperimentFeatureFlag;
  allocationPointName: AllocationPoint;
  experimentViewedProps: ExperimentViewedAdditionalProperties;
  loggerTeam: CloudTeams;
}

export type ExpSdkType = ReturnType<typeof ExperimentSDKClient.create<ExpTypes>>;
export type SDKAssignment<
  TestNameT extends ExpTypes['experimentName'] = ExpTypes['experimentName'],
  VariantNameT extends ExpTypes['experimentVariantName'] = ExpTypes['experimentVariantName'],
> = types.SDKAssignment<TestNameT, VariantNameT>;

// SDK singleton
let sdk: ExpSdkType | null = null;

// functions

/**
 * @description
 * Creates the singleton instance of the SDK. The property params are optional. If no explicit environment is
 * passed in, it is figured out based on the URL host. However, entityIds MUST be populated with
 * at least a groupId or an orgId (using {@link updateEntityIds}) before any of the
 * experiment-related functions (e.g. {@link useAssignment} or {@link useAssign}) can be used.
 *
 * The reason entityIds is allowed to be optional here is if the ID information comes from an
 * async call, you can first create the SDK instance synchronously and then update the entity IDs
 * when you have that information. If you are using the {@link ExperimentProvider}, creating the
 * SDK instance synchronously ensures it exists when the Provider is instantiated.
 *
 * Purposely doesn't not return the SDK as all interactions with the SDK should go through these
 * experimentUtils functions.
 *
 * On error, logs but does not throw.
 *
 * Only inits the SDK in non-FedRAMP environments.
 *
 * Can see {@link ExperimentSDKClient.create} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for more info on how to set up an experiment and best practices.
 *
 * @param config.environment Optional. See {@link types.Environment} and {@link getEnvironment}
 * @param config.entityIds Optional. See {@link types.EntityIds}
 * @param config.loggerOptions Optional. See {@link types.LoggerOptions}
 * @param config.trackerOptions Optional. See {@link types.TrackerOptions}
 */
export const init = ({
  environment,
  entityIds,
  loggerOptions,
  trackerOptions,
}: {
  environment?: types.Environment;
  entityIds?: types.EntityIds;
  loggerOptions?: types.LoggerOptions<ExpTypes['loggerTeam']>;
  trackerOptions?: types.TrackerOptions<ExpTypes['experimentViewedProps']>;
}): void => {
  try {
    // only init the SDK if not in an FedRAMP env
    if (!isGovEnv()) {
      let env;

      if (environment) {
        env = environment;
      } else if (isDemoboxEnv()) {
        env = Environment.DEMOBOX;
      } else {
        env = getEnvironment();
      }

      sdk = ExperimentSDKClient.create<ExpTypes>({
        entityIds: entityIds || PLACEHOLDER_ENTITY_IDS,
        environment: env,
        loggerOptions,
        trackerOptions,
      });

      // Subscribe to AB Test assignment updates and update the error tracker on change
      updateErrorTrackerOnTestAssignmentChange();
    }
  } catch (e) {
    // if loggerOptions or trackerOptions are invalid, "create" can throw an error
    logError(e);
  }
};

/**
 * @description
 * Gets the SDK singleton instance. If called before the SDK is created, will return null.
 *
 * Only call this if you NEED a direct reference to the SDK instance. Most experimentation uses
 * in MMS should not require using this and can just use the functions in experimentUtils to
 * interface with the SDK, which have additional safeguards for MMS usage.
 *
 * Can see {@link ExperimentSDKClient.getSDKInstance} for more details.
 *
 * @return the SDK or null
 */
export const getSdkInstance = (): ExpSdkType | null => {
  return ExperimentSDKClient.getSDKInstance<ExpTypes>();
};

/**
 * @description
 * Updates the {@link types.EntityIds} for the SDK. Updating these values wipes clean all
 * experiment-related data in the SDK state. This function replaces the ENTIRE entityIds object
 * -- it does not do a merge with the existing object.
 *
 * If SDK has not been created yet or if invalid entityIds are passed, this is a no-op.
 *
 * Can see {@link sdk.updateEntityIds} for more details.
 *
 * On error, logs but does not throw.
 *
 * @param config.entityIds Required. See {@link types.EntityIds}
 */
export const updateEntityIds = (entityIds: types.EntityIds): void => {
  if (!doesSdkExist()) {
    return;
  }

  try {
    sdk!.updateEntityIds(entityIds);
    updateErrorTrackerOnTestAssignmentChange();
  } catch (e) {
    // if orgId or groupId is missing or entityIds isn't an object, the function can throw an error
    logError(e);
  }
};

/**
 * @description
 * Updates the {@link types.LoggerOptions} for the SDK. Useful if the logger changes or if the
 * logger has to be set after SDK initialization.
 *
 * If SDK has not been created yet or invalid loggerOptions are passed, this is a no-op.
 *
 * Can see {@link sdk.updateLoggerOptions} for more details.
 *
 * On error, logs but does not throw.
 *
 * @param loggerOptions Required. See {@link types.LoggerOptions}
 */
export const updateLoggerOptions = (loggerOptions: types.LoggerOptions<ExpTypes['loggerTeam']>): void => {
  if (!doesSdkExist()) {
    return;
  }

  try {
    sdk!.updateLoggerOptions(loggerOptions);
  } catch (e) {
    // if loggerOptions is invalid, the function can throw an error
    logError(e);
  }
};

/**
 * @description
 * Returns the Sentry error logging function formatted as an object that is compatible with the SDK Logger type.
 * (We are using the MMS "sendError" function instead of the generic "Sentry.captureException" function to take advantage
 * of the MMS-specific Sentry logic in "sendError".)
 *
 * @param sentrySendError Required.
 *
 * @return a {@link types.Logger} object
 */
export const setSentryAsLogger = (sentrySendError: typeof sendError): types.Logger<ExpTypes['loggerTeam']> => {
  return {
    log: ({ logMessage, severity, team }) => {
      sentrySendError({
        error: new Error(logMessage),
        team: team ?? CloudTeams.AtlasGrowth,
        severity: sdkToErrorSeverity[severity],
      });
    },
  };
};

/**
 * @description
 * Updates the SDK state with existing experiment assignment and attribute data. Will do nothing
 * if it detects entityIds is not populated with real entity ID values or if
 * the SDK hasn't been created yet.
 *
 * Not meant to assign entities to experiments (use {@link useAssign} for that); meant for use in
 * special cases such as getting historical assignments at the start of an entity's session.
 *
 * Please see {@link sdk.addExistingExperimentData} for more details.
 *
 * @param existingData Required. See {@link types.ExistingData}
 * @param options Optional. See {@link types.AddExistingExperimentDataOptions}
 */
export const addExistingExperimentData = (
  existingData: types.ExistingData<ExpTypes>,
  options?: types.AddExistingExperimentDataOptions<ExpTypes['loggerTeam']>
): void => {
  if (!areEntityIdsPopulated()) {
    return;
  }

  sdk!.addExistingExperimentData(existingData, options);
};

/**
 * @description
 * An async Javascript function that returns the SDK entity's assignment for a given experiment. The
 * assignment is first checked in the sdk's state. If it doesn't exist in state, the sdk will
 * make a GET call to the assignment endpoint to fetch it and then store it in state. This function can
 * also optionally fire an "Experiment Viewed" tracking event if the entity is in the experiment sample.
 * Will do nothing and return null if it detects entityIds is not populated with real entity ID values.
 *
 * Is the primary method for getting assignment information.
 *
 * Can see {@link sdk.getAssignment} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link useAssignment} for the React hook version of this function.
 *
 * @param experimentName Required. An experiment name from the {@link ExpTypes.experimentName} enum.
 * @param trackIsInSample Required. Whether or not an "Experiment Viewed" event should be fired.
 * @param options Optional. See {@link types.GetAssignmentOptions}
 *
 * @returns a Promise that resolves to an {@link types.SDKAssignment} object or null.
 * If the value is null, assume entity is not in experiment.
 * If it's an SDKAssignment object, check "assignmentData.isInSample" to confirm if entity is in experiment.
 */
export const getAssignment = async (
  experimentName: ExpTypes['experimentName'],
  trackIsInSample: boolean,
  options?: types.GetAssignmentOptions<ExpTypes>
): Promise<SDKAssignment | null> => {
  if (!areEntityIdsPopulated()) {
    return null;
  }

  return await sdk!.getAssignment(experimentName, trackIsInSample, {
    timeoutMs: DEFAULT_API_TIMEOUT_MS,
    ...options,
  });
};

/**
 * @description
 * An async Javascript function that checks the test group assignment for a given experiment. Can also
 * optionally fire an "Experiment Viewed" tracking event if the entity is in the experiment sample
 * since it calls {@link getAssignment}.
 * Will return false if it detects entityIds is not populated with real entity ID values.
 *
 * CAVEAT: This function does NOT make an API call to get assignment information, so it should only be called when
 * you're confident an assign call has been created (pending is fine) or an assignment already exists.
 *
 * @param experimentName Required. An experiment name from the {@link ExpTypes.experimentName} enum.
 * @param experimentVariantName Required. An experiment variant name from the {@link ExpTypes.experimentVariantName} enum.
 * @param trackIsInSample Required. Whether or not an "Experiment Viewed" event should be fired.
 * @param options Optional. See {@link types.GetAssignmentOptions}
 *
 * @returns a Promise that resolves to a boolean value.
 * If the value is false, assume entity is not in experiment test group.
 */
export const isInTestGroup = async (
  experimentName: ExpTypes['experimentName'],
  experimentVariantName: TestGroupName,
  trackIsInSample: boolean,
  options?: types.GetAssignmentOptions<ExpTypes>
): Promise<boolean> => {
  const assignment = await getAssignment(experimentName, trackIsInSample, options);

  return assignment?.assignmentData.variant === experimentVariantName;
};

/**
 * @description
 * A Javascript function that returns the SDK entity's experiment attribute value for a given attribute name. If
 * no value found in the SDK state or it detects the SDK is not using real entity ID values,
 * returns the "fallback value".
 *
 * Does NOT make a call to the API to get values; relies solely on local SDK state.
 *
 * Please see {@link sdk.getExperimentAttribute} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link useExperimentAttribute} for the React version of this function.
 *
 * @param experimentAttribute Required. An experiment attribute name from the
 * {@link ExpTypes.experimentAttributeName} enum.
 * @param fallbackValue Required. The default/fallback value if no value found in the SDK state.
 *
 * @returns a value of {@link types.ExperimentAttributeValue} type.
 */
export const getExperimentAttribute = <Key extends ExpTypes['experimentAttributeName']>(
  attributeName: Key,
  fallbackValue: types.ExperimentAttributeValue & ExperimentFeatureFlags[Key]
): types.ExperimentAttributeValue & ExperimentFeatureFlags[Key] => {
  if (!areEntityIdsPopulated()) {
    return fallbackValue;
  }
  // TODO(AG-874): Remove the typecast once the SDK becomes smart enough to know the types itself
  return sdk!.getExperimentAttribute(attributeName, fallbackValue) as types.ExperimentAttributeValue &
    ExperimentFeatureFlags[Key];
};

/**
 * @description
 * An async Javascript function that runs the assignment logic on the SDK entity for a given experiment--that is,
 * figures out if the entity is in the experiment sample, and if so, which variant (including control) the entity
 * is in. Will do nothing and return a Promise with a null value if it detects
 * entityIds is not populated with real entity ID values.
 *
 * On error, logs but does not throw.
 *
 * Does NOT return assignment information. Use {@link getAssignment} for that.
 *
 * Please see {@link sdk.assign} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link useAssign} for the React version of this function.
 *
 * @param experimentName Required. An experiment name from the {@link ExpTypes.experimentName} enum.
 * @param options Optional. See {@link types.AssignOptions}
 *
 * @returns a Promise that resolves to either null or a success object.
 */
export const assign = async (
  experimentName: ExpTypes['experimentName'],
  options?: types.AssignOptions<ExpTypes['loggerTeam']>
): Promise<types.AsyncStatus | null> => {
  if (!areEntityIdsPopulated()) {
    return null;
  }

  let result: types.AsyncStatus | null = null;
  try {
    result = await sdk!.assign(experimentName, { timeoutMs: DEFAULT_API_TIMEOUT_MS, ...options });
  } catch (e) {
    // the SDK bubbles up errors from the MMS BE assign call
    logError(e);
  }

  return result;
};

/**
 * @description
 * An async Javascript function that runs the assignment logic on the SDK entity for an array of allocation points
 * --that is, for each experiment associated with each of the allocation points, figures out if the entity is
 * in the experiment sample, and if so, which variant (including control) the entity is in. Will do nothing
 * and return a Promise array with a null value if it detects entityIds is not populated with real entity ID values.
 *
 * On error, logs but does not throw.
 *
 * Does NOT return assignment information. Use {@link getAssignment} for that.
 *
 * Please see {@link sdk.assignByPoints} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link useAssignByPoints} for the React version of this function.
 *
 * @param allocationPoints Required. An array of values from {@link ExpTypes.allocationPointName} enum.
 * @param options Optional. See {@link types.AssignByPointsOptions}
 *
 * @returns a Promise with an array of values that are either null or a success object.
 */
export const assignByPoints = async (
  allocationPoints: Array<ExpTypes['allocationPointName']>,
  options?: types.AssignByPointsOptions<ExpTypes['loggerTeam']>
): Promise<Array<types.AsyncStatus | null>> => {
  if (!areEntityIdsPopulated()) {
    return [null];
  }

  let result: Array<types.AsyncStatus | null> = [null];
  try {
    result = await sdk!.assignByPoints(allocationPoints, { timeoutMs: DEFAULT_API_TIMEOUT_MS, ...options });
  } catch (e) {
    // the SDK bubbles up errors from the MMS BE assignByPoints call
    logError(e);
  }

  return result;
};

/**
 * @description
 * An async Javascript function that sends an "Experiment Viewed" event via the registered Tracker
 * if the SDK's entity is in the sample of the given experiment.
 * Will do nothing and return a promise with a null value if it detects entityIds is not populated
 * with real entity ID values.
 *
 * On error, logs but does not throw.
 *
 * Please see {@link sdk.trackIsInSample} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link reactSdk.useTrackIsInSample} for the React version of this function.
 *
 * @param config.experimentName Required. An experiment name from the {@link ExpTypes.experimentName} enum.
 * @param config.customProperties Optional. Allows augmenting the event with custom properties defined in
 * {@link ExpTypes.experimentViewedProps}.
 * @param config.team Optional. The team that logs should be associated with.
 *
 * @returns a Promise that resolves to either null or a success object.
 */
export const trackIsInSample = async (
  experimentName: ExpTypes['experimentName'],
  customProps?: ExpTypes['experimentViewedProps'],
  team?: ExpTypes['loggerTeam']
): Promise<types.AsyncStatus | null> => {
  if (!areEntityIdsPopulated()) {
    return null;
  }

  let result: types.AsyncStatus | null = null;
  try {
    result = await sdk!.trackIsInSample(experimentName, customProps, team);
  } catch (e) {
    // the SDK bubbles up errors from the registered Tracker's track call
    // Add the experimentName to the error log (eventually will be added to the SDK's native error message)
    logError(new Error(`${e.message}, experimentName: ${experimentName}`, { cause: e }));
  }

  return result;
};

/**
 * @description
 * A Javascript function that accepts a callback function as a param which is invoked whenever the SDK's
 * experiment assignment data is updated. The callback function takes in an object of type {@link types.AssignmentsMap}
 * as its argument which is updated with a snapshot of the SDK's experiment assignment map at the point of invocation.
 *
 * On error, logs but does not throw.
 *
 * Please see {@link sdk.subscribeToAssignmentsMap} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * @param subscriberCallback Required. The subscriber callback function.
 * @returns an unsubscribe function which when called will de-register the subscriberCallback
 * function passed in the params.
 */
export const subscribeToAssignmentsMap = (
  subscriberCallback: (assignmentsMap: types.AssignmentsMap<ExpTypes>) => void
): (() => void) => {
  let unsubscribe = () => {};
  if (!doesSdkExist()) {
    return unsubscribe;
  }
  try {
    unsubscribe = sdk!.subscribeToAssignmentsMap(subscriberCallback);
  } catch (e) {
    // if assignmentsMap isn't a valid object, the function can throw an error
    logError(e);
  }
  return unsubscribe;
};

/**
 * @description
 * A helper function that subscribes to the SDK's experiment assignment update event and
 * updates the error tracker state with the latest assignments map for Sentry reporting
 */
export const updateErrorTrackerOnTestAssignmentChange = () => {
  const subscriptionCb = (assignmentsMap: types.AssignmentsMap<ExpTypes>): void => {
    updateErrorTrackerState({
      extras: {
        abTestAssignments: assignmentsMap,
      },
    });
  };
  subscribeToAssignmentsMap(subscriptionCb);
};

// React components

/**
 * @description
 * Creates a Context in React within which all components have access to the SDK. Please make sure the
 * SDK is non-null (e.g. use {@link init} to create an SDK instance) before creating this component,
 * otherwise the experiment-relate hooks will not work.
 *
 * Please see {@link reactSdk.ExperimentSDKProvider} for more details.
 *
 * @returns an Experiment Provider Component (JSX Element)
 */
export const ExperimentProvider = ({ children }) => {
  const providerProps: typesReact.ExperimentSDKProviderProps<ExpTypes> = {
    children,
    sdkInstance: ExperimentSDKClient.getSDKInstance<ExpTypes>(),
  };

  return reactSdk.ExperimentSDKProvider(providerProps);
};

// React hooks

/**
 * @description
 * Hook to get correctly typed Experiment SDK context throughout your application. Grants access to
 * context values like the SDK instance.
 *
 * Only call this if you NEED a direct reference to the SDK instance. Most experimentation uses
 * in MMS should not require using this and can just use the functions in experimentUtils to
 * interface with the SDK, which have additional safeguards for MMS usage.
 *
 * @returns a React Context with values of {@link typesReact.ExperimentSDKContextType}
 */
export const useExperimentSdk = (): typesReact.ExperimentSDKContextType<ExpTypes> => {
  return useContext(reactSdk.ExperimentSDKContext) as typesReact.ExperimentSDKContextType<ExpTypes>;
};

/**
 * @description
 * A React hook that returns the SDK entity's assignment for a given experiment. Can also
 * optionally fire an "Experiment Viewed" tracking event if the entity is in the experiment sample.
 * Will do nothing and return a default object with no assignment data if it detects entityIds is
 * not populated with real entity ID values.
 *
 * Is the primary method for getting assignment information.
 *
 * Can see {@link reactSdk.useAssignment} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link getAssignment} for the non-React version of this function.
 *
 * @param experimentName Required. An experiment name from the {@link ExpTypes.experimentName} enum.
 * @param trackIsInSample Required. Whether or not an "Experiment Viewed" event should be fired.
 * @param options Optional. See {@link types.GetAssignmentOptions}
 *
 * @returns an {@link typesReact.UseAssignmentResponse} object. This contains info about the hook as well as
 * assignment data. If the "assignment" prop is null, assume entity is not in experiment.
 * If it's an SDKAssignment object, check "assignmentData.isInSample" to confirm if entity is in experiment.
 */
export const useAssignment = (
  experimentName: ExpTypes['experimentName'],
  trackIsInSample: boolean,
  options?: typesReact.UseAssignmentOptions<ExpTypes>
): typesReact.UseAssignmentResponse<ExpTypes> => {
  if (!areEntityIdsPopulated()) {
    return {
      ...DEFAULT_NO_ENTITIES_RESPONSE,
      assignment: null,
    };
  }

  return reactSdk.useAssignment<ExpTypes>(experimentName, trackIsInSample, options);
};

/**
 * @description
 * A React hook that returns the SDK entity's experiment attribute value for a given attribute name. If
 * no value found in the SDK state or it detects the SDK is not using real entity ID values,
 * returns the "fallback value".
 *
 * Does NOT make a call to the API to get values; relies solely on local SDK state.
 *
 * Please see {@link reactSdk.useExperimentAttribute} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link getExperimentAttribute} for the non-React version of this function.
 *
 * @param experimentAttribute Required. An experiment attribute name from the
 * {@link ExpTypes.experimentAttributeName} enum.
 * @param fallbackValue Required. The default/fallback value if no value found in the SDK state.
 *
 * @returns a value of {@link types.ExperimentAttributeValue} type.
 */
export const useExperimentAttribute = <Key extends ExpTypes['experimentAttributeName']>(
  experimentAttribute: Key,
  fallbackValue: types.ExperimentAttributeValue & ExperimentFeatureFlags[Key]
): types.ExperimentAttributeValue & ExperimentFeatureFlags[Key] => {
  if (!areEntityIdsPopulated()) {
    return fallbackValue;
  }

  // TODO(AG-874): Remove the typecast once the SDK becomes smart enough to know the types itself
  return reactSdk.useExperimentAttribute<ExpTypes>(
    experimentAttribute,
    fallbackValue
  ) as types.ExperimentAttributeValue & ExperimentFeatureFlags[Key];
};

/**
 * @description
 * A React hook that runs the assignment logic on the SDK entity for a given experiment--that is,
 * figures out if the entity is in the experiment sample, and if so, which variant (including control) the entity
 * is in. Will do nothing and return a default object with null props if it detects
 * entityIds is not populated with real entity ID values.
 *
 * Does NOT return assignment information. Use {@link useAssignment} for that.
 *
 * Please see {@link reactSdk.useAssign} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link assign} for the non-React version of this function.
 *
 * @param experimentName Required. An experiment name from the {@link ExpTypes.experimentName} enum.
 * @param options Optional. See {@link types.AssignOptions}
 *
 * @returns {reactTypes.BasicHookResponse} An object with details on hook status and SDK errors if any.
 */
export const useAssign = (
  experimentName: ExpTypes['experimentName'],
  options?: types.AssignOptions<ExpTypes['loggerTeam']>
): typesReact.BasicHookResponse => {
  if (!areEntityIdsPopulated()) {
    return DEFAULT_NO_ENTITIES_RESPONSE;
  }

  return reactSdk.useAssign<ExpTypes>(experimentName, { timeoutMs: DEFAULT_API_TIMEOUT_MS, ...options });
};

/**
 * @description
 * A React hook that runs the assignment logic on the SDK entity for an array of allocation points--that is,
 * for each experiment associated with each of the allocation points, figures out if the entity is
 * in the experiment sample, and if so, which variant (including control) the entity is in. Will do nothing
 * and return a default object with null props if it detects entityIds is not populated with real entity ID values.
 *
 * Does NOT return assignment information. Use {@link useAssignment} for that.
 *
 * Please see {@link reactSdk.useAssignByPoints} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link assignByPoints} for the non-React version of this function.
 *
 * @param allocationPoints Required. An array of values from {@link ExpTypes.allocationPointName} enum.
 * @param options Optional. See {@link types.AssignByPointsOptions}
 *
 * @returns {reactTypes.BasicHookResponse} An object with details on hook status and SDK errors if any.
 */
export const useAssignByPoints = (
  allocationPoints: Array<ExpTypes['allocationPointName']>,
  options?: types.AssignByPointsOptions<ExpTypes['loggerTeam']>
): typesReact.BasicHookResponse => {
  if (!areEntityIdsPopulated()) {
    return DEFAULT_NO_ENTITIES_RESPONSE;
  }

  return reactSdk.useAssignByPoints<ExpTypes>(allocationPoints, { timeoutMs: DEFAULT_API_TIMEOUT_MS, ...options });
};

/**
 * @description
 * A React hook that sends an "Experiment Viewed" event via the registered Tracker
 * if the SDK's entity is in the sample of the given experiment and the "shouldFireEvent" value is true.
 * Will do nothing and return a default object with null props if it detects entityIds is not populated
 * with real entity ID values.
 *
 * Please see {@link reactSdk.useTrackIsInSample} for more details and
 * [our internal wiki](https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage)
 * for best practices using this function.
 *
 * Please see {@link trackIsInSample} for the non-React version of this function.
 *
 * @param config.experimentName Required. An experiment name from the {@link ExpTypes.experimentName} enum.
 * @param config.shouldFireEvent Optional, defaults to "true". Allows passing in a conditional to control when the
 * hook should fire.
 * @param config.customProperties Optional. Allows augmenting the event with custom properties defined in
 * {@link ExpTypes.experimentViewedProps}.
 * @param config.team Optional. The team that logs should be associated with.
 *
 * @returns {reactTypes.BasicHookResponse} An object with details on hook status and SDK errors if any.
 */
export const useTrackIsInSample = ({
  experimentName,
  shouldFireEvent,
  customProperties,
  team,
}: typesReact.UseTrackIsInSampleConfig<
  ExpTypes['experimentViewedProps'],
  ExpTypes['loggerTeam']
>): typesReact.BasicHookResponse => {
  if (!areEntityIdsPopulated()) {
    return DEFAULT_NO_ENTITIES_RESPONSE;
  }

  return reactSdk.useTrackIsInSample<ExpTypes>({ experimentName, shouldFireEvent, customProperties, team });
};

/**
 * @description
 * A utility function to check if the SDK singleton has been created. If not and in a non-FedRAMP environment,
 * an error is logged for Atlas Growth before returning the boolean value.
 */
const doesSdkExist = (): boolean => {
  if (!sdk && !isGovEnv()) {
    logError(new Error('Attempted to use SDK without creating it first'));
  }

  return !!sdk;
};

/**
 * @description
 * A utility function to check if the SDK singleton has "real" entityId values. If not,
 * an error is logged for Atlas Growth before returning the boolean value.
 */
const areEntityIdsPopulated = (): boolean => {
  if (!doesSdkExist()) {
    return false;
  }

  const isDefaultIds = JSON.stringify(sdk!.getProperties().entityIds) === JSON.stringify(PLACEHOLDER_ENTITY_IDS);

  if (isDefaultIds) {
    logError(new Error('Attempted to use SDK without updating placeholder entityIds'));
  }

  return !isDefaultIds;
};

/**
 * @description
 * A utility function to log an error to Sentry, given an error. Meant to be used to catch and log
 * SDK errors.
 */
const logError = (e: Error): void => {
  sendError({
    error: new Error(`Experiment Utils: ${e.message}`, { cause: e }),
    team: CloudTeams.AtlasGrowth,
  });
};

/**
 * @description
 * A utility function to check if the session is in a DEMOBOX environment.
 * TODO: add wiki link.
 */
const isDemoboxEnv = (): boolean => {
  return !!window.location.host.match(/ec2[\d-]+.compute-1.amazonaws.com:9080/g);
};
