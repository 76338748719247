import {
  APIKey,
  AuthProviderType,
  CreateAppFunction,
  DataAPIConfig,
  DeploymentModel,
  EndpointReturnType,
  EndpointValidationMethod,
  Location,
  MongoDBBaseRule,
  MongoDBNamespaceRule,
  PartialAPIKey,
  PartialAppFunction,
  PartialAuthProviderConfig,
  PartialBuiltinRule,
  PartialMongoDBNamespaceRule,
  PartialServiceDesc,
  PresetRole,
  ProviderRegion,
  RequestLogItem,
} from 'baas-admin-sdk';
import { Dispatch } from 'redux';

import { MongoService } from '@packages/types/baas';
import { ClusterServiceConfig, DataAPILogItem, MongoServiceConfig } from '@packages/types/dataAPI';

import { getBaasParamsFromState } from '@packages/redux/common/getBaasParams';
import { loadClusterDescriptions } from '@packages/redux/common/nds/clusterDescriptions';
import { loadDataLakesWithStorage } from '@packages/redux/common/nds/dataLakes';

import { DefaultResetFunc, readAndWriteDefaultRule } from '@packages/common/constants/dataAPI';
import applicationsService from '@packages/common/services/applicationsService';
import { createJsonLocalStorage } from '@packages/common/services/localStorage';
import { isCluster } from '@packages/common/utils/dataAPI';
import { FilterObjectConfigProps } from '@packages/common/utils/triggers/logUtil';

import { getActiveGroupId } from '../app';
import { getBaasAppId, getDataAPIState } from './selectors';

interface BaseRequestPayload {
  appId: string;
}

interface CreateAPIKeyPayload extends BaseRequestPayload {
  key: APIKey;
}

const dataAPIAppName = 'data';
const dataAPIAppProduct = 'data-api';

let localStorageInstanceForGroup;
const createLocalStorageDataAPIKeyForGroup = (groupId) => `MMS.DataAPI.${groupId}`;
const getLocalStorageForGroup = (rootState) => {
  const groupId = getActiveGroupId(rootState);
  if (!groupId) {
    return null;
  }

  const key = createLocalStorageDataAPIKeyForGroup(groupId);

  if (!localStorageInstanceForGroup || localStorageInstanceForGroup.key !== key) {
    localStorageInstanceForGroup = createJsonLocalStorage(key);
  }

  return localStorageInstanceForGroup;
};

export enum ActionType {
  // create app
  GetDataAPIAppReq = 'dataApi/GET_DATA_API_APP_REQ',
  GetDataAPIAppRcv = 'dataApi/GET_DATA_API_APP_RCV',
  GetDataAPIAppErr = 'dataApi/GET_DATA_API_APP_ERR',
  CreateDataAPIAppReq = 'dataAPI/CREATE_DATA_API_APP_REQ',
  CreateDataAPIAppRcv = 'dataAPI/CREATE_DATA_API_APP_RCV',
  CreateDataAPIAppErr = 'dataAPI/CREATE_DATA_API_APP_ERR',

  // metrics
  SyncFromLocalStorage = 'dataAPI/SYNC_FROM_LOCAL_STORAGE',

  // API keys
  GetAPIKeys = 'dataAPI/GET_API_KEYS',
  CreateAPIKey = 'dataAPI/CREATE_API_KEY',
  DeleteAPIKey = 'dataAPI/DELETE_API_KEY',

  // logs
  LoadDataAPILogsReq = 'dataAPI/LOAD_DATA_API_LOGS_REQ',
  LoadDataAPILogsRcv = 'dataAPI/LOAD_DATA_API_LOGS_RCV',
  LoadDataAPILogsErr = 'dataAPI/LOAD_DATA_API_LOGS_ERR',

  // default rules
  LoadDefaultRulesReq = 'dataAPI/LOAD_DATA_SOURCE_DEFAULT_RULES_REQ',
  LoadDefaultRulesRcv = 'dataAPI/LOAD_DATA_SOURCE_DEFAULT_RULES_RCV',
  LoadDefaultRulesErr = 'dataAPI/LOAD_DATA_SOURCE_DEFAULT_RULES_ERR',
  SetDataSourceDefaultRuleErr = 'dataAPI/SET_DATA_SOURCE_DEFAULT_RULE_ERR',
  SetDataSourceDefaultRuleRcv = 'dataAPI/SET_DATA_SOURCE_DEFAULT_RULE_RCV',
  GetPresetRoleConfigs = 'dataAPI/GET_PRESET_ROLE_CONFIGS',

  // rules
  LoadDataSourceRulesReq = 'dataAPI/LOAD_DATA_SOURCE_RULES_REQ',
  LoadDataSourceRulesRcv = 'dataAPI/LOAD_DATA_SOURCE_RULES_RCV',
  LoadDataSourceRulesErr = 'dataAPI/LOAD_DATA_SOURCE_RULES_ERR',
  DeleteDataSourceRulesRcv = 'dataAPI/DELETE_DATA_SOURCE_RULES_RCV',
  DeleteDataSourceRulesErr = 'dataAPI/DELETE_DATA_SOURCE_RULES_ERR',

  // versions
  GetVersions = 'dataAPI/GET_VERSIONS',

  // config
  GetDataAPIConfigReq = 'dataAPI/GET_DATA_API_CONFIG_REQ',
  GetDataAPIConfigRcv = 'dataAPI/GET_DATA_API_CONFIG_RCV',
  GetDataAPIConfigErr = 'dataAPI/GET_DATA_API_CONFIG_ERR',

  // auth providers
  GetAuthProvidersReq = 'dataAPI/GET_AUTH_PROVIDERS_REQ',
  GetAuthProvidersRcv = 'dataAPI/GET_AUTH_PROVIDERS_RCV',
  GetAuthProvidersErr = 'dataAPI/GET_AUTH_PROVIDERS_ERR',

  // app settings
  GetAppSPASettingsGlobal = 'dataAPI/GET_APP_SPA_SETTINGS_GLOBAL',

  // provider regions
  GetNearestProviderRegionReq = 'dataAPI/GET_NEAREST_PROVIDER_REGION_REQ',
  GetNearestProviderRegionRcv = 'dataAPI/GET_NEAREST_PROVIDER_REGION_RCV',
  GetNearestProviderRegionErr = 'dataAPI/GET_NEAREST_PROVIDER_REGION_ERR',
}

interface GetDataAPIAppReqAction {
  type: ActionType.GetDataAPIAppReq;
}

interface GetDataAPIAppRcvAction {
  type: ActionType.GetDataAPIAppRcv;
  payload: any; // baasApp
}

interface GetDataAPIAppErrAction {
  type: ActionType.GetDataAPIAppErr;
  error: {
    message: string;
  };
}

interface CreateDataAPIAppReqAction {
  type: ActionType.CreateDataAPIAppReq;
}

interface CreateDataAPIAppRcvAction {
  type: ActionType.CreateDataAPIAppRcv;
  payload: DataAPIConfig;
}

interface CreateDataAPIAppErrAction {
  type: ActionType.CreateDataAPIAppErr;
  error: {
    message: string;
  };
}

interface SyncFromLocalStorageAction {
  type: ActionType.SyncFromLocalStorage;
  payload: any;
}

interface GetAPIKeysAction {
  type: ActionType.GetAPIKeys;
  payload: Array<PartialAPIKey>;
}

interface CreateAPIKeyAction {
  type: ActionType.CreateAPIKey;
  payload: PartialAPIKey;
}

interface DeleteAPIKeyAction {
  type: ActionType.DeleteAPIKey;
  payload: string;
}

interface LoadDataAPILogsReqAction {
  type: ActionType.LoadDataAPILogsReq;
}

interface LoadDataAPILogsRcvAction {
  type: ActionType.LoadDataAPILogsRcv;
  payload: {
    logs: Array<DataAPILogItem>;
    paginationNextEndTime: Date;
    paginationCurrentSkip?: number;
  };
}

interface LoadDataAPILogsErrAction {
  type: ActionType.LoadDataAPILogsErr;
  error: {
    message: string;
  };
}

interface LoadDefaultRulesReqAction {
  type: ActionType.LoadDefaultRulesReq;
}

interface LoadDefaultRulesRcvAction {
  type: ActionType.LoadDefaultRulesRcv;
  payload: Map<string, MongoDBBaseRule>;
}

interface LoadDefaultRulesErrAction {
  type: ActionType.LoadDefaultRulesErr;
  error: {
    message: string;
  };
}

interface LoadDataSourceRulesReqAction {
  type: ActionType.LoadDataSourceRulesReq;
}

interface LoadDataSourceRulesRcvAction {
  type: ActionType.LoadDataSourceRulesRcv;
  payload: Map<string, Array<MongoDBNamespaceRule>>;
}

interface LoadDataSourceRulesErrAction {
  type: ActionType.LoadDataSourceRulesErr;
  error: {
    message: string;
  };
}

interface DeleteDataSourceRulesRcvAction {
  type: ActionType.DeleteDataSourceRulesRcv;
  payload: string;
}

interface DeleteDataSourceRulesErrAction {
  type: ActionType.DeleteDataSourceRulesErr;
  error: {
    message: string;
  };
}

interface SetDataSourceDefaultRuleErrAction {
  type: ActionType.SetDataSourceDefaultRuleErr;
  error: {
    message: string;
  };
}

interface SetDataSourceDefaultRuleRcvAction {
  type: ActionType.SetDataSourceDefaultRuleRcv;
  payload: {
    name: string;
    rule: MongoDBBaseRule;
  };
}

interface GetVersionsAction {
  type: ActionType.GetVersions;
  payload: Array<string>;
}

interface GetNearestProviderRegionReqAction {
  type: ActionType.GetNearestProviderRegionReq;
}

interface GetNearestProviderRegionRcvAction {
  type: ActionType.GetNearestProviderRegionRcv;
  payload: ProviderRegion;
}

interface GetNearestProviderRegionErrAction {
  type: ActionType.GetNearestProviderRegionErr;
}

interface GetPresetRoleConfigsAction {
  type: ActionType.GetPresetRoleConfigs;
  payload: Array<PresetRole>;
}

interface GetDataAPIConfigReqAction {
  type: ActionType.GetDataAPIConfigReq;
}

interface GetDataAPIConfigRcvAction {
  type: ActionType.GetDataAPIConfigRcv;
  payload: DataAPIConfig;
}

interface GetDataAPIConfigErrAction {
  type: ActionType.GetDataAPIConfigErr;
  error: {
    message: string;
  };
}

interface GetAuthProvidersReqAction {
  type: ActionType.GetAuthProvidersReq;
}

interface GetAuthProvidersRcvAction {
  type: ActionType.GetAuthProvidersRcv;
  payload: Array<PartialAuthProviderConfig>;
}

interface GetAuthProvidersErrAction {
  type: ActionType.GetAuthProvidersErr;
  error: {
    message: string;
  };
}

interface GetAppSPASettingsGlobalAction {
  type: ActionType.GetAppSPASettingsGlobal;
  payload: any; //App Settings
}

export type DataAPIAction =
  | GetDataAPIAppReqAction
  | GetDataAPIAppRcvAction
  | GetDataAPIAppErrAction
  | CreateDataAPIAppReqAction
  | CreateDataAPIAppRcvAction
  | CreateDataAPIAppErrAction
  | SyncFromLocalStorageAction
  | GetAPIKeysAction
  | CreateAPIKeyAction
  | DeleteAPIKeyAction
  | LoadDataAPILogsReqAction
  | LoadDataAPILogsRcvAction
  | LoadDataAPILogsErrAction
  | LoadDefaultRulesReqAction
  | LoadDefaultRulesRcvAction
  | LoadDefaultRulesErrAction
  | LoadDataSourceRulesReqAction
  | LoadDataSourceRulesRcvAction
  | LoadDataSourceRulesErrAction
  | SetDataSourceDefaultRuleErrAction
  | SetDataSourceDefaultRuleRcvAction
  | DeleteDataSourceRulesRcvAction
  | DeleteDataSourceRulesErrAction
  | GetVersionsAction
  | GetPresetRoleConfigsAction
  | GetDataAPIConfigReqAction
  | GetDataAPIConfigRcvAction
  | GetDataAPIConfigErrAction
  | GetAuthProvidersReqAction
  | GetAuthProvidersRcvAction
  | GetAuthProvidersErrAction
  | GetAppSPASettingsGlobalAction
  | GetNearestProviderRegionReqAction
  | GetNearestProviderRegionRcvAction
  | GetNearestProviderRegionErrAction;

// Action Creators
const loadApp = async (loadFn, dispatch, getState) => {
  dispatch({
    type: ActionType.GetDataAPIAppReq,
  });

  try {
    const baasApps = await loadFn(getBaasParamsFromState(getState()));
    const dataAPIApp = baasApps.find((app: any) => app.getProduct() === 'data-api');
    dispatch({
      type: ActionType.GetDataAPIAppRcv,
      payload: dataAPIApp,
    });
    return dataAPIApp;
  } catch (error) {
    dispatch({
      type: ActionType.GetDataAPIAppErr,
      error,
    });
    return;
  }
};

const reloadDataAPIApp = () => (dispatch, getState) => {
  return loadApp(applicationsService.reloadDataAPIApplication, dispatch, getState);
};

export const loadDataAPIApp = () => (dispatch, getState) => {
  return loadApp(applicationsService.loadDataAPIApplication, dispatch, getState);
};

export const enableDataAPIIfNecessary =
  (
    dataSources: Array<MongoServiceConfig>,
    deploymentModel: DeploymentModel,
    deploymentRegionLocation: Location,
    deploymentProviderRegion: ProviderRegion
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.CreateDataAPIAppReq,
    });

    let { baasApp, config, defaultRuleByDataSourceName } = getDataAPIState(getState());

    // If the data app doesn't already exist, create it
    if (!baasApp) {
      try {
        await applicationsService.createApplication({
          name: dataAPIAppName,
          deploymentModel,
          location: deploymentRegionLocation,
          providerRegion: deploymentProviderRegion,
          ...getBaasParamsFromState(getState()),
          product: dataAPIAppProduct,
        });
      } catch (error) {
        dispatch({
          type: ActionType.CreateDataAPIAppErr,
          error,
        });
        return;
      }

      try {
        await dispatch(reloadDataAPIApp());
        baasApp = getDataAPIState(getState()).baasApp;
      } catch (error) {
        dispatch({
          type: ActionType.CreateDataAPIAppErr,
          error,
        });
        return;
      }
    }

    const baasParams = getBaasParamsFromState(getState());
    const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
    const versions = getDataAPIState(getState()).versions;

    // create the Data API config if it doesn't already exist
    try {
      if ((config?.versions?.length ?? 0) === 0) {
        config = await applicationsService.createDataAPIConfig({
          ...baasParams,
          appId: baasAppId,
          newConfig: new DataAPIConfig({
            versions: [versions[versions.length - 1]],
            disabled: false,
            returnType: EndpointReturnType.JSON,
            endpointValidationMethod: EndpointValidationMethod.NoValidation,
          }),
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.CreateDataAPIAppErr,
        error,
      });
      return;
    }

    // Create the api key provider if it doesn't already exist
    try {
      let authProviders: Array<PartialAuthProviderConfig>;
      authProviders = getDataAPIState(getState()).authProviders;
      const apiKeyProviderExists = authProviders.some((provider) => provider.type === AuthProviderType.APIKey);
      const emailPassProviderExists = authProviders.some((provider) => provider.type === AuthProviderType.Userpass);

      if (!apiKeyProviderExists) {
        const apiKeyProvider = await applicationsService.enableAPIKeyProvider({ ...baasParams, appId: baasAppId });
        dispatch({
          type: ActionType.GetAuthProvidersRcv,
          payload: [...authProviders, apiKeyProvider],
        });
      }

      let resetFunc: PartialAppFunction | undefined;
      const appFunctions = await applicationsService.getFunctions({ ...baasParams, appId: baasAppId });
      resetFunc = appFunctions.find((appFunc) => appFunc.name === 'resetFunc');

      if (!emailPassProviderExists) {
        if (!resetFunc) {
          resetFunc = await applicationsService.createFunction({
            ...getBaasParamsFromState(getState()),
            appId: baasAppId,
            func: new CreateAppFunction({
              name: 'resetFunc',
              source: DefaultResetFunc,
              private: false,
            }),
          });
        }
        const emailPassProvider = await applicationsService.createEmailPassProvider({
          ...baasParams,
          appId: baasAppId,
          providerConfig: {
            autoConfirm: true,
            resetFunctionId: resetFunc.id,
            resetFunctionName: resetFunc.name,
            runConfirmationFunction: false,
            runResetFunction: true,
          },
        });

        // Refetch auth providers in the event the API Key Provider was just added
        authProviders = getDataAPIState(getState()).authProviders;
        dispatch({
          type: ActionType.GetAuthProvidersRcv,
          payload: [...authProviders, emailPassProvider],
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.CreateDataAPIAppErr,
        error,
      });
      return;
    }

    // Link clusters and create default rules for each connected data source
    try {
      const mongoServices: Array<MongoService> = await applicationsService.getMongoServices({
        ...baasParams,
        appId: baasAppId,
      });

      const createDataSourceRequests: Array<() => Promise<PartialServiceDesc>> = [];
      dataSources.forEach((dataSource) => {
        if (
          isCluster(dataSource) &&
          !mongoServices.some((mongoService: MongoService) => mongoService.name === dataSource.clusterName)
        ) {
          const clusterDataSource = dataSource as ClusterServiceConfig;
          createDataSourceRequests.push(() =>
            applicationsService.createAndLinkClusterService({
              ...getBaasParamsFromState(getState()),
              appId: baasAppId,
              serviceName: clusterDataSource.clusterName,
              clusterName: clusterDataSource.clusterName,
            })
          );
        }
      });

      await Promise.all(
        createDataSourceRequests.map(async (dataSourceRequest) => {
          const service = await dataSourceRequest();
          if (!defaultRuleByDataSourceName.has(service.name)) {
            const defaultRule: MongoDBBaseRule = await applicationsService.createServiceDefaultRule({
              ...getBaasParamsFromState(getState()),
              appId: baasAppId,
              serviceId: service.id,
              newDefaultRule: readAndWriteDefaultRule,
            });
            if (defaultRule?.id) {
              defaultRuleByDataSourceName.set(service.name, defaultRule);
            }
          }
        })
      );
    } catch (error) {
      dispatch({
        type: ActionType.CreateDataAPIAppErr,
        error,
      });
    }

    dispatch({
      type: ActionType.LoadDefaultRulesRcv,
      payload: defaultRuleByDataSourceName,
    });

    dispatch({
      type: ActionType.CreateDataAPIAppRcv,
      payload: config,
    });
  };

export const loadDataSourcesDefaultRule = (dataSources: Array<MongoService>) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.LoadDefaultRulesReq,
  });

  const defaultRuleByDataSourceName = new Map<string, MongoDBBaseRule>();

  const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
  const baasParams = getBaasParamsFromState(getState());
  await Promise.all(
    dataSources.map(async (dataSource) => {
      try {
        const defaultRule: MongoDBBaseRule = await applicationsService.getServiceDefaultRule({
          ...baasParams,
          appId: baasAppId,
          serviceId: dataSource.id,
        });
        defaultRuleByDataSourceName.set(dataSource.name, defaultRule);
      } catch (error) {
        // suppress 404s for default rule
        if (error.response?.status !== 404) {
          dispatch({
            type: ActionType.LoadDefaultRulesErr,
            error,
          });
        }
      }
    })
  );

  dispatch({
    type: ActionType.LoadDefaultRulesRcv,
    payload: defaultRuleByDataSourceName,
  });

  const localStorageForGroup = getLocalStorageForGroup(getState());
  const data = getDataAPIState(getState());
  localStorageForGroup.saveData(data);
};

export const loadDataSourceRules = (dataSources: Array<MongoService>) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.LoadDataSourceRulesReq,
  });

  const rulesByDataSourceName = new Map<string, Array<PartialMongoDBNamespaceRule | PartialBuiltinRule>>();
  const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
  const baasParams = getBaasParamsFromState(getState());

  await Promise.all(
    dataSources.map(async (dataSource) => {
      try {
        const rules = await applicationsService.getDataSourceRules({
          ...baasParams,
          appId: baasAppId,
          serviceId: dataSource.id,
        });
        rulesByDataSourceName.set(dataSource.name, rules);
      } catch (error) {
        dispatch({
          type: ActionType.LoadDataSourceRulesErr,
          error,
        });
      }
    })
  );

  dispatch({
    type: ActionType.LoadDataSourceRulesRcv,
    payload: rulesByDataSourceName,
  });

  const localStorageForGroup = getLocalStorageForGroup(getState());
  const data = getDataAPIState(getState());
  localStorageForGroup.saveData(data);
};

export const linkDataSourceAndCreateDefaultRule =
  (dataSourceName: string, presetRole: PresetRole) => async (dispatch, getState) => {
    const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
    const rule = new MongoDBBaseRule({
      roles: presetRole?.roles,
    });

    let createdRule: MongoDBBaseRule;
    try {
      const baasParams = getBaasParamsFromState(getState());
      const dataSource = await applicationsService.createAndLinkClusterService({
        ...baasParams,
        appId: baasAppId,
        clusterName: dataSourceName,
        serviceName: dataSourceName,
      });

      createdRule = await applicationsService.createServiceDefaultRule({
        ...baasParams,
        appId: baasAppId,
        serviceId: dataSource.id,
        newDefaultRule: rule,
      });
    } catch (error) {
      dispatch({
        type: ActionType.SetDataSourceDefaultRuleErr,
        error,
      });
      return;
    }

    dispatch({
      type: ActionType.SetDataSourceDefaultRuleRcv,
      payload: { name: dataSourceName, rule: createdRule },
    });
  };

export const deleteDataSourceRules = (serviceId: string, serviceName: string) => async (dispatch, getState) => {
  const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
  try {
    await applicationsService.deleteDataSourceRules({
      ...getBaasParamsFromState(getState()),
      appId: baasAppId,
      serviceId,
    });
  } catch (error) {
    // suppress 404s for no namespace rules
    if (error.response?.status !== 404) {
      dispatch({
        type: ActionType.DeleteDataSourceRulesErr,
        error,
      });
      return;
    }
  }

  dispatch({
    type: ActionType.DeleteDataSourceRulesRcv,
    payload: serviceName,
  });
};

export const updateDataSourceDefaultRule =
  (dataSource: MongoService, presetRole: PresetRole) => async (dispatch, getState) => {
    const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
    const ruleId = getDataAPIState(getState()).defaultRuleByDataSourceName.get(dataSource?.name)?.id;
    const rule = new MongoDBBaseRule({
      id: ruleId,
      roles: presetRole.roles,
    });

    try {
      await applicationsService.updateServiceDefaultRule({
        ...getBaasParamsFromState(getState()),
        appId: baasAppId,
        serviceId: dataSource?.id,
        updatedDefaultRule: rule,
      });
    } catch (error) {
      dispatch({
        type: ActionType.SetDataSourceDefaultRuleErr,
        error,
      });
      return;
    }

    dispatch({
      type: ActionType.SetDataSourceDefaultRuleRcv,
      payload: { name: dataSource?.name, rule: rule },
    });
  };

export const createDataSourceDefaultRule =
  (dataSource: MongoService, presetRole: PresetRole) => async (dispatch, getState) => {
    const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
    const rule = new MongoDBBaseRule({
      roles: presetRole.roles,
    });

    try {
      await applicationsService.createServiceDefaultRule({
        ...getBaasParamsFromState(getState()),
        appId: baasAppId,
        serviceId: dataSource?.id,
        newDefaultRule: rule,
      });
    } catch (error) {
      dispatch({
        type: ActionType.SetDataSourceDefaultRuleErr,
        error,
      });
      return;
    }

    dispatch({
      type: ActionType.SetDataSourceDefaultRuleRcv,
      payload: { name: dataSource?.name, rule: rule },
    });
  };

export const loadDataAPILogs =
  (appId: string, filter: FilterObjectConfigProps) => async (dispatch: Dispatch, getState) => {
    dispatch({
      type: ActionType.LoadDataAPILogsReq,
    });

    let payload;
    try {
      payload = await applicationsService.getLogs({
        ...getBaasParamsFromState(getState()),
        appId,
        filter: { ...filter, startDate: filter.startDate?.toDate(), endDate: filter.endDate?.toDate() },
      });
    } catch (error) {
      dispatch({
        type: ActionType.LoadDataAPILogsErr,
        error,
      });
      return;
    }

    const logs: Array<RequestLogItem> = payload.logs;
    const paginationNextEndTime = payload.nextEndDate ? new Date(payload.nextEndDate) : undefined;
    const paginationCurrentSkip = filter.skip;

    const { groupId, baasUrl } = getBaasParamsFromState(getState());
    dispatch({
      type: ActionType.LoadDataAPILogsRcv,
      payload: {
        logs: logs.map((logItem) => ({
          ...logItem,
          apiKeyId: logItem.userId,
          url: `${baasUrl}/groups/${groupId}/apps/${appId}/logs?co_id=${logItem.coId}`,
        })),
        paginationCurrentSkip,
        paginationNextEndTime,
      },
    });
  };

export const syncFromLocalStorage = () => (dispatch, getState) => {
  const localStorageForGroup = getLocalStorageForGroup(getState());
  const localStorageData = { ...localStorageForGroup.getData() };
  if (!localStorageData?.metrics && !localStorageData?.serviceDefaultRules) {
    return;
  }

  dispatch({
    type: ActionType.SyncFromLocalStorage,
    payload: localStorageData,
  });
};

export const getAPIKeys =
  ({ appId }: BaseRequestPayload) =>
  async (dispatch: Dispatch, getState) => {
    const payload: Array<PartialAPIKey> = await applicationsService.getAPIKeys({
      ...getBaasParamsFromState(getState()),
      appId,
    });
    dispatch({
      type: ActionType.GetAPIKeys,
      payload,
    });
    return payload;
  };

export const createAPIKey =
  ({ appId, key }: CreateAPIKeyPayload) =>
  async (dispatch: Dispatch, getState) => {
    const payload: APIKey = await applicationsService.createAPIKey({
      ...getBaasParamsFromState(getState()),
      appId,
      key,
    });
    dispatch({
      type: ActionType.CreateAPIKey,
      payload,
    });
    return payload;
  };

export const getLinkedDataSources = () => async (dispatch, getState) => {
  const baasAppId = getBaasAppId(getState());
  const baasParamsFromState = getBaasParamsFromState(getState());
  const mongoServices = await applicationsService.getMongoServices({ ...baasParamsFromState, appId: baasAppId });
  const loadedClusterDescriptions = await dispatch(loadClusterDescriptions(baasParamsFromState.groupId));
  const clusterDescriptions = loadedClusterDescriptions.payload;

  return { mongoServices, clusterDescriptions };
};

export const linkDataAPIDataSource = (dataSourceName: string) => async (_dispatch, getState) => {
  const baasAppId = getBaasAppId(getState());

  // Note: We assume the service created will have the same name as the data source
  // this can cause name conflicts once we support data lakes since they can have the same name
  const payload = await applicationsService.createAndLinkClusterService({
    ...getBaasParamsFromState(getState()),
    appId: baasAppId,
    serviceName: dataSourceName,
    clusterName: dataSourceName,
  });

  return payload;
};

export const getClusterDescriptions = () => async (dispatch, getState) => {
  const baasParamsFromState = getBaasParamsFromState(getState());
  const clusterDescriptions = await dispatch(loadClusterDescriptions(baasParamsFromState.groupId));
  return clusterDescriptions.payload;
};

export const getDataLakes = () => async (dispatch, getState) => {
  const dataLakes = await dispatch(loadDataLakesWithStorage());
  return dataLakes.payload;
};

export const listSvcNamespaces = (svcId) => async (dispatch, getState) => {
  const baasAppId = getBaasAppId(getState());
  const payload = await applicationsService.listSvcNamespaces({
    ...getBaasParamsFromState(getState()),
    appId: baasAppId,
    svcId,
  });
  return payload;
};

export const getDataSourceDefaultRule = (serviceId) => async (dispatch, getState) => {
  const payload = await applicationsService.getServiceDefaultRule({
    ...getBaasParamsFromState(getState()),
    appId: getBaasAppId(getState()),
    serviceId,
  });

  return payload;
};

export const loadPresetRoleConfigs = () => async (dispatch, getState) => {
  const payload = await applicationsService.getPresetRoleConfigs(getBaasParamsFromState(getState()));

  dispatch({
    type: ActionType.GetPresetRoleConfigs,
    payload,
  });
};

export const loadDataAPIVersions = () => async (dispatch, getState) => {
  const versions = await applicationsService.getDataAPIVersions(getBaasParamsFromState(getState()));

  dispatch({
    type: ActionType.GetVersions,
    payload: versions,
  });
};

export const getNearestProviderRegion = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GetNearestProviderRegionReq,
  });

  try {
    const { nearestProviderRegion } = await applicationsService.getNearestProviderRegion(
      getBaasParamsFromState(getState())
    );

    dispatch({
      type: ActionType.GetNearestProviderRegionRcv,
      payload: nearestProviderRegion,
    });
  } catch (error) {
    dispatch({
      type: ActionType.GetNearestProviderRegionErr,
    });
  }
};

export const loadDataAPIConfig = (appId: string) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GetDataAPIConfigReq,
  });

  try {
    const payload = await applicationsService.getDataAPIConfig({ ...getBaasParamsFromState(getState()), appId });

    dispatch({
      type: ActionType.GetDataAPIConfigRcv,
      payload,
    });

    return payload;
  } catch (error) {
    dispatch({
      type: ActionType.GetDataAPIConfigErr,
      error,
    });
  }
};

export const loadAuthProviders = (appId: string) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GetAuthProvidersReq,
  });

  try {
    const payload = await applicationsService.getAuthProviders({ ...getBaasParamsFromState(getState()), appId });

    dispatch({
      type: ActionType.GetAuthProvidersRcv,
      payload,
    });
  } catch (error) {
    dispatch({
      type: ActionType.GetAuthProvidersErr,
      error,
    });
  }
};

export const getAppSPASettingsGlobal = () => async (dispatch, getState) => {
  const payload = await applicationsService.getAppSPASettingsGlobal(getBaasParamsFromState(getState()));

  dispatch({
    type: ActionType.GetAppSPASettingsGlobal,
    payload,
  });
};

export const updateDataAPIConfig = (appId: string, config: DataAPIConfig) => async (dispatch, getState) => {
  await applicationsService.updateDataAPIConfig({
    ...getBaasParamsFromState(getState()),
    appId,
    updatedConfig: config,
  });
};
