import { CloudProvider } from '@packages/types/nds/provider';
import { RegionName, RegionNames } from '@packages/types/nds/region';

export const PARTITION_ATTRIBUTE_TYPES = ['string', 'int', 'isodate', 'epoch_secs', 'epoch_millis', 'objectid', 'uuid'];
export const DLS_AWS_STORE_NAME = 'aws-dls-store';
export const DLS_AZURE_STORE_NAME = 'azure-dls-store';
export const DLS_GCP_STORE_NAME = 'gcp-dls-store';
export const DLS_ONLINE_ARCHIVE_STORE_NAME = 'dls-oa-store';
export const DEFAULT_AWS_REGION: RegionName = RegionNames.US_EAST_1;
export const WILDCARD = '*';
export const DEFAULT_CLOUD_PROVIDER = CloudProvider.AWS;
export const ELIGIBLE_ONLINE_ARCHIVE_STATES = ['ACTIVE', 'PAUSING', 'PAUSED', 'ORPHANED'];
