/**
 * DataProtectionCluster corresponds to the DataProtectionClusterView response type.
 */
export interface DataProtectionCluster {
  clusterId: string;
  clusterName: string;
  clusterState: DataProtectionClusterState;
  diskBackupEnabled: boolean;
  lastSnapshotTime: string;
  nextSnapshotTime: string;
  oldestSnapshotTime: string;
  snapshotRegions: Array<string>;
  pitEnabled: boolean;
}

export enum DataProtectionClusterState {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
}
