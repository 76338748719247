import { Language } from '@leafygreen-ui/code';
import { DeploymentModel, MongoDBBaseRule, MongoDBRuleRole } from 'baas-admin-sdk';

import { LanguageOption } from '@packages/types/dataAPI';

export enum LanguageType {
  Curl = 'cURL',
  Python = 'Python',
  Java = 'Java',
  Go = 'Go',
  Node = 'Node',
  CSharp = 'C#',
}

export const languageOptions: Array<LanguageOption> = [
  {
    language: Language.Bash,
    displayName: LanguageType.Curl,
  },
  {
    language: Language.Python,
    displayName: LanguageType.Python,
  },
  {
    language: Language.Java,
    displayName: LanguageType.Java,
  },
  {
    language: Language.Go,
    displayName: LanguageType.Go,
  },
  {
    language: Language.JavaScript,
    displayName: LanguageType.Node,
  },
  {
    language: Language.Csharp,
    displayName: LanguageType.CSharp,
  },
];

export enum DataAPIVersion {
  Beta = 'beta',
  V1 = 'v1',
}

export const DataAPIVersionDisplayName = {
  [DataAPIVersion.Beta]: 'Beta',
  [DataAPIVersion.V1]: 'V1',
};

// Postman variables
// Please do NOT edit these variables unless you got them directly from the MongoDB DevRel Postman workspace
export const DATA_API_POSTMAN_WORKSPACE_ID = '17898583-25682080-e247-4d25-8e5c-1798461c7db4';
export const DATA_API_POSTMAN_COLLECTION_URL =
  'entityId=17898583-25682080-e247-4d25-8e5c-1798461c7db4&entityType=collection&workspaceId=8355a86e-dec2-425c-9db0-cb5e0c3cec02';

export enum AccessName {
  DenyAllAccess = 'denyAllAccess',
  ReadOnly = 'readAll',
  ReadAndWrite = 'readAndWriteAll',
  CustomAccess = 'customAccess',
}

export const AccessDisplayName = {
  [AccessName.DenyAllAccess]: 'No Access',
  [AccessName.ReadOnly]: 'Read Only',
  [AccessName.ReadAndWrite]: 'Read and Write',
  [AccessName.CustomAccess]: 'Custom Access',
};

export const deploymentModelDisplayNames = {
  [DeploymentModel.Global]: 'Global',
  [DeploymentModel.Local]: 'Local',
};

export const noAccessRole = new MongoDBRuleRole({
  name: 'denyAllAccess',
  applyWhen: {},
  read: false,
  write: false,
  insert: false,
  delete: false,
  search: false,
});

export const noAccessDefaultRule = new MongoDBBaseRule({
  filters: [],
  roles: [noAccessRole],
});

export const readAndWriteAccessRole = new MongoDBRuleRole({
  name: 'readAndWriteAll',
  applyWhen: {},
  fields: {},
  read: true,
  write: true,
  insert: true,
  delete: true,
  search: true,
});

export const readAndWriteDefaultRule = new MongoDBBaseRule({
  filters: [],
  roles: [readAndWriteAccessRole],
});

export enum AdvancedBaasFeature {
  DataAPISettings = 'data-api-settings',
  Rules = 'rules',
  Auth = 'auth',
  AppSettings = 'app-settings',
}

// Any changes made to the DefaultResetFunc here should also be reflected in the equivalent funciton located in baas-ui
// https://github.com/10gen/baas-ui/blob/master/src/auth/components/email-password/common.tsx#L147

export const DefaultResetFunc = `/*

    This function will be run when the client SDK 'callResetPasswordFunction' is called with an object parameter that
    contains five keys: 'token', 'tokenId', 'username', 'password', and 'currentPasswordValid'.
    'currentPasswordValid' is a boolean will be true if a user changes their password by entering their existing
    password and the password matches the actual password that is stored. Additional parameters are passed in as part
    of the argument list from the SDK.

    The return object must contain a 'status' key which can be empty or one of three string values:
      'success', 'pending', or 'fail'

    'success': the user's password is set to the passed in 'password' parameter.

    'pending': the user's password is not reset and the UserPasswordAuthProviderClient 'resetPassword' function would
      need to be called with the token, tokenId, and new password via an SDK. (see below)

      const Realm = require("realm");
      const appConfig = {
          id: "my-app-id",
          timeout: 1000,
          app: {
              name: "my-app-name",
              version: "1"
          }
        };
      let app = new Realm.App(appConfig);
      let client = app.auth.emailPassword;
      await client.resetPassword(token, tokenId, newPassword);

    'fail': the user's password is not reset and will not be able to log in with that password.

    If an error is thrown within the function the result is the same as 'fail'.

    Example below:

    exports = (
      { token, tokenId, username, password, currentPasswordValid },
      sendEmail,
      securityQuestionAnswer
    ) => {
      // process the reset token, tokenId, username and password
      if (sendEmail) {
        context.functions.execute(
          "sendResetPasswordEmail",
          username,
          token,
          tokenId
        );
        // will wait for SDK resetPassword to be called with the token and tokenId
        return { status: "pending" };
      } else if (
        context.functions.execute(
          "validateSecurityQuestionAnswer",
          username,
          securityQuestionAnswer || currentPasswordValid
        )
      ) {
        // will set the users password to the password parameter
        return { status: "success" };
      }

    The uncommented function below is just a placeholder and will result in failure.
  */

  exports = ({ token, tokenId, username, password }) => {
    console.log("Please visit the App Services UI to fully configure your password reset function");
    // will not reset the password
    return { status: 'fail' };
  };
`;
