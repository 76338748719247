import _ from 'underscore';

import { CloudProvider } from '@packages/types/nds/provider';

const BI_CONNECTOR_READ_PREFERENCE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ANALYTICS: 'analytics',
};

interface PrivateIpMode {
  enabled: boolean;
}

interface ServerlessBillingEstimateInterface {
  tier1ReadDollars: number;
  tier2ReadDollars: number;
  tier3ReadDollars: number;
  tier4ReadDollars: number;
  serverlessWriteDollars: number;
  serverlessStorageDollars: number;
}

const CLUSTER_TYPE = {
  REPLICASET: 'REPLICASET',
  SHARDED: 'SHARDED',
  GEOSHARDED: 'GEOSHARDED',
};

const ENCRYPTION_AT_REST_PROVIDER = {
  AWS: 'AWS',
  AZURE: 'AZURE',
  GCP: 'GCP',
  NONE: 'NONE',
};

const VOLUME_TYPE = {
  gp3: 'gp3',
  gp2: 'gp2',
  io1: 'io1',
  io2: 'io2',
};

const INSTANCE_CLASS = {
  LOW_CPU: 'lowCPU',
  HIGH_CPU: 'highCPU',
  NVME: 'NVMe',
};

const ROOT_CERT_TYPE = {
  DST: 'DST',
  ISRGROOTX1: 'ISRGROOTX1',
};

type ZoneColorKey = 'CYAN' | 'DEEP_BLUE' | 'DEEP_ORANGE' | 'GREEN' | 'ORANGE' | 'PINK' | 'RED' | 'SKY_BLUE' | 'VIOLET';
const ZONE_COLORS: Record<
  ZoneColorKey,
  {
    r: number;
    g: number;
    b: number;
    alphaOptimal?: number;
    alphaExcellent?: number;
    alphaAcceptable?: number;
    zoneIndex: number;
    rgb: string;
  }
> = {
  CYAN: {
    alphaOptimal: 0.86,
    alphaExcellent: 0.51,
    alphaAcceptable: 0.25,
    r: 26,
    g: 255,
    b: 216,
    zoneIndex: 0,
    rgb: '', // programmatically overwritten below
  },
  DEEP_BLUE: {
    alphaExcellent: 0.51,
    alphaAcceptable: 0.2,
    r: 53,
    g: 125,
    b: 246,
    zoneIndex: 7,
    rgb: '', // programmatically overwritten below
  },
  DEEP_ORANGE: {
    alphaOptimal: 0.89,
    alphaExcellent: 0.59,
    alphaAcceptable: 0.29,
    r: 255,
    g: 104,
    b: 29,
    zoneIndex: 6,
    rgb: '', // programmatically overwritten below
  },
  GREEN: {
    alphaOptimal: 0.88,
    alphaExcellent: 0.5,
    alphaAcceptable: 0.21,
    r: 142,
    g: 255,
    b: 30,
    zoneIndex: 5,
    rgb: '', // programmatically overwritten below
  },
  ORANGE: {
    alphaOptimal: 0.92,
    alphaExcellent: 0.54,
    alphaAcceptable: 0.23,
    r: 247,
    g: 198,
    b: 74,
    zoneIndex: 2,
    rgb: '', // programmatically overwritten below
  },
  PINK: {
    alphaOptimal: 0.91,
    alphaExcellent: 0.55,
    alphaAcceptable: 0.25,
    r: 235,
    g: 61,
    b: 123,
    zoneIndex: 4,
    rgb: '', // programmatically overwritten below
  },
  RED: {
    alphaOptimal: 0.91,
    alphaExcellent: 0.62,
    alphaAcceptable: 0.25,
    r: 233,
    g: 59,
    b: 47,
    zoneIndex: 8,
    rgb: '', // programmatically overwritten below
  },
  SKY_BLUE: {
    alphaExcellent: 0.61,
    alphaAcceptable: 0.29,
    r: 28,
    g: 217,
    b: 255,
    zoneIndex: 3,
    rgb: '', // programmatically overwritten below
  },
  VIOLET: {
    alphaOptimal: 0.82,
    alphaAcceptable: 0.23,
    r: 199,
    g: 63,
    b: 246,
    zoneIndex: 1,
    rgb: '', // programmatically overwritten below
  },
};

_.each(ZONE_COLORS, (color) => {
  color.rgb = `rgb(${color.r}, ${color.g}, ${color.b})`;
});

const filteredColors = _.pick(ZONE_COLORS, (color) => !_.isUndefined(color.zoneIndex));
const filteredColorsWithKeys = _.mapObject(filteredColors, (color, key) => ({
  key,
  zoneIndex: color.zoneIndex,
}));
const sortedColors = _.sortBy(filteredColorsWithKeys, (color) => color.zoneIndex);
const ZONE_COLORS_ORDER = _.map(sortedColors, (color) => color.key);

const CLUSTER_FORM_VALUE_NAMES = [
  'clusterType',
  'replicationSpecList',
  'geoSharding',
  'diskSizeGB',
  'biConnector',
  'encryptionAtRestProvider',
  'mongoDBMajorVersion',
  'backupEnabled',
  'diskBackupEnabled',
  'pitEnabled',
  'tenantBackupEnabled',
  'serverlessBackupOptions',
  'name',
  'rootCertType',
  'versionReleaseSystem',
  'terminationProtectionEnabled',
  'acceptDataRisksAndForceReplicaSetReconfig',
  'diskWarmingMode',
  'replicaSetScalingStrategy',
  'configServerManagementMode',
  'redactClientLogData',
] as const;

const ZONE_TERMS: Partial<Record<CloudProvider, string>> = {
  AWS: 'availability zone',
  AZURE: 'fault domains/availability zone',
  GCP: 'zone',
};

const PROVIDER_TEXT = {
  AZURE: 'Azure',
  AWS: 'AWS',
  GCP: 'GCP',
};

const NODE_TYPE = {
  ELECTABLE: 'ELECTABLE',
  READ_ONLY: 'READ_ONLY',
  ANALYTICS: 'ANALYTICS',
};

const HOURS_PER_MONTH = 720;

// these are the attributes that should be set when switching between cloud providers
// TODO<CLOUDP-127225>: maybe need to add here for serverless
const PROVIDER_ATTRIBUTES = {
  AWS: ['diskSizeGB', 'replicationSpecList', 'encryptionAtRestProvider', 'pitEnabled', 'mongoDBMajorVersion'],
  AZURE: ['diskSizeGB', 'replicationSpecList', 'encryptionAtRestProvider', 'pitEnabled', 'mongoDBMajorVersion'],
  GCP: ['diskSizeGB', 'replicationSpecList', 'encryptionAtRestProvider', 'pitEnabled', 'mongoDBMajorVersion'],
  FREE: [
    'mongoDBMajorVersion',
    'biConnector',
    'clusterType',
    'backupEnabled',
    'diskBackupEnabled',
    'encryptionAtRestProvider',
    'pitEnabled',
    'replicationSpecList',
  ],
  FLEX: [
    'mongoDBMajorVersion',
    'biConnector',
    'clusterType',
    'backupEnabled',
    'diskBackupEnabled',
    'encryptionAtRestProvider',
    'pitEnabled',
    'replicationSpecList',
  ],
} as const;

export {
  CLUSTER_FORM_VALUE_NAMES,
  ServerlessBillingEstimateInterface,
  CLUSTER_TYPE,
  ROOT_CERT_TYPE,
  ZONE_COLORS,
  ZONE_COLORS_ORDER,
  ENCRYPTION_AT_REST_PROVIDER,
  PrivateIpMode,
  INSTANCE_CLASS,
  ZONE_TERMS,
  PROVIDER_TEXT,
  NODE_TYPE,
  BI_CONNECTOR_READ_PREFERENCE,
  HOURS_PER_MONTH,
  PROVIDER_ATTRIBUTES,
  VOLUME_TYPE,
};
