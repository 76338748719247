// backend errors from CreateUser flow that we don't want to send to Sentry
const createUserFilteredBackendErrors = [
  /DUPLICATE_DATABASE_USERNAME/i,
  /USERNAME_INVALID/i,
  /USERNAME_NOT_FOUND/i,
  /PASSWORD_TOO_COMMON/i,
  /The password provided is too weak, as it can be found in most commonly used password lists/i,
  /Username.*already exists/i,
  /The username.*is invalid. Usernames can only contain ASCII letters, numbers, hyphens, and underscores, and should begin with a letter or number/i,
];

export const isCreateUserError = (errorMessage: string) => {
  return createUserFilteredBackendErrors.some((errMsg) => errorMessage.match(errMsg));
};
