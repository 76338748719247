import { AppEnv } from '@packages/types/RequestParams';

/**
 * @description
 * A utility function to check if this is a FedRAMP environment.
 */
export function isGovEnv(): boolean {
  return window?.location?.host?.includes('mongodbgov');
}

export function isAppEnvGov(currAppEnv: AppEnv | undefined): boolean {
  if (!currAppEnv) return false;
  return [AppEnv.LOCAL_GOV, AppEnv.DEV_GOV, AppEnv.QA_GOV, AppEnv.PROD_GOV].includes(currAppEnv);
}
