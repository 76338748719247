import Backbone from 'backbone';

import { StitchApp } from '../services/applicationsService';

const StitchApplication = Backbone.Model.extend(
  {
    defaults: {
      name: null,
      dataSourceNames: [],
      groupId: null,
      appId: null,
      product: null,
      serviceIdsByDataSourceName: {},
      clientAppId: null,
      deploymentModel: null,
      providerRegion: null,
      clustersLinkedToSync: [],
      environment: null,
    },

    getClustersLinkedToSync() {
      return this.get('clustersLinkedToSync');
    },

    getName() {
      return this.get('name');
    },

    getGroupId() {
      return this.get('groupId');
    },

    getDataSourceNames() {
      return this.get('dataSourceNames');
    },

    getAppId() {
      return this.get('appId');
    },

    getProduct() {
      return this.get('product');
    },

    getRedirectUrl(baseUrl) {
      return `${baseUrl}/groups/${this.getGroupId()}/apps/${this.getAppId()}`;
    },

    getServiceId(dataSourceName) {
      return this.get('serviceIdsByDataSourceName')[dataSourceName];
    },

    getClientAppId() {
      return this.get('clientAppId');
    },

    getDeploymentModel() {
      return this.get('deploymentModel');
    },

    getProviderRegion() {
      return this.get('providerRegion');
    },

    getEnvironment() {
      return this.get('environment');
    },
  },
  {
    // Take the json response from Stitch and build a backbone model
    fromApiResponse(opt: StitchApp) {
      const atlasServices = opt.atlasServices ? opt.atlasServices : [];
      const serviceIdsByDataSourceName = atlasServices.reduce((partialServiceIdsByDataSourceName, service) => {
        if (service.config) {
          partialServiceIdsByDataSourceName[service.config.clusterName || service.config.dataLakeName] = service.id;
        }
        return partialServiceIdsByDataSourceName;
      }, {});

      const clustersLinkedToSync: Array<string> = [];
      atlasServices.forEach((service) => {
        const linkedToSyncStates = ['enabled', 'disabled'];
        const clusterLinkedToSync = linkedToSyncStates.includes(service.config?.sync?.state);
        const clusterLinkedToFlexibleSync = linkedToSyncStates.includes(service.config?.flexible_sync?.state);
        if (clusterLinkedToSync || clusterLinkedToFlexibleSync) {
          if (service.config.clusterName) {
            clustersLinkedToSync.push(service.config.clusterName);
          }
        }
      });

      const { id, groupId, product, clientAppId, deploymentModel, providerRegion, name, environment } = opt;

      return new StitchApplication({
        name,
        appId: id,
        dataSourceNames: Object.keys(serviceIdsByDataSourceName),
        groupId,
        product,
        serviceIdsByDataSourceName,
        clientAppId,
        deploymentModel,
        providerRegion,
        clustersLinkedToSync: clustersLinkedToSync,
        environment,
      });
    },
  }
);

export default StitchApplication;
