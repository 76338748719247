import { Region } from '@packages/types/nds/region';
import { AuditLog as AuditLogType } from '@packages/types/nds/security/auditLog';
import { ConnectionRegistryItem, SPIFeedback, StreamInstance, StreamProcessor } from '@packages/types/nds/streams';

import fetchWrapper, { formParams } from '../fetchWrapper';

export default {
  getStreamInstances(groupId: string): Promise<Array<StreamInstance>> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getStreamInstance(groupId: string, tenantName?: string, isStreamsOwner?: boolean): Promise<StreamInstance> {
    return fetchWrapper(
      `/nds/streams/${groupId}/tenants/${tenantName}${isStreamsOwner ? '?includeConnectionDetails=true' : ''}`,
      {
        method: 'GET',
      }
    ).then((resp) => resp.json());
  },

  getStreamInstanceMetrics(groupId: string, tenantName): Promise<any> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants/${tenantName}/metrics`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getStreamInstanceProcessors(groupId: string, tenantName): Promise<Array<StreamProcessor>> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants/${tenantName}/streamProcessors`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getStreamsAuditLogs(groupId: string, tenantName, params): Promise<Response> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants/${tenantName}/auditLogs.gz?${formParams(params)}`, {
      method: 'GET',
    });
  },

  createStreamConnectionRegistryItem(
    groupId: string,
    tenantName: string,
    connection: ConnectionRegistryItem
  ): Promise<StreamInstance> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants/${tenantName}/createConnection`, {
      method: 'POST',
      body: JSON.stringify(connection),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json());
  },

  editStreamConnectionRegistryItem(
    groupId: string,
    tenantName: string,
    connection: ConnectionRegistryItem
  ): Promise<StreamInstance> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants/${tenantName}/connections`, {
      method: 'PATCH',
      body: JSON.stringify(connection),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json());
  },

  saveStreamInstance(groupId: string, streamInstance: Partial<StreamInstance>): Promise<StreamInstance> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants`, {
      method: 'POST',
      body: JSON.stringify(streamInstance),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json());
  },

  getStreamAvailableRegions(groupId: string): Promise<Array<Region>> {
    return fetchWrapper(`/nds/streams/${groupId}/regions`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteStreamConnection(groupId: string, tenantName: string, connectionName: string): Promise<void> {
    return fetchWrapper(
      `/nds/streams/${groupId}/tenants/${tenantName}/connections/${encodeURIComponent(connectionName)}`,
      {
        method: 'DELETE',
      }
    ).then(_.noop);
  },

  deleteStreamInstance(groupId: string, tenantName: string): Promise<Response> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants/${tenantName}`, {
      method: 'DELETE',
    });
  },

  // Only used to send an activity feed event, should never throw
  sendViewStreamsConnectionEvent(groupId: string, tenantName: string, connectionName: string): Promise<void> {
    return fetchWrapper(
      `/nds/streams/${groupId}/tenants/${tenantName}/connections/${encodeURIComponent(connectionName)}`,
      {
        method: 'POST',
      }
    )
      .catch(() => _.noop)
      .then(_.noop);
  },

  patchAuditLog(groupId: string, auditLog: AuditLogType) {
    return fetchWrapper(`/nds/${groupId}/streamsAuditLog`, {
      method: 'PATCH',
      body: JSON.stringify(auditLog),
    }).then((resp) => resp.json() as Promise<AuditLogType>);
  },

  getAuditLog(groupId: string) {
    return fetchWrapper(`/nds/${groupId}/streamsAuditLog`, {
      method: 'GET',
    }).then((resp) => resp.json() as Promise<AuditLogType>);
  },

  deleteAuditLogs(streamInstance: Partial<StreamInstance>) {
    return fetchWrapper(`/nds/streams/${streamInstance.groupId}/tenants/${streamInstance.name}/auditLogs`, {
      method: 'DELETE',
    }).then((resp) => resp.json() as Promise<AuditLogType>);
  },

  costEstimate(streamInstance: Partial<StreamInstance>) {
    return fetchWrapper(`/nds/streams/${streamInstance.groupId}/costEstimate`, {
      method: 'POST',
      body: JSON.stringify(streamInstance),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json());
  },

  vpcPeeringCostEstimate(streamInstance: Partial<StreamInstance>) {
    return fetchWrapper(`/nds/streams/${streamInstance.groupId}/costEstimate/vpcPeering`, {
      method: 'POST',
      body: JSON.stringify(streamInstance),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json() as Promise<number>);
  },

  submitSPIFeedback(groupId: string, feedback: SPIFeedback): Promise<void> {
    return fetchWrapper(`/nds/streams/${groupId}/spiFeedback`, {
      method: 'POST',
      body: JSON.stringify(feedback),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(_.noop);
  },
};
