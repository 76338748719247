import { AlertConfigType } from '@packages/types/alerts/alertConfigs/AlertConfig/AlertConfigType';
import { PlanType } from '@packages/types/alerts/alertConfigs/PlanType';

type GlobalSettings = $TSFixMe & {
  FEATURE_FLAGS?: Array<AlertConfigType>;
  EFFECTIVE_PLAN_TYPE?: PlanType;
};

export default {
  MONGO_VERSIONS: {},
  ProfilerV2: {
    DEFAULT_GROUPING_V26: 'plan',
    DEFAULT_GROUPING: 'shape',
    DEFAULT_START_DATE: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    DEFAULT_END_DATE: new Date(),
    DEFAULT_STAT_TYPE: 'millis',
    DEFAULT_RESOLUTION: 'hourly',
    DEFAULT_DATETIME_FORMAT: 'YYYYMMDDHH',
    ONE_HOUR: 60 * 60 * 1000,
  },

  // Global flag that can be used to enable/disable Backbone model logging
  LOG_BACKBONE_MODELS: false,
  // Global flag that can be used to enable/disable Backbone model validations
  VALIDATE_BACKBONE_MODELS: true,

  REFRESH_INTERVAL: 60000, // in ms
} as GlobalSettings;
