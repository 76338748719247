import { ClusterDescription } from '@packages/types/nds/clusterDescription';

import fetchWrapper from '@packages/common/services/api/fetchWrapper';

export default {
  patchFlexCluster(groupId, flexClusterDescriptionJson: ClusterDescription) {
    return fetchWrapper(`/nds/flex/${groupId}/${flexClusterDescriptionJson.name}`, {
      method: 'PATCH',
      body: JSON.stringify(flexClusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  postFlexCluster(groupId, flexClusterDescriptionJson: ClusterDescription, recaptchaToken = '') {
    return fetchWrapper(`/nds/flex/${groupId}?recaptchaToken=${recaptchaToken}`, {
      method: 'POST',
      body: JSON.stringify(flexClusterDescriptionJson),
    }).then((resp) => resp.json());
  },
};
