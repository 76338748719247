import { MongoDBMajorVersion } from '@packages/types/nds/mongoVersion';
import { Region, RegionName, RegionNames } from '@packages/types/nds/region';
import { SearchInstanceSize } from '@packages/types/search/decoupled/deployment';

export const GRAVITON_INSTANCE_FAMILIES = ['t4g', 'm6g', 'r6g'];

export enum CpuArchitecture {
  ARM64,
  X86_64,
}

export enum CloudProvider {
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
  FREE = 'FREE',
  SERVERLESS = 'SERVERLESS',
  FLEX = 'FLEX',
}

export type NonServerlessCloudProvider = Exclude<CloudProvider, CloudProvider.SERVERLESS>;

export enum UserFacingCloudProvider {
  AWS = 'AWS',
  AZURE = 'Azure',
  GCP = 'GCP',
  FREE = 'Tenant',
  SERVERLESS = 'Serverless',
  FLEX = 'FLEX',
}

// For matching any Cloud Provider name:
export type CloudProviderName = keyof typeof CloudProvider;

// For an array of cloud provider names:
export type CloudProviderNameList = Array<CloudProviderName>;

export enum BackingCloudProvider {
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
}

// For matching any Cloud Provider name:
export type BackingCloudProviderName = keyof typeof BackingCloudProvider;

// For an array of cloud provider names:
export type RealCloudProviderNameList = Array<BackingCloudProviderName>;

export const COMMERCIAL_BACKING_CLOUD_PROVIDERS: Array<BackingCloudProvider> = [
  BackingCloudProvider.AWS,
  BackingCloudProvider.GCP,
  BackingCloudProvider.AZURE,
];

export enum DedicatedInstanceSize {
  M10 = 'M10',
  M100 = 'M100',
  M140 = 'M140',
  M20 = 'M20',
  M200 = 'M200',
  M250 = 'M250',
  M200_NVME = 'M200_NVME',
  M30 = 'M30',
  M300 = 'M300',
  M300_NVME = 'M300_NVME',
  M40 = 'M40',
  M400 = 'M400',
  M400_NVME = 'M400_NVME',
  M40_NVME = 'M40_NVME',
  M50 = 'M50',
  M50_NVME = 'M50_NVME',
  M600_NVME = 'M600_NVME',
  M60 = 'M60',
  M60_NVME = 'M60_NVME',
  M600 = 'M600',
  M700 = 'M700',
  M80 = 'M80',
  M80_NVME = 'M80_NVME',
  M90 = 'M90',
  R200 = 'R200',
  R300 = 'R300',
  R40 = 'R40',
  R400 = 'R400',
  R50 = 'R50',
  R60 = 'R60',
  R600 = 'R600',
  R700 = 'R700',
  R80 = 'R80',
}

export enum SharedInstanceSize {
  M0 = 'M0',
  M2 = 'M2',
  M5 = 'M5',
}

export enum FlexInstanceSize {
  FLEX = 'FLEX',
}

export enum ServerlessInstanceSize {
  SERVERLESS_V2 = 'SERVERLESS_V2',
}

export const InstanceSizes = {
  ...SharedInstanceSize,
  ...DedicatedInstanceSize,
  ...ServerlessInstanceSize,
  ...FlexInstanceSize,
};

export type InstanceSize = keyof typeof InstanceSizes;

export enum AnalyticsInstanceSizes {
  SERVERLESS = 'SERVERLESS',
}

export type AnalyticsInstanceSize = keyof typeof AnalyticsInstanceSizes;

export enum InstanceClass {
  LOW_CPU = 'lowCPU',
  HIGH_CPU = 'highCPU',
  NVME = 'NVMe',
}

export interface InstanceClassProperties {
  instance: Instance | null;
  isDisabled: boolean;
  tooltip?: string;
}

export enum AvailableFamilies {
  g1 = 'g1',
  i3 = 'i3',
  m4 = 'm4',
  m5 = 'm5',
  m6g = 'm6g',
  n1 = 'n1',
  n2 = 'n2',
  n5 = 'n5',
  r4 = 'r4',
  r5 = 'r5',
  r6g = 'r6g',
  Standard_B = 'Standard_B',
  Standard_DSv2 = 'Standard_DSv2',
  Standard_DSv3 = 'Standard_DSv3',
  Standard_ESv3 = 'Standard_ESv3',
  t2 = 't2',
  t3 = 't3',
  t4g = 't4g',
}

export type AvailableFamily = keyof typeof AvailableFamilies;

export interface AvailableRegions {
  availableFamilies: Array<AvailableFamily>;
  isBlacklisted: boolean;
  providerName: CloudProviderName;
  regionName: RegionName;
  mtmMongoDBMajorVersion?: MongoDBMajorVersion;
}

export enum ProviderFeature {
  CLASS_LOW_CPU_SERIES = 'CLASS_LOW_CPU_SERIES',
  DISK_SIZES_PRECISE = 'DISK_SIZES_PRECISE', // slider
  DISK_SIZES_DISCRETE = 'DISK_SIZES_DISCRETE', // stepper for azure
  AUTOSCALING = 'AUTOSCALING',
  IOPS_PROVISIONED = 'IOPS_PROVISIONED', // for aws
  IOPS_READ_WRITE = 'IOPS_READ_WRITE', // for gcp
  IOPS_THROUGHPUT = 'IOPS_THROUGHPUT', // for azure
  CROSS_CLOUD_COMPATIBLE = 'CROSS_CLOUD_COMPATIBLE',
}

export interface InstanceGroup {
  displayName: InstanceSize;
  highCPU: Instance;
  lowCPU: Instance;
  NVMe: Instance;
}

export interface Instance {
  availableRegions: Array<AvailableRegions>;
  baseBackupStorageDollarsPerHour?: {
    [key in RegionNames]?: number;
  };
  baseDollarsPerHour: {
    [key in RegionNames]?: number;
  };
  databaseLimit?: number;
  defaultStorageGB: number;
  hasInstanceFamilies?: boolean;
  highCPUEquivalent?: InstanceSize | null;
  isDeprecated: boolean;
  isLowCPU: boolean;
  iopsPerGB?: number;
  isMetal?: boolean;
  isNVMe: boolean;
  maxAllowedStorageGB: number;
  defaultMaxAllowedStorageGB?: number;
  maxAllowedStorageGBForV2?: number;
  defaultMaxAllowedStorageGBForV2?: number;
  maxConns?: number;
  maxIOPS?: number;
  maxIOPSPerGB?: number;
  maxReadIOPS?: number;
  maxSSDReadIOPS?: number;
  maxSSDWriteIOPS?: number;
  maxStandardIOPS?: number;
  maxThroughput?: number;
  defaultDiskType?: AzureDiskType;
  maxStorageGB: number;
  maxWriteIOPS?: number;
  minIOPS?: number;
  minIOPSPerGB?: number;
  minStorageGB: number;
  name: InstanceSize;
  networkingPerformance?: string;
  operationsPerSecondLimit?: number;
  ram: number;
  ramPerInstanceFamily: {
    [key: string]: number;
  };
  readIopsPerGB?: number;
  storageOptionsGB: null | Array<number>;
  supportsSharding: boolean;
  vCPU: number;
  writeIopsPerGB?: number;
  collectionLimit?: number;
  providerFeatures?: Array<ProviderFeature>;
}

export enum AzureDiskType {
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P6 = 'P6',
  P10 = 'P10',
  P15 = 'P15',
  P20 = 'P20',
  P30 = 'P30',
  P40 = 'P40',
  P50 = 'P50',
  P60 = 'P60',
  V2 = 'V2',
}

export enum PreferredStorageType {
  V1 = 'V1',
  V2 = 'V2',
  NONE = 'NONE',
}

export interface DiskSizes {
  [diskSizeGB: number]: {
    iops: number;
    throughputMBPerSecond: number;
    type: AzureDiskType;
  };
}

/**
 * Abstract base class for providers
 * @see com.xgen.svc.nds.model.ui.ProviderOptionsView
 */
export interface ProviderOptionsView {
  '@provider': CloudProviderName;
  mongodbMajorVersions: Array<MongoDBMajorVersion>;

  /** Default MongoDB version for Continuous Delivery */
  defaultCDMongoDBVersion: string;

  /** Default MongoDB FCV for Continuous Delivery */
  defaultCDMongoDBFCV: string;

  /**
   * Maps MongoDB major version to text that is displayed alongside the version in the cluster
   * editor's MongoDB version dropdown. For example: the mapping "4.4" -> "Realm Early Access" would
   * result in "4.4 - Realm Early Access" being displayed in the cluster editor's version dropdown
   * for its 4.4 entry. If no text is specified for a MongoDB major version, then no extra text is
   * displayed alongside MongoDB version in the version dropdown.
   */
  mongoDBMajorVersionsSubtext: Record<string, string>;
  regions: Array<Region>;
}

/** @see com.xgen.svc.nds.aws.model.ui.AWSProviderOptionsView */
export interface AWSProviderOptionsView extends ProviderOptionsView {
  instanceSizes: { [key in InstanceSize]?: Instance };
  minProductionInstanceSize: InstanceSize;
  minDevelopmentInstanceSize: InstanceSize;
  minShardingInstanceSize: InstanceSize;
  searchInstanceSizes: Array<SearchInstanceSize>;
  searchSupportedRegions: Array<Region>;
  defaultSearchInstanceSizeName: string;
}

/** @see com.xgen.svc.nds.aws.model.ui.AzureProviderOptionsView */
export interface AzureProviderOptionsView extends ProviderOptionsView {
  instanceSizes: { [key in InstanceSize]?: Instance };
  diskSizes: DiskSizes;
  minProductionInstanceSize: InstanceSize;
  minDevelopmentInstanceSize: InstanceSize;
  minShardingInstanceSize: InstanceSize;
  searchInstanceSizes: Array<SearchInstanceSize>;
  searchSupportedRegions: Array<Region>;
  defaultSearchInstanceSizeName: string;
}

/** @see com.xgen.svc.nds.aws.model.ui.GCPProviderOptionsView */
export interface GCPProviderOptionsView extends ProviderOptionsView {
  instanceSizes: { [key in InstanceSize]?: Instance };
  minProductionInstanceSize: InstanceSize;
  minDevelopmentInstanceSize: InstanceSize;
  minShardingInstanceSize: InstanceSize;
  searchInstanceSizes: Array<SearchInstanceSize>;
  searchSupportedRegions: Array<Region>;
  defaultSearchInstanceSizeName: string;
}

/** @see com.xgen.svc.nds.aws.model.ui.TenantProviderOptionsView */
export interface TenantProviderOptionsView extends ProviderOptionsView {
  instanceSizes: { [key in InstanceSize]?: Instance };
}

/** @see com.xgen.svc.nds.aws.model.ui.CrossCloudProviderOptionsView */
export interface CrossCloudProviderOptionsView extends ProviderOptionsView {
  instanceSizes: { [key in InstanceSize]?: Instance };
  minProductionInstanceSize: InstanceSize;
  minDevelopmentInstanceSize: InstanceSize;
  minShardingInstanceSize: InstanceSize;
  searchInstanceSizes: Array<SearchInstanceSize>;
  searchSupportedRegions: Array<Region>;
  defaultSearchInstanceSizeName: string;
}

export interface ProviderToOptionsMapping {
  [CloudProvider.AWS]: AWSProviderOptionsView;
  [CloudProvider.AZURE]: AzureProviderOptionsView;
  [CloudProvider.GCP]: GCPProviderOptionsView;
  [CloudProvider.FREE]: TenantProviderOptionsView;
  [CloudProvider.SERVERLESS]: TenantProviderOptionsView;
  [CloudProvider.FLEX]: TenantProviderOptionsView;
}

export type Provider = ProviderToOptionsMapping[CloudProvider];
export type OptionalProvider = CloudProvider.SERVERLESS;

export type Providers = {
  [key in Exclude<CloudProvider, OptionalProvider>]: ProviderToOptionsMapping[key];
} & {
  [key in OptionalProvider]?: ProviderToOptionsMapping[key];
};

export type RegionProviderFeatures = {
  [provider in CloudProvider]?: { [regionName in RegionName as string]: Array<ProviderFeature> };
};
