import { AppEnv } from '@packages/types/RequestParams';

import { ENVIRONMENT } from '@packages/common/utils/segmentAnalytics';

import localStorageWrapper from '../services/localStorage';

const getHeliotropeEnvironment = (accountCentralUrl: string, appEnv?: AppEnv): ENVIRONMENT => {
  const locallyTestingE2E = appEnv === AppEnv.LOCAL && !!localStorageWrapper.getItem('locallyTestingE2E');

  if (accountCentralUrl === 'http://localhost:9080' || locallyTestingE2E) return ENVIRONMENT.E2E;
  if (appEnv === AppEnv.LOCAL) return ENVIRONMENT.LOCAL;
  if (appEnv === AppEnv.DEV) return ENVIRONMENT.DEV;
  if (appEnv === AppEnv.QA) return ENVIRONMENT.QA;

  return ENVIRONMENT.PROD;
};
export default getHeliotropeEnvironment;
