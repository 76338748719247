import { Action, AnyAction, configureStore, Reducer } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { State } from '@packages/test-utils/createMockStore';

export function createStore<S, A extends Action = AnyAction>(rootReducer: Reducer<S, A>, preloadedState?) {
  return configureStore<S, A>({
    reducer: rootReducer,
    preloadedState,
    middleware: [thunk], // TODO(CLOUDP-142465): Remove this and use Redux default middleware
  });
}

export function createMainReduxStore<S, A extends Action = AnyAction>(
  testReducer: Reducer<S, A>,
  preloadedState: State = {}
) {
  return createStore(testReducer, preloadedState);
}
