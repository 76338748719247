import { produce } from 'immer';

import { PersonalizationWizardForm, PersonalizationWizardResponseDb } from '@packages/types/setup/personalization';

import * as api from '@packages/common/services/api';
import { createJsonLocalStorage } from '@packages/common/services/localStorage';
import {
  createLocalStorageKeyForConnectPage,
  SELECTED_DRIVER_KEY,
  SELECTED_DRIVER_VERSION_KEY,
} from '@packages/common/utils/connectPageUtils';
import { mapSelectedLanguage } from '@packages/common/utils/programmingLanguageUtils';
import { MINUTES_IN_HOUR, SECONDS_IN_MINUTE } from '@packages/common/utils/timeUtils';

import * as backboneReduxSync from './backboneReduxSync';

const {
  handleSyncFromBackbone,
  handleSyncChangesFromBackbone,
  actionTypes: { SYNC_CHANGES_FROM_BACKBONE, SYNC_FROM_BACKBONE },
} = backboneReduxSync;

const RESET = 'settings/RESET';
const SET_AB_TEST_ASSIGNMENTS = 'settings/SET_AB_TEST_ASSIGNMENTS';
// the following 3 properties are used for the Config Service
// Feature Flag Migration, and they can be removed once it
// is complete
const SET_DISABLE_BETA_FEAURES_PAGES = 'settings/SET_DISABLE_BETA_FEAURES_PAGES';
const SET_MONITORING_PERIOD_ENABLED = 'settings/SET_MONITORING_PERIOD_ENABLED';
const SET_USE_CONFIG_SERVICE_FOR_FEATURE_FLAGS = 'settings/SET_USE_CONFIG_SERVICE_FOR_FEATURE_FLAGS';
const SET_DOUBLE_WRITE_TO_CONFIG_SERVICE_ENABLED = 'settings/SET_DOUBLE_WRITE_TO_CONFIG_SERVICE_ENABLED';
const TOGGLE_ORG_ENABLED_FEATURE_FLAGS = 'settings/TOGGLE_ORG_ENABLED_FEATURE_FLAGS';
const SET_ORG_CONTROLLED_FEATURE_FLAGS = 'settings/SET_ORG_CONTROLLED_FEATURE_FLAGS';
const SET_NON_CONFIG_SERVICE_ORG_CONTROLLED_FEATURE_FLAGS =
  'settings/SET_NON_CONFIG_SERVICE_ORG_CONTROLLED_FEATURE_FLAGS';
const SET_GROUP_CONTROLLED_FEATURE_FLAGS = 'settings/SET_GROUP_CONTROLLED_FEATURE_FLAGS';
const SET_NON_CONFIG_SERVICE_GROUP_CONTROLLED_FEATURE_FLAGS =
  'settings/SET_NON_CONFIG_SERVICE_GROUP_CONTROLLED_FEATURE_FLAGS';
const TOGGLE_GROUP_ENABLED_FEATURE_FLAGS = 'settings/TOGGLE_GROUP_CONTROLLED_FEATURE_FLAGS';
const TOGGLE_EXTENDED_STORAGE_SIZES_ENABLED = 'settings/TOGGLE_EXTENDED_STORAGE_SIZES_ENABLED';
const SET_PERSONALIZATION_WIZARD_RESPONSE = 'settings/SET_PERSONALIZATION_WIZARD_RESPONSE';
const SET_BAAS_CENTRAL_URL = 'settings/SET_BAAS_CENTRAL_URL';

const initialState = {
  appEnv: 'PROD',
  accountCentralUrl: '',
  userRegistrationEnabled: true,
  siteName: '',
  multiFactorAuthResetAllowed: false,
  docsUrl: '',
  docsSearchUrl: '',
  reCaptchaEnabledRegistration: false,
  reCaptchaEnabledLogin: false,
  reCaptchaPublic: '',
  analyticsEnabled: true,
  selfServePaymentsEnabled: true,
  emailVerificationEnabled: false,
  isLdap: false,
  isSaml: false,
  featureFlags: {
    groupEnabledFeatureFlags: [] as Array<string>,
    orgEnabledFeatureFlags: [] as Array<string>,
    groupControlledFeatureFlags: [] as Array<string>,
    nonConfigServiceGroupControlledFeatureFlags: [] as Array<string>,
    orgControlledFeatureFlags: [] as Array<string>,
    nonConfigServiceOrgControlledFeatureFlags: [] as Array<string>,
  },
  // the following 4 properties are used for the Config Service
  // Feature Flag Migration, and they can be removed once it
  // is complete
  disableBetaFeaturesPages: false,
  monitoringPeriodEnabled: false,
  useConfigServiceForFeatureFlags: false,
  doubleWriteToConfigServiceEnabled: false,
  abTestAssignments: [],
  oktaEnabled: false,
  oktaAuthRedirectBase: '',
  oktaBaseUrl: '',
  isGoogleAuthEnabled: false,
  isGithubAuthEnabled: false,
  isPersonalApiKeysPageVisible: true,
  isCloudManagerEnabled: true,
  accountMultiFactorAuthEnabled: false,
  mongoNavEnv: '',
  mmsSessionMode: 'FIXED',
  mmsIdleSessionTimeoutSeconds: 12 * MINUTES_IN_HOUR * SECONDS_IN_MINUTE,
  mmsKubernetesEnabled: false,
  mmsPushLiveMigrationsEnabled: false,
  isProjectLinkedToVercel: false,
  vercelLinkedClusters: [],
  permittedDatadogRegions: [],
  permittedPagerDutyRegions: [],
  extendedStorageSizesEnabled: false,
  personalizationWizardResponse: null,
  baasCentralUrl: '',
  baasLegacyCentralUrl: '',
  isMTMCompactionEnabled: false,
  isCreatingSamlIdpDisabled: false,
  isOidcIdpEnabledNonAtlas: false,
  isFineGrainedAuthEnabled: false,
  azureSsdV2RegionNames: [],
  isDatabaseIdpDiscoveryEnabled: false,
  isServiceAccountsFeatureEnabled: false,
  isAtlasBICOrg: false,
  isOrgTagsEnabled: false,
};

// Using typeof for a quick approximation, feel free to flesh this type out with the correct types.
export type SettingsState = typeof initialState;

// Add more fields as we need them.
const backboneToReduxKeyMapping = {
  // Mappings for Project's Settings model.
  APP_ENV: 'appEnv',
  CENTRAL_URL: 'centralUrl',
  ACCOUNT_CENTRAL_URL: 'accountCentralUrl',
  DOCS_URL: 'docsUrl',
  DOCS_SEARCH_URL: 'docsSearchUrl',
  IS_LDAP: 'isLdap',
  IS_SAML: 'isSaml',
  OKTA_ENABLED: 'oktaEnabled',
  OKTA_AUTH_REDIRECT_BASE: 'oktaAuthRedirectBase',
  OKTA_BASE_URL: 'oktaBaseUrl',
  GIT_BRANCH: 'gitBranch',
  GIT_SHORT_VERSION: 'gitShortVersion',
  PLAN_TYPE: 'planType',
  EFFECTIVE_PLAN_TYPE: 'effectivePlanType',
  GROUP_TYPE: 'groupType',
  AWS_GRAVITON_MINIMUM_MONGODB_VERSION: 'awsGravitonMinimumMongoDBVersion',
  EMBEDDED_CONFIG_MINIMUM_MONGODB_VERSION: 'embeddedConfigMinimumMongoDBVersion',
  SITE_FULL_NAME: 'siteFullName',
  SITE_NAME: 'siteName',
  PUBLISHABLE_KEY_INC: 'publishableKeyInc',
  PUBLISHABLE_KEY_LTD: 'publishableKeyLtd',
  ENABLED_FEATURE_FLAGS: 'featureFlags',
  CURRENT_IPV4_ADDR: 'currentIpv4Address',
  GROUP_HAS_ACTIVE_BACKUPS: 'hasActiveBackups',
  CAN_USE_AUTOMATION_LOG_COLLECTION: 'canUseAutomationLogCollection',
  LOG_COLLECTION_MAX_DISK_SPACE_MB: 'logCollectionMaxDiskSpaceMB',
  AB_TEST_ASSIGNMENTS: 'abTestAssignments',
  SLACK_OAUTH2_CLIENT_ID: 'slackOAuth2ClientId',
  PAGERDUTY_APP_ID: 'pagerDutyAppId',
  PERMITTED_DATADOG_REGIONS: 'permittedDatadogRegions',
  PERMITTED_PAGER_DUTY_REGIONS: 'permittedPagerDutyRegions',
  MULTI_FACTOR_AUTH_LEVEL: 'multiFactorAuthLevel',
  SUPPORTS_MULTI_FACTOR_AUTH: 'supportsMultiFactorAuth',
  ACCOUNT_MULTI_FACTOR_AUTH_ENABLED: 'accountMultiFactorAuthEnabled',
  BAAS_URL: 'baasCentralUrl',
  BAAS_LEGACY_URL: 'baasLegacyCentralUrl',
  CHARTS_URL: 'chartsCentralUrl',
  CHARTS_ACTIVATED: 'chartsActivated',
  UNIVERSITY_URL: 'universityCentralUrl',
  SEGMENT_DEFAULT_USER_ID: 'segmentDefaultUserId',
  ANALYTICS_ENABLED: 'analyticsEnabled',
  SELF_SERVE_PAYMENTS_ENABLED: 'selfServePaymentsEnabled',
  EMAIL_VERIFICATION_ENABLED: 'emailVerificationEnabled',
  MONGO_NAV_ENV: 'mongoNavEnv',
  MMS_SESSION_MODE: 'mmsSessionMode',
  MMS_IDLE_SESSION_TIME_SECONDS: 'mmsIdleSessionTimeoutSeconds',
  MMS_KUBERNETES_ENABLED: 'mmsKubernetesEnabled',
  IS_NDS_GOV_ENABLED: 'isNdsGovEnabled',
  NDS_SERVERLESS_FEATURE_ENABLED: 'isNdsServerlessEnabled',
  NDS_USS_FEATURE_ENABLED: 'isNdsUSSEnabled',
  EXTENDED_STORAGE_SIZES_ENABLED: 'extendedStorageSizesEnabled',
  NDS_SERVERLESS_ALLOW_SETUP_SERVERLESS_CREATE_TEST_INSTANCE: 'setupServerlessCreateTestInstanceAllowed',
  GROUP_ENABLED_FEATURE_FLAGS: 'featureFlags.groupEnabledFeatureFlags',
  ATLAS_CLUSTER_WEBSOCKET_CONNECTION_BASE_URL: 'atlasClusterWebsocketConnectionBaseUrl',
  MMS_PUSH_LIVE_MIGRATIONS_ENABLED: 'mmsPushLiveMigrationsEnabled',
  IS_PROJECT_LINKED_TO_VERCEL: 'isProjectLinkedToVercel',
  VERCEL_LINKED_CLUSTERS: 'vercelLinkedClusters',
  PERSONALIZATION_WIZARD_RESPONSE: 'personalizationWizardResponse',
  DATA_REGION: 'dataRegion',
  REGIONAL_CONNECTION_BASE_URL: 'regionalConnectionBaseUrl',
  ATLAS_REGIONAL_DATA_ENABLED: 'regionalDataEnabled',
  OIDC_IDP_ENABLED_NON_ATLAS: 'isOidcIdpEnabledNonAtlas',
  DARK_MODE_PREVIEW_ENABLED: 'isDarkModePreviewEnabled',
  DARK_MODE_MODAL_ENABLED: 'isDarkModeModalEnabled',
  APP_SERVICES_GUIDE_CUE_ENABLED: 'isAppServicesGuideCueEnabled',
  CLOUD_NAV_ENABLED: 'showCloudNav',
  FINE_GRAINED_AUTH_ENABLED: 'isFineGrainedAuthEnabled',
  AZURE_SSD_V2_REGION_NAMES: 'azureSsdV2RegionNames',
  SERVICE_ACCOUNT_MANAGEMENT_ENABLED: 'isServiceAccountsFeatureEnabled',
  IS_ATLAS_BIC_ORG: 'isAtlasBICOrg',
  IS_REASONS_REQUIRE_JIRA: 'isReasonsRequireJira',
  USE_NEW_CLUSTER_DRAFTS_API: 'useNewClusterDraftsApi',
  DEFAULT_CHART_EPOCH_TYPE_ID: 'defaultChartEpochTypeId',
  DEFAULT_CHART_WINDOW: 'defaultChartWindow',
  CHART_REFRESH_RATE: 'chartRefreshRate',

  // Mappings for Admin's AdminSettings model.
  emailVerificationEnabled: 'emailVerificationEnabled',
  mmsIdleSessionTimeoutSeconds: 'mmsIdleSessionTimeoutSeconds',
  mmsSessionMode: 'mmsSessionMode',
  isLdap: 'isLdap',
  isSaml: 'isSaml',
  userServiceType: 'userServiceType', // DB, Okta, LDAP, or SAML
  multiFactorAuthLevel: 'multiFactorAuthLevel',
  siteFullName: 'siteFullName',
  currentIpv4Address: 'currentIpv4Address',
  telecomSvcEnabled: 'telecomSvcEnabled',
  ndsServerlessOrganizationId: 'ndsServerlessOrganizationId',
  isNdsServerlessEnabled: 'isNdsServerlessEnabled',
  isNdsUSSEnabled: 'isNdsUSSEnabled',
  atlasClusterWebsocketConnectionBaseUrl: 'atlasClusterWebsocketConnectionBaseUrl',
  dataRegion: 'dataRegion',
  regionalConnectionBaseUrl: 'regionalConnectionBaseUrl',
  setupServerlessCreateTestInstanceAllowed: 'setupServerlessCreateTestInstanceAllowed',
  isMTMCompactionEnabled: 'isMTMCompactionEnabled',
  isReasonsRequireJira: 'isReasonsRequireJira',

  // Mappings for intercom
  INTERCOM_APP_ID: 'intercomAppId',
  INTERCOM_ENABLED: 'intercomEnabled', // Is intercom enabled for this environment
  INTERCOM_ENABLED_LOGGED_IN_PAGES: 'intercomEnabledLoggedInPages',
  INTERCOM_USER_HASH: 'intercomUserHash',
};

export default function settingsReducer(state = initialState, action) {
  return produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case RESET:
        return payload;
      case SET_AB_TEST_ASSIGNMENTS:
        draft.abTestAssignments = payload;
        break;
      case SYNC_FROM_BACKBONE:
        return handleSyncFromBackbone(draft, action, backboneToReduxKeyMapping);
      case SYNC_CHANGES_FROM_BACKBONE:
        return handleSyncChangesFromBackbone(draft, action, backboneToReduxKeyMapping);
      case SET_GROUP_CONTROLLED_FEATURE_FLAGS:
        draft.featureFlags.groupControlledFeatureFlags = payload;
        break;
      case SET_ORG_CONTROLLED_FEATURE_FLAGS:
        draft.featureFlags.orgControlledFeatureFlags = payload;
        break;
      case SET_NON_CONFIG_SERVICE_GROUP_CONTROLLED_FEATURE_FLAGS:
        draft.featureFlags.nonConfigServiceGroupControlledFeatureFlags = payload;
        break;
      case SET_NON_CONFIG_SERVICE_ORG_CONTROLLED_FEATURE_FLAGS:
        draft.featureFlags.nonConfigServiceOrgControlledFeatureFlags = payload;
        break;
      // the following 4 cases are used for the Config Service
      // Feature Flag Migration, and they can be removed once it
      // is complete
      case SET_DISABLE_BETA_FEAURES_PAGES:
        draft.disableBetaFeaturesPages = payload;
        break;
      case SET_MONITORING_PERIOD_ENABLED:
        draft.monitoringPeriodEnabled = payload;
        break;
      case SET_USE_CONFIG_SERVICE_FOR_FEATURE_FLAGS:
        draft.useConfigServiceForFeatureFlags = payload;
        break;
      case SET_DOUBLE_WRITE_TO_CONFIG_SERVICE_ENABLED:
        draft.doubleWriteToConfigServiceEnabled = payload;
        break;
      case TOGGLE_GROUP_ENABLED_FEATURE_FLAGS:
        if (payload.enabled) {
          draft.featureFlags.groupEnabledFeatureFlags = draft.featureFlags.groupEnabledFeatureFlags.filter(
            (enabledFlag) => enabledFlag !== payload.featureFlag
          );
          break;
        }
        draft.featureFlags.groupEnabledFeatureFlags.push(payload.featureFlag);
        break;
      case TOGGLE_ORG_ENABLED_FEATURE_FLAGS:
        if (payload.enabled) {
          draft.featureFlags.orgEnabledFeatureFlags = draft.featureFlags.orgEnabledFeatureFlags.filter(
            (enabledFlag) => enabledFlag !== payload.featureFlag
          );
          break;
        }
        draft.featureFlags.orgEnabledFeatureFlags.push(payload.featureFlag);
        break;
      case TOGGLE_EXTENDED_STORAGE_SIZES_ENABLED:
        draft.extendedStorageSizesEnabled = payload.enabled;
        break;
      case SET_PERSONALIZATION_WIZARD_RESPONSE:
        draft.personalizationWizardResponse = payload;
        break;
      case SET_BAAS_CENTRAL_URL:
        draft.baasCentralUrl = payload;
        break;
      default:
        return draft;
    }
  });
}

// Selectors
export const getDocsUrl = (state) => state.settings.docsUrl;
export const getDocsSearchUrl = (state) => state.settings.docsSearchUrl;
export const getGitBranch = (state) => state.settings.gitBranch;
export const getGitShortVersion = (state) => state.settings.gitShortVersion;
export const getTelecomSvcEnabled = (state) => state.settings.telecomSvcEnabled;
export const isLdap = (state) => state.settings.isLdap;
export const isSaml = (state) => state.settings.isSaml;
export const isMultiFactorAuthEnabled = (state) => state.settings.multiFactorAuthLevel !== 'OFF';
export const isMultiFactorAuthLevelRequired = (state) => state.settings.multiFactorAuthLevel === 'REQUIRED';
export const supportsMultiFactorAuth = (state) => state.settings.supportsMultiFactorAuth;
export const appEnv = (state) => state.settings.appEnv;
export const getUserServiceType = (state) => state.settings.userServiceType;
export const getCentralUrl = (state) => state.settings.centralUrl;
export const getAccountCentralUrl = (state) => state.settings.accountCentralUrl;
export const getSiteName = (state) => state.settings.siteName;
export const getSiteFullName = (state) => state.settings.siteFullName;
export const getSlackOAuth2ClientId = (state) => state.settings.slackOAuth2ClientId;
export const getPagerDutyAppId = (state) => state.settings.pagerDutyAppId;
export const getPermittedDatadogRegions = (state) => state.settings.permittedDatadogRegions;
export const getPermittedPagerDutyRegions = (state) => state.settings.permittedPagerDutyRegions;
export const getBaasCentralUrl = (state): string => state.settings.baasCentralUrl;
export const getBaasLegacyCentralUrl = (state) => state.settings.baasLegacyCentralUrl;
export const getChartsCentralUrl = (state) => state.settings.chartsCentralUrl;
export const getChartsActivated = (state) => state.settings.chartsActivated;
export const getUniversityCentralUrl = (state) => state.settings.universityCentralUrl;
export const isOktaEnabled = (state) => state.settings.oktaEnabled;
export const getOktaRedirectBase = (state) => state.settings.oktaAuthRedirectBase;
export const getOktaBaseUrl = (state) => state.settings.oktaBaseUrl;
export const getSegmentDefaultUserId = (state) => state.settings.segmentDefaultUserId;
export const getAnalyticsEnabled = (state) => state.settings.analyticsEnabled;
export const isCloudManagerEnabled = (state) => state.settings.isCloudManagerEnabled;
export const accountMultiFactorAuthEnabled = (state) => state.settings.accountMultiFactorAuthEnabled;
export const getMongoNavEnv = (state) => state.settings.mongoNavEnv;
export const getMmsSessionMode = (state) => state.settings.mmsSessionMode;
export const getMmsIdleSessionTimeoutSeconds = (state) => state.settings.mmsIdleSessionTimeoutSeconds;
export const getPersonalizationWizardResponse = (state): PersonalizationWizardResponseDb | null =>
  state.settings.personalizationWizardResponse;

export const getPlanType = (state) => state.settings.planType;
export const getEffectivePlanType = (state) => state.settings.effectivePlanType;
export const getGroupType = (state) => state.settings.groupType;
export const isNDSPlan = (state) => getGroupType(state) === 'NDS';
export const isCloudManager = (state) => getGroupType(state) === 'CLOUD';
export const isNdsGovEnabled = (state) => state.settings.isNdsGovEnabled;
export const isNdsServerlessEnabled = (state) => state.settings.isNdsServerlessEnabled;
export const isNdsUSSEnabled = (state) => state.settings.isNdsUSSEnabled;
export const isMTMCompactionEnabled = (state) => state.settings.isMTMCompactionEnabled;
export const setupServerlessCreateTestInstanceAllowed = (state) =>
  state.settings.setupServerlessCreateTestInstanceAllowed;
export const getRootCertPerEnv = (state) => state.settings.getRootCertPerEnv;
export const isSelfServePaymentsEnabled = (state) => state.settings.selfServePaymentsEnabled;
export const isEmailVerificationEnabled = (state) => state.settings.emailVerificationEnabled;
export const getPublishableKeyInc = (state) => state.settings.publishableKeyInc;
export const getPublishableKeyLtd = (state) => state.settings.publishableKeyLtd;
export const getDateFormats = (state) => state.settings.dateFormats;
export const getTimeFormats = (state) => state.settings.timeFormats;
export const getOrgControlledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.orgControlledFeatureFlags) || [];
export const getOrgEnabledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.orgEnabledFeatureFlags) || [];
export const getGroupControlledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.groupControlledFeatureFlags) || [];
export const getGroupEnabledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.groupEnabledFeatureFlags) || [];
export const getNonConfigServiceGroupControlledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.nonConfigServiceGroupControlledFeatureFlags) || [];
export const getNonConfigServiceOrgControlledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.nonConfigServiceOrgControlledFeatureFlags) || [];
// the following 3 functions are used for the Config Service
// Feature Flag Migration, and they can be removed once it
// is complete
export const getDisableBetaFeaturesPages = (state) => state.settings.disableBetaFeaturesPages;
export const getMonitoringPeriodEnabled = (state) => state.settings.monitoringPeriodEnabled;
export const getUseConfigServiceForFeatureFlags = (state) => state.settings.useConfigServiceForFeatureFlags;
export const getDoubleWriteToConfigServiceEnabled = (state) => state.settings.doubleWriteToConfigServiceEnabled;
export const getNDSServerlessOrganizationId = (state) => state.settings.ndsServerlessOrganizationId || '';
export const getSuppressMongosDiscovery = (state) => state.settings.suppressMongosAutoDiscovery;
export const isExtendedStorageSizesEnabled = (state) => state.settings.extendedStorageSizesEnabled;
export const getAtlasClusterWebsocketConnectionBaseUrl = (state) =>
  state.settings.atlasClusterWebsocketConnectionBaseUrl || '';
export const getAtlasRegionalDataEnabled = (state) =>
  hasOrgFeature(state, 'ATLAS_DATA_REGIONALIZATION_ENABLED') ||
  hasProjectFeature(state, 'ATLAS_DATA_REGIONALIZATION_ENABLED_GROUP');
export const getDataRegion = (state) => {
  return state.settings.dataRegion || '';
};
export const getRegionalConnectionBaseUrl = (state) => {
  return state.settings.regionalConnectionBaseUrl || '';
};
export const isPerformanceAdvisorExposed = (state) => hasProjectFeature(state, 'PERFORMANCE_ADVISOR');
export const isClusterCentricPerformanceAdvisorEnabled = (state) =>
  hasProjectFeature(state, 'CLUSTER_CENTRIC_PERFORMANCE_ADVISOR');

export const hasOrgFeature = (state, featureFlag) =>
  getOrgEnabledFeatureFlags(state).indexOf(featureFlag.toUpperCase()) >= 0;
export const hasProjectFeature = (state, featureFlag) =>
  getGroupEnabledFeatureFlags(state).indexOf(featureFlag.toUpperCase()) >= 0;

export const getCurrentIpv4Address = (state): string => state.settings.currentIpv4Address;
export const getCurrentIpAddress = (state) => state.settings.currentIpAddress;
export const hasActiveBackups = (state) => state.settings.hasActiveBackups;
export const canUseAutomationLogCollection = (state) => state.settings.canUseAutomationLogCollection;
export const getLogCollectionMaxDiskSpaceMB = (state) => state.settings.logCollectionMaxDiskSpaceMB;
export const getABTestAssignments = (state) => state.settings.abTestAssignments || [];

// If a free trial has expired for a customer their plan type will be free tier instead of standard
export const isFreeTrialExpired = (state) => getEffectivePlanType(state) === 'FREE_TIER';
export const isAutomationDisabled = (state) => isFreeTrialExpired(state) || !hasProjectFeature(state, 'AUTOMATION');

export const isStandardPlan = (state) => getEffectivePlanType(state) === 'STANDARD'; // Note free trial users in their first 30 days have STANDARD plans

// Captcha related selectors
export const isReCaptchaEnabledLogin = (state) => state.settings.reCaptchaEnabledLogin;
export const isReCaptchaEnabledRegistration = (state) => state.settings.reCaptchaEnabledRegistration;
export const getReCaptchaPublic = (state) => state.settings.reCaptchaPublic;

// Registration specific selectors
export const isUserRegistrationEnabled = (state) => state.settings.userRegistrationEnabled;
export const getMultiFactorAuthResetAllowed = (state) => state.settings.multiFactorAuthResetAllowed;
export const isAnalyticsEnabled = (state) => state.settings.analyticsEnabled;

// Live migration specific selectors
export const isPushLiveMigrationEnabled = (state) => state.settings.mmsPushLiveMigrationsEnabled;

// Vercel integration specific selectors
export const isProjectLinkedToVercel = (state) => state.settings.isProjectLinkedToVercel;
export const vercelLinkedClusters = (state) => state.settings.vercelLinkedClusters;

export const isZeroTouchKubernetesEnabled = (state) => state.settings.mmsKubernetesEnabled && !isNDSPlan(state);
export const isOrgCommentsEnabled = (state) => hasOrgFeature(state, 'COMMENT_SERVICE');
export const isOrgTagsEnabled = (state) => state.settings.isOrgTagsEnabled;
export const isDarkModePreviewEnabled = (state) => state.settings.isDarkModePreviewEnabled;
export const isDarkModeModalEnabled = (state) => state.settings.isDarkModeModalEnabled;
export const isAppServicesGuideCueEnabled = (state) => state.settings.isAppServicesGuideCueEnabled;
export const isCloudNavEnabled = (state) => state.settings.showCloudNav;

export const getAuid = (state) => state.settings.userAuid;

export const isCreatingSamlIdpDisabled = (state) => state.settings.isCreatingSamlIdpDisabled;

export const isOidcIdpEnabledNonAtlas = (state) => state.settings.isOidcIdpEnabledNonAtlas;

export const isFineGrainedAuthEnabled = (state) => hasOrgFeature(state, 'FINE_GRAINED_AUTH');

export const isAtlasResourcePoliciesEnabled = (state) => hasOrgFeature(state, 'ATLAS_RESOURCE_POLICIES');

export const isAzureSsdV2FeatureEnabled = (state) => hasProjectFeature(state, 'ATLAS_AZURE_SSD_PV2');
export const isAzureSsdForceV1FeatureEnabled = (state) => hasProjectFeature(state, 'ATLAS_AZURE_SSD_FORCE_PV1');
export const getAzureSsdV2RegionNames = (state) => state.settings.azureSsdV2RegionNames;

export const isDatabaseIdpDiscoveryEnabled = (state) => state.settings.isDatabaseIdpDiscoveryEnabled;

export const isServiceAccountsFeatureEnabled = (state) => hasOrgFeature(state, 'SERVICE_ACCOUNT_MANAGEMENT_ENABLED');

export const isBAASBillingMigrationEnabled = (state) => hasProjectFeature(state, 'BAAS_BILLING_MIGRATION');

export const isMongodb80Enabled = (state) => hasProjectFeature(state, 'ATLAS_MONGODB_8_0');

export const isAtlasBICOrg = (state) => state.settings.isAtlasBICOrg;

export const isReasonsRequireJira = (state): boolean => state.settings.isReasonsRequireJira;

export const getDefaultChartEpochTypeId = (state) => state.settings.defaultChartEpochTypeId;
export const getDefaultChartWindow = (state) => state.settings.defaultChartWindow;
export const getChartRefreshRate = (state) => state.settings.chartRefreshRate;

export const isAtlasFlexMigrationWarningEnabled = (state): boolean => state.settings.atlasFlexMigrationWarningEnabled;

// Action Creators
export const resetState = (payload) => ({
  type: RESET,
  payload,
});

export const setBaasCentralUrl = (payload) => ({
  type: SET_BAAS_CENTRAL_URL,
  payload,
});

export const setABTestAssignments = (payload) => ({
  type: SET_AB_TEST_ASSIGNMENTS,
  payload,
});

export const setPersonalizationWizardResponse = (payload: PersonalizationWizardResponseDb) => (dispatch) => {
  dispatch({ type: SET_PERSONALIZATION_WIZARD_RESPONSE, payload });
};

export const setGroupControlledFeatureFlags = (payload) => ({
  type: SET_GROUP_CONTROLLED_FEATURE_FLAGS,
  payload,
});

export const setOrgControlledFeatureFlags = (payload) => ({
  type: SET_ORG_CONTROLLED_FEATURE_FLAGS,
  payload,
});

export const setNonConfigServiceGroupControlledFeatureFlags = (payload) => ({
  type: SET_NON_CONFIG_SERVICE_GROUP_CONTROLLED_FEATURE_FLAGS,
  payload,
});

export const setNonConfigServiceOrgControlledFeatureFlags = (payload) => ({
  type: SET_NON_CONFIG_SERVICE_ORG_CONTROLLED_FEATURE_FLAGS,
  payload,
});

// the following 3 functions are used for the Config Service
// Feature Flag Migration, and they can be removed once it
// is complete
export const setDisableBetaFeaturesPages = (payload) => ({
  type: SET_DISABLE_BETA_FEAURES_PAGES,
  payload,
});

export const setMonitoringPeriodEnabled = (payload) => ({
  type: SET_MONITORING_PERIOD_ENABLED,
  payload,
});

export const setUseConfigServiceForFeatureFlags = (payload) => ({
  type: SET_USE_CONFIG_SERVICE_FOR_FEATURE_FLAGS,
  payload,
});

export const setDoubleWriteToConfigServiceEnabled = (payload) => ({
  type: SET_DOUBLE_WRITE_TO_CONFIG_SERVICE_ENABLED,
  payload,
});

export const toggleGroupEnabledFeatureFlags = ({ enabled, featureFlag }) => ({
  type: TOGGLE_GROUP_ENABLED_FEATURE_FLAGS,
  payload: {
    enabled,
    featureFlag,
  },
});

export const toggleOrgEnabledFeatureFlags = ({ enabled, featureFlag }) => ({
  type: TOGGLE_ORG_ENABLED_FEATURE_FLAGS,
  payload: {
    enabled,
    featureFlag,
  },
});

export const loadGroupControlledFeatureFlags = (groupId) => (dispatch) => {
  api.settings.getGroupControlledFeatureFlags(groupId).then((response) => {
    dispatch(setGroupControlledFeatureFlags(response.groupControlledFeatureFlags));
    dispatch(setNonConfigServiceGroupControlledFeatureFlags(response.nonConfigServiceGroupControlledFeatureFlags));
    // the following 4 function calls are used for the Config Service
    // Feature Flag Migration, and they can be removed once it
    // is complete
    dispatch(setDisableBetaFeaturesPages(response.disableBetaFeaturesPages));
    dispatch(setMonitoringPeriodEnabled(response.monitoringPeriodEnabled));
    dispatch(setUseConfigServiceForFeatureFlags(response.useConfigServiceForFeatureFlags));
    dispatch(setDoubleWriteToConfigServiceEnabled(response.doubleWriteToConfigServiceEnabled));
  });
};

export const loadOrgControlledFeatureFlags = (orgId) => (dispatch) => {
  api.settings.getOrgControlledFeatureFlags(orgId).then((response) => {
    dispatch(setOrgControlledFeatureFlags(response.orgControlledFeatureFlags));
    dispatch(setNonConfigServiceOrgControlledFeatureFlags(response.nonConfigServiceOrgControlledFeatureFlags));
    // the following 4 function calls are used for the Config Service
    // Feature Flag Migration, and they can be removed once it
    // is complete
    dispatch(setDisableBetaFeaturesPages(response.disableBetaFeaturesPages));
    dispatch(setMonitoringPeriodEnabled(response.monitoringPeriodEnabled));
    dispatch(setUseConfigServiceForFeatureFlags(response.useConfigServiceForFeatureFlags));
    dispatch(setDoubleWriteToConfigServiceEnabled(response.doubleWriteToConfigServiceEnabled));
  });
};

export const toggleGroupFeatureFlag =
  ({ groupId, featureFlag, enabled }) =>
  async (dispatch) => {
    try {
      dispatch(toggleGroupEnabledFeatureFlags({ featureFlag, enabled }));
      await api.settings.toggleGroupFeatureFlag({ groupId, featureFlag, enabled: !enabled });
    } catch {
      dispatch(toggleGroupEnabledFeatureFlags({ featureFlag, enabled: !enabled }));
    }
  };

export const toggleOrgFeatureFlag =
  ({ orgId, featureFlag, enabled }: { orgId: string; featureFlag: string; enabled: boolean }) =>
  async (dispatch) => {
    try {
      dispatch(toggleOrgEnabledFeatureFlags({ featureFlag, enabled }));
      await api.settings.toggleOrgFeatureFlag({ orgId, featureFlag, enabled: !enabled });
    } catch {
      dispatch(toggleOrgEnabledFeatureFlags({ featureFlag, enabled: !enabled }));
    }
  };

export const toggleGroupEditableFeatureFlag =
  ({ groupId, featureFlag, enabled }: { groupId: string; featureFlag: string; enabled: boolean }) =>
  async (dispatch) => {
    try {
      dispatch(toggleGroupEnabledFeatureFlags({ featureFlag, enabled: !enabled }));
      await api.settings.toggleEditableFeatureFlag({ groupId, featureFlag, enabled });
    } catch {
      dispatch(toggleGroupEnabledFeatureFlags({ featureFlag, enabled }));
    }
  };

export const toggleExtendedStorageSizesEnabled =
  ({ groupId, enabled }) =>
  async (dispatch) => {
    const response = await api.settings.toggleExtendedStorageSizesEnabled({ groupId, enabled });

    dispatch({
      type: TOGGLE_EXTENDED_STORAGE_SIZES_ENABLED,
      meta: { groupId },
      payload: {
        enabled: response.value,
      },
    });
  };

export const upsertPersonalizationWizardResponse =
  (groupId: string, personalizationWizardResponse: PersonalizationWizardForm) => async (dispatch, getState) => {
    // If cloudUserId field exists in response body, delete before submitting to BE
    delete personalizationWizardResponse?.cloudUserId;
    const previousLanguage = getPersonalizationWizardResponse(getState())?.selectedLanguage;
    const selectedLanguage = personalizationWizardResponse?.selectedLanguage;
    selectedLanguage &&
      (personalizationWizardResponse.selectedLanguage = mapSelectedLanguage(selectedLanguage, 'toValue'));

    const response = await api.nds.personalizationWizard.upsertPersonalizationWizardForm(
      groupId,
      personalizationWizardResponse
    );

    // Maintain display value for selectedLanguage
    selectedLanguage && (response.selectedLanguage = selectedLanguage);
    dispatch({ type: SET_PERSONALIZATION_WIZARD_RESPONSE, payload: response });

    // If selectedLanguage changes, clear the Connect Application Settings LS, so the Connect driver will default to this newly selected language
    if (selectedLanguage !== previousLanguage) {
      const key = `MMS.ConnectApplicationSettings.${groupId}`;
      const connectApplicationLsWrapper = createJsonLocalStorage(key);
      const connectPageLsWrapper = createJsonLocalStorage(createLocalStorageKeyForConnectPage(groupId));
      connectApplicationLsWrapper.saveData({});

      // Reset saved driver version displayed in connect page, so it falls back to PW value
      connectPageLsWrapper.removeItem(SELECTED_DRIVER_KEY);
      connectPageLsWrapper.removeItem(SELECTED_DRIVER_VERSION_KEY);
    }
  };

export const hasDecoupledSearchFeatureEnabled = (state) => hasProjectFeature(state, 'DEDICATED_ATLAS_SEARCH_NODES');
export const hasDecoupledSearchMigrationEnabled = (state) =>
  hasProjectFeature(state, 'DEDICATED_ATLAS_SEARCH_NODES_MIGRATION');

export const hasDedicatedSearchNodesGCPEnabled = (state) =>
  hasProjectFeature(state, 'DEDICATED_ATLAS_SEARCH_NODES_GCP');

export const hasDedicatedSearchNodesAzureEnabled = (state) =>
  hasProjectFeature(state, 'DEDICATED_ATLAS_SEARCH_NODES_AZURE');

export const hasDedicatedSearchNodesMultiRegionEnabled = (state) =>
  hasProjectFeature(state, 'DEDICATED_ATLAS_SEARCH_NODES_FOR_MULTI_REGIONS');

export const hasDedicatedSearchNodesForShardedClusterEnabled = (state) =>
  hasProjectFeature(state, 'DEDICATED_ATLAS_SEARCH_NODES_FOR_SHARDED_CLUSTER');

// Embedded Config
export const getEmbeddedConfigMinimumMongoDBVersion = (state) => state.settings.embeddedConfigMinimumMongoDBVersion;
export const hasAtlasAutomaticEmbeddedConfigTransitionsEnabled = (state) =>
  hasProjectFeature(state, 'ATLAS_AUTOMATIC_EMBEDDED_CONFIG_TRANSITIONS');
