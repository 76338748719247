import { getOrgData } from '.';
import { createReducer } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { groupBy } from 'underscore';

import { HydratedProject } from '@packages/types/access';
import { ResourceType } from '@packages/types/auth/fga';
import { ResourceView } from '@packages/types/resourceView';

import * as api from '@packages/common/services/api';

// Actions
const SET_RESOURCES = 'organization/SET_RESOURCES';

// Reducer
interface State {
  resources: Array<ResourceView>;
  hydratedProjects: Array<HydratedProject>;
}

const initialState: State = {
  resources: [],
  hydratedProjects: [],
};

export default createReducer(initialState, {
  [SET_RESOURCES]: (state: State, action: { payload: State }) => action.payload,
});

// Selectors
export const getData = (state, props: { orgId: string }) => {
  const orgData = getOrgData(state, props);
  return orgData?.resources || initialState;
};

export const getResources = (state, props: { orgId: string }): Array<ResourceView> => {
  const data = getData(state, props);
  return data?.resources || [];
};

export const getHydratedProjects = (state, props: { orgId: string }): Array<HydratedProject> => {
  const data = getData(state, props);
  return data?.hydratedProjects || [];
};

// Action Creators
export const setResources = ({ orgId, resources }: { orgId: string; resources: Array<ResourceView> }) => {
  return {
    type: SET_RESOURCES,
    payload: {
      resources,
      hydratedProjects: generateHydratedProjects(resources),
    },
    meta: {
      orgId,
    },
  };
};

export const loadAllOrgResources = (orgId: string) => (dispatch: Dispatch) => {
  return api.organization.allResources({ orgId }).then((response) => {
    dispatch(
      setResources({
        resources: response,
        orgId,
      })
    );
  });
};

function generateHydratedProjects(resources: Array<ResourceView>) {
  const typeToResourcesMap = groupBy(resources, (r) => r.resourceType);
  const projectIdToClustersMap = groupBy(typeToResourcesMap[ResourceType.CLUSTER], (r) => r.parentResourceId);

  const projects = (typeToResourcesMap[ResourceType.PROJECT] || []).map((project) => ({
    id: project.resourceId,
    name: project.resourceName,
    deployments: (projectIdToClustersMap[project.resourceId] || []).map((cluster) => ({
      id: cluster.resourceId,
      name: cluster.resourceName,
    })),
  }));

  return projects;
}
