import fetchWrapper from '../fetchWrapper';

export function deleteBlacklistedInstanceSize(provider: string, region: string, instanceSize: string) {
  return fetchWrapper(`/admin/nds/${provider}/blacklistedInstanceSizes/${region}/${instanceSize}`, {
    method: 'DELETE',
  });
}

export const getDenylistForProvider = (provider: string) => {
  return fetchWrapper(`/admin/nds/${provider}/blacklistedInstanceSizes`, {
    method: 'GET',
  }).then((resp) => resp.json());
};

export const getGroupCapacityFailures = () => {
  return fetchWrapper('/admin/nds/groups/capacityFailures', {
    method: 'GET',
  }).then((resp) => resp.json());
};

export const getOpenAvailabilityZoneIssues = () => {
  return fetchWrapper('/admin/nds/openAvailabilityZoneIssues', {
    method: 'GET',
  }).then((resp) => resp.json());
};
