import {
  CostExplorerFilterOptions,
  CostExplorerQueryState,
  UsageDetailsFilterOptions,
  UsageDetailsPaginationMetadata,
  UsageDetailsQueryState,
} from '@packages/types/billing';

import * as api from '@packages/common/services/api';

import * as invoice from '../invoice';

// actions
const SET_PLAN = 'organization/billing/SET_PLAN';
const SET_PREPAID_PLANS = 'organization/billing/SET_PREPAID_PLANS';
const SET_INVOICES = 'organization/billing/SET_INVOICES';
const SET_LINKED_INVOICES = 'organization/billing/SET_LINKED_INVOICES';
const SET_PAYMENT_METHODS = 'organization/billing/SET_PAYMENT_METHODS';
const SET_BRAINTREE_CUSTOMER = 'organization/billing/SET_BRAINTREE_CUSTOMER';
const SET_COMPANY_ADDRESS = 'organization/billing/SET_COMPANY_ADDRESS';
const SET_ORG_PREFERENCES = 'organization/billing/SET_ORG_PREFERENCES';
const SET_PENDING_INVOICE = 'organization/billing/SET_PENDING_INVOICE';
const SET_RECENT_INVOICE = 'organization/billing/SET_RECENT_INVOICE';
const SET_AVAILABLE_CREDITS = 'organization/billing/SET_AVAILABLE_CREDITS';
const SET_CREDITS = 'organization/billing/SET_CREDITS';
const SET_HISTORICAL_CREDITS = 'organization/billing/SET_HISTORICAL_CREDITS';
const SET_PENDING_CREDITS = 'organization/billing/SET_PENDING_CREDITS';
const SET_MONTHLY_COMMITMENT_CREDITS = 'organization/billing/SET_MONTHLY_COMMITMENT_CREDITS';
const SET_DISCOUNTS = 'organization/billing/SET_DISCOUNTS';
const SET_HISTORIC_DISCOUNTS = 'organization/billing/SET_HISTORIC_DISCOUNTS';
const SET_BILLING_STATUS = 'organization/billing/SET_BILLING_STATUS';
const SET_USAGE_SUMMARY = 'organization/billing/SET_USAGE_SUMMARY';
const SET_CURRENT_ORG_VIEW = 'organization/billing/SET_CURRENT_ORG_VIEW';
const SET_LINKABLE_ORGANIZATIONS = 'organization/billing/SET_LINKABLE_ORGANIZATIONS';
const SET_LINKED_ORGANIZATIONS = 'organization/billing/SET_LINKED_ORGANIZATIONS';
const SET_PAYING_ORGANIZATION = 'organization/billing/SET_PAYING_ORGANIZATION';
const SET_IS_ELIGIBLE_TO_BECOME_PAYING_ORG = 'organization/billing/SET_IS_ELIGIBLE_TO_BECOME_PAYING_ORG';
const SET_CONSULTING_UNIT_PRICE = 'organization/billing/SET_CONSULTING_UNIT_PRICE';
const SET_CONSULTING_DEPTHS = 'organization/billing/SET_CONSULTING_DEPTHS';
const SET_CROSS_ORG_TOTAL = 'organization/billing/SET_CROSS_ORG_TOTAL';
const SET_MONTHLY_AGGREGATED_USAGE_SUMMARY = 'organization/billing/SET_MONTHLY_AGGREGATED_USAGE_SUMMARY';
const SET_ORGANIZATION_SALESFORCE_METADATA = 'organization/billing/SET_ORGANIZATION_SALESFORCE_METADATA';
export const SET_COST_EXPLORER_FILTER_OPTIONS = 'organization/billing/SET_COST_EXPLORER_FILTER_OPTIONS';
export const SET_COST_EXPLORER_QUERY_RESULTS = 'organization/billing/SET_COST_EXPLORER_QUERY_RESULTS';
export const SET_USAGE_DETAILS_FILTER_OPTIONS = 'organization/billing/SET_USAGE_DETAILS_FILTER_OPTIONS';
export const SET_USAGE_DETAILS_QUERY_STATE = 'organization/billing/SET_USAGE_DETAILS_QUERY_STATE';
export const SET_USAGE_DETAILS_PAGINATION_METADATA = 'organization/billing/SET_USAGE_DETAILS_PAGINATION_METADATA';
const INVOICE_ID_FIELD = 'invoiceId';

// Reducer
const initialState = {};

export default function organizationBillingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PLAN: {
      return {
        ...state,
        plan: action.payload,
      };
    }
    case SET_PREPAID_PLANS: {
      return {
        ...state,
        prepaidPlans: action.payload,
      };
    }
    case SET_INVOICES: {
      return {
        ...state,
        invoices: action.payload,
      };
    }
    case SET_LINKED_INVOICES: {
      return {
        ...state,
        linkedInvoices: action.payload,
      };
    }
    case SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: action.payload,
      };
    }
    case SET_COMPANY_ADDRESS: {
      return {
        ...state,
        companyAddress: action.payload,
      };
    }
    case SET_ORG_PREFERENCES: {
      return {
        ...state,
        orgPreferences: action.payload,
      };
    }
    case SET_PENDING_INVOICE: {
      return {
        ...state,
        pendingInvoice: action.payload,
      };
    }
    case SET_RECENT_INVOICE: {
      return {
        ...state,
        recentInvoice: action.payload,
      };
    }
    case SET_CREDITS: {
      return {
        ...state,
        credits: action.payload,
      };
    }
    case SET_AVAILABLE_CREDITS: {
      return {
        ...state,
        availableCredits: action.payload,
      };
    }
    case SET_HISTORICAL_CREDITS: {
      return {
        ...state,
        historicalCredits: action.payload,
      };
    }
    case SET_PENDING_CREDITS: {
      return {
        ...state,
        pendingCredits: action.payload,
      };
    }
    case SET_MONTHLY_COMMITMENT_CREDITS: {
      return {
        ...state,
        monthlyCommitmentCredits: action.payload,
      };
    }
    case SET_DISCOUNTS: {
      return {
        ...state,
        discounts: action.payload,
      };
    }
    case SET_HISTORIC_DISCOUNTS: {
      return {
        ...state,
        historicDiscounts: action.payload,
      };
    }
    case SET_BILLING_STATUS: {
      return {
        ...state,
        billingStatus: action.payload,
      };
    }
    case SET_USAGE_SUMMARY: {
      return {
        ...state,
        usageSummary: action.payload,
      };
    }
    case SET_BRAINTREE_CUSTOMER: {
      return {
        ...state,
        braintreeCustomer: action.payload,
      };
    }
    case SET_CURRENT_ORG_VIEW: {
      return {
        ...state,
        currentOrgView: action.payload,
      };
    }
    case SET_LINKABLE_ORGANIZATIONS: {
      return {
        ...state,
        linkableOrganizations: action.payload,
      };
    }
    case SET_LINKED_ORGANIZATIONS: {
      return {
        ...state,
        linkedOrganizations: action.payload,
      };
    }
    case SET_PAYING_ORGANIZATION: {
      return {
        ...state,
        payingOrganization: action.payload,
      };
    }
    case SET_IS_ELIGIBLE_TO_BECOME_PAYING_ORG: {
      return {
        ...state,
        isEligibleToBecomePayingOrg: action.payload,
      };
    }
    case SET_CONSULTING_UNIT_PRICE: {
      return {
        ...state,
        consultingUnitPrice: action.payload,
      };
    }
    case SET_CONSULTING_DEPTHS: {
      return {
        ...state,
        consultingDepths: action.payload,
      };
    }
    case SET_CROSS_ORG_TOTAL: {
      return {
        ...state,
        crossOrgTotal: action.payload,
      };
    }
    case SET_MONTHLY_AGGREGATED_USAGE_SUMMARY: {
      return {
        ...state,
        monthlyAggregatedUsageSummary: action.payload,
      };
    }
    case SET_ORGANIZATION_SALESFORCE_METADATA: {
      return {
        ...state,
        organizationSalesforceMetadata: action.payload,
      };
    }
    case SET_COST_EXPLORER_FILTER_OPTIONS: {
      return {
        ...state,
        costExplorerFilterOptions: action.payload,
      };
    }
    case SET_COST_EXPLORER_QUERY_RESULTS: {
      return {
        ...state,
        costExplorerQueryState: action.payload,
      };
    }
    case SET_USAGE_DETAILS_FILTER_OPTIONS: {
      const invoiceId = action.meta[INVOICE_ID_FIELD];
      const previousUsageDetails = getStateUsageDetails(state, invoiceId);
      return {
        ...state,
        invoice: {
          [invoiceId]: {
            usageDetails: {
              ...previousUsageDetails,
              usageDetailsFilterOptions: action.payload,
            },
          },
        },
      };
    }
    case SET_USAGE_DETAILS_QUERY_STATE: {
      const invoiceId = action.meta[INVOICE_ID_FIELD];
      const previousUsageDetails = getStateUsageDetails(state, invoiceId);
      return {
        ...state,
        invoice: {
          [invoiceId]: {
            usageDetails: {
              ...previousUsageDetails,
              usageDetailsQueryState: action.payload,
            },
          },
        },
      };
    }
    case SET_USAGE_DETAILS_PAGINATION_METADATA: {
      const invoiceId = action.meta[INVOICE_ID_FIELD];
      const previousUsageDetails = getStateUsageDetails(state, invoiceId);
      return {
        ...state,
        invoice: {
          [invoiceId]: {
            usageDetails: {
              ...previousUsageDetails,
              usageDetailsPaginationMetadata: action.payload,
            },
          },
        },
      };
    }

    default:
      return state;
  }
}

// Selectors

// TODO(hswolff): De-dupe from organization/index.js once we move to es modules
const getOrgData = (state, props) => state.organization[props.orgId] || {};

const getData = (state, props) => {
  const orgData = getOrgData(state, props);
  orgData.billing = orgData.billing || {};
  return orgData.billing;
};

const getInvoiceIds = (state, props) => getData(state, props).invoices || [];

export const getHydratedInvoices = (state, props) =>
  getInvoiceIds(state, props).map((invoiceId) => invoice.getInvoice(state, { invoiceId }));

const getLinkedInvoiceIds = (state, props) => getData(state, props).linkedInvoices || [];

export const getLinkedInvoices = (state, props) =>
  getLinkedInvoiceIds(state, props).map((invoiceId) => invoice.getInvoice(state, { invoiceId }));

export const getPlan = (state, props) => getData(state, props).plan || null;
export const getPaymentMethods = (state, props) => getData(state, props).paymentMethods || [];
export const getCompanyAddress = (state, props) => getData(state, props).companyAddress || {};
export const getPendingInvoice = (state, props) => getData(state, props).pendingInvoice || null;
export const getRecentInvoice = (state, props) => getData(state, props).recentInvoice || null;
export const getCredits = (state, props) => getData(state, props).credits || [];
export const getMonthlyCommitmentCredits = (state, props) => getData(state, props).monthlyCommitmentCredits || [];
export const getDiscounts = (state, props) => getData(state, props).discounts || [];
export const getUsageSummary = (state, props) => getData(state, props).usageSummary || null;
export const getBraintreeCustomer = (state, props) => getData(state, props).braintreeCustomer || null;
export const getCurrentOrgView = (state, props) => getData(state, props).currentOrgView || null;
export const getLinkableOrganizations = (state, props) => getData(state, props).linkableOrganizations || null;
export const getLinkedOrganizations = (state, props) => getData(state, props).linkedOrganizations || null;
export const getPayingOrganization = (state, props) => getData(state, props).payingOrganization || null;
export const getIsEligibleToBecomePayingOrg = (state, props) =>
  getData(state, props).isEligibleToBecomePayingOrg || null;
export const getMonthlyAggregatedUsageSummary = (state, props) =>
  getData(state, props).monthlyAggregatedUsageSummary || null;

const getCreditActionType = (filter) => {
  switch (filter) {
    case 'available':
      return SET_AVAILABLE_CREDITS;
    case 'historical':
      return SET_HISTORICAL_CREDITS;
    case 'pending':
      return SET_PENDING_CREDITS;
    default:
      return SET_CREDITS;
  }
};

// Action Creators

const setPlan = ({ plan, orgId }) => ({
  type: SET_PLAN,
  payload: plan,
  meta: {
    orgId,
  },
});

const setPrepaidPlans = ({ prepaidPlans, orgId }) => ({
  type: SET_PREPAID_PLANS,
  payload: prepaidPlans,
  meta: {
    orgId,
  },
});

const setInvoices = ({ invoices, orgId }) => ({
  type: SET_INVOICES,
  payload: invoices,
  meta: {
    orgId,
  },
});

const setLinkedInvoices = ({ linkedInvoices, orgId }) => ({
  type: SET_LINKED_INVOICES,
  payload: linkedInvoices,
  meta: {
    orgId,
  },
});

const setPaymentMethods = ({ paymentMethods, orgId }) => ({
  type: SET_PAYMENT_METHODS,
  payload: paymentMethods,
  meta: {
    orgId,
  },
});

const setCompanyAddress = ({ companyAddress, orgId }) => ({
  type: SET_COMPANY_ADDRESS,
  payload: companyAddress,
  meta: {
    orgId,
  },
});

const setOrgPreferences = ({ orgPreferences, orgId }) => ({
  type: SET_ORG_PREFERENCES,
  payload: orgPreferences,
  meta: {
    orgId,
  },
});

const setPendingInvoice = ({ pendingInvoice, orgId }) => ({
  type: SET_PENDING_INVOICE,
  payload: pendingInvoice,
  meta: {
    orgId,
  },
});

const setRecentInvoice = ({ recentInvoice, orgId }) => ({
  type: SET_RECENT_INVOICE,
  payload: recentInvoice,
  meta: {
    orgId,
  },
});

const setCredits = ({ credits, orgId, filter }) => ({
  type: getCreditActionType(filter),
  payload: credits,
  meta: {
    orgId,
  },
});

const setMonthlyCommitmentCredits = ({ monthlyCommitmentCredits, orgId }) => ({
  type: SET_MONTHLY_COMMITMENT_CREDITS,
  payload: monthlyCommitmentCredits,
  meta: {
    orgId,
  },
});

const setDiscounts = ({ discounts, orgId }) => ({
  type: SET_DISCOUNTS,
  payload: discounts,
  meta: {
    orgId,
  },
});

const setHistoricDiscounts = ({ historicDiscounts, orgId }) => ({
  type: SET_HISTORIC_DISCOUNTS,
  payload: historicDiscounts,
  meta: {
    orgId,
  },
});

export {
  getData,
  setPlan,
  setInvoices,
  setLinkedInvoices,
  setPaymentMethods,
  setCompanyAddress,
  setPendingInvoice,
  setRecentInvoice,
  setCredits,
  setMonthlyCommitmentCredits,
  setDiscounts,
  setMonthlyAggregatedUsageSummary,
};

const setBillingStatus = ({ billingStatus, orgId }) => ({
  type: SET_BILLING_STATUS,
  payload: billingStatus,
  meta: {
    orgId,
  },
});

const setUsageSummary = ({ usageSummary, orgId }) => ({
  type: SET_USAGE_SUMMARY,
  payload: usageSummary,
  meta: {
    orgId,
  },
});

const setCrossOrgTotal = ({ crossOrgTotal, orgId }) => ({
  type: SET_CROSS_ORG_TOTAL,
  payload: crossOrgTotal,
  meta: {
    orgId,
  },
});

const setMonthlyAggregatedUsageSummary = ({ monthlyAggregatedUsageSummary, orgId }) => ({
  type: SET_MONTHLY_AGGREGATED_USAGE_SUMMARY,
  payload: monthlyAggregatedUsageSummary,
  meta: {
    orgId,
  },
});

export const setBraintreeCustomer = ({ braintreeCustomer, orgId }) => ({
  type: SET_BRAINTREE_CUSTOMER,
  payload: braintreeCustomer,
  meta: {
    orgId,
  },
});

export const setCurrentOrgView = ({ currentOrgView, orgId }) => ({
  type: SET_CURRENT_ORG_VIEW,
  payload: currentOrgView,
  meta: {
    orgId,
  },
});

export const setLinkableOrganizations = ({ linkableOrganizations, orgId }) => ({
  type: SET_LINKABLE_ORGANIZATIONS,
  payload: linkableOrganizations,
  meta: {
    orgId,
  },
});

export const setLinkedOrganizations = ({ linkedOrganizations, orgId }) => ({
  type: SET_LINKED_ORGANIZATIONS,
  payload: linkedOrganizations,
  meta: {
    orgId,
  },
});

export const setPayingOrganization = ({ payingOrganization, orgId }) => ({
  type: SET_PAYING_ORGANIZATION,
  payload: payingOrganization,
  meta: {
    orgId,
  },
});

export const setIsEligibleToBecomePayingOrg = ({ isEligibleToBecomePayingOrg, orgId }) => ({
  type: SET_IS_ELIGIBLE_TO_BECOME_PAYING_ORG,
  payload: isEligibleToBecomePayingOrg,
  meta: {
    orgId,
  },
});

export const setConsultingUnitPrice = ({ consultingUnitPrice, orgId }) => ({
  type: SET_CONSULTING_UNIT_PRICE,
  payload: consultingUnitPrice,
  meta: {
    orgId,
  },
});

export const setConsultingDepths = ({ consultingDepths, orgId }) => ({
  type: SET_CONSULTING_DEPTHS,
  payload: consultingDepths,
  meta: {
    orgId,
  },
});

export const setOrganizationSalesforceMetadata = ({ organizationSalesforceMetadata, orgId }) => ({
  type: SET_ORGANIZATION_SALESFORCE_METADATA,
  payload: organizationSalesforceMetadata,
  meta: {
    orgId,
  },
});

export const loadPlan = (orgId) => (dispatch) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ orgId: any; }' is not assignab... Remove this comment to see the full error message
  return api.billing.getPlan({ orgId }).then((response) => {
    dispatch(
      setPlan({
        plan: response,
        orgId,
      })
    );
  });
};

export const loadPrepaidPlans = (orgId) => (dispatch) => {
  return api.billing.getPrepaidPlans({ orgId }).then((response) => {
    dispatch(
      setPrepaidPlans({
        prepaidPlans: response,
        orgId,
      })
    );
  });
};

export const loadInvoices = (orgId) => (dispatch) => {
  return api.billing.getAllInvoices({ orgId }).then((response) => {
    dispatch(invoice.setMultipleInvoices(response));

    dispatch(
      setInvoices({
        invoices: response.map((r) => r.id),
        orgId,
      })
    );
  });
};

export const loadLinkedInvoices = (orgId) => (dispatch) => {
  return api.billing.getAllLinkedInvoices({ orgId }).then((response) => {
    dispatch(invoice.setMultipleInvoices(response));

    dispatch(
      setLinkedInvoices({
        linkedInvoices: response.map((r) => r.id),
        orgId,
      })
    );
  });
};

export const loadActivePaymentMethods = (orgId, useEffectivePayingOrg) => (dispatch) => {
  return api.billing.getPaymentMethods(orgId, useEffectivePayingOrg).then((response) => {
    dispatch(
      setPaymentMethods({
        paymentMethods: response.filter((paymentMethods) => paymentMethods.status === 'ACTIVE'),
        orgId,
      })
    );
  });
};

export const loadPaymentMethods = (orgId, useEffectivePayingOrg) => (dispatch) => {
  return api.billing.getPaymentMethods(orgId, useEffectivePayingOrg).then((response) => {
    dispatch(
      setPaymentMethods({
        paymentMethods: response,
        orgId,
      })
    );
  });
};

export const loadCompanyAddress = (orgId) => (dispatch) => {
  return api.billing.getCompanyAddress({ orgId }).then((response) => {
    dispatch(
      setCompanyAddress({
        companyAddress: response,
        orgId,
      })
    );
  });
};

export const loadOrgPreferences = (orgId) => (dispatch) => {
  return api.billing.getOrgPreferences({ orgId }).then((response) => {
    dispatch(
      setOrgPreferences({
        orgPreferences: response,
        orgId,
      })
    );
  });
};

export const loadPendingInvoice = (orgId) => (dispatch) => {
  return api.billing.getPendingInvoice({ orgId }).then((response) => {
    dispatch(
      setPendingInvoice({
        pendingInvoice: response,
        orgId,
      })
    );
  });
};

export const loadRecentInvoice = (orgId) => (dispatch) => {
  return api.billing.getRecentInvoice({ orgId }).then((response) => {
    dispatch(
      setRecentInvoice({
        recentInvoice: response,
        orgId,
      })
    );
  });
};

export const loadCredits =
  (orgId, filter = '') =>
  (dispatch) => {
    return api.billing.getCredits({ orgId, filter }).then((response) => {
      dispatch(
        setCredits({
          credits: response,
          orgId,
          filter,
        })
      );
    });
  };

export const loadMonthlyCommitmentCredits = (orgId) => (dispatch) => {
  return api.billing.getMonthlyCommitmentCredits({ orgId }).then((response) => {
    dispatch(
      setMonthlyCommitmentCredits({
        monthlyCommitmentCredits: response,
        orgId,
      })
    );
  });
};

export const loadDiscounts = (orgId) => (dispatch) => {
  return api.billing.getDiscounts({ orgId }).then((response) => {
    dispatch(
      setDiscounts({
        discounts: response,
        orgId,
      })
    );
  });
};

export const loadHistoricDiscounts = (orgId) => (dispatch) => {
  return api.billing.getHistoricDiscounts({ orgId }).then((response) => {
    dispatch(
      setHistoricDiscounts({
        historicDiscounts: response,
        orgId,
      })
    );
  });
};

export const loadBillingStatus = (orgId) => (dispatch) => {
  return api.billing.getBillingStatus({ orgId }).then((response) => {
    dispatch(
      setBillingStatus({
        billingStatus: response,
        orgId,
      })
    );
  });
};

export const loadUsageSummary = (orgId) => (dispatch) => {
  return api.billing.getUsageSummary({ orgId }).then((response) => {
    dispatch(
      setUsageSummary({
        usageSummary: response,
        orgId,
      })
    );
  });
};

export const loadCrossOrgTotal = (orgId) => (dispatch) => {
  return api.billing.getCrossOrgTotal({ orgId }).then((response) => {
    dispatch(
      setCrossOrgTotal({
        crossOrgTotal: response,
        orgId,
      })
    );
  });
};

export const loadBraintreeCustomer = (orgId) => (dispatch) => {
  return api.billing.getBraintreeCustomer({ orgId }).then((response) => {
    dispatch(
      setBraintreeCustomer({
        braintreeCustomer: response,
        orgId,
      })
    );
  });
};

export const loadCurrentOrgView = (orgId) => (dispatch) => {
  return api.billing.getLinkableOrgView({ orgId }).then((response) => {
    dispatch(
      setCurrentOrgView({
        currentOrgView: response,
        orgId,
      })
    );
  });
};

export const loadLinkableOrganizations = (orgId) => (dispatch) => {
  return api.billing.getLinkableOrganizations({ orgId }).then((response) => {
    dispatch(
      setLinkableOrganizations({
        linkableOrganizations: response,
        orgId,
      })
    );
  });
};

export const loadLinkedOrganizations = (orgId) => (dispatch) => {
  return api.billing.getLinkedOrganizations({ orgId }).then((response) => {
    dispatch(
      setLinkedOrganizations({
        linkedOrganizations: response,
        orgId,
      })
    );
  });
};

export const loadPayingOrganization = (orgId) => (dispatch) => {
  return api.billing.getPayingOrganization({ orgId }).then((response) => {
    dispatch(
      setPayingOrganization({
        payingOrganization: response,
        orgId,
      })
    );
  });
};

export const loadIsEligibleToBecomePayingOrg = (orgId) => (dispatch) => {
  return api.billing.isEligibleToBecomePayingOrg({ orgId }).then((response) => {
    dispatch(
      setIsEligibleToBecomePayingOrg({
        isEligibleToBecomePayingOrg: response,
        orgId,
      })
    );
  });
};

export const loadConsultingUnitPrice = (orgId) => (dispatch) => {
  return api.billing.getConsultingUnitPrice({ orgId }).then((response) => {
    dispatch(
      setConsultingUnitPrice({
        consultingUnitPrice: response,
        orgId,
      })
    );
  });
};

export const loadConsultingDepths = (orgId) => (dispatch) => {
  return api.billing.getConsultingDepths({ orgId }).then((response) => {
    dispatch(
      setConsultingDepths({
        consultingDepths: response,
        orgId,
      })
    );
  });
};

export const loadMonthlyAggregatedUsageSummary = (orgId) => (dispatch) => {
  return api.billing.getMonthlyUsageForOrg({ orgId }).then((response) => {
    dispatch(
      setMonthlyAggregatedUsageSummary({
        monthlyAggregatedUsageSummary: response,
        orgId,
      })
    );
  });
};

export const loadOrganizationSalesforceMetadata = (orgId) => (dispatch) => {
  return api.organization.canRedirectToSupportPortal({ orgId }).then((response) => {
    dispatch(
      setOrganizationSalesforceMetadata({
        organizationSalesforceMetadata: response,
        orgId,
      })
    );
  });
};

export const setCostExplorerFilterOptions = (params: {
  costExplorerFilterOptions: CostExplorerFilterOptions;
  orgId: string;
}) => ({
  type: SET_COST_EXPLORER_FILTER_OPTIONS,
  payload: params.costExplorerFilterOptions,
  meta: {
    orgId: params.orgId,
  },
});

export const setUsageDetailsFilterOptions = (params: {
  usageDetailsFilterOptions: UsageDetailsFilterOptions;
  orgId: string;
  invoiceId: string;
}) => ({
  type: SET_USAGE_DETAILS_FILTER_OPTIONS,
  payload: params.usageDetailsFilterOptions,
  meta: {
    orgId: params.orgId,
    invoiceId: params.invoiceId,
  },
});

export const loadCostExplorerFilterOptions = (orgId: string) => (dispatch: any) => {
  return api.billing.getCostExplorerFilterOptions(orgId).then((response) => {
    dispatch(
      setCostExplorerFilterOptions({
        costExplorerFilterOptions: response,
        orgId,
      })
    );
  });
};

export const loadUsageDetailsFilterOptions = (orgId: string, invoiceId: string) => (dispatch: any) => {
  return api.billing.getUsageDetailsFilterOptions(orgId, invoiceId).then((response) => {
    dispatch(
      setUsageDetailsFilterOptions({
        usageDetailsFilterOptions: response,
        orgId,
        invoiceId,
      })
    );
  });
};

export const setCostExplorerQueryState = (
  dispatch: any,
  params: {
    result?: CostExplorerQueryState;
    orgId: string;
  }
) => {
  dispatch({
    type: SET_COST_EXPLORER_QUERY_RESULTS,
    payload: params.result,
    meta: {
      orgId: params.orgId,
    },
  });
};

export const setUsageDetailsQueryState = (
  dispatch: any,
  params: {
    result?: UsageDetailsQueryState;
    orgId: string;
    invoiceId: string;
  }
) => {
  dispatch({
    type: SET_USAGE_DETAILS_QUERY_STATE,
    payload: params.result,
    meta: {
      orgId: params.orgId,
      invoiceId: params.invoiceId,
    },
  });
};

export const setUsageDetailsPaginationMetadata = (
  dispatch: any,
  params: {
    result?: UsageDetailsPaginationMetadata;
    orgId: string;
    invoiceId: string;
  }
) => {
  dispatch({
    type: SET_USAGE_DETAILS_PAGINATION_METADATA,
    payload: params.result,
    meta: {
      orgId: params.orgId,
      invoiceId: params.invoiceId,
    },
  });
};

// Used to retrieve the usage details out of the appropriate place in Redux.
//  This is relative to the root of organization level metadata in billing.
//  It also normalizes the state so that if fields are missing, empty structs
//  are added.
export const getStateUsageDetails = (state: any, invoiceId: string) => {
  if (!state.invoice || !state.invoice[invoiceId]) {
    return {};
  }

  state.invoice[invoiceId].usageDetails ||= {};
  return state.invoice[invoiceId].usageDetails;
};
