import Backbone from 'backbone';
import type { History } from 'react-router-dom-v5-compat-history';

let historyReference: History | null = null;
let forcePageRefresh = () => {};

export function storeHistoryReference(history: History, forcePageRefreshFn: () => unknown) {
  historyReference = history;
  forcePageRefresh = forcePageRefreshFn;
}

function normalizeHashAndSlash(path: string) {
  if (path.startsWith('#')) {
    path = path.slice(1);
  }
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  return path;
}

interface NavigateOptions {
  trigger?: boolean;
  replace?: boolean;
  pushState?: boolean;
}

/**
 * `Backbone.history.navigate` removes the leading slash from the hash before `location.hash` is updated.
 * This causes two entries to be pushed to browser's navigation history. That is, one without the leading
 * slash, and another with the leading hash.
 *
 * To workaround this issue until the codebase is migrated off Backbone, update `location.hash` directly,
 * and load the URL, so the backbone router triggers the handler for the new route.
 *
 * @param fragment The fragment to update.
 * @returns Whether a Backbone route has been matched.
 */
function navigateUsingBackbone(fragment: string, options: NavigateOptions): boolean {
  if (!Backbone.History.started) {
    return false;
  }
  if (Backbone.history.getHash() === fragment) {
    return false;
  }

  const href = location.href.replace(/(javascript:|#).*$/, '');
  const url = `${href}#${fragment}`;

  // If pushState is available, we use it to set the fragment as a real URL.
  const history = window.history;
  if (options.pushState && !!history.pushState) {
    history[options.replace ? 'replaceState' : 'pushState']({}, document.title, url);

    // Update the hash location, either replacing the current entry, or adding a new one to the browser history.
  } else {
    const location = window.location;
    if (options.replace) {
      const href = location.href.replace(/(javascript:|#).*$/, '');
      location.replace(`${href}#${fragment}`);
    } else {
      location.hash = `#${fragment}`;
    }
  }

  if (options.trigger) {
    return Backbone.history.loadUrl(fragment);
  }
  return false;
}

const backboneHistoryShim = {
  navigate(fragment: string, options: NavigateOptions = {}) {
    // Add slash to the beginning of any passed-in fragment
    fragment = normalizeHashAndSlash(fragment);

    if (historyReference == null) {
      // Probably in admin that's not migrated to React Router yet.
      // Fall back to still using Backbone history
      return navigateUsingBackbone(fragment, options);
    }

    if (options.replace) {
      historyReference.replace(fragment);
    } else {
      historyReference.push(fragment);
    }
    // NOTE: React router doesn't seem to have an equivalent for trigger: false, which as far as I can tell
    // would change the hash without actually rendering the new page. It seems like React Router
    // and React in general will be smart enough to not unmount/mount a page if its hash still matches, so we might
    // just not need the trigger behavior? It's something to keep an eye on, though, if things start acting weird.
  },

  getFragment() {
    if (historyReference == null) {
      // Probably in admin that's not migrated to React Router yet.
      // Fall back to still using Backbone history
      return Backbone.history.getFragment();
    }
    return historyReference.location.pathname.slice(1) + historyReference.location.search;
  },

  /** Copied from Backbone.history.getHash */
  getHash() {
    const match = window.location.href.match(/#(.*)$/);
    return match ? match[1] : '';
  },

  loadUrl(url: string) {
    // Add slash to the beginning of any passed-in url
    // NOTE: For some reason, if Backbone history gets *both* the hash and the leading slash, it will only
    // strip off one of them. So this normalization step also helps avoid running into issues caused by that,
    // even if we're just passing it through to Backbone.history.
    url = normalizeHashAndSlash(url);

    if (historyReference == null) {
      // Probably in admin that's not migrated to React Router yet.
      // Fall back to still using Backbone history
      return Backbone.history.loadUrl(url);
    }

    if (url === normalizeHashAndSlash(backboneHistoryShim.getFragment())) {
      // For refreshing the same url as the current one, we need to force React to re-render
      forcePageRefresh();
    } else {
      // This isn't a direct equivalent to loadUrl, but it's close enough on cases that aren't a refresh
      historyReference.replace(url);
    }
  },

  get location() {
    return window.location;
  },

  get fragment() {
    if (historyReference == null) {
      // Probably in admin that's not migrated to React Router yet.
      // Fall back to still using Backbone history
      return Backbone.history.fragment;
    }
    return backboneHistoryShim.getFragment();
  },
};
export default backboneHistoryShim;
